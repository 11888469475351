import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  ArrayType: { input: any; output: any };
  /** The `DateTime` scalar type represents a point in time. */
  DateTime: { input: any; output: any };
  /** The `Number` scalar type represents a number that can be a float, an integer or a null value. */
  Number: { input: any; output: any };
  /** The `QueryArgument` scalar type represents a value to be using in Craft element queries. It can be an integer, a string, or a boolean value. */
  QueryArgument: { input: any; output: any };
};

export type Address = AddressInterface &
  ElementInterface & {
    __typename?: 'Address';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** First line of the address */
    addressLine1?: Maybe<Scalars['String']['output']>;
    /** Second line of the address */
    addressLine2?: Maybe<Scalars['String']['output']>;
    /** Administrative area. */
    administrativeArea?: Maybe<Scalars['String']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** Two-letter country code */
    countryCode: Scalars['String']['output'];
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Dependent locality */
    dependentLocality?: Maybe<Scalars['String']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The first name on the address. */
    firstName?: Maybe<Scalars['String']['output']>;
    /** The full name on the address. */
    fullName?: Maybe<Scalars['String']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The last name on the address. */
    lastName?: Maybe<Scalars['String']['output']>;
    /** Latitude */
    latitude?: Maybe<Scalars['String']['output']>;
    /** Locality */
    locality?: Maybe<Scalars['String']['output']>;
    /** Longitude */
    longitude?: Maybe<Scalars['String']['output']>;
    /** Organization name */
    organization?: Maybe<Scalars['String']['output']>;
    /** Organization tax ID */
    organizationTaxId?: Maybe<Scalars['String']['output']>;
    /** Postal code */
    postalCode?: Maybe<Scalars['String']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** Sorting code */
    sortingCode?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
  };

export type Address_CountArgs = {
  field: Scalars['String']['input'];
};

/** This is the interface implemented by all addresses. */
export type AddressInterface = {
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']['output']>;
  /** First line of the address */
  addressLine1?: Maybe<Scalars['String']['output']>;
  /** Second line of the address */
  addressLine2?: Maybe<Scalars['String']['output']>;
  /** Administrative area. */
  administrativeArea?: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived?: Maybe<Scalars['Boolean']['output']>;
  /** Two-letter country code */
  countryCode: Scalars['String']['output'];
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']['output']>;
  /** Dependent locality */
  dependentLocality?: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled?: Maybe<Scalars['Boolean']['output']>;
  /** The first name on the address. */
  firstName?: Maybe<Scalars['String']['output']>;
  /** The full name on the address. */
  fullName?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']['output']>;
  /** The last name on the address. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Latitude */
  latitude?: Maybe<Scalars['String']['output']>;
  /** Locality */
  locality?: Maybe<Scalars['String']['output']>;
  /** Longitude */
  longitude?: Maybe<Scalars['String']['output']>;
  /** Organization name */
  organization?: Maybe<Scalars['String']['output']>;
  /** Organization tax ID */
  organizationTaxId?: Maybe<Scalars['String']['output']>;
  /** Postal code */
  postalCode?: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle?: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']['output']>;
  /** Sorting code */
  sortingCode?: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status?: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed?: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all addresses. */
export type AddressInterface_CountArgs = {
  field: Scalars['String']['input'];
};

export type AssetCriteriaInput = {
  altText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  caption?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  credits?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the assets’ files’ last-modified dates. */
  dateModified?: InputMaybe<Scalars['String']['input']>;
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalUrl?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  /** Narrows the query results based on the assets’ filenames. */
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the folders the assets belong to, per the folders’ IDs. */
  folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on whether the assets have alternative text. */
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the assets’ image heights. */
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ IDs. */
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Causes the query results to be returned in reverse order. */
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  /** Broadens the query results to include assets from any of the subfolders of the folder specified by `folderId`. */
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the assets’ file kinds. */
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines which site(s) the elements should be queried in, based on their language. */
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Sets the limit for paginated results. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  mux?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Sets the offset for paginated results. */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Sets the field the returned elements should be ordered by. */
  orderBy?: InputMaybe<Scalars['String']['input']>;
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  /** Narrows the query results based on a reference string. */
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  /** Narrows the query results to only elements that match a search query. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  /** Narrows the query results based on the assets’ file sizes (in bytes). */
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ slugs. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ titles. */
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ UIDs. */
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the user the assets were uploaded by, per the user’s ID. */
  uploader?: InputMaybe<Scalars['QueryArgument']['input']>;
  /** Narrows the query results based on the elements’ URIs. */
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the volumes the assets belong to, per the volumes’ handles. */
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the volumes the assets belong to, per the volumes’ IDs. */
  volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the assets’ image widths. */
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** A list of transform handles to preload. */
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** This is the interface implemented by all assets. */
export type AssetInterface = {
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']['output']>;
  /** Alternative text for the asset. */
  alt?: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived?: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  /** The date the asset file was last modified. */
  dateModified?: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled?: Maybe<Scalars['Boolean']['output']>;
  /** The file extension for the asset file. */
  extension: Scalars['String']['output'];
  /** The filename of the asset file. */
  filename: Scalars['String']['output'];
  /** The focal point represented as an array with `x` and `y` keys, or null if it’s not an image. */
  focalPoint?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  /** The ID of the folder that the asset belongs to. */
  folderId: Scalars['Int']['output'];
  /** Returns the file’s format. */
  format?: Maybe<Scalars['String']['output']>;
  /** Whether a user-defined focal point is set on the asset. */
  hasFocalPoint: Scalars['Boolean']['output'];
  /** The height in pixels or null if it’s not an image. */
  height?: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Returns a list of images produced from the named Imager X transform. */
  imagerTransform?: Maybe<Array<Maybe<ImagerTransformedImageInterface>>>;
  /** An `<img>` tag based on this asset. */
  img?: Maybe<Scalars['String']['output']>;
  /** The file kind. */
  kind: Scalars['String']['output'];
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']['output']>;
  /** The file’s MIME type, if it can be determined. */
  mimeType?: Maybe<Scalars['String']['output']>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<AssetInterface>;
  /** The asset’s path in the volume. */
  path: Scalars['String']['output'];
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<AssetInterface>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle?: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']['output']>;
  /** The file size in bytes. */
  size?: Maybe<Scalars['String']['output']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']['output']>;
  /** Returns a `srcset` attribute value based on the given widths or x-descriptors. */
  srcset?: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status?: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed?: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']['output']>;
  /** The full URL of the asset. This field accepts the same fields as the `transform` directive. */
  url?: Maybe<Scalars['String']['output']>;
  /** The ID of the volume that the asset belongs to. */
  volumeId?: Maybe<Scalars['Int']['output']>;
  /** The width in pixels or null if it’s not an image. */
  width?: Maybe<Scalars['Int']['output']>;
};

/** This is the interface implemented by all assets. */
export type AssetInterface_CountArgs = {
  field: Scalars['String']['input'];
};

/** This is the interface implemented by all assets. */
export type AssetInterfaceFormatArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
  handle?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  immediately?: InputMaybe<Scalars['Boolean']['input']>;
  interlace?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  transform?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** This is the interface implemented by all assets. */
export type AssetInterfaceHeightArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
  handle?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  immediately?: InputMaybe<Scalars['Boolean']['input']>;
  interlace?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  transform?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** This is the interface implemented by all assets. */
export type AssetInterfaceImagerTransformArgs = {
  transform?: InputMaybe<Scalars['String']['input']>;
};

/** This is the interface implemented by all assets. */
export type AssetInterfaceNextArgs = {
  altText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  caption?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  credits?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified?: InputMaybe<Scalars['String']['input']>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalUrl?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mux?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uploader?: InputMaybe<Scalars['QueryArgument']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** This is the interface implemented by all assets. */
export type AssetInterfacePrevArgs = {
  altText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  caption?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  credits?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified?: InputMaybe<Scalars['String']['input']>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalUrl?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mux?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uploader?: InputMaybe<Scalars['QueryArgument']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** This is the interface implemented by all assets. */
export type AssetInterfaceSrcsetArgs = {
  sizes: Array<Scalars['String']['input']>;
};

/** This is the interface implemented by all assets. */
export type AssetInterfaceUrlArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
  handle?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  immediately?: InputMaybe<Scalars['Boolean']['input']>;
  interlace?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  transform?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** This is the interface implemented by all assets. */
export type AssetInterfaceWidthArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
  handle?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  immediately?: InputMaybe<Scalars['Boolean']['input']>;
  interlace?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  transform?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type CategoryCriteriaInput = {
  /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `ancestorOf`. */
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  /** Narrows the query results to only elements that are ancestors of another element in its structure, provided by its ID. */
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `descendantOf`. */
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  /** Narrows the query results to only elements that are descendants of another element in its structure provided by its ID. */
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  /** Whether to only return categories that the user has permission to edit. */
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the category groups the categories belong to per the group’s handles. */
  group?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the category groups the categories belong to, per the groups’ IDs. */
  groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on whether the elements have any descendants in their structure. */
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the elements’ IDs. */
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Causes the query results to be returned in reverse order. */
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  /** Determines which site(s) the elements should be queried in, based on their language. */
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on whether the elements are “leaves” in their structure (element with no descendants). */
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the elements’ level within the structure. */
  level?: InputMaybe<Scalars['Int']['input']>;
  /** Sets the limit for paginated results. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Narrows the query results to only the entry that comes immediately after another element in its structure, provided by its ID. */
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  /** Sets the offset for paginated results. */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Sets the field the returned elements should be ordered by. */
  orderBy?: InputMaybe<Scalars['String']['input']>;
  /** Narrows the query results to only entries that are positioned after another element in its structure, provided by its ID. */
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  /** Narrows the query results to only entries that are positioned before another element in its structure, provided by its ID. */
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  /** Narrows the query results to only the entry that comes immediately before another element in its structure, provided by its ID. */
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  /** Narrows the query results based on a reference string. */
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  /** Narrows the query results to only elements that match a search query. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  /** Narrows the query results based on the elements’ slugs. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines which structure data should be joined into the query. */
  structureId?: InputMaybe<Scalars['Int']['input']>;
  /** Narrows the query results based on the elements’ titles. */
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ UIDs. */
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the elements’ URIs. */
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Explicitly determines whether the query should join in the structure data. */
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Element = ElementInterface & {
  __typename?: 'Element';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived?: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle?: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status?: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed?: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']['output']>;
};

export type Element_CountArgs = {
  field: Scalars['String']['input'];
};

/** This is the interface implemented by all elements. */
export type ElementInterface = {
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived?: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle?: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status?: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed?: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all elements. */
export type ElementInterface_CountArgs = {
  field: Scalars['String']['input'];
};

export type EntryCriteriaInput = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  /** Narrows the query results to only entries that were posted on or after a certain date. */
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `ancestorOf`. */
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  /** Narrows the query results to only elements that are ancestors of another element in its structure, provided by its ID. */
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the user group the entries’ authors belong to. */
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the user group the entries’ authors belong to, per the groups’ IDs. */
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  /** Narrows the query results based on the entries’ authors. */
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to only entries that were posted before a certain date. */
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `descendantOf`. */
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  /** Narrows the query results to only elements that are descendants of another element in its structure provided by its ID. */
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Whether to only return entries that the user has permission to edit. */
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the entries’ expiry dates. */
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on whether the elements have any descendants in their structure. */
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the elements’ IDs. */
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Causes the query results to be returned in reverse order. */
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Determines which site(s) the elements should be queried in, based on their language. */
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on whether the elements are “leaves” in their structure (element with no descendants). */
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the elements’ level within the structure. */
  level?: InputMaybe<Scalars['Int']['input']>;
  /** Sets the limit for paginated results. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to only the entry that comes immediately after another element in its structure, provided by its ID. */
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Sets the offset for paginated results. */
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  /** Sets the field the returned elements should be ordered by. */
  orderBy?: InputMaybe<Scalars['String']['input']>;
  /** Narrows the query results to only entries that are positioned after another element in its structure, provided by its ID. */
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  /** Narrows the query results to only entries that are positioned before another element in its structure, provided by its ID. */
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  /** Narrows the query results based on the entries’ post dates. */
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  /** Narrows the query results to only the entry that comes immediately before another element in its structure, provided by its ID. */
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  /** Narrows the query results based on a reference string. */
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  /** Narrows the query results to only elements that match a search query. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Narrows the query results based on the section handles the entries belong to. */
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the sections the entries belong to, per the sections’ IDs. */
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  /** Narrows the query results based on the elements’ slugs. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  /** Determines which structure data should be joined into the query. */
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  /** Narrows the query results based on the elements’ titles. */
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the entries’ entry type handles. */
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the entries’ entry types, per the types’ IDs. */
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the elements’ UIDs. */
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the elements’ URIs. */
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Explicitly determines whether the query should join in the structure data. */
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterface = {
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived?: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId?: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid?: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId?: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName?: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes?: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite?: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Returns whether this is a draft. */
  isDraft?: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision?: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft?: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate?: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId?: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes?: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  /** This query is used to query for SEOmatic meta data. */
  seomatic?: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle?: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId?: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid?: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status?: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed?: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterface_CountArgs = {
  field: Scalars['String']['input'];
};

/** This is the interface implemented by all entries. */
export type EntryInterfaceAncestorsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterfaceChildrenArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterfaceDescendantsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterfaceLocalizedArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterfaceNextArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterfaceParentArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterfacePrevArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterfaceSeomaticArgs = {
  asArray?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<SeomaticEnvironment>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type FieldAttribute = {
  __typename?: 'FieldAttribute';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all fields. */
export type FieldInterface = {
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
};

export type FieldOption = {
  __typename?: 'FieldOption';
  /** Whether this option has been marked as disabled. */
  disabled?: Maybe<Scalars['Boolean']['output']>;
  /** Whether this option has been marked as a default. */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  /** Whether this option has been marked as an `optgroup`. */
  isOptgroup?: Maybe<Scalars['Boolean']['output']>;
  /** The label of the option. */
  label?: Maybe<Scalars['String']['output']>;
  /** The value of the option. */
  value?: Maybe<Scalars['String']['output']>;
};

export type Field_Address = FieldInterface & {
  __typename?: 'Field_Address';
  address1Collapsed?: Maybe<Scalars['Boolean']['output']>;
  address1DefaultValue?: Maybe<Scalars['String']['output']>;
  address1Enabled?: Maybe<Scalars['Boolean']['output']>;
  address1ErrorMessage?: Maybe<Scalars['String']['output']>;
  address1Hidden?: Maybe<Scalars['Boolean']['output']>;
  address1Label?: Maybe<Scalars['String']['output']>;
  address1Placeholder?: Maybe<Scalars['String']['output']>;
  address1PrePopulate?: Maybe<Scalars['String']['output']>;
  address1Required?: Maybe<Scalars['Boolean']['output']>;
  address2Collapsed?: Maybe<Scalars['Boolean']['output']>;
  address2DefaultValue?: Maybe<Scalars['String']['output']>;
  address2Enabled?: Maybe<Scalars['Boolean']['output']>;
  address2ErrorMessage?: Maybe<Scalars['String']['output']>;
  address2Hidden?: Maybe<Scalars['Boolean']['output']>;
  address2Label?: Maybe<Scalars['String']['output']>;
  address2Placeholder?: Maybe<Scalars['String']['output']>;
  address2PrePopulate?: Maybe<Scalars['String']['output']>;
  address2Required?: Maybe<Scalars['Boolean']['output']>;
  address3Collapsed?: Maybe<Scalars['Boolean']['output']>;
  address3DefaultValue?: Maybe<Scalars['String']['output']>;
  address3Enabled?: Maybe<Scalars['Boolean']['output']>;
  address3ErrorMessage?: Maybe<Scalars['String']['output']>;
  address3Hidden?: Maybe<Scalars['Boolean']['output']>;
  address3Label?: Maybe<Scalars['String']['output']>;
  address3Placeholder?: Maybe<Scalars['String']['output']>;
  address3PrePopulate?: Maybe<Scalars['String']['output']>;
  address3Required?: Maybe<Scalars['Boolean']['output']>;
  autocompleteCollapsed?: Maybe<Scalars['Boolean']['output']>;
  autocompleteCurrentLocation?: Maybe<Scalars['Boolean']['output']>;
  autocompleteDefaultValue?: Maybe<Scalars['String']['output']>;
  autocompleteEnabled?: Maybe<Scalars['Boolean']['output']>;
  autocompleteErrorMessage?: Maybe<Scalars['String']['output']>;
  autocompleteIntegration?: Maybe<Scalars['String']['output']>;
  autocompleteLabel?: Maybe<Scalars['String']['output']>;
  autocompletePlaceholder?: Maybe<Scalars['String']['output']>;
  autocompletePrePopulate?: Maybe<Scalars['String']['output']>;
  autocompleteRequired?: Maybe<Scalars['Boolean']['output']>;
  cityCollapsed?: Maybe<Scalars['Boolean']['output']>;
  cityDefaultValue?: Maybe<Scalars['String']['output']>;
  cityEnabled?: Maybe<Scalars['Boolean']['output']>;
  cityErrorMessage?: Maybe<Scalars['String']['output']>;
  cityHidden?: Maybe<Scalars['Boolean']['output']>;
  cityLabel?: Maybe<Scalars['String']['output']>;
  cityPlaceholder?: Maybe<Scalars['String']['output']>;
  cityPrePopulate?: Maybe<Scalars['String']['output']>;
  cityRequired?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  countryCollapsed?: Maybe<Scalars['Boolean']['output']>;
  countryDefaultValue?: Maybe<Scalars['String']['output']>;
  countryEnabled?: Maybe<Scalars['Boolean']['output']>;
  countryErrorMessage?: Maybe<Scalars['String']['output']>;
  countryHidden?: Maybe<Scalars['Boolean']['output']>;
  countryLabel?: Maybe<Scalars['String']['output']>;
  countryOptionLabel?: Maybe<Scalars['String']['output']>;
  countryOptionValue?: Maybe<Scalars['String']['output']>;
  countryOptions?: Maybe<Array<Maybe<FieldAttribute>>>;
  countryPlaceholder?: Maybe<Scalars['String']['output']>;
  countryPrePopulate?: Maybe<Scalars['String']['output']>;
  countryRequired?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  stateCollapsed?: Maybe<Scalars['Boolean']['output']>;
  stateDefaultValue?: Maybe<Scalars['String']['output']>;
  stateEnabled?: Maybe<Scalars['Boolean']['output']>;
  stateErrorMessage?: Maybe<Scalars['String']['output']>;
  stateHidden?: Maybe<Scalars['Boolean']['output']>;
  stateLabel?: Maybe<Scalars['String']['output']>;
  statePlaceholder?: Maybe<Scalars['String']['output']>;
  statePrePopulate?: Maybe<Scalars['String']['output']>;
  stateRequired?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
  zipCollapsed?: Maybe<Scalars['Boolean']['output']>;
  zipDefaultValue?: Maybe<Scalars['String']['output']>;
  zipEnabled?: Maybe<Scalars['Boolean']['output']>;
  zipErrorMessage?: Maybe<Scalars['String']['output']>;
  zipHidden?: Maybe<Scalars['Boolean']['output']>;
  zipLabel?: Maybe<Scalars['String']['output']>;
  zipPlaceholder?: Maybe<Scalars['String']['output']>;
  zipPrePopulate?: Maybe<Scalars['String']['output']>;
  zipRequired?: Maybe<Scalars['Boolean']['output']>;
};

export type Field_Agree = FieldInterface & {
  __typename?: 'Field_Agree';
  checkedValue?: Maybe<Scalars['String']['output']>;
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  defaultState?: Maybe<Scalars['Boolean']['output']>;
  defaultValue?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionHtml?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  uncheckedValue?: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
};

export type Field_Calculations = FieldInterface & {
  __typename?: 'Field_Calculations';
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  decimals?: Maybe<Scalars['Int']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  formatting?: Maybe<Scalars['String']['output']>;
  formula?: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  prefix?: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
};

export type Field_Checkboxes = FieldInterface & {
  __typename?: 'Field_Checkboxes';
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  layout?: Maybe<Scalars['String']['output']>;
  limitOptions?: Maybe<Scalars['Boolean']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  multi?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Array<Maybe<FieldOption>>>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  toggleCheckbox?: Maybe<Scalars['String']['output']>;
  toggleCheckboxLabel?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
};

export type Field_Date = FieldInterface & {
  __typename?: 'Field_Date';
  ampmLabel?: Maybe<Scalars['String']['output']>;
  ampmPlaceholder?: Maybe<Scalars['String']['output']>;
  availableDaysOfWeek?: Maybe<Scalars['String']['output']>;
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  dateFormat?: Maybe<Scalars['String']['output']>;
  datePickerOptions?: Maybe<Array<Maybe<FieldAttribute>>>;
  dayLabel?: Maybe<Scalars['String']['output']>;
  dayPlaceholder?: Maybe<Scalars['String']['output']>;
  defaultDate?: Maybe<Scalars['DateTime']['output']>;
  defaultOption?: Maybe<Scalars['String']['output']>;
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  displayType?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  hourLabel?: Maybe<Scalars['String']['output']>;
  hourPlaceholder?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  includeDate?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  includeTime?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  maxDate?: Maybe<Scalars['DateTime']['output']>;
  maxDateOffset?: Maybe<Scalars['String']['output']>;
  maxDateOffsetNumber?: Maybe<Scalars['Int']['output']>;
  maxDateOffsetType?: Maybe<Scalars['String']['output']>;
  maxDateOption?: Maybe<Scalars['String']['output']>;
  maxYearRange?: Maybe<Scalars['Int']['output']>;
  minDate?: Maybe<Scalars['DateTime']['output']>;
  minDateOffset?: Maybe<Scalars['String']['output']>;
  minDateOffsetNumber?: Maybe<Scalars['Int']['output']>;
  minDateOffsetType?: Maybe<Scalars['String']['output']>;
  minDateOption?: Maybe<Scalars['String']['output']>;
  minYearRange?: Maybe<Scalars['Int']['output']>;
  minuteLabel?: Maybe<Scalars['String']['output']>;
  minutePlaceholder?: Maybe<Scalars['String']['output']>;
  monthLabel?: Maybe<Scalars['String']['output']>;
  monthPlaceholder?: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  secondLabel?: Maybe<Scalars['String']['output']>;
  secondPlaceholder?: Maybe<Scalars['String']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  timeFormat?: Maybe<Scalars['String']['output']>;
  timeLabel?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  useDatePicker?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
  yearLabel?: Maybe<Scalars['String']['output']>;
  yearPlaceholder?: Maybe<Scalars['String']['output']>;
};

export type Field_Dropdown = FieldInterface & {
  __typename?: 'Field_Dropdown';
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  limitOptions?: Maybe<Scalars['Boolean']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  multi?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Array<Maybe<FieldOption>>>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
};

export type Field_Email = FieldInterface & {
  __typename?: 'Field_Email';
  blockedDomains?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  uniqueValue?: Maybe<Scalars['Boolean']['output']>;
  validateDomain?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
};

export type Field_Entries = FieldInterface & {
  __typename?: 'Field_Entries';
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  defaultEntry?: Maybe<EntryInterface>;
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  displayType?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  entries?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  searchable?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
};

export type Field_EntriesDefaultEntryArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Field_EntriesEntriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Field_Group = FieldInterface & {
  __typename?: 'Field_Group';
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The field’s nested fields. */
  fields?: Maybe<Array<Maybe<FieldInterface>>>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The field’s nested rows. */
  nestedRows?: Maybe<Array<Maybe<RowInterface>>>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
};

export type Field_Heading = FieldInterface & {
  __typename?: 'Field_Heading';
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  headingSize?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
};

export type Field_Hidden = FieldInterface & {
  __typename?: 'Field_Hidden';
  columnType?: Maybe<Scalars['String']['output']>;
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  cookieName?: Maybe<Scalars['String']['output']>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  defaultOption?: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  queryParameter?: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
};

export type Field_Html = FieldInterface & {
  __typename?: 'Field_Html';
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  htmlContent?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  purifyContent?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
};

export type Field_MultiLineText = FieldInterface & {
  __typename?: 'Field_MultiLineText';
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  limit?: Maybe<Scalars['Boolean']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  max?: Maybe<Scalars['Int']['output']>;
  maxType?: Maybe<Scalars['String']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
  minType?: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  richTextButtons?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  uniqueValue?: Maybe<Scalars['Boolean']['output']>;
  useRichText?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
};

export type Field_Name = FieldInterface & {
  __typename?: 'Field_Name';
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  firstNameCollapsed?: Maybe<Scalars['Boolean']['output']>;
  firstNameDefaultValue?: Maybe<Scalars['String']['output']>;
  firstNameEnabled?: Maybe<Scalars['Boolean']['output']>;
  firstNameErrorMessage?: Maybe<Scalars['String']['output']>;
  firstNameLabel?: Maybe<Scalars['String']['output']>;
  firstNamePlaceholder?: Maybe<Scalars['String']['output']>;
  firstNamePrePopulate?: Maybe<Scalars['String']['output']>;
  firstNameRequired?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  lastNameCollapsed?: Maybe<Scalars['Boolean']['output']>;
  lastNameDefaultValue?: Maybe<Scalars['String']['output']>;
  lastNameEnabled?: Maybe<Scalars['Boolean']['output']>;
  lastNameErrorMessage?: Maybe<Scalars['String']['output']>;
  lastNameLabel?: Maybe<Scalars['String']['output']>;
  lastNamePlaceholder?: Maybe<Scalars['String']['output']>;
  lastNamePrePopulate?: Maybe<Scalars['String']['output']>;
  lastNameRequired?: Maybe<Scalars['Boolean']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  middleNameCollapsed?: Maybe<Scalars['Boolean']['output']>;
  middleNameDefaultValue?: Maybe<Scalars['String']['output']>;
  middleNameEnabled?: Maybe<Scalars['Boolean']['output']>;
  middleNameErrorMessage?: Maybe<Scalars['String']['output']>;
  middleNameLabel?: Maybe<Scalars['String']['output']>;
  middleNamePlaceholder?: Maybe<Scalars['String']['output']>;
  middleNamePrePopulate?: Maybe<Scalars['String']['output']>;
  middleNameRequired?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  prefixCollapsed?: Maybe<Scalars['Boolean']['output']>;
  prefixDefaultValue?: Maybe<Scalars['String']['output']>;
  prefixEnabled?: Maybe<Scalars['Boolean']['output']>;
  prefixErrorMessage?: Maybe<Scalars['String']['output']>;
  prefixLabel?: Maybe<Scalars['String']['output']>;
  prefixOptions?: Maybe<Array<Maybe<FieldAttribute>>>;
  prefixPlaceholder?: Maybe<Scalars['String']['output']>;
  prefixPrePopulate?: Maybe<Scalars['String']['output']>;
  prefixRequired?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  useMultipleFields?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
};

export type Field_Number = FieldInterface & {
  __typename?: 'Field_Number';
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  decimals?: Maybe<Scalars['Int']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  limit?: Maybe<Scalars['Boolean']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  max?: Maybe<Scalars['Int']['output']>;
  maxValue?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
  minValue?: Maybe<Scalars['Float']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  uniqueValue?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
};

export type Field_Password = FieldInterface & {
  __typename?: 'Field_Password';
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
};

export type Field_Payment = FieldInterface & {
  __typename?: 'Field_Payment';
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  paymentIntegration?: Maybe<Scalars['String']['output']>;
  paymentIntegrationType?: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  providerSettings?: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
};

export type Field_Phone = FieldInterface & {
  __typename?: 'Field_Phone';
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  countryAllowed?: Maybe<Scalars['String']['output']>;
  countryCollapsed?: Maybe<Scalars['Boolean']['output']>;
  countryDefaultValue?: Maybe<Scalars['String']['output']>;
  countryEnabled?: Maybe<Scalars['Boolean']['output']>;
  countryOptions?: Maybe<Array<Maybe<FieldAttribute>>>;
  countryShowDialCode?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
};

export type Field_Radio = FieldInterface & {
  __typename?: 'Field_Radio';
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  layout?: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Array<Maybe<FieldOption>>>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
};

export type Field_Recipients = FieldInterface & {
  __typename?: 'Field_Recipients';
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  displayType?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  multiple?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Array<Maybe<FieldOption>>>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
};

export type Field_Section = FieldInterface & {
  __typename?: 'Field_Section';
  borderColor?: Maybe<Scalars['String']['output']>;
  borderStyle?: Maybe<Scalars['String']['output']>;
  borderWidth?: Maybe<Scalars['Int']['output']>;
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
};

export type Field_Signature = FieldInterface & {
  __typename?: 'Field_Signature';
  backgroundColor?: Maybe<Scalars['String']['output']>;
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  penColor?: Maybe<Scalars['String']['output']>;
  penWeight?: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
};

export type Field_SingleLineText = FieldInterface & {
  __typename?: 'Field_SingleLineText';
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  limit?: Maybe<Scalars['Boolean']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  max?: Maybe<Scalars['Int']['output']>;
  maxType?: Maybe<Scalars['String']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
  minType?: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  uniqueValue?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
};

export type Field_Summary = FieldInterface & {
  __typename?: 'Field_Summary';
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
};

export type Field_Table = FieldInterface & {
  __typename?: 'Field_Table';
  addRowLabel?: Maybe<Scalars['String']['output']>;
  columnType?: Maybe<Scalars['String']['output']>;
  columns?: Maybe<Array<Maybe<KeyValueType>>>;
  /** The field’s conditions as a JSON string. */
  conditions?: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  defaults?: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition?: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField?: Maybe<Scalars['String']['output']>;
  maxRows?: Maybe<Scalars['Int']['output']>;
  minRows?: Maybe<Scalars['Int']['output']>;
  /** The field’s name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate?: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required?: Maybe<Scalars['Boolean']['output']>;
  searchable?: Maybe<Scalars['Boolean']['output']>;
  static?: Maybe<Scalars['Boolean']['output']>;
  staticRows?: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition?: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type?: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility?: Maybe<Scalars['String']['output']>;
};

/** Contents of a file */
export type FileContents = {
  __typename?: 'FileContents';
  contents?: Maybe<Scalars['String']['output']>;
  filename: Scalars['String']['output'];
};

export type FormIntegrationsType = {
  __typename?: 'FormIntegrationsType';
  /** Whether the integration is enabled. */
  enabled?: Maybe<Scalars['Boolean']['output']>;
  /** The integration’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The integration’s name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The integration’s settings as a JSON string. */
  settings?: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all forms. */
export type FormInterface = {
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived?: Maybe<Scalars['Boolean']['output']>;
  /** A list of captcha values (name and value) to assist with spam protection. */
  captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
  /** The form’s config as JSON. */
  configJson?: Maybe<Scalars['String']['output']>;
  /** A CSRF token (name and value). */
  csrfToken?: Maybe<FormieCsrfTokenType>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled?: Maybe<Scalars['Boolean']['output']>;
  /** The form’s fields. */
  formFields?: Maybe<Array<Maybe<FieldInterface>>>;
  /** The form’s handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the form is considered available according to user checks, scheduling and more. */
  isAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']['output']>;
  /** The form’s pages. */
  pages?: Maybe<Array<Maybe<PageInterface>>>;
  /** The form’s rows. */
  rows?: Maybe<Array<Maybe<RowInterface>>>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['Int']['output']>;
  /** The form’s settings. */
  settings?: Maybe<FormSettingsType>;
  /** The handle of the site the element is associated with. */
  siteHandle?: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status?: Maybe<Scalars['String']['output']>;
  /** The form’s endpoint for sending submissions to, if using POST requests. */
  submissionEndpoint?: Maybe<Scalars['String']['output']>;
  /** The form’s GQL mutation name for submissions to use. */
  submissionMutationName?: Maybe<Scalars['String']['output']>;
  /** The form’s rendered HTML. */
  templateHtml?: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed?: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all forms. */
export type FormInterface_CountArgs = {
  field: Scalars['String']['input'];
};

/** This is the interface implemented by all forms. */
export type FormInterfaceFormFieldsArgs = {
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** This is the interface implemented by all forms. */
export type FormInterfaceTemplateHtmlArgs = {
  options?: InputMaybe<Scalars['String']['input']>;
  populateFormValues?: InputMaybe<Scalars['String']['input']>;
};

export type FormSettingsType = {
  __typename?: 'FormSettingsType';
  /** The form’s default instructions position for fields. This will be a `verbb\formie\positions` class name. */
  defaultInstructionsPosition?: Maybe<Scalars['String']['output']>;
  /** The form’s default label position for fields. This will be a `verbb\formie\positions` class name. */
  defaultLabelPosition?: Maybe<Scalars['String']['output']>;
  /** Whether to show the form’s current page title. */
  displayCurrentPageTitle?: Maybe<Scalars['Boolean']['output']>;
  /** Whether to show the form’s title. */
  displayFormTitle?: Maybe<Scalars['Boolean']['output']>;
  /** Whether to show the form’s page progress. */
  displayPageProgress?: Maybe<Scalars['Boolean']['output']>;
  /** Whether to show the form’s page tabs. */
  displayPageTabs?: Maybe<Scalars['Boolean']['output']>;
  /** The form’s submit error message. */
  errorMessageHtml?: Maybe<Scalars['String']['output']>;
  /** The form’s error message position. Either `null`, `top-form` or `bottom-form`. */
  errorMessagePosition?: Maybe<Scalars['String']['output']>;
  /** The form’s enabled integrations. */
  integrations?: Maybe<Array<Maybe<FormIntegrationsType>>>;
  /** The type of loading indicator to use. Either `spinner` or `text`. */
  loadingIndicator?: Maybe<Scalars['String']['output']>;
  /** The form’s loading indicator text. */
  loadingIndicatorText?: Maybe<Scalars['String']['output']>;
  /** The form’s progress bar position. Either `start` or `end`. */
  progressPosition?: Maybe<Scalars['String']['output']>;
  /** The form’s submit action entry (for redirection), if `submitAction` is `entry`. */
  redirectEntry?: Maybe<EntryInterface>;
  /** The form’s submit action redirect URL, resolved depending on `submitAction` being `entry` or `url`. */
  redirectUrl?: Maybe<Scalars['String']['output']>;
  /** Whether to the form should scroll to the top of the page when submitted. */
  scrollToTop?: Maybe<Scalars['Boolean']['output']>;
  /** The form’s submit action. Either `message`, `entry`, `url`, `reload`. */
  submitAction?: Maybe<Scalars['String']['output']>;
  /** Whether to hide the form’s success message. */
  submitActionFormHide?: Maybe<Scalars['Boolean']['output']>;
  /** The form’s submit success message. */
  submitActionMessageHtml?: Maybe<Scalars['String']['output']>;
  /** The form’s submit message position. Either `top-form` or `bottom-form`. */
  submitActionMessagePosition?: Maybe<Scalars['String']['output']>;
  /** The form’s submit success message timeout in seconds. */
  submitActionMessageTimeout?: Maybe<Scalars['Int']['output']>;
  /** The form’s submit redirect option (if in new tab or same tab). Either `same-tab` or `new-tab`. */
  submitActionTab?: Maybe<Scalars['String']['output']>;
  /** The form’s submit method. Either `page-reload` or `ajax`. */
  submitMethod?: Maybe<Scalars['String']['output']>;
  /** Whether to validate the form’s on focus. */
  validationOnFocus?: Maybe<Scalars['Boolean']['output']>;
  /** Whether to validate the form’s on submit. */
  validationOnSubmit?: Maybe<Scalars['Boolean']['output']>;
};

export type FormSettingsTypeRedirectEntryArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FormieCaptchaType = {
  __typename?: 'FormieCaptchaType';
  /** The captcha handle. */
  handle?: Maybe<Scalars['String']['output']>;
  /** The captcha name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The catpcha value. */
  value?: Maybe<Scalars['String']['output']>;
};

export type FormieCsrfTokenType = {
  __typename?: 'FormieCsrfTokenType';
  /** The CSRF name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The CSRF token. */
  value?: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all global sets. */
export type GlobalSetInterface = {
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived?: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled?: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the global set. */
  handle: Scalars['String']['output'];
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']['output']>;
  /** The name of the global set. */
  name: Scalars['String']['output'];
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle?: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status?: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed?: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all global sets. */
export type GlobalSetInterface_CountArgs = {
  field: Scalars['String']['input'];
};

/** This is the interface implemented by Imager X. */
export type ImagerTransformedImageInterface = {
  /** Extension of transformed image. */
  extension?: Maybe<Scalars['String']['output']>;
  /** Filename of transformed image. */
  filename?: Maybe<Scalars['String']['output']>;
  /** Height of transformed image. */
  height?: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Indicates if the transformed image is newly created. */
  isNew?: Maybe<Scalars['Boolean']['output']>;
  /** Mime type of transformed image. */
  mimeType?: Maybe<Scalars['String']['output']>;
  /** Path to transformed image. */
  path?: Maybe<Scalars['String']['output']>;
  /** Size of transformed image. */
  size?: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** URL for transformed image. */
  url?: Maybe<Scalars['String']['output']>;
  /** Width of transformed image. */
  width?: Maybe<Scalars['Int']['output']>;
};

export type KeyValueType = {
  __typename?: 'KeyValueType';
  handle?: Maybe<Scalars['String']['output']>;
  heading?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['String']['output']>;
};

/** The interface implemented by all link types. */
export type Linkit_Link = {
  __typename?: 'Linkit_Link';
  available?: Maybe<Scalars['Boolean']['output']>;
  element?: Maybe<ElementInterface>;
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  target?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  typeHandle?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all matrix blocks. */
export type MatrixBlockInterface = {
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived?: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the matrix block. */
  fieldId: Scalars['Int']['output'];
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']['output']>;
  /** The ID of the primary owner of the Matrix block. */
  primaryOwnerId: Scalars['Int']['output'];
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle?: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']['output']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']['output']>;
  /** The element’s status. */
  status?: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed?: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the matrix block’s type. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the matrix block’s type. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all matrix blocks. */
export type MatrixBlockInterface_CountArgs = {
  field: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  ping?: Maybe<Scalars['String']['output']>;
  /** Save the “Newsletter Lehrer*innen” submission. */
  save_newsletter_Submission?: Maybe<Newsletter_Submission>;
};

export type MutationSave_Newsletter_SubmissionArgs = {
  company?: InputMaybe<Scalars['String']['input']>;
  confirmPrivacy?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isIncomplete?: InputMaybe<Scalars['Boolean']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
  surName?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
};

/** This is the interface implemented by all Neo blocks. */
export type NeoBlockInterface = {
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived?: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId?: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level?: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId?: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle?: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']['output']>;
  /** The element’s status. */
  status?: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed?: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle?: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId?: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all Neo blocks. */
export type NeoBlockInterface_CountArgs = {
  field: Scalars['String']['input'];
};

export type NodeCollection = {
  __typename?: 'NodeCollection';
  /** Query nodes for this node collection. */
  nodes?: Maybe<Array<Maybe<VizyNodeInterface>>>;
  /** The raw JSON of nodes for this node collection. */
  rawNodes?: Maybe<Scalars['ArrayType']['output']>;
  /** The rendered HTML of nodes for this node collection. */
  renderHtml?: Maybe<Scalars['String']['output']>;
};

export type NodeCollectionNodesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Scalars['String']['input']>;
};

export type NodeCustomAttribute = {
  __typename?: 'NodeCustomAttribute';
  attribute?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all nodes. */
export type NodeInterface = {
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived?: Maybe<Scalars['Boolean']['output']>;
  /** The node’s children. Accepts the same arguments as the `nodes` query. */
  children?: Maybe<Array<Maybe<NodeInterface>>>;
  /** Any additional classes for the node. */
  classes?: Maybe<Scalars['String']['output']>;
  /** Any additional custom attributes for the node. */
  customAttributes?: Maybe<Array<Maybe<NodeCustomAttribute>>>;
  /** Any additional data for the node. */
  data?: Maybe<Scalars['String']['output']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']['output']>;
  /** The element the node links to. */
  element?: Maybe<ElementInterface>;
  /** The ID of the element this node is linked to. */
  elementId?: Maybe<Scalars['Int']['output']>;
  /** Whether the element is enabled. */
  enabled?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']['output']>;
  /** The handle of the navigation this node belongs to. */
  navHandle?: Maybe<Scalars['String']['output']>;
  /** The ID of the navigation this node belongs to. */
  navId?: Maybe<Scalars['Int']['output']>;
  /** The name of the navigation this node belongs to. */
  navName?: Maybe<Scalars['String']['output']>;
  /** Whether this node should open in a new window. */
  newWindow?: Maybe<Scalars['String']['output']>;
  /** The node’s URI */
  nodeUri?: Maybe<Scalars['String']['output']>;
  /** The node’s parent. */
  parent?: Maybe<NodeInterface>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle?: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status?: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed?: Maybe<Scalars['Boolean']['output']>;
  /** The type of node this is. */
  type?: Maybe<Scalars['String']['output']>;
  /** The display name for the type of node this is. */
  typeLabel?: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']['output']>;
  /** The node’s full URL */
  url?: Maybe<Scalars['String']['output']>;
  /** The URL for this navigation item. */
  urlSuffix?: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all nodes. */
export type NodeInterface_CountArgs = {
  field: Scalars['String']['input'];
};

/** This is the interface implemented by all nodes. */
export type NodeInterfaceChildrenArgs = {
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nav?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  navHandle?: InputMaybe<Scalars['String']['input']>;
  navId?: InputMaybe<Scalars['Int']['input']>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

/** This is the interface implemented by all pages. */
export type PageInterface = {
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** The page’s name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The page’s fields. */
  pageFields?: Maybe<Array<Maybe<FieldInterface>>>;
  /** The page’s rows. */
  rows?: Maybe<Array<Maybe<RowInterface>>>;
  /** The page’s settings, including buttons. */
  settings?: Maybe<PageSettingsInterface>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all pages. */
export type PageInterfacePageFieldsArgs = {
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** This is the interface implemented by all pages. */
export type PageSettingsInterface = {
  /** The page’s back button label. */
  backButtonLabel?: Maybe<Scalars['String']['output']>;
  /** The page’s button (back and submit) positions. */
  buttonsPosition?: Maybe<Scalars['String']['output']>;
  /** The page’s button (back and submit) container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The page’s button (back and submit) CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  /** Whether the page’s next button has conditions enabled, for multi-page forms. */
  enableNextButtonConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the page has conditions enabled. */
  enablePageConditions?: Maybe<Scalars['Boolean']['output']>;
  /** The page’s button (back and submit) input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The page’s conditions for whether to show the next button, for multi-page forms as a JSON string. */
  nextButtonConditions?: Maybe<Scalars['String']['output']>;
  /** The page’s conditions as a JSON string. */
  pageConditions?: Maybe<Scalars['String']['output']>;
  /** The page’s draft button label. */
  saveButtonLabel?: Maybe<Scalars['String']['output']>;
  /** Whether to show the page’s back button. */
  showBackButton?: Maybe<Scalars['Boolean']['output']>;
  /** Whether to show the page’s draft button. */
  showSaveButton?: Maybe<Scalars['Boolean']['output']>;
  /** The page’s submit button label. */
  submitButtonLabel?: Maybe<Scalars['String']['output']>;
};

export type PageSettingsType = PageSettingsInterface & {
  __typename?: 'PageSettingsType';
  /** The page’s back button label. */
  backButtonLabel?: Maybe<Scalars['String']['output']>;
  /** The page’s button (back and submit) positions. */
  buttonsPosition?: Maybe<Scalars['String']['output']>;
  /** The page’s button (back and submit) container attributes. */
  containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The page’s button (back and submit) CSS classes. */
  cssClasses?: Maybe<Scalars['String']['output']>;
  /** Whether the page’s next button has conditions enabled, for multi-page forms. */
  enableNextButtonConditions?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the page has conditions enabled. */
  enablePageConditions?: Maybe<Scalars['Boolean']['output']>;
  /** The page’s button (back and submit) input attributes. */
  inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The page’s conditions for whether to show the next button, for multi-page forms as a JSON string. */
  nextButtonConditions?: Maybe<Scalars['String']['output']>;
  /** The page’s conditions as a JSON string. */
  pageConditions?: Maybe<Scalars['String']['output']>;
  /** The page’s draft button label. */
  saveButtonLabel?: Maybe<Scalars['String']['output']>;
  /** Whether to show the page’s back button. */
  showBackButton?: Maybe<Scalars['Boolean']['output']>;
  /** Whether to show the page’s draft button. */
  showSaveButton?: Maybe<Scalars['Boolean']['output']>;
  /** The page’s submit button label. */
  submitButtonLabel?: Maybe<Scalars['String']['output']>;
};

export type PageType = PageInterface & {
  __typename?: 'PageType';
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** The page’s name. */
  name?: Maybe<Scalars['String']['output']>;
  /** The page’s fields. */
  pageFields?: Maybe<Array<Maybe<FieldInterface>>>;
  /** The page’s rows. */
  rows?: Maybe<Array<Maybe<RowInterface>>>;
  /** The page’s settings, including buttons. */
  settings?: Maybe<PageSettingsInterface>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
};

export type PageTypePageFieldsArgs = {
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Query = {
  __typename?: 'Query';
  /** Entries within the accessibilityFeatures section. */
  accessibilityFeaturesEntries?: Maybe<
    Array<Maybe<AccessibilityFeaturesSectionEntryUnion>>
  >;
  /** Entries within the ageGroups section. */
  ageGroupsEntries?: Maybe<Array<Maybe<AgeGroupsSectionEntryUnion>>>;
  /** This query is used to query for a single asset. */
  asset?: Maybe<AssetInterface>;
  /** This query is used to return the number of assets. */
  assetCount: Scalars['Int']['output'];
  /** This query is used to query for assets. */
  assets?: Maybe<Array<Maybe<AssetInterface>>>;
  /** Entries within the audiences section. */
  audiencesEntries?: Maybe<Array<Maybe<AudiencesSectionEntryUnion>>>;
  /** Entries within the badges section. */
  badgesEntries?: Maybe<Array<Maybe<BadgesSectionEntryUnion>>>;
  /** Entries within the countries section. */
  countriesEntries?: Maybe<Array<Maybe<CountriesSectionEntryUnion>>>;
  /** This query is used to query for entries. */
  entries?: Maybe<Array<Maybe<EntryInterface>>>;
  /** This query is used to query for a single entry. */
  entry?: Maybe<EntryInterface>;
  /** This query is used to return the number of entries. */
  entryCount: Scalars['Int']['output'];
  /** Entries within the exhibitions section. */
  exhibitionsEntries?: Maybe<Array<Maybe<ExhibitionsSectionEntryUnion>>>;
  /** This query is used to query for a single form. */
  formieForm?: Maybe<FormInterface>;
  /** This query is used to return the number of forms. */
  formieFormCount: Scalars['Int']['output'];
  /** This query is used to query for forms. */
  formieForms?: Maybe<Array<Maybe<FormInterface>>>;
  /** This query is used to query for a single global set. */
  globalSet?: Maybe<GlobalSetInterface>;
  /** This query is used to query for global sets. */
  globalSets?: Maybe<Array<Maybe<GlobalSetInterface>>>;
  /** Entries within the goMusSettings section. */
  goMusSettingsEntries?: Maybe<Array<Maybe<GoMusSettingsSectionEntryUnion>>>;
  /** Entries within the homePage section. */
  homePageEntries?: Maybe<Array<Maybe<HomePageSectionEntryUnion>>>;
  /** This query is used to query for Imager X transforms. */
  imagerTransform?: Maybe<Array<Maybe<ImagerTransformedImageInterface>>>;
  /** Entries within the locations section. */
  locationsEntries?: Maybe<Array<Maybe<LocationsSectionEntryUnion>>>;
  /** Entries within the museums section. */
  museumsEntries?: Maybe<Array<Maybe<MuseumsSectionEntryUnion>>>;
  /** Entries within the museumsOverview section. */
  museumsOverviewEntries?: Maybe<
    Array<Maybe<MuseumsOverviewSectionEntryUnion>>
  >;
  /** This query is used to query for a single node. */
  navigationNode?: Maybe<NodeInterface>;
  /** This query is used to query for nodes. */
  navigationNodes?: Maybe<Array<Maybe<NodeInterface>>>;
  /** Entries within the newsItems section. */
  newsItemsEntries?: Maybe<Array<Maybe<NewsItemsSectionEntryUnion>>>;
  /** Entries within the offerCategories section. */
  offerCategoriesEntries?: Maybe<
    Array<Maybe<OfferCategoriesSectionEntryUnion>>
  >;
  /** Entries within the offerLanguages section. */
  offerLanguagesEntries?: Maybe<Array<Maybe<OfferLanguagesSectionEntryUnion>>>;
  /** Entries within the offers section. */
  offersEntries?: Maybe<Array<Maybe<OffersSectionEntryUnion>>>;
  /** Entries within the offersOverview section. */
  offersOverviewEntries?: Maybe<Array<Maybe<OffersOverviewSectionEntryUnion>>>;
  /** Entries within the pages section. */
  pagesEntries?: Maybe<Array<Maybe<PagesSectionEntryUnion>>>;
  ping?: Maybe<Scalars['String']['output']>;
  /** Entries within the proposalCategories section. */
  proposalCategoriesEntries?: Maybe<
    Array<Maybe<ProposalCategoriesSectionEntryUnion>>
  >;
  /**
   * This query is used to query for Retour redirects.
   * @deprecated This query is deprecated and will be removed in the future. You should use `retourResolveRedirect` instead.
   */
  retour?: Maybe<RetourInterface>;
  /** This query is used to query for all Retour redirects for a site. */
  retourRedirects?: Maybe<Array<Maybe<RetourInterface>>>;
  /** This query is used to query for Retour redirects. */
  retourResolveRedirect?: Maybe<RetourInterface>;
  /** Entries within the schoolGrades section. */
  schoolGradesEntries?: Maybe<Array<Maybe<SchoolGradesSectionEntryUnion>>>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic?: Maybe<SeomaticInterface>;
  /** Entries within the siteInfo section. */
  siteInfoEntries?: Maybe<Array<Maybe<SiteInfoSectionEntryUnion>>>;
  /** Entries within the topics section. */
  topicsEntries?: Maybe<Array<Maybe<TopicsSectionEntryUnion>>>;
};

export type QueryAccessibilityFeaturesEntriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAgeGroupsEntriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAssetArgs = {
  altText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  caption?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  credits?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified?: InputMaybe<Scalars['String']['input']>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalUrl?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mux?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uploader?: InputMaybe<Scalars['QueryArgument']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryAssetCountArgs = {
  altText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  caption?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  credits?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified?: InputMaybe<Scalars['String']['input']>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalUrl?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mux?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uploader?: InputMaybe<Scalars['QueryArgument']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryAssetsArgs = {
  altText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  caption?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  credits?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified?: InputMaybe<Scalars['String']['input']>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalUrl?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mux?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uploader?: InputMaybe<Scalars['QueryArgument']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryAudiencesEntriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryBadgesEntriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCountriesEntriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryEntriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryEntryArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryEntryCountArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryExhibitionsEntriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFormieFormArgs = {
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  handle?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryFormieFormCountArgs = {
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  handle?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryFormieFormsArgs = {
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  handle?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryGlobalSetArgs = {
  confirmPrivacy?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  handle?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryGlobalSetsArgs = {
  confirmPrivacy?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  handle?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryGoMusSettingsEntriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryHomePageEntriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryImagerTransformArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  transform?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type QueryLocationsEntriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryMuseumsEntriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryMuseumsOverviewEntriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryNavigationNodeArgs = {
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nav?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  navHandle?: InputMaybe<Scalars['String']['input']>;
  navId?: InputMaybe<Scalars['Int']['input']>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryNavigationNodesArgs = {
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nav?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  navHandle?: InputMaybe<Scalars['String']['input']>;
  navId?: InputMaybe<Scalars['Int']['input']>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryNewsItemsEntriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryOfferCategoriesEntriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryOfferLanguagesEntriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryOffersEntriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryOffersOverviewEntriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPagesEntriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryProposalCategoriesEntriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryRetourArgs = {
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type QueryRetourRedirectsArgs = {
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryRetourResolveRedirectArgs = {
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySchoolGradesEntriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySeomaticArgs = {
  asArray?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<SeomaticEnvironment>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySiteInfoEntriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryTopicsEntriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

/** This is the interface implemented by Retour. */
export type RetourInterface = {
  /** The id of the Element associated with this redirect (unused/vestigial). */
  associatedElementId?: Maybe<Scalars['Int']['output']>;
  /** Whether the redirect is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']['output']>;
  /** The number of times this redirect has been hit. */
  hitCount?: Maybe<Scalars['Int']['output']>;
  /** A datetime string of when this redirect was last hit. */
  hitLastTime?: Maybe<Scalars['String']['output']>;
  /** The id of the redirect. */
  id?: Maybe<Scalars['Int']['output']>;
  /** The URL that should be redirected to. */
  redirectDestUrl?: Maybe<Scalars['String']['output']>;
  /** The http status code that should be used for the redirect. */
  redirectHttpCode?: Maybe<Scalars['Int']['output']>;
  /** Whether an `exactmatch` or `regexmatch` should be used when matching the URL. */
  redirectMatchType?: Maybe<Scalars['String']['output']>;
  /** Should the legacy URL be matched by path or by full URL? */
  redirectSrcMatch?: Maybe<Scalars['String']['output']>;
  /** The unparsed URL pattern that Retour should match. */
  redirectSrcUrl?: Maybe<Scalars['String']['output']>;
  /** The parsed URL pattern that Retour should match. */
  redirectSrcUrlParsed?: Maybe<Scalars['String']['output']>;
  /** The site handle of the redirect (or null for all sites). */
  site?: Maybe<Scalars['String']['output']>;
  /** The siteId of the redirect (0 or null for all sites). */
  siteId?: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
};

/** This entity has all the Retour fields */
export type RetourType = RetourInterface & {
  __typename?: 'RetourType';
  /** The id of the Element associated with this redirect (unused/vestigial). */
  associatedElementId?: Maybe<Scalars['Int']['output']>;
  /** Whether the redirect is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']['output']>;
  /** The number of times this redirect has been hit. */
  hitCount?: Maybe<Scalars['Int']['output']>;
  /** A datetime string of when this redirect was last hit. */
  hitLastTime?: Maybe<Scalars['String']['output']>;
  /** The id of the redirect. */
  id?: Maybe<Scalars['Int']['output']>;
  /** The URL that should be redirected to. */
  redirectDestUrl?: Maybe<Scalars['String']['output']>;
  /** The http status code that should be used for the redirect. */
  redirectHttpCode?: Maybe<Scalars['Int']['output']>;
  /** Whether an `exactmatch` or `regexmatch` should be used when matching the URL. */
  redirectMatchType?: Maybe<Scalars['String']['output']>;
  /** Should the legacy URL be matched by path or by full URL? */
  redirectSrcMatch?: Maybe<Scalars['String']['output']>;
  /** The unparsed URL pattern that Retour should match. */
  redirectSrcUrl?: Maybe<Scalars['String']['output']>;
  /** The parsed URL pattern that Retour should match. */
  redirectSrcUrlParsed?: Maybe<Scalars['String']['output']>;
  /** The site handle of the redirect (or null for all sites). */
  site?: Maybe<Scalars['String']['output']>;
  /** The siteId of the redirect (0 or null for all sites). */
  siteId?: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all rows. */
export type RowInterface = {
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** The row’s fields. */
  rowFields?: Maybe<Array<Maybe<FieldInterface>>>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all rows. */
export type RowInterfaceRowFieldsArgs = {
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RowType = RowInterface & {
  __typename?: 'RowType';
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** The row’s fields. */
  rowFields?: Maybe<Array<Maybe<FieldInterface>>>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
};

export type RowTypeRowFieldsArgs = {
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Optional - The SEOmatic environment that should be used */
export enum SeomaticEnvironment {
  /** Live production environment, with indexing enabled */
  Live = 'live',
  /** Local Development environment, with debugging enabled and indexing disabled */
  Local = 'local',
  /** Staging environment, with indexing disabled */
  Staging = 'staging',
}

export enum SeomaticFrontendTemplate {
  /** The ads.txt file */
  Ads = 'ads',
  /** The humans.txt file */
  Humans = 'humans',
  /** The robots.txt file */
  Robots = 'robots',
  /** The security.txt file */
  Security = 'security',
}

/** This is the interface implemented by SEOmatic. */
export type SeomaticInterface = {
  /**
   * The FrontendTemplateContainer SEOmatic container.
   * @deprecated This query is deprecated and will be removed in the future. You should use `frontendTemplates` instead.
   */
  frontendTemplateContainer?: Maybe<Scalars['String']['output']>;
  frontendTemplates?: Maybe<Array<Maybe<FileContents>>>;
  /** The MetaJsonLdContainer SEOmatic container. */
  metaJsonLdContainer?: Maybe<Scalars['String']['output']>;
  /** The MetaLinkContainer SEOmatic container. */
  metaLinkContainer?: Maybe<Scalars['String']['output']>;
  /** The MetaScriptContainer SEOmatic container. */
  metaScriptContainer?: Maybe<Scalars['String']['output']>;
  /** The MetaSiteVarsContainer SEOmatic container. */
  metaSiteVarsContainer?: Maybe<Scalars['String']['output']>;
  /** The MetaTagContainer SEOmatic container. */
  metaTagContainer?: Maybe<Scalars['String']['output']>;
  /** The MetaTitleContainer SEOmatic container. */
  metaTitleContainer?: Maybe<Scalars['String']['output']>;
  sitemapIndexes?: Maybe<Array<Maybe<FileContents>>>;
  sitemapStyles?: Maybe<FileContents>;
  sitemaps?: Maybe<Array<Maybe<FileContents>>>;
};

/** This is the interface implemented by SEOmatic. */
export type SeomaticInterfaceFrontendTemplatesArgs = {
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<SeomaticFrontendTemplate>;
};

/** This is the interface implemented by SEOmatic. */
export type SeomaticInterfaceSitemapIndexesArgs = {
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
};

/** This is the interface implemented by SEOmatic. */
export type SeomaticInterfaceSitemapsArgs = {
  filename?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  sourceBundleHandle?: InputMaybe<Scalars['String']['input']>;
  sourceBundleType?: InputMaybe<Scalars['String']['input']>;
};

/** This entity has all the SEOmatic fields */
export type SeomaticType = SeomaticInterface & {
  __typename?: 'SeomaticType';
  /**
   * The FrontendTemplateContainer SEOmatic container.
   * @deprecated This query is deprecated and will be removed in the future. You should use `frontendTemplates` instead.
   */
  frontendTemplateContainer?: Maybe<Scalars['String']['output']>;
  frontendTemplates?: Maybe<Array<Maybe<FileContents>>>;
  /** The MetaJsonLdContainer SEOmatic container. */
  metaJsonLdContainer?: Maybe<Scalars['String']['output']>;
  /** The MetaLinkContainer SEOmatic container. */
  metaLinkContainer?: Maybe<Scalars['String']['output']>;
  /** The MetaScriptContainer SEOmatic container. */
  metaScriptContainer?: Maybe<Scalars['String']['output']>;
  /** The MetaSiteVarsContainer SEOmatic container. */
  metaSiteVarsContainer?: Maybe<Scalars['String']['output']>;
  /** The MetaTagContainer SEOmatic container. */
  metaTagContainer?: Maybe<Scalars['String']['output']>;
  /** The MetaTitleContainer SEOmatic container. */
  metaTitleContainer?: Maybe<Scalars['String']['output']>;
  sitemapIndexes?: Maybe<Array<Maybe<FileContents>>>;
  sitemapStyles?: Maybe<FileContents>;
  sitemaps?: Maybe<Array<Maybe<FileContents>>>;
};

/** This entity has all the SEOmatic fields */
export type SeomaticTypeFrontendTemplatesArgs = {
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<SeomaticFrontendTemplate>;
};

/** This entity has all the SEOmatic fields */
export type SeomaticTypeSitemapIndexesArgs = {
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
};

/** This entity has all the SEOmatic fields */
export type SeomaticTypeSitemapsArgs = {
  filename?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  sourceBundleHandle?: InputMaybe<Scalars['String']['input']>;
  sourceBundleType?: InputMaybe<Scalars['String']['input']>;
};

/** This is the interface implemented by all submissions. */
export type SubmissionInterface = {
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived?: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** The submission’s IP Address. */
  ipAddress?: Maybe<Scalars['String']['output']>;
  /** Whether the submission is incomplete. */
  isIncomplete?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the submission is spam. */
  isSpam?: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle?: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']['output']>;
  /** The submission’s spam type. */
  spamClass?: Maybe<Scalars['String']['output']>;
  /** The submission’s spam reason. */
  spamReason?: Maybe<Scalars['String']['output']>;
  /** The submission’s status. */
  status?: Maybe<Scalars['String']['output']>;
  /** The submission’s status ID. */
  statusId?: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed?: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all submissions. */
export type SubmissionInterface_CountArgs = {
  field: Scalars['String']['input'];
};

export type TagCriteriaInput = {
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the tag groups the tags belong to per the group’s handles. */
  group?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the tag groups the tags belong to, per the groups’ IDs. */
  groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the elements’ IDs. */
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Causes the query results to be returned in reverse order. */
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  /** Determines which site(s) the elements should be queried in, based on their language. */
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Sets the limit for paginated results. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Sets the offset for paginated results. */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Sets the field the returned elements should be ordered by. */
  orderBy?: InputMaybe<Scalars['String']['input']>;
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  /** Narrows the query results based on a reference string. */
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  /** Narrows the query results to only elements that match a search query. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  /** Narrows the query results based on the elements’ slugs. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ titles. */
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ UIDs. */
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the elements’ URIs. */
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type User = ElementInterface &
  UserInterface & {
    __typename?: 'User';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** The user’s addresses. */
    addresses?: Maybe<Array<Maybe<AddressInterface>>>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The user’s email. */
    email?: Maybe<Scalars['String']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The user’s first name. */
    firstName?: Maybe<Scalars['String']['output']>;
    /** The user’s first name or username. */
    friendlyName?: Maybe<Scalars['String']['output']>;
    /** The user’s full name. */
    fullName?: Maybe<Scalars['String']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The user’s last name. */
    lastName?: Maybe<Scalars['String']['output']>;
    /** The user’s full name or username. */
    name: Scalars['String']['output'];
    /** The user’s photo. */
    photo?: Maybe<AssetInterface>;
    /** The user’s preferences. */
    preferences: Scalars['String']['output'];
    /** The user’s preferred language. */
    preferredLanguage?: Maybe<Scalars['String']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The username. */
    username?: Maybe<Scalars['String']['output']>;
  };

export type User_CountArgs = {
  field: Scalars['String']['input'];
};

export type UserAddressesArgs = {
  administrativeArea?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  countryCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UserCriteriaInput = {
  /** Narrows the query results based on whether the users have uploaded any assets. */
  assetUploaders?: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on whether the users are listed as the author of any entries. */
  authors?: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the users’ email addresses. */
  email?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the users’ first names. */
  firstName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the users’ full names. */
  fullName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the user group the users belong to. */
  group?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the user group the users belong to, per the groups’ IDs. */
  groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to only users that have (or don’t have) a user photo. */
  hasPhoto?: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the elements’ IDs. */
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Causes the query results to be returned in reverse order. */
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  /** Determines which site(s) the elements should be queried in, based on their language. */
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the users’ last names. */
  lastName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Sets the limit for paginated results. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Sets the offset for paginated results. */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Sets the field the returned elements should be ordered by. */
  orderBy?: InputMaybe<Scalars['String']['input']>;
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  /** Narrows the query results based on a reference string. */
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  /** Narrows the query results to only elements that match a search query. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  /** Narrows the query results based on the elements’ slugs. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ titles. */
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ UIDs. */
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the elements’ URIs. */
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the users’ usernames. */
  username?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** This is the interface implemented by all users. */
export type UserInterface = {
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']['output']>;
  /** The user’s addresses. */
  addresses?: Maybe<Array<Maybe<AddressInterface>>>;
  /** Whether the element is archived. */
  archived?: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']['output']>;
  /** The user’s email. */
  email?: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled?: Maybe<Scalars['Boolean']['output']>;
  /** The user’s first name. */
  firstName?: Maybe<Scalars['String']['output']>;
  /** The user’s first name or username. */
  friendlyName?: Maybe<Scalars['String']['output']>;
  /** The user’s full name. */
  fullName?: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']['output']>;
  /** The user’s last name. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The user’s full name or username. */
  name: Scalars['String']['output'];
  /** The user’s photo. */
  photo?: Maybe<AssetInterface>;
  /** The user’s preferences. */
  preferences: Scalars['String']['output'];
  /** The user’s preferred language. */
  preferredLanguage?: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle?: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status?: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed?: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']['output']>;
  /** The username. */
  username?: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all users. */
export type UserInterface_CountArgs = {
  field: Scalars['String']['input'];
};

/** This is the interface implemented by all users. */
export type UserInterfaceAddressesArgs = {
  administrativeArea?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  countryCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** This is the interface implemented by all fields. */
export type VizyMarkInterface = {
  /** The attributes for this mark. */
  attrs?: Maybe<Scalars['ArrayType']['output']>;
  /** The HTML tag used for this mark. */
  tagName?: Maybe<Scalars['String']['output']>;
  /** The mark type. */
  type?: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all fields. */
export type VizyNodeInterface = {
  /** The attributes for this node. */
  attrs?: Maybe<Scalars['ArrayType']['output']>;
  /** The content for this node. */
  content?: Maybe<Scalars['ArrayType']['output']>;
  /** The content nodes and marks for this node. */
  contentNodes?: Maybe<Array<Maybe<VizyNodeInterface>>>;
  /** The rendered HTML for this node. */
  html?: Maybe<Scalars['String']['output']>;
  /** The nested marks for this node. */
  marks?: Maybe<Array<Maybe<VizyMarkInterface>>>;
  /** The raw JSON content for this node. */
  rawNode?: Maybe<Scalars['ArrayType']['output']>;
  /** The HTML tag used for this node. */
  tagName?: Maybe<Scalars['String']['output']>;
  /** The textual content for this node. */
  text?: Maybe<Scalars['String']['output']>;
  /** The node type. */
  type?: Maybe<Scalars['String']['output']>;
};

export type VizyNode_Blockquote = VizyNodeInterface & {
  __typename?: 'VizyNode_Blockquote';
  /** The attributes for this node. */
  attrs?: Maybe<Scalars['ArrayType']['output']>;
  /** The content for this node. */
  content?: Maybe<Scalars['ArrayType']['output']>;
  /** The content nodes and marks for this node. */
  contentNodes?: Maybe<Array<Maybe<VizyNodeInterface>>>;
  /** The rendered HTML for this node. */
  html?: Maybe<Scalars['String']['output']>;
  /** The nested marks for this node. */
  marks?: Maybe<Array<Maybe<VizyMarkInterface>>>;
  /** The raw JSON content for this node. */
  rawNode?: Maybe<Scalars['ArrayType']['output']>;
  /** The HTML tag used for this node. */
  tagName?: Maybe<Scalars['String']['output']>;
  /** The textual content for this node. */
  text?: Maybe<Scalars['String']['output']>;
  /** The node type. */
  type?: Maybe<Scalars['String']['output']>;
};

export type VizyNode_BulletList = VizyNodeInterface & {
  __typename?: 'VizyNode_BulletList';
  /** The attributes for this node. */
  attrs?: Maybe<Scalars['ArrayType']['output']>;
  /** The content for this node. */
  content?: Maybe<Scalars['ArrayType']['output']>;
  /** The content nodes and marks for this node. */
  contentNodes?: Maybe<Array<Maybe<VizyNodeInterface>>>;
  /** The rendered HTML for this node. */
  html?: Maybe<Scalars['String']['output']>;
  /** The nested marks for this node. */
  marks?: Maybe<Array<Maybe<VizyMarkInterface>>>;
  /** The raw JSON content for this node. */
  rawNode?: Maybe<Scalars['ArrayType']['output']>;
  /** The HTML tag used for this node. */
  tagName?: Maybe<Scalars['String']['output']>;
  /** The textual content for this node. */
  text?: Maybe<Scalars['String']['output']>;
  /** The node type. */
  type?: Maybe<Scalars['String']['output']>;
};

export type VizyNode_CodeBlock = VizyNodeInterface & {
  __typename?: 'VizyNode_CodeBlock';
  /** The attributes for this node. */
  attrs?: Maybe<Scalars['ArrayType']['output']>;
  /** The content for this node. */
  content?: Maybe<Scalars['ArrayType']['output']>;
  /** The content nodes and marks for this node. */
  contentNodes?: Maybe<Array<Maybe<VizyNodeInterface>>>;
  /** The rendered HTML for this node. */
  html?: Maybe<Scalars['String']['output']>;
  /** The nested marks for this node. */
  marks?: Maybe<Array<Maybe<VizyMarkInterface>>>;
  /** The raw JSON content for this node. */
  rawNode?: Maybe<Scalars['ArrayType']['output']>;
  /** The HTML tag used for this node. */
  tagName?: Maybe<Scalars['String']['output']>;
  /** The textual content for this node. */
  text?: Maybe<Scalars['String']['output']>;
  /** The node type. */
  type?: Maybe<Scalars['String']['output']>;
};

export type VizyNode_HardBreak = VizyNodeInterface & {
  __typename?: 'VizyNode_HardBreak';
  /** The attributes for this node. */
  attrs?: Maybe<Scalars['ArrayType']['output']>;
  /** The content for this node. */
  content?: Maybe<Scalars['ArrayType']['output']>;
  /** The content nodes and marks for this node. */
  contentNodes?: Maybe<Array<Maybe<VizyNodeInterface>>>;
  /** The rendered HTML for this node. */
  html?: Maybe<Scalars['String']['output']>;
  /** The nested marks for this node. */
  marks?: Maybe<Array<Maybe<VizyMarkInterface>>>;
  /** The raw JSON content for this node. */
  rawNode?: Maybe<Scalars['ArrayType']['output']>;
  /** The HTML tag used for this node. */
  tagName?: Maybe<Scalars['String']['output']>;
  /** The textual content for this node. */
  text?: Maybe<Scalars['String']['output']>;
  /** The node type. */
  type?: Maybe<Scalars['String']['output']>;
};

export type VizyNode_Heading = VizyNodeInterface & {
  __typename?: 'VizyNode_Heading';
  /** The attributes for this node. */
  attrs?: Maybe<Scalars['ArrayType']['output']>;
  /** The content for this node. */
  content?: Maybe<Scalars['ArrayType']['output']>;
  /** The content nodes and marks for this node. */
  contentNodes?: Maybe<Array<Maybe<VizyNodeInterface>>>;
  /** The rendered HTML for this node. */
  html?: Maybe<Scalars['String']['output']>;
  /** The nested marks for this node. */
  marks?: Maybe<Array<Maybe<VizyMarkInterface>>>;
  /** The raw JSON content for this node. */
  rawNode?: Maybe<Scalars['ArrayType']['output']>;
  /** The HTML tag used for this node. */
  tagName?: Maybe<Scalars['String']['output']>;
  /** The textual content for this node. */
  text?: Maybe<Scalars['String']['output']>;
  /** The node type. */
  type?: Maybe<Scalars['String']['output']>;
};

export type VizyNode_HorizontalRule = VizyNodeInterface & {
  __typename?: 'VizyNode_HorizontalRule';
  /** The attributes for this node. */
  attrs?: Maybe<Scalars['ArrayType']['output']>;
  /** The content for this node. */
  content?: Maybe<Scalars['ArrayType']['output']>;
  /** The content nodes and marks for this node. */
  contentNodes?: Maybe<Array<Maybe<VizyNodeInterface>>>;
  /** The rendered HTML for this node. */
  html?: Maybe<Scalars['String']['output']>;
  /** The nested marks for this node. */
  marks?: Maybe<Array<Maybe<VizyMarkInterface>>>;
  /** The raw JSON content for this node. */
  rawNode?: Maybe<Scalars['ArrayType']['output']>;
  /** The HTML tag used for this node. */
  tagName?: Maybe<Scalars['String']['output']>;
  /** The textual content for this node. */
  text?: Maybe<Scalars['String']['output']>;
  /** The node type. */
  type?: Maybe<Scalars['String']['output']>;
};

export type VizyNode_Iframe = VizyNodeInterface & {
  __typename?: 'VizyNode_Iframe';
  /** The attributes for this node. */
  attrs?: Maybe<Scalars['ArrayType']['output']>;
  /** The content for this node. */
  content?: Maybe<Scalars['ArrayType']['output']>;
  /** The content nodes and marks for this node. */
  contentNodes?: Maybe<Array<Maybe<VizyNodeInterface>>>;
  /** The rendered HTML for this node. */
  html?: Maybe<Scalars['String']['output']>;
  /** The nested marks for this node. */
  marks?: Maybe<Array<Maybe<VizyMarkInterface>>>;
  /** The raw JSON content for this node. */
  rawNode?: Maybe<Scalars['ArrayType']['output']>;
  /** The HTML tag used for this node. */
  tagName?: Maybe<Scalars['String']['output']>;
  /** The textual content for this node. */
  text?: Maybe<Scalars['String']['output']>;
  /** The node type. */
  type?: Maybe<Scalars['String']['output']>;
};

export type VizyNode_Image = VizyNodeInterface & {
  __typename?: 'VizyNode_Image';
  /** Returns the asset element used for this image. */
  asset?: Maybe<AssetInterface>;
  /** The attributes for this node. */
  attrs?: Maybe<Scalars['ArrayType']['output']>;
  /** The content for this node. */
  content?: Maybe<Scalars['ArrayType']['output']>;
  /** The content nodes and marks for this node. */
  contentNodes?: Maybe<Array<Maybe<VizyNodeInterface>>>;
  /** The rendered HTML for this node. */
  html?: Maybe<Scalars['String']['output']>;
  /** The nested marks for this node. */
  marks?: Maybe<Array<Maybe<VizyMarkInterface>>>;
  /** The raw JSON content for this node. */
  rawNode?: Maybe<Scalars['ArrayType']['output']>;
  /** The HTML tag used for this node. */
  tagName?: Maybe<Scalars['String']['output']>;
  /** The textual content for this node. */
  text?: Maybe<Scalars['String']['output']>;
  /** The node type. */
  type?: Maybe<Scalars['String']['output']>;
};

export type VizyNode_ImageAssetArgs = {
  altText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  caption?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  credits?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified?: InputMaybe<Scalars['String']['input']>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalUrl?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mux?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uploader?: InputMaybe<Scalars['QueryArgument']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VizyNode_ListItem = VizyNodeInterface & {
  __typename?: 'VizyNode_ListItem';
  /** The attributes for this node. */
  attrs?: Maybe<Scalars['ArrayType']['output']>;
  /** The content for this node. */
  content?: Maybe<Scalars['ArrayType']['output']>;
  /** The content nodes and marks for this node. */
  contentNodes?: Maybe<Array<Maybe<VizyNodeInterface>>>;
  /** The rendered HTML for this node. */
  html?: Maybe<Scalars['String']['output']>;
  /** The nested marks for this node. */
  marks?: Maybe<Array<Maybe<VizyMarkInterface>>>;
  /** The raw JSON content for this node. */
  rawNode?: Maybe<Scalars['ArrayType']['output']>;
  /** The HTML tag used for this node. */
  tagName?: Maybe<Scalars['String']['output']>;
  /** The textual content for this node. */
  text?: Maybe<Scalars['String']['output']>;
  /** The node type. */
  type?: Maybe<Scalars['String']['output']>;
};

export type VizyNode_MediaEmbed = VizyNodeInterface & {
  __typename?: 'VizyNode_MediaEmbed';
  /** The attributes for this node. */
  attrs?: Maybe<Scalars['ArrayType']['output']>;
  /** The content for this node. */
  content?: Maybe<Scalars['ArrayType']['output']>;
  /** The content nodes and marks for this node. */
  contentNodes?: Maybe<Array<Maybe<VizyNodeInterface>>>;
  /** The rendered HTML for this node. */
  html?: Maybe<Scalars['String']['output']>;
  /** The nested marks for this node. */
  marks?: Maybe<Array<Maybe<VizyMarkInterface>>>;
  /** The raw JSON content for this node. */
  rawNode?: Maybe<Scalars['ArrayType']['output']>;
  /** The HTML tag used for this node. */
  tagName?: Maybe<Scalars['String']['output']>;
  /** The textual content for this node. */
  text?: Maybe<Scalars['String']['output']>;
  /** The node type. */
  type?: Maybe<Scalars['String']['output']>;
};

export type VizyNode_OrderedList = VizyNodeInterface & {
  __typename?: 'VizyNode_OrderedList';
  /** The attributes for this node. */
  attrs?: Maybe<Scalars['ArrayType']['output']>;
  /** The content for this node. */
  content?: Maybe<Scalars['ArrayType']['output']>;
  /** The content nodes and marks for this node. */
  contentNodes?: Maybe<Array<Maybe<VizyNodeInterface>>>;
  /** The rendered HTML for this node. */
  html?: Maybe<Scalars['String']['output']>;
  /** The nested marks for this node. */
  marks?: Maybe<Array<Maybe<VizyMarkInterface>>>;
  /** The raw JSON content for this node. */
  rawNode?: Maybe<Scalars['ArrayType']['output']>;
  /** The HTML tag used for this node. */
  tagName?: Maybe<Scalars['String']['output']>;
  /** The textual content for this node. */
  text?: Maybe<Scalars['String']['output']>;
  /** The node type. */
  type?: Maybe<Scalars['String']['output']>;
};

export type VizyNode_Paragraph = VizyNodeInterface & {
  __typename?: 'VizyNode_Paragraph';
  /** The attributes for this node. */
  attrs?: Maybe<Scalars['ArrayType']['output']>;
  /** The content for this node. */
  content?: Maybe<Scalars['ArrayType']['output']>;
  /** The content nodes and marks for this node. */
  contentNodes?: Maybe<Array<Maybe<VizyNodeInterface>>>;
  /** The rendered HTML for this node. */
  html?: Maybe<Scalars['String']['output']>;
  /** The nested marks for this node. */
  marks?: Maybe<Array<Maybe<VizyMarkInterface>>>;
  /** The raw JSON content for this node. */
  rawNode?: Maybe<Scalars['ArrayType']['output']>;
  /** The HTML tag used for this node. */
  tagName?: Maybe<Scalars['String']['output']>;
  /** The textual content for this node. */
  text?: Maybe<Scalars['String']['output']>;
  /** The node type. */
  type?: Maybe<Scalars['String']['output']>;
};

export type VizyNode_Table = VizyNodeInterface & {
  __typename?: 'VizyNode_Table';
  /** The attributes for this node. */
  attrs?: Maybe<Scalars['ArrayType']['output']>;
  /** The content for this node. */
  content?: Maybe<Scalars['ArrayType']['output']>;
  /** The content nodes and marks for this node. */
  contentNodes?: Maybe<Array<Maybe<VizyNodeInterface>>>;
  /** The rendered HTML for this node. */
  html?: Maybe<Scalars['String']['output']>;
  /** The nested marks for this node. */
  marks?: Maybe<Array<Maybe<VizyMarkInterface>>>;
  /** The raw JSON content for this node. */
  rawNode?: Maybe<Scalars['ArrayType']['output']>;
  /** The HTML tag used for this node. */
  tagName?: Maybe<Scalars['String']['output']>;
  /** The textual content for this node. */
  text?: Maybe<Scalars['String']['output']>;
  /** The node type. */
  type?: Maybe<Scalars['String']['output']>;
};

export type VizyNode_TableCell = VizyNodeInterface & {
  __typename?: 'VizyNode_TableCell';
  /** The attributes for this node. */
  attrs?: Maybe<Scalars['ArrayType']['output']>;
  /** The content for this node. */
  content?: Maybe<Scalars['ArrayType']['output']>;
  /** The content nodes and marks for this node. */
  contentNodes?: Maybe<Array<Maybe<VizyNodeInterface>>>;
  /** The rendered HTML for this node. */
  html?: Maybe<Scalars['String']['output']>;
  /** The nested marks for this node. */
  marks?: Maybe<Array<Maybe<VizyMarkInterface>>>;
  /** The raw JSON content for this node. */
  rawNode?: Maybe<Scalars['ArrayType']['output']>;
  /** The HTML tag used for this node. */
  tagName?: Maybe<Scalars['String']['output']>;
  /** The textual content for this node. */
  text?: Maybe<Scalars['String']['output']>;
  /** The node type. */
  type?: Maybe<Scalars['String']['output']>;
};

export type VizyNode_TableHeader = VizyNodeInterface & {
  __typename?: 'VizyNode_TableHeader';
  /** The attributes for this node. */
  attrs?: Maybe<Scalars['ArrayType']['output']>;
  /** The content for this node. */
  content?: Maybe<Scalars['ArrayType']['output']>;
  /** The content nodes and marks for this node. */
  contentNodes?: Maybe<Array<Maybe<VizyNodeInterface>>>;
  /** The rendered HTML for this node. */
  html?: Maybe<Scalars['String']['output']>;
  /** The nested marks for this node. */
  marks?: Maybe<Array<Maybe<VizyMarkInterface>>>;
  /** The raw JSON content for this node. */
  rawNode?: Maybe<Scalars['ArrayType']['output']>;
  /** The HTML tag used for this node. */
  tagName?: Maybe<Scalars['String']['output']>;
  /** The textual content for this node. */
  text?: Maybe<Scalars['String']['output']>;
  /** The node type. */
  type?: Maybe<Scalars['String']['output']>;
};

export type VizyNode_TableRow = VizyNodeInterface & {
  __typename?: 'VizyNode_TableRow';
  /** The attributes for this node. */
  attrs?: Maybe<Scalars['ArrayType']['output']>;
  /** The content for this node. */
  content?: Maybe<Scalars['ArrayType']['output']>;
  /** The content nodes and marks for this node. */
  contentNodes?: Maybe<Array<Maybe<VizyNodeInterface>>>;
  /** The rendered HTML for this node. */
  html?: Maybe<Scalars['String']['output']>;
  /** The nested marks for this node. */
  marks?: Maybe<Array<Maybe<VizyMarkInterface>>>;
  /** The raw JSON content for this node. */
  rawNode?: Maybe<Scalars['ArrayType']['output']>;
  /** The HTML tag used for this node. */
  tagName?: Maybe<Scalars['String']['output']>;
  /** The textual content for this node. */
  text?: Maybe<Scalars['String']['output']>;
  /** The node type. */
  type?: Maybe<Scalars['String']['output']>;
};

export type VizyNode_Text = VizyNodeInterface & {
  __typename?: 'VizyNode_Text';
  /** The attributes for this node. */
  attrs?: Maybe<Scalars['ArrayType']['output']>;
  /** The content for this node. */
  content?: Maybe<Scalars['ArrayType']['output']>;
  /** The content nodes and marks for this node. */
  contentNodes?: Maybe<Array<Maybe<VizyNodeInterface>>>;
  /** The rendered HTML for this node. */
  html?: Maybe<Scalars['String']['output']>;
  /** The nested marks for this node. */
  marks?: Maybe<Array<Maybe<VizyMarkInterface>>>;
  /** The raw JSON content for this node. */
  rawNode?: Maybe<Scalars['ArrayType']['output']>;
  /** The HTML tag used for this node. */
  tagName?: Maybe<Scalars['String']['output']>;
  /** The textual content for this node. */
  text?: Maybe<Scalars['String']['output']>;
  /** The node type. */
  type?: Maybe<Scalars['String']['output']>;
};

export type AccessibilityFeaturesSectionEntryUnion =
  AccessibilityFeatures_AccessibilityFeature_Entry;

export type AccessibilityFeatures_AccessibilityFeature_Entry =
  ElementInterface &
    EntryInterface & {
      __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
      /** Return a number of related elements for a field. */
      _count?: Maybe<Scalars['Int']['output']>;
      /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
      ancestors: Array<EntryInterface>;
      /** Whether the element is archived. */
      archived?: Maybe<Scalars['Boolean']['output']>;
      /** Returns the entry’s canonical ID. */
      canonicalId?: Maybe<Scalars['Int']['output']>;
      /** Returns the entry’s canonical UUID. */
      canonicalUid?: Maybe<Scalars['String']['output']>;
      /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
      children: Array<EntryInterface>;
      /** The date the element was created. */
      dateCreated?: Maybe<Scalars['DateTime']['output']>;
      /** The date the element was last updated. */
      dateUpdated?: Maybe<Scalars['DateTime']['output']>;
      /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
      descendants: Array<EntryInterface>;
      /** The draft ID (from the `drafts` table). */
      draftId?: Maybe<Scalars['Int']['output']>;
      /** The name of the draft. */
      draftName?: Maybe<Scalars['String']['output']>;
      /** The notes for the draft. */
      draftNotes?: Maybe<Scalars['String']['output']>;
      /** Whether the element is enabled. */
      enabled?: Maybe<Scalars['Boolean']['output']>;
      /** Whether the element is enabled for the site. */
      enabledForSite?: Maybe<Scalars['Boolean']['output']>;
      /** The expiry date of the entry. */
      expiryDate?: Maybe<Scalars['DateTime']['output']>;
      externalData?: Maybe<Scalars['String']['output']>;
      externalId?: Maybe<Scalars['String']['output']>;
      icon?: Maybe<Icon_Icon>;
      /** The ID of the entity */
      id?: Maybe<Scalars['ID']['output']>;
      /** Returns whether this is a draft. */
      isDraft?: Maybe<Scalars['Boolean']['output']>;
      /** Returns whether this is a revision. */
      isRevision?: Maybe<Scalars['Boolean']['output']>;
      /** Returns whether this is an unpublished draft. */
      isUnpublishedDraft?: Maybe<Scalars['Boolean']['output']>;
      /** The language of the site element is associated with. */
      language?: Maybe<Scalars['String']['output']>;
      /** The element’s level within its structure */
      level?: Maybe<Scalars['Int']['output']>;
      /** The element’s left position within its structure. */
      lft?: Maybe<Scalars['Int']['output']>;
      /** The same element in other locales. */
      localized: Array<EntryInterface>;
      /** Returns the next element relative to this one, from a given set of criteria. */
      next?: Maybe<EntryInterface>;
      /** The entry’s parent, if the section is a structure. */
      parent?: Maybe<EntryInterface>;
      /** The entry’s post date. */
      postDate?: Maybe<Scalars['DateTime']['output']>;
      /** Returns the previous element relative to this one, from a given set of criteria. */
      prev?: Maybe<EntryInterface>;
      /** The revision ID (from the `revisions` table). */
      revisionId?: Maybe<Scalars['Int']['output']>;
      /** The revision notes (from the `revisions` table). */
      revisionNotes?: Maybe<Scalars['String']['output']>;
      /** The element’s right position within its structure. */
      rgt?: Maybe<Scalars['Int']['output']>;
      /** The element’s structure’s root ID */
      root?: Maybe<Scalars['Int']['output']>;
      /** The element’s search score, if the `search` parameter was used when querying for the element. */
      searchScore?: Maybe<Scalars['Int']['output']>;
      /** The handle of the section that contains the entry. */
      sectionHandle: Scalars['String']['output'];
      /** The ID of the section that contains the entry. */
      sectionId: Scalars['Int']['output'];
      seoSettings?: Maybe<Scalars['String']['output']>;
      /** This query is used to query for SEOmatic meta data. */
      seomatic?: Maybe<SeomaticInterface>;
      shortLink?: Maybe<Scalars['String']['output']>;
      /** The handle of the site the element is associated with. */
      siteHandle?: Maybe<Scalars['String']['output']>;
      /** The ID of the site the element is associated with. */
      siteId?: Maybe<Scalars['Int']['output']>;
      /** The unique identifier for an element-site relation. */
      siteSettingsId?: Maybe<Scalars['ID']['output']>;
      /** The element’s slug. */
      slug?: Maybe<Scalars['String']['output']>;
      /**
       * Returns the entry’s canonical ID.
       * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
       */
      sourceId?: Maybe<Scalars['Int']['output']>;
      /**
       * Returns the entry’s canonical UUID.
       * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
       */
      sourceUid?: Maybe<Scalars['String']['output']>;
      /** The element’s status. */
      status?: Maybe<Scalars['String']['output']>;
      /** The element’s structure ID. */
      structureId?: Maybe<Scalars['Int']['output']>;
      /** The element’s title. */
      title?: Maybe<Scalars['String']['output']>;
      /** Whether the element has been soft-deleted. */
      trashed?: Maybe<Scalars['Boolean']['output']>;
      /** The handle of the entry type that contains the entry. */
      typeHandle: Scalars['String']['output'];
      /** The ID of the entry type that contains the entry. */
      typeId: Scalars['Int']['output'];
      /** The UID of the entity */
      uid?: Maybe<Scalars['String']['output']>;
      /** The element’s URI. */
      uri?: Maybe<Scalars['String']['output']>;
      /** The element’s full URL */
      url?: Maybe<Scalars['String']['output']>;
    };

export type AccessibilityFeatures_AccessibilityFeature_Entry_CountArgs = {
  field: Scalars['String']['input'];
};

export type AccessibilityFeatures_AccessibilityFeature_EntryAncestorsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccessibilityFeatures_AccessibilityFeature_EntryChildrenArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccessibilityFeatures_AccessibilityFeature_EntryDescendantsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccessibilityFeatures_AccessibilityFeature_EntryLocalizedArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccessibilityFeatures_AccessibilityFeature_EntryNextArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccessibilityFeatures_AccessibilityFeature_EntryParentArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccessibilityFeatures_AccessibilityFeature_EntryPrevArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccessibilityFeatures_AccessibilityFeature_EntrySeomaticArgs = {
  asArray?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<SeomaticEnvironment>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type AgeGroupsSectionEntryUnion = AgeGroups_AgeGroup_Entry;

export type AgeGroups_AgeGroup_Entry = ElementInterface &
  EntryInterface & {
    __typename?: 'ageGroups_ageGroup_Entry';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
    ancestors: Array<EntryInterface>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** Returns the entry’s canonical ID. */
    canonicalId?: Maybe<Scalars['Int']['output']>;
    /** Returns the entry’s canonical UUID. */
    canonicalUid?: Maybe<Scalars['String']['output']>;
    /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
    children: Array<EntryInterface>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
    descendants: Array<EntryInterface>;
    /** The draft ID (from the `drafts` table). */
    draftId?: Maybe<Scalars['Int']['output']>;
    /** The name of the draft. */
    draftName?: Maybe<Scalars['String']['output']>;
    /** The notes for the draft. */
    draftNotes?: Maybe<Scalars['String']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the element is enabled for the site. */
    enabledForSite?: Maybe<Scalars['Boolean']['output']>;
    /** The expiry date of the entry. */
    expiryDate?: Maybe<Scalars['DateTime']['output']>;
    externalData?: Maybe<Scalars['String']['output']>;
    externalId?: Maybe<Scalars['String']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** Returns whether this is a draft. */
    isDraft?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is a revision. */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is an unpublished draft. */
    isUnpublishedDraft?: Maybe<Scalars['Boolean']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The same element in other locales. */
    localized: Array<EntryInterface>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<EntryInterface>;
    /** The entry’s parent, if the section is a structure. */
    parent?: Maybe<EntryInterface>;
    /** The entry’s post date. */
    postDate?: Maybe<Scalars['DateTime']['output']>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<EntryInterface>;
    /** The revision ID (from the `revisions` table). */
    revisionId?: Maybe<Scalars['Int']['output']>;
    /** The revision notes (from the `revisions` table). */
    revisionNotes?: Maybe<Scalars['String']['output']>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the section that contains the entry. */
    sectionHandle: Scalars['String']['output'];
    /** The ID of the section that contains the entry. */
    sectionId: Scalars['Int']['output'];
    seoSettings?: Maybe<Scalars['String']['output']>;
    /** This query is used to query for SEOmatic meta data. */
    seomatic?: Maybe<SeomaticInterface>;
    shortLink?: Maybe<Scalars['String']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /**
     * Returns the entry’s canonical ID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
     */
    sourceId?: Maybe<Scalars['Int']['output']>;
    /**
     * Returns the entry’s canonical UUID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
     */
    sourceUid?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the entry type that contains the entry. */
    typeHandle: Scalars['String']['output'];
    /** The ID of the entry type that contains the entry. */
    typeId: Scalars['Int']['output'];
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The element’s full URL */
    url?: Maybe<Scalars['String']['output']>;
  };

export type AgeGroups_AgeGroup_Entry_CountArgs = {
  field: Scalars['String']['input'];
};

export type AgeGroups_AgeGroup_EntryAncestorsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AgeGroups_AgeGroup_EntryChildrenArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AgeGroups_AgeGroup_EntryDescendantsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AgeGroups_AgeGroup_EntryLocalizedArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AgeGroups_AgeGroup_EntryNextArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AgeGroups_AgeGroup_EntryParentArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AgeGroups_AgeGroup_EntryPrevArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AgeGroups_AgeGroup_EntrySeomaticArgs = {
  asArray?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<SeomaticEnvironment>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type AudiencesSectionEntryUnion = Audiences_Audience_Entry;

export type Audiences_Audience_Entry = ElementInterface &
  EntryInterface & {
    __typename?: 'audiences_audience_Entry';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
    ancestors: Array<EntryInterface>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** Returns the entry’s canonical ID. */
    canonicalId?: Maybe<Scalars['Int']['output']>;
    /** Returns the entry’s canonical UUID. */
    canonicalUid?: Maybe<Scalars['String']['output']>;
    /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
    children: Array<EntryInterface>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
    descendants: Array<EntryInterface>;
    /** The draft ID (from the `drafts` table). */
    draftId?: Maybe<Scalars['Int']['output']>;
    /** The name of the draft. */
    draftName?: Maybe<Scalars['String']['output']>;
    /** The notes for the draft. */
    draftNotes?: Maybe<Scalars['String']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the element is enabled for the site. */
    enabledForSite?: Maybe<Scalars['Boolean']['output']>;
    /** The expiry date of the entry. */
    expiryDate?: Maybe<Scalars['DateTime']['output']>;
    externalData?: Maybe<Scalars['String']['output']>;
    externalId?: Maybe<Scalars['String']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** Returns whether this is a draft. */
    isDraft?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is a revision. */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is an unpublished draft. */
    isUnpublishedDraft?: Maybe<Scalars['Boolean']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The same element in other locales. */
    localized: Array<EntryInterface>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<EntryInterface>;
    /** The entry’s parent, if the section is a structure. */
    parent?: Maybe<EntryInterface>;
    /** The entry’s post date. */
    postDate?: Maybe<Scalars['DateTime']['output']>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<EntryInterface>;
    /** The revision ID (from the `revisions` table). */
    revisionId?: Maybe<Scalars['Int']['output']>;
    /** The revision notes (from the `revisions` table). */
    revisionNotes?: Maybe<Scalars['String']['output']>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the section that contains the entry. */
    sectionHandle: Scalars['String']['output'];
    /** The ID of the section that contains the entry. */
    sectionId: Scalars['Int']['output'];
    seoSettings?: Maybe<Scalars['String']['output']>;
    /** This query is used to query for SEOmatic meta data. */
    seomatic?: Maybe<SeomaticInterface>;
    shortLink?: Maybe<Scalars['String']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /**
     * Returns the entry’s canonical ID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
     */
    sourceId?: Maybe<Scalars['Int']['output']>;
    /**
     * Returns the entry’s canonical UUID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
     */
    sourceUid?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the entry type that contains the entry. */
    typeHandle: Scalars['String']['output'];
    /** The ID of the entry type that contains the entry. */
    typeId: Scalars['Int']['output'];
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The element’s full URL */
    url?: Maybe<Scalars['String']['output']>;
  };

export type Audiences_Audience_Entry_CountArgs = {
  field: Scalars['String']['input'];
};

export type Audiences_Audience_EntryAncestorsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Audiences_Audience_EntryChildrenArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Audiences_Audience_EntryDescendantsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Audiences_Audience_EntryLocalizedArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Audiences_Audience_EntryNextArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Audiences_Audience_EntryParentArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Audiences_Audience_EntryPrevArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Audiences_Audience_EntrySeomaticArgs = {
  asArray?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<SeomaticEnvironment>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type BadgesSectionEntryUnion = Badges_Badge_Entry;

export type Badges_Badge_Entry = ElementInterface &
  EntryInterface & {
    __typename?: 'badges_badge_Entry';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
    ancestors: Array<EntryInterface>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** Returns the entry’s canonical ID. */
    canonicalId?: Maybe<Scalars['Int']['output']>;
    /** Returns the entry’s canonical UUID. */
    canonicalUid?: Maybe<Scalars['String']['output']>;
    /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
    children: Array<EntryInterface>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
    descendants: Array<EntryInterface>;
    /** The draft ID (from the `drafts` table). */
    draftId?: Maybe<Scalars['Int']['output']>;
    /** The name of the draft. */
    draftName?: Maybe<Scalars['String']['output']>;
    /** The notes for the draft. */
    draftNotes?: Maybe<Scalars['String']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the element is enabled for the site. */
    enabledForSite?: Maybe<Scalars['Boolean']['output']>;
    /** The expiry date of the entry. */
    expiryDate?: Maybe<Scalars['DateTime']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** Returns whether this is a draft. */
    isDraft?: Maybe<Scalars['Boolean']['output']>;
    isHighlighted?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is a revision. */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is an unpublished draft. */
    isUnpublishedDraft?: Maybe<Scalars['Boolean']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The same element in other locales. */
    localized: Array<EntryInterface>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<EntryInterface>;
    /** The entry’s parent, if the section is a structure. */
    parent?: Maybe<EntryInterface>;
    /** The entry’s post date. */
    postDate?: Maybe<Scalars['DateTime']['output']>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<EntryInterface>;
    /** The revision ID (from the `revisions` table). */
    revisionId?: Maybe<Scalars['Int']['output']>;
    /** The revision notes (from the `revisions` table). */
    revisionNotes?: Maybe<Scalars['String']['output']>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the section that contains the entry. */
    sectionHandle: Scalars['String']['output'];
    /** The ID of the section that contains the entry. */
    sectionId: Scalars['Int']['output'];
    /** This query is used to query for SEOmatic meta data. */
    seomatic?: Maybe<SeomaticInterface>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /**
     * Returns the entry’s canonical ID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
     */
    sourceId?: Maybe<Scalars['Int']['output']>;
    /**
     * Returns the entry’s canonical UUID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
     */
    sourceUid?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the entry type that contains the entry. */
    typeHandle: Scalars['String']['output'];
    /** The ID of the entry type that contains the entry. */
    typeId: Scalars['Int']['output'];
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The element’s full URL */
    url?: Maybe<Scalars['String']['output']>;
  };

export type Badges_Badge_Entry_CountArgs = {
  field: Scalars['String']['input'];
};

export type Badges_Badge_EntryAncestorsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Badges_Badge_EntryChildrenArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Badges_Badge_EntryDescendantsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Badges_Badge_EntryLocalizedArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Badges_Badge_EntryNextArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Badges_Badge_EntryParentArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Badges_Badge_EntryPrevArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Badges_Badge_EntrySeomaticArgs = {
  asArray?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<SeomaticEnvironment>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type Banner_MatrixField = Banner_Banner_BlockType;

export type Banner_Banner_BlockType = ElementInterface &
  MatrixBlockInterface & {
    __typename?: 'banner_banner_BlockType';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the field that owns the matrix block. */
    fieldId: Scalars['Int']['output'];
    filter: Array<Maybe<EntryInterface>>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The ID of the primary owner of the Matrix block. */
    primaryOwnerId: Scalars['Int']['output'];
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The sort order of the matrix block within the owner element field. */
    sortOrder?: Maybe<Scalars['Int']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    text?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the matrix block’s type. */
    typeHandle: Scalars['String']['output'];
    /** The ID of the matrix block’s type. */
    typeId: Scalars['Int']['output'];
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
  };

export type Banner_Banner_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type Banner_Banner_BlockTypeFilterArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

/** This entity has all the CodeData properties */
export type Code_CodeData = {
  __typename?: 'code_CodeData';
  /** The language of the Code Field */
  language?: Maybe<Scalars['String']['output']>;
  /** The data entered into the Code Field */
  value?: Maybe<Scalars['String']['output']>;
};

export type ContentBlocks_NeoField =
  | ContentBlocks_AccordionItem_BlockType
  | ContentBlocks_Accordion_BlockType
  | ContentBlocks_CookieInfo_BlockType
  | ContentBlocks_FilteredCalendar_BlockType
  | ContentBlocks_MediaItem_BlockType
  | ContentBlocks_NewsletterSubscribe_BlockType
  | ContentBlocks_Oembed_BlockType
  | ContentBlocks_Spacer_BlockType
  | ContentBlocks_TextBlock_BlockType
  | ContentBlocks_TextMediaBlock_BlockType
  | ContentBlocks_Topic_BlockType
  | ContentBlocks_VideoPlayer_BlockType;

export type ContentBlocks_AccordionItem_BlockType = ElementInterface &
  NeoBlockInterface & {
    __typename?: 'contentBlocks_accordionItem_BlockType';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** The child block types for this Neo block */
    children?: Maybe<Array<Maybe<NeoBlockInterface>>>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the field that owns the Neo block. */
    fieldId?: Maybe<Scalars['Int']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The Neo block’s level. */
    level?: Maybe<Scalars['Int']['output']>;
    /** The ID of the primary owner of the Neo block. */
    primaryOwnerId?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The sort order of the Neo block within the owner element field. */
    sortOrder?: Maybe<Scalars['Int']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the Neo block’s type. */
    typeHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the Neo block’s type. */
    typeId?: Maybe<Scalars['Int']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
  };

export type ContentBlocks_AccordionItem_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type ContentBlocks_Accordion_BlockType = ElementInterface &
  NeoBlockInterface & {
    __typename?: 'contentBlocks_accordion_BlockType';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** The child block types for this Neo block */
    children?: Maybe<Array<Maybe<NeoBlockInterface>>>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the field that owns the Neo block. */
    fieldId?: Maybe<Scalars['Int']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The Neo block’s level. */
    level?: Maybe<Scalars['Int']['output']>;
    /** The ID of the primary owner of the Neo block. */
    primaryOwnerId?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The sort order of the Neo block within the owner element field. */
    sortOrder?: Maybe<Scalars['Int']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the Neo block’s type. */
    typeHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the Neo block’s type. */
    typeId?: Maybe<Scalars['Int']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
  };

export type ContentBlocks_Accordion_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type ContentBlocks_CookieInfo_BlockType = ElementInterface &
  NeoBlockInterface & {
    __typename?: 'contentBlocks_cookieInfo_BlockType';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    bodyText?: Maybe<NodeCollection>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the field that owns the Neo block. */
    fieldId?: Maybe<Scalars['Int']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The Neo block’s level. */
    level?: Maybe<Scalars['Int']['output']>;
    /** The ID of the primary owner of the Neo block. */
    primaryOwnerId?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The sort order of the Neo block within the owner element field. */
    sortOrder?: Maybe<Scalars['Int']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the Neo block’s type. */
    typeHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the Neo block’s type. */
    typeId?: Maybe<Scalars['Int']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
  };

export type ContentBlocks_CookieInfo_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type ContentBlocks_FilteredCalendar_BlockType = ElementInterface &
  NeoBlockInterface & {
    __typename?: 'contentBlocks_filteredCalendar_BlockType';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the field that owns the Neo block. */
    fieldId?: Maybe<Scalars['Int']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The Neo block’s level. */
    level?: Maybe<Scalars['Int']['output']>;
    offerCategories: Array<Maybe<EntryInterface>>;
    /** The ID of the primary owner of the Neo block. */
    primaryOwnerId?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The sort order of the Neo block within the owner element field. */
    sortOrder?: Maybe<Scalars['Int']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the Neo block’s type. */
    typeHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the Neo block’s type. */
    typeId?: Maybe<Scalars['Int']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
  };

export type ContentBlocks_FilteredCalendar_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type ContentBlocks_FilteredCalendar_BlockTypeOfferCategoriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentBlocks_MediaItem_BlockType = ElementInterface &
  NeoBlockInterface & {
    __typename?: 'contentBlocks_mediaItem_BlockType';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the field that owns the Neo block. */
    fieldId?: Maybe<Scalars['Int']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The Neo block’s level. */
    level?: Maybe<Scalars['Int']['output']>;
    mediaAsset: Array<Maybe<AssetInterface>>;
    /** The ID of the primary owner of the Neo block. */
    primaryOwnerId?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The sort order of the Neo block within the owner element field. */
    sortOrder?: Maybe<Scalars['Int']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the Neo block’s type. */
    typeHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the Neo block’s type. */
    typeId?: Maybe<Scalars['Int']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
  };

export type ContentBlocks_MediaItem_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type ContentBlocks_MediaItem_BlockTypeMediaAssetArgs = {
  altText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  caption?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  credits?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified?: InputMaybe<Scalars['String']['input']>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalUrl?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mux?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uploader?: InputMaybe<Scalars['QueryArgument']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentBlocks_NewsletterSubscribe_BlockType = ElementInterface &
  NeoBlockInterface & {
    __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    bodyText?: Maybe<NodeCollection>;
    confirmPrivacy?: Maybe<NodeCollection>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the field that owns the Neo block. */
    fieldId?: Maybe<Scalars['Int']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The Neo block’s level. */
    level?: Maybe<Scalars['Int']['output']>;
    /** The ID of the primary owner of the Neo block. */
    primaryOwnerId?: Maybe<Scalars['Int']['output']>;
    relatedForm?: Maybe<Array<Maybe<FormInterface>>>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The sort order of the Neo block within the owner element field. */
    sortOrder?: Maybe<Scalars['Int']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the Neo block’s type. */
    typeHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the Neo block’s type. */
    typeId?: Maybe<Scalars['Int']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
  };

export type ContentBlocks_NewsletterSubscribe_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type ContentBlocks_NewsletterSubscribe_BlockTypeRelatedFormArgs = {
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  handle?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContentBlocks_Oembed_BlockType = ElementInterface &
  NeoBlockInterface & {
    __typename?: 'contentBlocks_oembed_BlockType';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** Code Editor field */
    code?: Maybe<Code_CodeData>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    description?: Maybe<NodeCollection>;
    /** Oembed field */
    embed?: Maybe<Embed_OembedField>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the field that owns the Neo block. */
    fieldId?: Maybe<Scalars['Int']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    htmlOrLink?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The Neo block’s level. */
    level?: Maybe<Scalars['Int']['output']>;
    /** The ID of the primary owner of the Neo block. */
    primaryOwnerId?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The sort order of the Neo block within the owner element field. */
    sortOrder?: Maybe<Scalars['Int']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the Neo block’s type. */
    typeHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the Neo block’s type. */
    typeId?: Maybe<Scalars['Int']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
  };

export type ContentBlocks_Oembed_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type ContentBlocks_Oembed_BlockTypeEmbedArgs = {
  cacheProps?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['String']['input']>;
};

export type ContentBlocks_Spacer_BlockType = ElementInterface &
  NeoBlockInterface & {
    __typename?: 'contentBlocks_spacer_BlockType';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the field that owns the Neo block. */
    fieldId?: Maybe<Scalars['Int']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The Neo block’s level. */
    level?: Maybe<Scalars['Int']['output']>;
    /** The ID of the primary owner of the Neo block. */
    primaryOwnerId?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    size?: Maybe<Scalars['String']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The sort order of the Neo block within the owner element field. */
    sortOrder?: Maybe<Scalars['Int']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the Neo block’s type. */
    typeHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the Neo block’s type. */
    typeId?: Maybe<Scalars['Int']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
  };

export type ContentBlocks_Spacer_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type ContentBlocks_Spacer_BlockTypeSizeArgs = {
  label?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentBlocks_TextBlock_BlockType = ElementInterface &
  NeoBlockInterface & {
    __typename?: 'contentBlocks_textBlock_BlockType';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    bodyText?: Maybe<NodeCollection>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the field that owns the Neo block. */
    fieldId?: Maybe<Scalars['Int']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The Neo block’s level. */
    level?: Maybe<Scalars['Int']['output']>;
    /** The ID of the primary owner of the Neo block. */
    primaryOwnerId?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The sort order of the Neo block within the owner element field. */
    sortOrder?: Maybe<Scalars['Int']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the Neo block’s type. */
    typeHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the Neo block’s type. */
    typeId?: Maybe<Scalars['Int']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
  };

export type ContentBlocks_TextBlock_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type ContentBlocks_TextMediaBlock_BlockType = ElementInterface &
  NeoBlockInterface & {
    __typename?: 'contentBlocks_textMediaBlock_BlockType';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** The child block types for this Neo block */
    children?: Maybe<Array<Maybe<NeoBlockInterface>>>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the field that owns the Neo block. */
    fieldId?: Maybe<Scalars['Int']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The Neo block’s level. */
    level?: Maybe<Scalars['Int']['output']>;
    /** The ID of the primary owner of the Neo block. */
    primaryOwnerId?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The sort order of the Neo block within the owner element field. */
    sortOrder?: Maybe<Scalars['Int']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the Neo block’s type. */
    typeHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the Neo block’s type. */
    typeId?: Maybe<Scalars['Int']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
  };

export type ContentBlocks_TextMediaBlock_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type ContentBlocks_Topic_BlockType = ElementInterface &
  NeoBlockInterface & {
    __typename?: 'contentBlocks_topic_BlockType';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the field that owns the Neo block. */
    fieldId?: Maybe<Scalars['Int']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    hideHeading?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The Neo block’s level. */
    level?: Maybe<Scalars['Int']['output']>;
    /** The ID of the primary owner of the Neo block. */
    primaryOwnerId?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The sort order of the Neo block within the owner element field. */
    sortOrder?: Maybe<Scalars['Int']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    subheading?: Maybe<NodeCollection>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the Neo block’s type. */
    typeHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the Neo block’s type. */
    typeId?: Maybe<Scalars['Int']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
  };

export type ContentBlocks_Topic_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type ContentBlocks_VideoPlayer_BlockType = ElementInterface &
  NeoBlockInterface & {
    __typename?: 'contentBlocks_videoPlayer_BlockType';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the field that owns the Neo block. */
    fieldId?: Maybe<Scalars['Int']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The Neo block’s level. */
    level?: Maybe<Scalars['Int']['output']>;
    /** The ID of the primary owner of the Neo block. */
    primaryOwnerId?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The sort order of the Neo block within the owner element field. */
    sortOrder?: Maybe<Scalars['Int']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the Neo block’s type. */
    typeHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the Neo block’s type. */
    typeId?: Maybe<Scalars['Int']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    video: Array<Maybe<AssetInterface>>;
  };

export type ContentBlocks_VideoPlayer_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type ContentBlocks_VideoPlayer_BlockTypeVideoArgs = {
  altText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  caption?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  credits?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified?: InputMaybe<Scalars['String']['input']>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalUrl?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mux?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uploader?: InputMaybe<Scalars['QueryArgument']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CountriesSectionEntryUnion = Countries_Country_Entry;

export type Countries_Country_Entry = ElementInterface &
  EntryInterface & {
    __typename?: 'countries_country_Entry';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
    ancestors: Array<EntryInterface>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** Returns the entry’s canonical ID. */
    canonicalId?: Maybe<Scalars['Int']['output']>;
    /** Returns the entry’s canonical UUID. */
    canonicalUid?: Maybe<Scalars['String']['output']>;
    /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
    children: Array<EntryInterface>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
    descendants: Array<EntryInterface>;
    /** The draft ID (from the `drafts` table). */
    draftId?: Maybe<Scalars['Int']['output']>;
    /** The name of the draft. */
    draftName?: Maybe<Scalars['String']['output']>;
    /** The notes for the draft. */
    draftNotes?: Maybe<Scalars['String']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the element is enabled for the site. */
    enabledForSite?: Maybe<Scalars['Boolean']['output']>;
    /** The expiry date of the entry. */
    expiryDate?: Maybe<Scalars['DateTime']['output']>;
    externalData?: Maybe<Scalars['String']['output']>;
    externalId?: Maybe<Scalars['String']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** Returns whether this is a draft. */
    isDraft?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is a revision. */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is an unpublished draft. */
    isUnpublishedDraft?: Maybe<Scalars['Boolean']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The same element in other locales. */
    localized: Array<EntryInterface>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<EntryInterface>;
    /** The entry’s parent, if the section is a structure. */
    parent?: Maybe<EntryInterface>;
    /** The entry’s post date. */
    postDate?: Maybe<Scalars['DateTime']['output']>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<EntryInterface>;
    /** The revision ID (from the `revisions` table). */
    revisionId?: Maybe<Scalars['Int']['output']>;
    /** The revision notes (from the `revisions` table). */
    revisionNotes?: Maybe<Scalars['String']['output']>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the section that contains the entry. */
    sectionHandle: Scalars['String']['output'];
    /** The ID of the section that contains the entry. */
    sectionId: Scalars['Int']['output'];
    /** This query is used to query for SEOmatic meta data. */
    seomatic?: Maybe<SeomaticInterface>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /**
     * Returns the entry’s canonical ID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
     */
    sourceId?: Maybe<Scalars['Int']['output']>;
    /**
     * Returns the entry’s canonical UUID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
     */
    sourceUid?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the entry type that contains the entry. */
    typeHandle: Scalars['String']['output'];
    /** The ID of the entry type that contains the entry. */
    typeId: Scalars['Int']['output'];
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The element’s full URL */
    url?: Maybe<Scalars['String']['output']>;
  };

export type Countries_Country_Entry_CountArgs = {
  field: Scalars['String']['input'];
};

export type Countries_Country_EntryAncestorsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Countries_Country_EntryChildrenArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Countries_Country_EntryDescendantsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Countries_Country_EntryLocalizedArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Countries_Country_EntryNextArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Countries_Country_EntryParentArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Countries_Country_EntryPrevArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Countries_Country_EntrySeomaticArgs = {
  asArray?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<SeomaticEnvironment>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type DateSpans_NeoField = DateSpans_DateSpan_BlockType;

export type DateSpans_DateSpan_BlockType = ElementInterface &
  NeoBlockInterface & {
    __typename?: 'dateSpans_dateSpan_BlockType';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    endDateTime?: Maybe<Scalars['DateTime']['output']>;
    /** The ID of the field that owns the Neo block. */
    fieldId?: Maybe<Scalars['Int']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The Neo block’s level. */
    level?: Maybe<Scalars['Int']['output']>;
    /** The ID of the primary owner of the Neo block. */
    primaryOwnerId?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The sort order of the Neo block within the owner element field. */
    sortOrder?: Maybe<Scalars['Int']['output']>;
    startDateTime?: Maybe<Scalars['DateTime']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the Neo block’s type. */
    typeHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the Neo block’s type. */
    typeId?: Maybe<Scalars['Int']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
  };

export type DateSpans_DateSpan_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type Documents_Asset = AssetInterface &
  ElementInterface & {
    __typename?: 'documents_Asset';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Alternative text for the asset. */
    alt?: Maybe<Scalars['String']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    caption?: Maybe<NodeCollection>;
    credits?: Maybe<NodeCollection>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the asset file was last modified. */
    dateModified?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The file extension for the asset file. */
    extension: Scalars['String']['output'];
    /** The filename of the asset file. */
    filename: Scalars['String']['output'];
    /** The focal point represented as an array with `x` and `y` keys, or null if it’s not an image. */
    focalPoint?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
    /** The ID of the folder that the asset belongs to. */
    folderId: Scalars['Int']['output'];
    /** Returns the file’s format. */
    format?: Maybe<Scalars['String']['output']>;
    /** Whether a user-defined focal point is set on the asset. */
    hasFocalPoint: Scalars['Boolean']['output'];
    /** The height in pixels or null if it’s not an image. */
    height?: Maybe<Scalars['Int']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** Returns a list of images produced from the named Imager X transform. */
    imagerTransform?: Maybe<Array<Maybe<ImagerTransformedImageInterface>>>;
    /** An `<img>` tag based on this asset. */
    img?: Maybe<Scalars['String']['output']>;
    /** The file kind. */
    kind: Scalars['String']['output'];
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The file’s MIME type, if it can be determined. */
    mimeType?: Maybe<Scalars['String']['output']>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<AssetInterface>;
    /** The asset’s path in the volume. */
    path: Scalars['String']['output'];
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<AssetInterface>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The file size in bytes. */
    size?: Maybe<Scalars['String']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** Returns a `srcset` attribute value based on the given widths or x-descriptors. */
    srcset?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The full URL of the asset. This field accepts the same fields as the `transform` directive. */
    url?: Maybe<Scalars['String']['output']>;
    /** The ID of the volume that the asset belongs to. */
    volumeId?: Maybe<Scalars['Int']['output']>;
    /** The width in pixels or null if it’s not an image. */
    width?: Maybe<Scalars['Int']['output']>;
  };

export type Documents_Asset_CountArgs = {
  field: Scalars['String']['input'];
};

export type Documents_AssetFormatArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
  handle?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  immediately?: InputMaybe<Scalars['Boolean']['input']>;
  interlace?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  transform?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type Documents_AssetHeightArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
  handle?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  immediately?: InputMaybe<Scalars['Boolean']['input']>;
  interlace?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  transform?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type Documents_AssetImagerTransformArgs = {
  transform?: InputMaybe<Scalars['String']['input']>;
};

export type Documents_AssetNextArgs = {
  altText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  caption?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  credits?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified?: InputMaybe<Scalars['String']['input']>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalUrl?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mux?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uploader?: InputMaybe<Scalars['QueryArgument']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Documents_AssetPrevArgs = {
  altText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  caption?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  credits?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified?: InputMaybe<Scalars['String']['input']>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalUrl?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mux?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uploader?: InputMaybe<Scalars['QueryArgument']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Documents_AssetSrcsetArgs = {
  sizes: Array<Scalars['String']['input']>;
};

export type Documents_AssetUrlArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
  handle?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  immediately?: InputMaybe<Scalars['Boolean']['input']>;
  interlace?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  transform?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type Documents_AssetWidthArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
  handle?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  immediately?: InputMaybe<Scalars['Boolean']['input']>;
  interlace?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  transform?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** This entity has all the Oembed Field properties */
export type Embed_OembedField = {
  __typename?: 'embed_OembedField';
  aspectRatio?: Maybe<Scalars['String']['output']>;
  authorName?: Maybe<Scalars['String']['output']>;
  authorUrl?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  imageHeight?: Maybe<Scalars['String']['output']>;
  imageWidth?: Maybe<Scalars['String']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
  providerUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['String']['output']>;
};

export type ExhibitionsSectionEntryUnion = Exhibitions_Exhibition_Entry;

export type Exhibitions_Exhibition_Entry = ElementInterface &
  EntryInterface & {
    __typename?: 'exhibitions_exhibition_Entry';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
    ancestors: Array<EntryInterface>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    bodyText?: Maybe<NodeCollection>;
    /** Returns the entry’s canonical ID. */
    canonicalId?: Maybe<Scalars['Int']['output']>;
    /** Returns the entry’s canonical UUID. */
    canonicalUid?: Maybe<Scalars['String']['output']>;
    /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
    children: Array<EntryInterface>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    dateSpans?: Maybe<Array<Maybe<DateSpans_NeoField>>>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
    descendants: Array<EntryInterface>;
    description?: Maybe<NodeCollection>;
    /** The draft ID (from the `drafts` table). */
    draftId?: Maybe<Scalars['Int']['output']>;
    /** The name of the draft. */
    draftName?: Maybe<Scalars['String']['output']>;
    /** The notes for the draft. */
    draftNotes?: Maybe<Scalars['String']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the element is enabled for the site. */
    enabledForSite?: Maybe<Scalars['Boolean']['output']>;
    endDateTimeFormatted?: Maybe<Scalars['String']['output']>;
    /** The expiry date of the entry. */
    expiryDate?: Maybe<Scalars['DateTime']['output']>;
    externalData?: Maybe<Scalars['String']['output']>;
    externalId?: Maybe<Scalars['String']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    image: Array<Maybe<AssetInterface>>;
    /** Returns whether this is a draft. */
    isDraft?: Maybe<Scalars['Boolean']['output']>;
    isFeatured?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is a revision. */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is an unpublished draft. */
    isUnpublishedDraft?: Maybe<Scalars['Boolean']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The same element in other locales. */
    localized: Array<EntryInterface>;
    locations: Array<Maybe<EntryInterface>>;
    museum: Array<Maybe<EntryInterface>>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<EntryInterface>;
    /** The entry’s parent, if the section is a structure. */
    parent?: Maybe<EntryInterface>;
    /** The entry’s post date. */
    postDate?: Maybe<Scalars['DateTime']['output']>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<EntryInterface>;
    /** The revision ID (from the `revisions` table). */
    revisionId?: Maybe<Scalars['Int']['output']>;
    /** The revision notes (from the `revisions` table). */
    revisionNotes?: Maybe<Scalars['String']['output']>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the section that contains the entry. */
    sectionHandle: Scalars['String']['output'];
    /** The ID of the section that contains the entry. */
    sectionId: Scalars['Int']['output'];
    seoSettings?: Maybe<Scalars['String']['output']>;
    /** This query is used to query for SEOmatic meta data. */
    seomatic?: Maybe<SeomaticInterface>;
    shortLink?: Maybe<Scalars['String']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /**
     * Returns the entry’s canonical ID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
     */
    sourceId?: Maybe<Scalars['Int']['output']>;
    /**
     * Returns the entry’s canonical UUID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
     */
    sourceUid?: Maybe<Scalars['String']['output']>;
    startDateTimeFormatted?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    subheading?: Maybe<NodeCollection>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the entry type that contains the entry. */
    typeHandle: Scalars['String']['output'];
    /** The ID of the entry type that contains the entry. */
    typeId: Scalars['Int']['output'];
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The element’s full URL */
    url?: Maybe<Scalars['String']['output']>;
  };

export type Exhibitions_Exhibition_Entry_CountArgs = {
  field: Scalars['String']['input'];
};

export type Exhibitions_Exhibition_EntryAncestorsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Exhibitions_Exhibition_EntryChildrenArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Exhibitions_Exhibition_EntryDateSpansArgs = {
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level?: InputMaybe<Scalars['QueryArgument']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  primaryOwnerId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Exhibitions_Exhibition_EntryDescendantsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Exhibitions_Exhibition_EntryImageArgs = {
  altText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  caption?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  credits?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified?: InputMaybe<Scalars['String']['input']>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalUrl?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mux?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uploader?: InputMaybe<Scalars['QueryArgument']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Exhibitions_Exhibition_EntryLocalizedArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Exhibitions_Exhibition_EntryLocationsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Exhibitions_Exhibition_EntryMuseumArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Exhibitions_Exhibition_EntryNextArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Exhibitions_Exhibition_EntryParentArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Exhibitions_Exhibition_EntryPrevArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Exhibitions_Exhibition_EntrySeomaticArgs = {
  asArray?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<SeomaticEnvironment>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type FooterNavigation_Node = ElementInterface &
  NodeInterface & {
    __typename?: 'footerNavigation_Node';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** The node’s children. Accepts the same arguments as the `nodes` query. */
    children?: Maybe<Array<Maybe<NodeInterface>>>;
    /** Any additional classes for the node. */
    classes?: Maybe<Scalars['String']['output']>;
    /** Any additional custom attributes for the node. */
    customAttributes?: Maybe<Array<Maybe<NodeCustomAttribute>>>;
    /** Any additional data for the node. */
    data?: Maybe<Scalars['String']['output']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The element the node links to. */
    element?: Maybe<ElementInterface>;
    /** The ID of the element this node is linked to. */
    elementId?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The handle of the navigation this node belongs to. */
    navHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the navigation this node belongs to. */
    navId?: Maybe<Scalars['Int']['output']>;
    /** The name of the navigation this node belongs to. */
    navName?: Maybe<Scalars['String']['output']>;
    /** Whether this node should open in a new window. */
    newWindow?: Maybe<Scalars['String']['output']>;
    /** The node’s URI */
    nodeUri?: Maybe<Scalars['String']['output']>;
    /** The node’s parent. */
    parent?: Maybe<NodeInterface>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The type of node this is. */
    type?: Maybe<Scalars['String']['output']>;
    /** The display name for the type of node this is. */
    typeLabel?: Maybe<Scalars['String']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The node’s full URL */
    url?: Maybe<Scalars['String']['output']>;
    /** The URL for this navigation item. */
    urlSuffix?: Maybe<Scalars['String']['output']>;
  };

export type FooterNavigation_Node_CountArgs = {
  field: Scalars['String']['input'];
};

export type FooterNavigation_NodeChildrenArgs = {
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nav?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  navHandle?: InputMaybe<Scalars['String']['input']>;
  navId?: InputMaybe<Scalars['Int']['input']>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FooterSubNavigation_Node = ElementInterface &
  NodeInterface & {
    __typename?: 'footerSubNavigation_Node';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** The node’s children. Accepts the same arguments as the `nodes` query. */
    children?: Maybe<Array<Maybe<NodeInterface>>>;
    /** Any additional classes for the node. */
    classes?: Maybe<Scalars['String']['output']>;
    /** Any additional custom attributes for the node. */
    customAttributes?: Maybe<Array<Maybe<NodeCustomAttribute>>>;
    /** Any additional data for the node. */
    data?: Maybe<Scalars['String']['output']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The element the node links to. */
    element?: Maybe<ElementInterface>;
    /** The ID of the element this node is linked to. */
    elementId?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The handle of the navigation this node belongs to. */
    navHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the navigation this node belongs to. */
    navId?: Maybe<Scalars['Int']['output']>;
    /** The name of the navigation this node belongs to. */
    navName?: Maybe<Scalars['String']['output']>;
    /** Whether this node should open in a new window. */
    newWindow?: Maybe<Scalars['String']['output']>;
    /** The node’s URI */
    nodeUri?: Maybe<Scalars['String']['output']>;
    /** The node’s parent. */
    parent?: Maybe<NodeInterface>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The type of node this is. */
    type?: Maybe<Scalars['String']['output']>;
    /** The display name for the type of node this is. */
    typeLabel?: Maybe<Scalars['String']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The node’s full URL */
    url?: Maybe<Scalars['String']['output']>;
    /** The URL for this navigation item. */
    urlSuffix?: Maybe<Scalars['String']['output']>;
  };

export type FooterSubNavigation_Node_CountArgs = {
  field: Scalars['String']['input'];
};

export type FooterSubNavigation_NodeChildrenArgs = {
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nav?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  navHandle?: InputMaybe<Scalars['String']['input']>;
  navId?: InputMaybe<Scalars['Int']['input']>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GoMusSettingsSectionEntryUnion = GoMusSettings_GoMusSettings_Entry;

export type GoMusSettings_GoMusSettings_Entry = ElementInterface &
  EntryInterface & {
    __typename?: 'goMusSettings_goMusSettings_Entry';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
    ancestors: Array<EntryInterface>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** Returns the entry’s canonical ID. */
    canonicalId?: Maybe<Scalars['Int']['output']>;
    /** Returns the entry’s canonical UUID. */
    canonicalUid?: Maybe<Scalars['String']['output']>;
    /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
    children: Array<EntryInterface>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
    descendants: Array<EntryInterface>;
    /** The draft ID (from the `drafts` table). */
    draftId?: Maybe<Scalars['Int']['output']>;
    /** The name of the draft. */
    draftName?: Maybe<Scalars['String']['output']>;
    /** The notes for the draft. */
    draftNotes?: Maybe<Scalars['String']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the element is enabled for the site. */
    enabledForSite?: Maybe<Scalars['Boolean']['output']>;
    /** The expiry date of the entry. */
    expiryDate?: Maybe<Scalars['DateTime']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** Returns whether this is a draft. */
    isDraft?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is a revision. */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is an unpublished draft. */
    isUnpublishedDraft?: Maybe<Scalars['Boolean']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The same element in other locales. */
    localized: Array<EntryInterface>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<EntryInterface>;
    /** The entry’s parent, if the section is a structure. */
    parent?: Maybe<EntryInterface>;
    /** The entry’s post date. */
    postDate?: Maybe<Scalars['DateTime']['output']>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<EntryInterface>;
    /** The revision ID (from the `revisions` table). */
    revisionId?: Maybe<Scalars['Int']['output']>;
    /** The revision notes (from the `revisions` table). */
    revisionNotes?: Maybe<Scalars['String']['output']>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the section that contains the entry. */
    sectionHandle: Scalars['String']['output'];
    /** The ID of the section that contains the entry. */
    sectionId: Scalars['Int']['output'];
    /** This query is used to query for SEOmatic meta data. */
    seomatic?: Maybe<SeomaticInterface>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /**
     * Returns the entry’s canonical ID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
     */
    sourceId?: Maybe<Scalars['Int']['output']>;
    /**
     * Returns the entry’s canonical UUID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
     */
    sourceUid?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the entry type that contains the entry. */
    typeHandle: Scalars['String']['output'];
    /** The ID of the entry type that contains the entry. */
    typeId: Scalars['Int']['output'];
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The element’s full URL */
    url?: Maybe<Scalars['String']['output']>;
  };

export type GoMusSettings_GoMusSettings_Entry_CountArgs = {
  field: Scalars['String']['input'];
};

export type GoMusSettings_GoMusSettings_EntryAncestorsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GoMusSettings_GoMusSettings_EntryChildrenArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GoMusSettings_GoMusSettings_EntryDescendantsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GoMusSettings_GoMusSettings_EntryLocalizedArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GoMusSettings_GoMusSettings_EntryNextArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GoMusSettings_GoMusSettings_EntryParentArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GoMusSettings_GoMusSettings_EntryPrevArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GoMusSettings_GoMusSettings_EntrySeomaticArgs = {
  asArray?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<SeomaticEnvironment>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type HomePageSectionEntryUnion = HomePage_HomePage_Entry;

export type HomePage_HomePage_Entry = ElementInterface &
  EntryInterface & {
    __typename?: 'homePage_homePage_Entry';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
    ancestors: Array<EntryInterface>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** Returns the entry’s canonical ID. */
    canonicalId?: Maybe<Scalars['Int']['output']>;
    /** Returns the entry’s canonical UUID. */
    canonicalUid?: Maybe<Scalars['String']['output']>;
    /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
    children: Array<EntryInterface>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
    descendants: Array<EntryInterface>;
    /** The draft ID (from the `drafts` table). */
    draftId?: Maybe<Scalars['Int']['output']>;
    /** The name of the draft. */
    draftName?: Maybe<Scalars['String']['output']>;
    /** The notes for the draft. */
    draftNotes?: Maybe<Scalars['String']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the element is enabled for the site. */
    enabledForSite?: Maybe<Scalars['Boolean']['output']>;
    /** The expiry date of the entry. */
    expiryDate?: Maybe<Scalars['DateTime']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    hideHeading?: Maybe<Scalars['Boolean']['output']>;
    homepageIntro?: Maybe<Array<Maybe<HomepageIntro_NeoField>>>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** Returns whether this is a draft. */
    isDraft?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is a revision. */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is an unpublished draft. */
    isUnpublishedDraft?: Maybe<Scalars['Boolean']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The same element in other locales. */
    localized: Array<EntryInterface>;
    moreLink?: Maybe<Array<Maybe<MoreLink_NeoField>>>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<EntryInterface>;
    offers: Array<Maybe<EntryInterface>>;
    /** The entry’s parent, if the section is a structure. */
    parent?: Maybe<EntryInterface>;
    /** The entry’s post date. */
    postDate?: Maybe<Scalars['DateTime']['output']>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<EntryInterface>;
    /** The revision ID (from the `revisions` table). */
    revisionId?: Maybe<Scalars['Int']['output']>;
    /** The revision notes (from the `revisions` table). */
    revisionNotes?: Maybe<Scalars['String']['output']>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the section that contains the entry. */
    sectionHandle: Scalars['String']['output'];
    /** The ID of the section that contains the entry. */
    sectionId: Scalars['Int']['output'];
    seoSettings?: Maybe<Scalars['String']['output']>;
    /** This query is used to query for SEOmatic meta data. */
    seomatic?: Maybe<SeomaticInterface>;
    shortLink?: Maybe<Scalars['String']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /**
     * Returns the entry’s canonical ID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
     */
    sourceId?: Maybe<Scalars['Int']['output']>;
    /**
     * Returns the entry’s canonical UUID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
     */
    sourceUid?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the entry type that contains the entry. */
    typeHandle: Scalars['String']['output'];
    /** The ID of the entry type that contains the entry. */
    typeId: Scalars['Int']['output'];
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The element’s full URL */
    url?: Maybe<Scalars['String']['output']>;
  };

export type HomePage_HomePage_Entry_CountArgs = {
  field: Scalars['String']['input'];
};

export type HomePage_HomePage_EntryAncestorsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HomePage_HomePage_EntryChildrenArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HomePage_HomePage_EntryDescendantsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HomePage_HomePage_EntryHomepageIntroArgs = {
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level?: InputMaybe<Scalars['QueryArgument']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  primaryOwnerId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type HomePage_HomePage_EntryLocalizedArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HomePage_HomePage_EntryMoreLinkArgs = {
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level?: InputMaybe<Scalars['QueryArgument']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  primaryOwnerId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type HomePage_HomePage_EntryNextArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HomePage_HomePage_EntryOffersArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HomePage_HomePage_EntryParentArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HomePage_HomePage_EntryPrevArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HomePage_HomePage_EntrySeomaticArgs = {
  asArray?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<SeomaticEnvironment>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type HomepageIntro_NeoField =
  | HomepageIntro_Element_BlockType
  | HomepageIntro_Link_BlockType;

export type HomepageIntro_Element_BlockType = ElementInterface &
  NeoBlockInterface & {
    __typename?: 'homepageIntro_element_BlockType';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** The child block types for this Neo block */
    children?: Maybe<Array<Maybe<NeoBlockInterface>>>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the field that owns the Neo block. */
    fieldId?: Maybe<Scalars['Int']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The Neo block’s level. */
    level?: Maybe<Scalars['Int']['output']>;
    mediaAsset: Array<Maybe<AssetInterface>>;
    /** The ID of the primary owner of the Neo block. */
    primaryOwnerId?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The sort order of the Neo block within the owner element field. */
    sortOrder?: Maybe<Scalars['Int']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the Neo block’s type. */
    typeHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the Neo block’s type. */
    typeId?: Maybe<Scalars['Int']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
  };

export type HomepageIntro_Element_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type HomepageIntro_Element_BlockTypeMediaAssetArgs = {
  altText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  caption?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  credits?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified?: InputMaybe<Scalars['String']['input']>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalUrl?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mux?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uploader?: InputMaybe<Scalars['QueryArgument']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type HomepageIntro_Link_BlockType = ElementInterface &
  NeoBlockInterface & {
    __typename?: 'homepageIntro_link_BlockType';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    anchorId?: Maybe<Scalars['String']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the field that owns the Neo block. */
    fieldId?: Maybe<Scalars['Int']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    label?: Maybe<Scalars['String']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The Neo block’s level. */
    level?: Maybe<Scalars['Int']['output']>;
    linkStyle?: Maybe<Scalars['String']['output']>;
    /** The ID of the primary owner of the Neo block. */
    primaryOwnerId?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The sort order of the Neo block within the owner element field. */
    sortOrder?: Maybe<Scalars['Int']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the Neo block’s type. */
    typeHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the Neo block’s type. */
    typeId?: Maybe<Scalars['Int']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    webLink?: Maybe<Linkit_Link>;
  };

export type HomepageIntro_Link_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type HomepageIntro_Link_BlockTypeLinkStyleArgs = {
  label?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IconNavigation_Node = ElementInterface &
  NodeInterface & {
    __typename?: 'iconNavigation_Node';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** The node’s children. Accepts the same arguments as the `nodes` query. */
    children?: Maybe<Array<Maybe<NodeInterface>>>;
    /** Any additional classes for the node. */
    classes?: Maybe<Scalars['String']['output']>;
    /** Any additional custom attributes for the node. */
    customAttributes?: Maybe<Array<Maybe<NodeCustomAttribute>>>;
    /** Any additional data for the node. */
    data?: Maybe<Scalars['String']['output']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The element the node links to. */
    element?: Maybe<ElementInterface>;
    /** The ID of the element this node is linked to. */
    elementId?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    icon?: Maybe<Icon_Icon>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The handle of the navigation this node belongs to. */
    navHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the navigation this node belongs to. */
    navId?: Maybe<Scalars['Int']['output']>;
    /** The name of the navigation this node belongs to. */
    navName?: Maybe<Scalars['String']['output']>;
    /** Whether this node should open in a new window. */
    newWindow?: Maybe<Scalars['String']['output']>;
    /** The node’s URI */
    nodeUri?: Maybe<Scalars['String']['output']>;
    /** The node’s parent. */
    parent?: Maybe<NodeInterface>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The type of node this is. */
    type?: Maybe<Scalars['String']['output']>;
    /** The display name for the type of node this is. */
    typeLabel?: Maybe<Scalars['String']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The node’s full URL */
    url?: Maybe<Scalars['String']['output']>;
    /** The URL for this navigation item. */
    urlSuffix?: Maybe<Scalars['String']['output']>;
  };

export type IconNavigation_Node_CountArgs = {
  field: Scalars['String']['input'];
};

export type IconNavigation_NodeChildrenArgs = {
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nav?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  navHandle?: InputMaybe<Scalars['String']['input']>;
  navId?: InputMaybe<Scalars['Int']['input']>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Icon_Icon = {
  __typename?: 'icon_Icon';
  /** Returns the character representation of a font glyph. */
  glyph?: Maybe<Scalars['String']['output']>;
  /** Returns the named representation of a font glyph. */
  glyphName?: Maybe<Scalars['String']['output']>;
  /** The icon set this icon belongs to. */
  iconSet?: Maybe<Scalars['String']['output']>;
  /** Returns the raw contents of the icon. */
  inline?: Maybe<Scalars['String']['output']>;
  /** Returns whether or not there‘s an icon selected for this field. */
  isEmpty?: Maybe<Scalars['Boolean']['output']>;
  /** The keywords used to search for the icon by. Defaults to the `label`. */
  keywords?: Maybe<Scalars['String']['output']>;
  /** The named representation of the icon. */
  label?: Maybe<Scalars['String']['output']>;
  /** Return the full path to the icon. */
  path?: Maybe<Scalars['String']['output']>;
  /** What type of icon this is: `svg`, `sprite`, `glyph` or `css`. */
  type?: Maybe<Scalars['String']['output']>;
  /** Return the full URL to the icon. */
  url?: Maybe<Scalars['String']['output']>;
  /** The value of the icon. This will vary depending on the type of icon. */
  value?: Maybe<Scalars['String']['output']>;
};

/** This entity has all the Imager X transform image interface fields. */
export type Imagerx = ImagerTransformedImageInterface & {
  __typename?: 'imagerx';
  /** Extension of transformed image. */
  extension?: Maybe<Scalars['String']['output']>;
  /** Filename of transformed image. */
  filename?: Maybe<Scalars['String']['output']>;
  /** Height of transformed image. */
  height?: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Indicates if the transformed image is newly created. */
  isNew?: Maybe<Scalars['Boolean']['output']>;
  /** Mime type of transformed image. */
  mimeType?: Maybe<Scalars['String']['output']>;
  /** Path to transformed image. */
  path?: Maybe<Scalars['String']['output']>;
  /** Size of transformed image. */
  size?: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid?: Maybe<Scalars['String']['output']>;
  /** URL for transformed image. */
  url?: Maybe<Scalars['String']['output']>;
  /** Width of transformed image. */
  width?: Maybe<Scalars['Int']['output']>;
};

export type LocationsSectionEntryUnion = Locations_Location_Entry;

export type Locations_Location_Entry = ElementInterface &
  EntryInterface & {
    __typename?: 'locations_location_Entry';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
    ancestors: Array<EntryInterface>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** Returns the entry’s canonical ID. */
    canonicalId?: Maybe<Scalars['Int']['output']>;
    /** Returns the entry’s canonical UUID. */
    canonicalUid?: Maybe<Scalars['String']['output']>;
    /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
    children: Array<EntryInterface>;
    city?: Maybe<Scalars['String']['output']>;
    country?: Maybe<Scalars['String']['output']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
    descendants: Array<EntryInterface>;
    /** The draft ID (from the `drafts` table). */
    draftId?: Maybe<Scalars['Int']['output']>;
    /** The name of the draft. */
    draftName?: Maybe<Scalars['String']['output']>;
    /** The notes for the draft. */
    draftNotes?: Maybe<Scalars['String']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the element is enabled for the site. */
    enabledForSite?: Maybe<Scalars['Boolean']['output']>;
    /** The expiry date of the entry. */
    expiryDate?: Maybe<Scalars['DateTime']['output']>;
    externalData?: Maybe<Scalars['String']['output']>;
    externalId?: Maybe<Scalars['String']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** Returns whether this is a draft. */
    isDraft?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is a revision. */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is an unpublished draft. */
    isUnpublishedDraft?: Maybe<Scalars['Boolean']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    latitude?: Maybe<Scalars['Number']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The same element in other locales. */
    localized: Array<EntryInterface>;
    longitude?: Maybe<Scalars['Number']['output']>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<EntryInterface>;
    /** The entry’s parent, if the section is a structure. */
    parent?: Maybe<EntryInterface>;
    /** The entry’s post date. */
    postDate?: Maybe<Scalars['DateTime']['output']>;
    postalCode?: Maybe<Scalars['String']['output']>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<EntryInterface>;
    /** The revision ID (from the `revisions` table). */
    revisionId?: Maybe<Scalars['Int']['output']>;
    /** The revision notes (from the `revisions` table). */
    revisionNotes?: Maybe<Scalars['String']['output']>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the section that contains the entry. */
    sectionHandle: Scalars['String']['output'];
    /** The ID of the section that contains the entry. */
    sectionId: Scalars['Int']['output'];
    seoSettings?: Maybe<Scalars['String']['output']>;
    /** This query is used to query for SEOmatic meta data. */
    seomatic?: Maybe<SeomaticInterface>;
    shortLink?: Maybe<Scalars['String']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /**
     * Returns the entry’s canonical ID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
     */
    sourceId?: Maybe<Scalars['Int']['output']>;
    /**
     * Returns the entry’s canonical UUID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
     */
    sourceUid?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    streetAddress?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the entry type that contains the entry. */
    typeHandle: Scalars['String']['output'];
    /** The ID of the entry type that contains the entry. */
    typeId: Scalars['Int']['output'];
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The element’s full URL */
    url?: Maybe<Scalars['String']['output']>;
  };

export type Locations_Location_Entry_CountArgs = {
  field: Scalars['String']['input'];
};

export type Locations_Location_EntryAncestorsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Locations_Location_EntryChildrenArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Locations_Location_EntryDescendantsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Locations_Location_EntryLocalizedArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Locations_Location_EntryNextArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Locations_Location_EntryParentArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Locations_Location_EntryPrevArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Locations_Location_EntrySeomaticArgs = {
  asArray?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<SeomaticEnvironment>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type MainNavigation_Node = ElementInterface &
  NodeInterface & {
    __typename?: 'mainNavigation_Node';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** The node’s children. Accepts the same arguments as the `nodes` query. */
    children?: Maybe<Array<Maybe<NodeInterface>>>;
    /** Any additional classes for the node. */
    classes?: Maybe<Scalars['String']['output']>;
    /** Any additional custom attributes for the node. */
    customAttributes?: Maybe<Array<Maybe<NodeCustomAttribute>>>;
    /** Any additional data for the node. */
    data?: Maybe<Scalars['String']['output']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The element the node links to. */
    element?: Maybe<ElementInterface>;
    /** The ID of the element this node is linked to. */
    elementId?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The handle of the navigation this node belongs to. */
    navHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the navigation this node belongs to. */
    navId?: Maybe<Scalars['Int']['output']>;
    /** The name of the navigation this node belongs to. */
    navName?: Maybe<Scalars['String']['output']>;
    /** Whether this node should open in a new window. */
    newWindow?: Maybe<Scalars['String']['output']>;
    /** The node’s URI */
    nodeUri?: Maybe<Scalars['String']['output']>;
    /** The node’s parent. */
    parent?: Maybe<NodeInterface>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The type of node this is. */
    type?: Maybe<Scalars['String']['output']>;
    /** The display name for the type of node this is. */
    typeLabel?: Maybe<Scalars['String']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The node’s full URL */
    url?: Maybe<Scalars['String']['output']>;
    /** The URL for this navigation item. */
    urlSuffix?: Maybe<Scalars['String']['output']>;
  };

export type MainNavigation_Node_CountArgs = {
  field: Scalars['String']['input'];
};

export type MainNavigation_NodeChildrenArgs = {
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nav?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  navHandle?: InputMaybe<Scalars['String']['input']>;
  navId?: InputMaybe<Scalars['Int']['input']>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MainSubNavigation_Node = ElementInterface &
  NodeInterface & {
    __typename?: 'mainSubNavigation_Node';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** The node’s children. Accepts the same arguments as the `nodes` query. */
    children?: Maybe<Array<Maybe<NodeInterface>>>;
    /** Any additional classes for the node. */
    classes?: Maybe<Scalars['String']['output']>;
    /** Any additional custom attributes for the node. */
    customAttributes?: Maybe<Array<Maybe<NodeCustomAttribute>>>;
    /** Any additional data for the node. */
    data?: Maybe<Scalars['String']['output']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The element the node links to. */
    element?: Maybe<ElementInterface>;
    /** The ID of the element this node is linked to. */
    elementId?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The handle of the navigation this node belongs to. */
    navHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the navigation this node belongs to. */
    navId?: Maybe<Scalars['Int']['output']>;
    /** The name of the navigation this node belongs to. */
    navName?: Maybe<Scalars['String']['output']>;
    /** Whether this node should open in a new window. */
    newWindow?: Maybe<Scalars['String']['output']>;
    /** The node’s URI */
    nodeUri?: Maybe<Scalars['String']['output']>;
    /** The node’s parent. */
    parent?: Maybe<NodeInterface>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The type of node this is. */
    type?: Maybe<Scalars['String']['output']>;
    /** The display name for the type of node this is. */
    typeLabel?: Maybe<Scalars['String']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The node’s full URL */
    url?: Maybe<Scalars['String']['output']>;
    /** The URL for this navigation item. */
    urlSuffix?: Maybe<Scalars['String']['output']>;
  };

export type MainSubNavigation_Node_CountArgs = {
  field: Scalars['String']['input'];
};

export type MainSubNavigation_NodeChildrenArgs = {
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nav?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  navHandle?: InputMaybe<Scalars['String']['input']>;
  navId?: InputMaybe<Scalars['Int']['input']>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MediaFiles_Asset = AssetInterface &
  ElementInterface & {
    __typename?: 'mediaFiles_Asset';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Alternative text for the asset. */
    alt?: Maybe<Scalars['String']['output']>;
    altText?: Maybe<NodeCollection>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    caption?: Maybe<NodeCollection>;
    credits?: Maybe<NodeCollection>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the asset file was last modified. */
    dateModified?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The file extension for the asset file. */
    extension: Scalars['String']['output'];
    externalData?: Maybe<Scalars['String']['output']>;
    externalUrl?: Maybe<Scalars['String']['output']>;
    /** The filename of the asset file. */
    filename: Scalars['String']['output'];
    /** The focal point represented as an array with `x` and `y` keys, or null if it’s not an image. */
    focalPoint?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
    /** The ID of the folder that the asset belongs to. */
    folderId: Scalars['Int']['output'];
    /** Returns the file’s format. */
    format?: Maybe<Scalars['String']['output']>;
    /** Whether a user-defined focal point is set on the asset. */
    hasFocalPoint: Scalars['Boolean']['output'];
    /** The height in pixels or null if it’s not an image. */
    height?: Maybe<Scalars['Int']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** Returns a list of images produced from the named Imager X transform. */
    imagerTransform?: Maybe<Array<Maybe<ImagerTransformedImageInterface>>>;
    /** An `<img>` tag based on this asset. */
    img?: Maybe<Scalars['String']['output']>;
    /** The file kind. */
    kind: Scalars['String']['output'];
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The file’s MIME type, if it can be determined. */
    mimeType?: Maybe<Scalars['String']['output']>;
    /** MuxMate field */
    mux?: Maybe<Mux_MuxMateField>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<AssetInterface>;
    /** The asset’s path in the volume. */
    path: Scalars['String']['output'];
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<AssetInterface>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The file size in bytes. */
    size?: Maybe<Scalars['String']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** Returns a `srcset` attribute value based on the given widths or x-descriptors. */
    srcset?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    subtitles: Array<Maybe<Subtitles_MatrixField>>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The full URL of the asset. This field accepts the same fields as the `transform` directive. */
    url?: Maybe<Scalars['String']['output']>;
    /** The ID of the volume that the asset belongs to. */
    volumeId?: Maybe<Scalars['Int']['output']>;
    /** The width in pixels or null if it’s not an image. */
    width?: Maybe<Scalars['Int']['output']>;
  };

export type MediaFiles_Asset_CountArgs = {
  field: Scalars['String']['input'];
};

export type MediaFiles_AssetFormatArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
  handle?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  immediately?: InputMaybe<Scalars['Boolean']['input']>;
  interlace?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  transform?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type MediaFiles_AssetHeightArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
  handle?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  immediately?: InputMaybe<Scalars['Boolean']['input']>;
  interlace?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  transform?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type MediaFiles_AssetImagerTransformArgs = {
  transform?: InputMaybe<Scalars['String']['input']>;
};

export type MediaFiles_AssetNextArgs = {
  altText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  caption?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  credits?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified?: InputMaybe<Scalars['String']['input']>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalUrl?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mux?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uploader?: InputMaybe<Scalars['QueryArgument']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MediaFiles_AssetPrevArgs = {
  altText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  caption?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  credits?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified?: InputMaybe<Scalars['String']['input']>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalUrl?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mux?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uploader?: InputMaybe<Scalars['QueryArgument']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MediaFiles_AssetSrcsetArgs = {
  sizes: Array<Scalars['String']['input']>;
};

export type MediaFiles_AssetSubtitlesArgs = {
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  primaryOwnerId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MediaFiles_AssetUrlArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
  handle?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  immediately?: InputMaybe<Scalars['Boolean']['input']>;
  interlace?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  transform?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type MediaFiles_AssetWidthArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
  handle?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  immediately?: InputMaybe<Scalars['Boolean']['input']>;
  interlace?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  transform?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type MoreLink_NeoField = MoreLink_Link_BlockType;

export type MoreLink_Link_BlockType = ElementInterface &
  NeoBlockInterface & {
    __typename?: 'moreLink_link_BlockType';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    anchorId?: Maybe<Scalars['String']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the field that owns the Neo block. */
    fieldId?: Maybe<Scalars['Int']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    label?: Maybe<Scalars['String']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The Neo block’s level. */
    level?: Maybe<Scalars['Int']['output']>;
    linkStyle?: Maybe<Scalars['String']['output']>;
    /** The ID of the primary owner of the Neo block. */
    primaryOwnerId?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The sort order of the Neo block within the owner element field. */
    sortOrder?: Maybe<Scalars['Int']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the Neo block’s type. */
    typeHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the Neo block’s type. */
    typeId?: Maybe<Scalars['Int']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    webLink?: Maybe<Linkit_Link>;
  };

export type MoreLink_Link_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type MoreLink_Link_BlockTypeLinkStyleArgs = {
  label?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MoreLinks_Link_BlockType = ElementInterface &
  NeoBlockInterface & {
    __typename?: 'moreLinks_link_BlockType';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    anchorId?: Maybe<Scalars['String']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the field that owns the Neo block. */
    fieldId?: Maybe<Scalars['Int']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    label?: Maybe<Scalars['String']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The Neo block’s level. */
    level?: Maybe<Scalars['Int']['output']>;
    linkStyle?: Maybe<Scalars['String']['output']>;
    /** The ID of the primary owner of the Neo block. */
    primaryOwnerId?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The sort order of the Neo block within the owner element field. */
    sortOrder?: Maybe<Scalars['Int']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the Neo block’s type. */
    typeHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the Neo block’s type. */
    typeId?: Maybe<Scalars['Int']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    webLink?: Maybe<Linkit_Link>;
  };

export type MoreLinks_Link_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type MoreLinks_Link_BlockTypeLinkStyleArgs = {
  label?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MuseumsOverviewSectionEntryUnion =
  MuseumsOverview_MuseumsOverview_Entry;

export type MuseumsOverview_MuseumsOverview_Entry = ElementInterface &
  EntryInterface & {
    __typename?: 'museumsOverview_museumsOverview_Entry';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
    ancestors: Array<EntryInterface>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** Returns the entry’s canonical ID. */
    canonicalId?: Maybe<Scalars['Int']['output']>;
    /** Returns the entry’s canonical UUID. */
    canonicalUid?: Maybe<Scalars['String']['output']>;
    /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
    children: Array<EntryInterface>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
    descendants: Array<EntryInterface>;
    description?: Maybe<NodeCollection>;
    /** The draft ID (from the `drafts` table). */
    draftId?: Maybe<Scalars['Int']['output']>;
    /** The name of the draft. */
    draftName?: Maybe<Scalars['String']['output']>;
    /** The notes for the draft. */
    draftNotes?: Maybe<Scalars['String']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the element is enabled for the site. */
    enabledForSite?: Maybe<Scalars['Boolean']['output']>;
    /** The expiry date of the entry. */
    expiryDate?: Maybe<Scalars['DateTime']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    image: Array<Maybe<AssetInterface>>;
    /** Returns whether this is a draft. */
    isDraft?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is a revision. */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is an unpublished draft. */
    isUnpublishedDraft?: Maybe<Scalars['Boolean']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The same element in other locales. */
    localized: Array<EntryInterface>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<EntryInterface>;
    /** The entry’s parent, if the section is a structure. */
    parent?: Maybe<EntryInterface>;
    /** The entry’s post date. */
    postDate?: Maybe<Scalars['DateTime']['output']>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<EntryInterface>;
    /** The revision ID (from the `revisions` table). */
    revisionId?: Maybe<Scalars['Int']['output']>;
    /** The revision notes (from the `revisions` table). */
    revisionNotes?: Maybe<Scalars['String']['output']>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the section that contains the entry. */
    sectionHandle: Scalars['String']['output'];
    /** The ID of the section that contains the entry. */
    sectionId: Scalars['Int']['output'];
    seoSettings?: Maybe<Scalars['String']['output']>;
    /** This query is used to query for SEOmatic meta data. */
    seomatic?: Maybe<SeomaticInterface>;
    shortLink?: Maybe<Scalars['String']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /**
     * Returns the entry’s canonical ID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
     */
    sourceId?: Maybe<Scalars['Int']['output']>;
    /**
     * Returns the entry’s canonical UUID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
     */
    sourceUid?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the entry type that contains the entry. */
    typeHandle: Scalars['String']['output'];
    /** The ID of the entry type that contains the entry. */
    typeId: Scalars['Int']['output'];
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The element’s full URL */
    url?: Maybe<Scalars['String']['output']>;
  };

export type MuseumsOverview_MuseumsOverview_Entry_CountArgs = {
  field: Scalars['String']['input'];
};

export type MuseumsOverview_MuseumsOverview_EntryAncestorsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MuseumsOverview_MuseumsOverview_EntryChildrenArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MuseumsOverview_MuseumsOverview_EntryDescendantsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MuseumsOverview_MuseumsOverview_EntryImageArgs = {
  altText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  caption?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  credits?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified?: InputMaybe<Scalars['String']['input']>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalUrl?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mux?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uploader?: InputMaybe<Scalars['QueryArgument']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MuseumsOverview_MuseumsOverview_EntryLocalizedArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MuseumsOverview_MuseumsOverview_EntryNextArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MuseumsOverview_MuseumsOverview_EntryParentArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MuseumsOverview_MuseumsOverview_EntryPrevArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MuseumsOverview_MuseumsOverview_EntrySeomaticArgs = {
  asArray?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<SeomaticEnvironment>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type MuseumsSectionEntryUnion = Museums_Museum_Entry;

export type Museums_Museum_Entry = ElementInterface &
  EntryInterface & {
    __typename?: 'museums_museum_Entry';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    accessibilityFeatures: Array<Maybe<EntryInterface>>;
    /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
    ancestors: Array<EntryInterface>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    bodyText?: Maybe<NodeCollection>;
    /** Returns the entry’s canonical ID. */
    canonicalId?: Maybe<Scalars['Int']['output']>;
    /** Returns the entry’s canonical UUID. */
    canonicalUid?: Maybe<Scalars['String']['output']>;
    /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
    children: Array<EntryInterface>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
    descendants: Array<EntryInterface>;
    description?: Maybe<NodeCollection>;
    /** The draft ID (from the `drafts` table). */
    draftId?: Maybe<Scalars['Int']['output']>;
    /** The name of the draft. */
    draftName?: Maybe<Scalars['String']['output']>;
    /** The notes for the draft. */
    draftNotes?: Maybe<Scalars['String']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the element is enabled for the site. */
    enabledForSite?: Maybe<Scalars['Boolean']['output']>;
    /** The expiry date of the entry. */
    expiryDate?: Maybe<Scalars['DateTime']['output']>;
    externalData?: Maybe<Scalars['String']['output']>;
    externalId?: Maybe<Scalars['String']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    image: Array<Maybe<AssetInterface>>;
    infoLink?: Maybe<Linkit_Link>;
    infoText?: Maybe<NodeCollection>;
    /** Returns whether this is a draft. */
    isDraft?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is a revision. */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is an unpublished draft. */
    isUnpublishedDraft?: Maybe<Scalars['Boolean']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The same element in other locales. */
    localized: Array<EntryInterface>;
    locations: Array<Maybe<EntryInterface>>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<EntryInterface>;
    offers: Array<Maybe<EntryInterface>>;
    openingTimes?: Maybe<Array<Maybe<OpeningTimes_TableRow>>>;
    openingTimesExtra?: Maybe<Array<Maybe<OpeningTimesExtra_TableRow>>>;
    /** The entry’s parent, if the section is a structure. */
    parent?: Maybe<EntryInterface>;
    /** The entry’s post date. */
    postDate?: Maybe<Scalars['DateTime']['output']>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<EntryInterface>;
    purchaseLink?: Maybe<Linkit_Link>;
    refundPolicyText?: Maybe<NodeCollection>;
    /** The revision ID (from the `revisions` table). */
    revisionId?: Maybe<Scalars['Int']['output']>;
    /** The revision notes (from the `revisions` table). */
    revisionNotes?: Maybe<Scalars['String']['output']>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the section that contains the entry. */
    sectionHandle: Scalars['String']['output'];
    /** The ID of the section that contains the entry. */
    sectionId: Scalars['Int']['output'];
    seoSettings?: Maybe<Scalars['String']['output']>;
    /** This query is used to query for SEOmatic meta data. */
    seomatic?: Maybe<SeomaticInterface>;
    shortLink?: Maybe<Scalars['String']['output']>;
    shortTitle?: Maybe<Scalars['String']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /**
     * Returns the entry’s canonical ID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
     */
    sourceId?: Maybe<Scalars['Int']['output']>;
    /**
     * Returns the entry’s canonical UUID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
     */
    sourceUid?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    storeHours?: Maybe<NodeCollection>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    subheading?: Maybe<NodeCollection>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the entry type that contains the entry. */
    typeHandle: Scalars['String']['output'];
    /** The ID of the entry type that contains the entry. */
    typeId: Scalars['Int']['output'];
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The element’s full URL */
    url?: Maybe<Scalars['String']['output']>;
  };

export type Museums_Museum_Entry_CountArgs = {
  field: Scalars['String']['input'];
};

export type Museums_Museum_EntryAccessibilityFeaturesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Museums_Museum_EntryAncestorsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Museums_Museum_EntryChildrenArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Museums_Museum_EntryDescendantsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Museums_Museum_EntryImageArgs = {
  altText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  caption?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  credits?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified?: InputMaybe<Scalars['String']['input']>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalUrl?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mux?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uploader?: InputMaybe<Scalars['QueryArgument']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Museums_Museum_EntryLocalizedArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Museums_Museum_EntryLocationsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Museums_Museum_EntryNextArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Museums_Museum_EntryOffersArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Museums_Museum_EntryParentArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Museums_Museum_EntryPrevArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Museums_Museum_EntrySeomaticArgs = {
  asArray?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<SeomaticEnvironment>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

/** This entity has all the MuxMate Field properties */
export type Mux_MuxMateField = {
  __typename?: 'mux_MuxMateField';
  aspect_ratio?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  encoding_tier?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  ingest_type?: Maybe<Scalars['String']['output']>;
  master_access?: Maybe<Scalars['String']['output']>;
  max_resolution_tier?: Maybe<Scalars['String']['output']>;
  max_stored_frame_rate?: Maybe<Scalars['Int']['output']>;
  max_stored_resolution?: Maybe<Scalars['String']['output']>;
  mp4_support?: Maybe<Scalars['String']['output']>;
  normalize_audio?: Maybe<Scalars['Boolean']['output']>;
  /** Returns a Mux playback ID for the given policy. */
  playback_id?: Maybe<Scalars['String']['output']>;
  resolution_tier?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  test?: Maybe<Scalars['Boolean']['output']>;
};

/** This entity has all the MuxMate Field properties */
export type Mux_MuxMateFieldPlayback_IdArgs = {
  policy?: InputMaybe<Scalars['String']['input']>;
};

export type NewsItemsSectionEntryUnion = NewsItems_NewsItem_Entry;

export type NewsItems_NewsItem_Entry = ElementInterface &
  EntryInterface & {
    __typename?: 'newsItems_newsItem_Entry';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
    ancestors: Array<EntryInterface>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    bodyText?: Maybe<NodeCollection>;
    /** Returns the entry’s canonical ID. */
    canonicalId?: Maybe<Scalars['Int']['output']>;
    /** Returns the entry’s canonical UUID. */
    canonicalUid?: Maybe<Scalars['String']['output']>;
    /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
    children: Array<EntryInterface>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
    descendants: Array<EntryInterface>;
    /** The draft ID (from the `drafts` table). */
    draftId?: Maybe<Scalars['Int']['output']>;
    /** The name of the draft. */
    draftName?: Maybe<Scalars['String']['output']>;
    /** The notes for the draft. */
    draftNotes?: Maybe<Scalars['String']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the element is enabled for the site. */
    enabledForSite?: Maybe<Scalars['Boolean']['output']>;
    /** The expiry date of the entry. */
    expiryDate?: Maybe<Scalars['DateTime']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    image: Array<Maybe<AssetInterface>>;
    /** Returns whether this is a draft. */
    isDraft?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is a revision. */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is an unpublished draft. */
    isUnpublishedDraft?: Maybe<Scalars['Boolean']['output']>;
    label?: Maybe<Scalars['String']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The same element in other locales. */
    localized: Array<EntryInterface>;
    museum: Array<Maybe<EntryInterface>>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<EntryInterface>;
    /** The entry’s parent, if the section is a structure. */
    parent?: Maybe<EntryInterface>;
    /** The entry’s post date. */
    postDate?: Maybe<Scalars['DateTime']['output']>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<EntryInterface>;
    /** The revision ID (from the `revisions` table). */
    revisionId?: Maybe<Scalars['Int']['output']>;
    /** The revision notes (from the `revisions` table). */
    revisionNotes?: Maybe<Scalars['String']['output']>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the section that contains the entry. */
    sectionHandle: Scalars['String']['output'];
    /** The ID of the section that contains the entry. */
    sectionId: Scalars['Int']['output'];
    seoSettings?: Maybe<Scalars['String']['output']>;
    /** This query is used to query for SEOmatic meta data. */
    seomatic?: Maybe<SeomaticInterface>;
    shortLink?: Maybe<Scalars['String']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /**
     * Returns the entry’s canonical ID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
     */
    sourceId?: Maybe<Scalars['Int']['output']>;
    /**
     * Returns the entry’s canonical UUID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
     */
    sourceUid?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the entry type that contains the entry. */
    typeHandle: Scalars['String']['output'];
    /** The ID of the entry type that contains the entry. */
    typeId: Scalars['Int']['output'];
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The element’s full URL */
    url?: Maybe<Scalars['String']['output']>;
  };

export type NewsItems_NewsItem_Entry_CountArgs = {
  field: Scalars['String']['input'];
};

export type NewsItems_NewsItem_EntryAncestorsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewsItems_NewsItem_EntryChildrenArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewsItems_NewsItem_EntryDescendantsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewsItems_NewsItem_EntryImageArgs = {
  altText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  caption?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  credits?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified?: InputMaybe<Scalars['String']['input']>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalUrl?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mux?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uploader?: InputMaybe<Scalars['QueryArgument']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type NewsItems_NewsItem_EntryLocalizedArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewsItems_NewsItem_EntryMuseumArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewsItems_NewsItem_EntryNextArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewsItems_NewsItem_EntryParentArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewsItems_NewsItem_EntryPrevArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewsItems_NewsItem_EntrySeomaticArgs = {
  asArray?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<SeomaticEnvironment>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type Newsletter_Form = ElementInterface &
  FormInterface & {
    __typename?: 'newsletter_Form';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** A list of captcha values (name and value) to assist with spam protection. */
    captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
    /** The form’s config as JSON. */
    configJson?: Maybe<Scalars['String']['output']>;
    /** A CSRF token (name and value). */
    csrfToken?: Maybe<FormieCsrfTokenType>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The form’s fields. */
    formFields?: Maybe<Array<Maybe<FieldInterface>>>;
    /** The form’s handle. */
    handle?: Maybe<Scalars['String']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** Whether the form is considered available according to user checks, scheduling and more. */
    isAvailable?: Maybe<Scalars['Boolean']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The form’s pages. */
    pages?: Maybe<Array<Maybe<PageInterface>>>;
    /** The form’s rows. */
    rows?: Maybe<Array<Maybe<RowInterface>>>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The form’s settings. */
    settings?: Maybe<FormSettingsType>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The form’s endpoint for sending submissions to, if using POST requests. */
    submissionEndpoint?: Maybe<Scalars['String']['output']>;
    /** The form’s GQL mutation name for submissions to use. */
    submissionMutationName?: Maybe<Scalars['String']['output']>;
    /** The form’s rendered HTML. */
    templateHtml?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
  };

export type Newsletter_Form_CountArgs = {
  field: Scalars['String']['input'];
};

export type Newsletter_FormFormFieldsArgs = {
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Newsletter_FormTemplateHtmlArgs = {
  options?: InputMaybe<Scalars['String']['input']>;
  populateFormValues?: InputMaybe<Scalars['String']['input']>;
};

export type Newsletter_Submission = ElementInterface &
  SubmissionInterface & {
    __typename?: 'newsletter_Submission';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    company?: Maybe<Scalars['String']['output']>;
    confirmPrivacy?: Maybe<Scalars['String']['output']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    email?: Maybe<Scalars['String']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    firstName?: Maybe<Scalars['String']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The submission’s IP Address. */
    ipAddress?: Maybe<Scalars['String']['output']>;
    /** Whether the submission is incomplete. */
    isIncomplete?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the submission is spam. */
    isSpam?: Maybe<Scalars['Boolean']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The submission’s spam type. */
    spamClass?: Maybe<Scalars['String']['output']>;
    /** The submission’s spam reason. */
    spamReason?: Maybe<Scalars['String']['output']>;
    /** The submission’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The submission’s status ID. */
    statusId?: Maybe<Scalars['Int']['output']>;
    surName?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
  };

export type Newsletter_Submission_CountArgs = {
  field: Scalars['String']['input'];
};

export type OfferCategoriesSectionEntryUnion =
  OfferCategories_OfferCategory_Entry;

export type OfferCategories_OfferCategory_Entry = ElementInterface &
  EntryInterface & {
    __typename?: 'offerCategories_offerCategory_Entry';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
    ancestors: Array<EntryInterface>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** Returns the entry’s canonical ID. */
    canonicalId?: Maybe<Scalars['Int']['output']>;
    /** Returns the entry’s canonical UUID. */
    canonicalUid?: Maybe<Scalars['String']['output']>;
    /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
    children: Array<EntryInterface>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
    descendants: Array<EntryInterface>;
    /** The draft ID (from the `drafts` table). */
    draftId?: Maybe<Scalars['Int']['output']>;
    /** The name of the draft. */
    draftName?: Maybe<Scalars['String']['output']>;
    /** The notes for the draft. */
    draftNotes?: Maybe<Scalars['String']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the element is enabled for the site. */
    enabledForSite?: Maybe<Scalars['Boolean']['output']>;
    /** The expiry date of the entry. */
    expiryDate?: Maybe<Scalars['DateTime']['output']>;
    externalData?: Maybe<Scalars['String']['output']>;
    externalId?: Maybe<Scalars['String']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** Returns whether this is a draft. */
    isDraft?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is a revision. */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is an unpublished draft. */
    isUnpublishedDraft?: Maybe<Scalars['Boolean']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The same element in other locales. */
    localized: Array<EntryInterface>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<EntryInterface>;
    /** The entry’s parent, if the section is a structure. */
    parent?: Maybe<EntryInterface>;
    /** The entry’s post date. */
    postDate?: Maybe<Scalars['DateTime']['output']>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<EntryInterface>;
    /** The revision ID (from the `revisions` table). */
    revisionId?: Maybe<Scalars['Int']['output']>;
    /** The revision notes (from the `revisions` table). */
    revisionNotes?: Maybe<Scalars['String']['output']>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the section that contains the entry. */
    sectionHandle: Scalars['String']['output'];
    /** The ID of the section that contains the entry. */
    sectionId: Scalars['Int']['output'];
    seoSettings?: Maybe<Scalars['String']['output']>;
    /** This query is used to query for SEOmatic meta data. */
    seomatic?: Maybe<SeomaticInterface>;
    shortLink?: Maybe<Scalars['String']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /**
     * Returns the entry’s canonical ID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
     */
    sourceId?: Maybe<Scalars['Int']['output']>;
    /**
     * Returns the entry’s canonical UUID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
     */
    sourceUid?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the entry type that contains the entry. */
    typeHandle: Scalars['String']['output'];
    /** The ID of the entry type that contains the entry. */
    typeId: Scalars['Int']['output'];
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The element’s full URL */
    url?: Maybe<Scalars['String']['output']>;
  };

export type OfferCategories_OfferCategory_Entry_CountArgs = {
  field: Scalars['String']['input'];
};

export type OfferCategories_OfferCategory_EntryAncestorsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferCategories_OfferCategory_EntryChildrenArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferCategories_OfferCategory_EntryDescendantsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferCategories_OfferCategory_EntryLocalizedArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferCategories_OfferCategory_EntryNextArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferCategories_OfferCategory_EntryParentArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferCategories_OfferCategory_EntryPrevArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferCategories_OfferCategory_EntrySeomaticArgs = {
  asArray?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<SeomaticEnvironment>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type OfferDates_NeoField = OfferDates_OfferDate_BlockType;

export type OfferDates_OfferDate_BlockType = ElementInterface &
  NeoBlockInterface & {
    __typename?: 'offerDates_offerDate_BlockType';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    city?: Maybe<Scalars['String']['output']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    externalId?: Maybe<Scalars['String']['output']>;
    /** The ID of the field that owns the Neo block. */
    fieldId?: Maybe<Scalars['Int']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The Neo block’s level. */
    level?: Maybe<Scalars['Int']['output']>;
    maxParticipants?: Maybe<Scalars['Number']['output']>;
    maxPerRegistration?: Maybe<Scalars['Number']['output']>;
    offerLanguages: Array<Maybe<EntryInterface>>;
    /** The ID of the primary owner of the Neo block. */
    primaryOwnerId?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    seatsAvailable?: Maybe<Scalars['Number']['output']>;
    seatsBooked?: Maybe<Scalars['Number']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The sort order of the Neo block within the owner element field. */
    sortOrder?: Maybe<Scalars['Int']['output']>;
    startDateTime?: Maybe<Scalars['DateTime']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the Neo block’s type. */
    typeHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the Neo block’s type. */
    typeId?: Maybe<Scalars['Int']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
  };

export type OfferDates_OfferDate_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type OfferDates_OfferDate_BlockTypeOfferLanguagesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferLanguagesSectionEntryUnion =
  OfferLanguages_OfferLanguage_Entry;

export type OfferLanguages_OfferLanguage_Entry = ElementInterface &
  EntryInterface & {
    __typename?: 'offerLanguages_offerLanguage_Entry';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
    ancestors: Array<EntryInterface>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** Returns the entry’s canonical ID. */
    canonicalId?: Maybe<Scalars['Int']['output']>;
    /** Returns the entry’s canonical UUID. */
    canonicalUid?: Maybe<Scalars['String']['output']>;
    /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
    children: Array<EntryInterface>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
    descendants: Array<EntryInterface>;
    /** The draft ID (from the `drafts` table). */
    draftId?: Maybe<Scalars['Int']['output']>;
    /** The name of the draft. */
    draftName?: Maybe<Scalars['String']['output']>;
    /** The notes for the draft. */
    draftNotes?: Maybe<Scalars['String']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the element is enabled for the site. */
    enabledForSite?: Maybe<Scalars['Boolean']['output']>;
    /** The expiry date of the entry. */
    expiryDate?: Maybe<Scalars['DateTime']['output']>;
    externalData?: Maybe<Scalars['String']['output']>;
    externalId?: Maybe<Scalars['String']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** Returns whether this is a draft. */
    isDraft?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is a revision. */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is an unpublished draft. */
    isUnpublishedDraft?: Maybe<Scalars['Boolean']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The same element in other locales. */
    localized: Array<EntryInterface>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<EntryInterface>;
    /** The entry’s parent, if the section is a structure. */
    parent?: Maybe<EntryInterface>;
    /** The entry’s post date. */
    postDate?: Maybe<Scalars['DateTime']['output']>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<EntryInterface>;
    /** The revision ID (from the `revisions` table). */
    revisionId?: Maybe<Scalars['Int']['output']>;
    /** The revision notes (from the `revisions` table). */
    revisionNotes?: Maybe<Scalars['String']['output']>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the section that contains the entry. */
    sectionHandle: Scalars['String']['output'];
    /** The ID of the section that contains the entry. */
    sectionId: Scalars['Int']['output'];
    seoSettings?: Maybe<Scalars['String']['output']>;
    /** This query is used to query for SEOmatic meta data. */
    seomatic?: Maybe<SeomaticInterface>;
    shortLink?: Maybe<Scalars['String']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /**
     * Returns the entry’s canonical ID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
     */
    sourceId?: Maybe<Scalars['Int']['output']>;
    /**
     * Returns the entry’s canonical UUID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
     */
    sourceUid?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the entry type that contains the entry. */
    typeHandle: Scalars['String']['output'];
    /** The ID of the entry type that contains the entry. */
    typeId: Scalars['Int']['output'];
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The element’s full URL */
    url?: Maybe<Scalars['String']['output']>;
  };

export type OfferLanguages_OfferLanguage_Entry_CountArgs = {
  field: Scalars['String']['input'];
};

export type OfferLanguages_OfferLanguage_EntryAncestorsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferLanguages_OfferLanguage_EntryChildrenArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferLanguages_OfferLanguage_EntryDescendantsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferLanguages_OfferLanguage_EntryLocalizedArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferLanguages_OfferLanguage_EntryNextArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferLanguages_OfferLanguage_EntryParentArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferLanguages_OfferLanguage_EntryPrevArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferLanguages_OfferLanguage_EntrySeomaticArgs = {
  asArray?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<SeomaticEnvironment>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type OfferPricingInfo_NeoField =
  OfferPricingInfo_OfferPricingInfo_BlockType;

export type OfferPricingInfo_OfferPricingInfo_BlockType = ElementInterface &
  NeoBlockInterface & {
    __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the field that owns the Neo block. */
    fieldId?: Maybe<Scalars['Int']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The Neo block’s level. */
    level?: Maybe<Scalars['Int']['output']>;
    maxPrice?: Maybe<Scalars['Number']['output']>;
    minPrice?: Maybe<Scalars['Number']['output']>;
    /** The ID of the primary owner of the Neo block. */
    primaryOwnerId?: Maybe<Scalars['Int']['output']>;
    proposalCategory: Array<Maybe<EntryInterface>>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The sort order of the Neo block within the owner element field. */
    sortOrder?: Maybe<Scalars['Int']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the Neo block’s type. */
    typeHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the Neo block’s type. */
    typeId?: Maybe<Scalars['Int']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
  };

export type OfferPricingInfo_OfferPricingInfo_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type OfferPricingInfo_OfferPricingInfo_BlockTypeProposalCategoryArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OffersOverviewSectionEntryUnion =
  OffersOverview_OffersOverview_Entry;

export type OffersOverview_OffersOverview_Entry = ElementInterface &
  EntryInterface & {
    __typename?: 'offersOverview_offersOverview_Entry';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
    ancestors: Array<EntryInterface>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** Returns the entry’s canonical ID. */
    canonicalId?: Maybe<Scalars['Int']['output']>;
    /** Returns the entry’s canonical UUID. */
    canonicalUid?: Maybe<Scalars['String']['output']>;
    /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
    children: Array<EntryInterface>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
    descendants: Array<EntryInterface>;
    description?: Maybe<NodeCollection>;
    /** The draft ID (from the `drafts` table). */
    draftId?: Maybe<Scalars['Int']['output']>;
    /** The name of the draft. */
    draftName?: Maybe<Scalars['String']['output']>;
    /** The notes for the draft. */
    draftNotes?: Maybe<Scalars['String']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the element is enabled for the site. */
    enabledForSite?: Maybe<Scalars['Boolean']['output']>;
    /** The expiry date of the entry. */
    expiryDate?: Maybe<Scalars['DateTime']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    image: Array<Maybe<AssetInterface>>;
    /** Returns whether this is a draft. */
    isDraft?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is a revision. */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is an unpublished draft. */
    isUnpublishedDraft?: Maybe<Scalars['Boolean']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The same element in other locales. */
    localized: Array<EntryInterface>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<EntryInterface>;
    /** The entry’s parent, if the section is a structure. */
    parent?: Maybe<EntryInterface>;
    /** The entry’s post date. */
    postDate?: Maybe<Scalars['DateTime']['output']>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<EntryInterface>;
    /** The revision ID (from the `revisions` table). */
    revisionId?: Maybe<Scalars['Int']['output']>;
    /** The revision notes (from the `revisions` table). */
    revisionNotes?: Maybe<Scalars['String']['output']>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the section that contains the entry. */
    sectionHandle: Scalars['String']['output'];
    /** The ID of the section that contains the entry. */
    sectionId: Scalars['Int']['output'];
    seoSettings?: Maybe<Scalars['String']['output']>;
    /** This query is used to query for SEOmatic meta data. */
    seomatic?: Maybe<SeomaticInterface>;
    shortLink?: Maybe<Scalars['String']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /**
     * Returns the entry’s canonical ID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
     */
    sourceId?: Maybe<Scalars['Int']['output']>;
    /**
     * Returns the entry’s canonical UUID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
     */
    sourceUid?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the entry type that contains the entry. */
    typeHandle: Scalars['String']['output'];
    /** The ID of the entry type that contains the entry. */
    typeId: Scalars['Int']['output'];
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The element’s full URL */
    url?: Maybe<Scalars['String']['output']>;
  };

export type OffersOverview_OffersOverview_Entry_CountArgs = {
  field: Scalars['String']['input'];
};

export type OffersOverview_OffersOverview_EntryAncestorsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OffersOverview_OffersOverview_EntryChildrenArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OffersOverview_OffersOverview_EntryDescendantsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OffersOverview_OffersOverview_EntryImageArgs = {
  altText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  caption?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  credits?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified?: InputMaybe<Scalars['String']['input']>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalUrl?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mux?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uploader?: InputMaybe<Scalars['QueryArgument']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type OffersOverview_OffersOverview_EntryLocalizedArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OffersOverview_OffersOverview_EntryNextArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OffersOverview_OffersOverview_EntryParentArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OffersOverview_OffersOverview_EntryPrevArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OffersOverview_OffersOverview_EntrySeomaticArgs = {
  asArray?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<SeomaticEnvironment>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type OffersSectionEntryUnion = Offers_Event_Entry | Offers_Tour_Entry;

export type Offers_Event_Entry = ElementInterface &
  EntryInterface & {
    __typename?: 'offers_event_Entry';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    accessibilityFeatures: Array<Maybe<EntryInterface>>;
    ageGroups: Array<Maybe<EntryInterface>>;
    ageRating?: Maybe<Scalars['String']['output']>;
    /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
    ancestors: Array<EntryInterface>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    audiences: Array<Maybe<EntryInterface>>;
    badge: Array<Maybe<EntryInterface>>;
    bodyText?: Maybe<NodeCollection>;
    /** Returns the entry’s canonical ID. */
    canonicalId?: Maybe<Scalars['Int']['output']>;
    /** Returns the entry’s canonical UUID. */
    canonicalUid?: Maybe<Scalars['String']['output']>;
    /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
    children: Array<EntryInterface>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
    descendants: Array<EntryInterface>;
    description?: Maybe<NodeCollection>;
    /** The draft ID (from the `drafts` table). */
    draftId?: Maybe<Scalars['Int']['output']>;
    /** The name of the draft. */
    draftName?: Maybe<Scalars['String']['output']>;
    /** The notes for the draft. */
    draftNotes?: Maybe<Scalars['String']['output']>;
    duration?: Maybe<Scalars['Number']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the element is enabled for the site. */
    enabledForSite?: Maybe<Scalars['Boolean']['output']>;
    exhibition: Array<Maybe<EntryInterface>>;
    /** The expiry date of the entry. */
    expiryDate?: Maybe<Scalars['DateTime']['output']>;
    externalData?: Maybe<Scalars['String']['output']>;
    externalId?: Maybe<Scalars['String']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    image: Array<Maybe<AssetInterface>>;
    isBookable?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is a draft. */
    isDraft?: Maybe<Scalars['Boolean']['output']>;
    isFeatured?: Maybe<Scalars['Boolean']['output']>;
    isRegisterable?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is a revision. */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    isSchoolOptional?: Maybe<Scalars['String']['output']>;
    /** Returns whether this is an unpublished draft. */
    isUnpublishedDraft?: Maybe<Scalars['Boolean']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The same element in other locales. */
    localized: Array<EntryInterface>;
    location: Array<Maybe<EntryInterface>>;
    museum: Array<Maybe<EntryInterface>>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<EntryInterface>;
    offerCategory: Array<Maybe<EntryInterface>>;
    offerDates?: Maybe<Array<Maybe<OfferDates_NeoField>>>;
    offerLanguages: Array<Maybe<EntryInterface>>;
    /** The entry’s parent, if the section is a structure. */
    parent?: Maybe<EntryInterface>;
    /** The entry’s post date. */
    postDate?: Maybe<Scalars['DateTime']['output']>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<EntryInterface>;
    proposalCategories: Array<Maybe<EntryInterface>>;
    /** The revision ID (from the `revisions` table). */
    revisionId?: Maybe<Scalars['Int']['output']>;
    /** The revision notes (from the `revisions` table). */
    revisionNotes?: Maybe<Scalars['String']['output']>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    schoolGrades: Array<Maybe<EntryInterface>>;
    schoolGradesFormated?: Maybe<Scalars['String']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the section that contains the entry. */
    sectionHandle: Scalars['String']['output'];
    /** The ID of the section that contains the entry. */
    sectionId: Scalars['Int']['output'];
    seoSettings?: Maybe<Scalars['String']['output']>;
    /** This query is used to query for SEOmatic meta data. */
    seomatic?: Maybe<SeomaticInterface>;
    shortLink?: Maybe<Scalars['String']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /**
     * Returns the entry’s canonical ID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
     */
    sourceId?: Maybe<Scalars['Int']['output']>;
    /**
     * Returns the entry’s canonical UUID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
     */
    sourceUid?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    subheading?: Maybe<NodeCollection>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    topics: Array<Maybe<EntryInterface>>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the entry type that contains the entry. */
    typeHandle: Scalars['String']['output'];
    /** The ID of the entry type that contains the entry. */
    typeId: Scalars['Int']['output'];
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The element’s full URL */
    url?: Maybe<Scalars['String']['output']>;
  };

export type Offers_Event_Entry_CountArgs = {
  field: Scalars['String']['input'];
};

export type Offers_Event_EntryAccessibilityFeaturesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Event_EntryAgeGroupsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Event_EntryAncestorsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Event_EntryAudiencesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Event_EntryBadgeArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Event_EntryChildrenArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Event_EntryDescendantsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Event_EntryExhibitionArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Event_EntryImageArgs = {
  altText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  caption?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  credits?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified?: InputMaybe<Scalars['String']['input']>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalUrl?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mux?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uploader?: InputMaybe<Scalars['QueryArgument']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Offers_Event_EntryLocalizedArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Event_EntryLocationArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Event_EntryMuseumArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Event_EntryNextArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Event_EntryOfferCategoryArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Event_EntryOfferDatesArgs = {
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level?: InputMaybe<Scalars['QueryArgument']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  primaryOwnerId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Offers_Event_EntryOfferLanguagesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Event_EntryParentArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Event_EntryPrevArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Event_EntryProposalCategoriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Event_EntrySchoolGradesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Event_EntrySeomaticArgs = {
  asArray?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<SeomaticEnvironment>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type Offers_Event_EntryTopicsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Tour_Entry = ElementInterface &
  EntryInterface & {
    __typename?: 'offers_tour_Entry';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    accessibilityFeatures: Array<Maybe<EntryInterface>>;
    ageGroups: Array<Maybe<EntryInterface>>;
    ageRating?: Maybe<Scalars['String']['output']>;
    /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
    ancestors: Array<EntryInterface>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    audiences: Array<Maybe<EntryInterface>>;
    badge: Array<Maybe<EntryInterface>>;
    bodyText?: Maybe<NodeCollection>;
    /** Returns the entry’s canonical ID. */
    canonicalId?: Maybe<Scalars['Int']['output']>;
    /** Returns the entry’s canonical UUID. */
    canonicalUid?: Maybe<Scalars['String']['output']>;
    /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
    children: Array<EntryInterface>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
    descendants: Array<EntryInterface>;
    description?: Maybe<NodeCollection>;
    /** The draft ID (from the `drafts` table). */
    draftId?: Maybe<Scalars['Int']['output']>;
    /** The name of the draft. */
    draftName?: Maybe<Scalars['String']['output']>;
    /** The notes for the draft. */
    draftNotes?: Maybe<Scalars['String']['output']>;
    duration?: Maybe<Scalars['Number']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the element is enabled for the site. */
    enabledForSite?: Maybe<Scalars['Boolean']['output']>;
    exhibition: Array<Maybe<EntryInterface>>;
    /** The expiry date of the entry. */
    expiryDate?: Maybe<Scalars['DateTime']['output']>;
    externalData?: Maybe<Scalars['String']['output']>;
    externalId?: Maybe<Scalars['String']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    image: Array<Maybe<AssetInterface>>;
    isBookable?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is a draft. */
    isDraft?: Maybe<Scalars['Boolean']['output']>;
    isFeatured?: Maybe<Scalars['Boolean']['output']>;
    isRegisterable?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is a revision. */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    isSchoolOptional?: Maybe<Scalars['String']['output']>;
    /** Returns whether this is an unpublished draft. */
    isUnpublishedDraft?: Maybe<Scalars['Boolean']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The same element in other locales. */
    localized: Array<EntryInterface>;
    locations: Array<Maybe<EntryInterface>>;
    maxParticipants?: Maybe<Scalars['Number']['output']>;
    minParticipants?: Maybe<Scalars['Number']['output']>;
    museum: Array<Maybe<EntryInterface>>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<EntryInterface>;
    offerCategory: Array<Maybe<EntryInterface>>;
    offerLanguages: Array<Maybe<EntryInterface>>;
    offerPricingInfo?: Maybe<Array<Maybe<OfferPricingInfo_NeoField>>>;
    /** The entry’s parent, if the section is a structure. */
    parent?: Maybe<EntryInterface>;
    /** The entry’s post date. */
    postDate?: Maybe<Scalars['DateTime']['output']>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<EntryInterface>;
    proposalCategories: Array<Maybe<EntryInterface>>;
    /** The revision ID (from the `revisions` table). */
    revisionId?: Maybe<Scalars['Int']['output']>;
    /** The revision notes (from the `revisions` table). */
    revisionNotes?: Maybe<Scalars['String']['output']>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    schoolGrades: Array<Maybe<EntryInterface>>;
    schoolGradesFormated?: Maybe<Scalars['String']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the section that contains the entry. */
    sectionHandle: Scalars['String']['output'];
    /** The ID of the section that contains the entry. */
    sectionId: Scalars['Int']['output'];
    seoSettings?: Maybe<Scalars['String']['output']>;
    /** This query is used to query for SEOmatic meta data. */
    seomatic?: Maybe<SeomaticInterface>;
    shortLink?: Maybe<Scalars['String']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /**
     * Returns the entry’s canonical ID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
     */
    sourceId?: Maybe<Scalars['Int']['output']>;
    /**
     * Returns the entry’s canonical UUID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
     */
    sourceUid?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    subheading?: Maybe<NodeCollection>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    topics: Array<Maybe<EntryInterface>>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the entry type that contains the entry. */
    typeHandle: Scalars['String']['output'];
    /** The ID of the entry type that contains the entry. */
    typeId: Scalars['Int']['output'];
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The element’s full URL */
    url?: Maybe<Scalars['String']['output']>;
  };

export type Offers_Tour_Entry_CountArgs = {
  field: Scalars['String']['input'];
};

export type Offers_Tour_EntryAccessibilityFeaturesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Tour_EntryAgeGroupsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Tour_EntryAncestorsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Tour_EntryAudiencesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Tour_EntryBadgeArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Tour_EntryChildrenArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Tour_EntryDescendantsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Tour_EntryExhibitionArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Tour_EntryImageArgs = {
  altText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  caption?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  credits?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified?: InputMaybe<Scalars['String']['input']>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalUrl?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mux?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uploader?: InputMaybe<Scalars['QueryArgument']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Offers_Tour_EntryLocalizedArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Tour_EntryLocationsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Tour_EntryMuseumArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Tour_EntryNextArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Tour_EntryOfferCategoryArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Tour_EntryOfferLanguagesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Tour_EntryOfferPricingInfoArgs = {
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level?: InputMaybe<Scalars['QueryArgument']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  primaryOwnerId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Offers_Tour_EntryParentArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Tour_EntryPrevArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Tour_EntryProposalCategoriesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Tour_EntrySchoolGradesArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offers_Tour_EntrySeomaticArgs = {
  asArray?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<SeomaticEnvironment>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type Offers_Tour_EntryTopicsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OpeningTimesExtra_TableRow = {
  __typename?: 'openingTimesExtra_TableRow';
  closingTime?: Maybe<Scalars['DateTime']['output']>;
  col1?: Maybe<Scalars['String']['output']>;
  col2?: Maybe<Scalars['DateTime']['output']>;
  col3?: Maybe<Scalars['DateTime']['output']>;
  days?: Maybe<Scalars['String']['output']>;
  openingTime?: Maybe<Scalars['DateTime']['output']>;
};

export type OpeningTimes_TableRow = {
  __typename?: 'openingTimes_TableRow';
  closingTime?: Maybe<Scalars['DateTime']['output']>;
  col1?: Maybe<Scalars['String']['output']>;
  col2?: Maybe<Scalars['DateTime']['output']>;
  col3?: Maybe<Scalars['DateTime']['output']>;
  day?: Maybe<Scalars['String']['output']>;
  openingTime?: Maybe<Scalars['DateTime']['output']>;
};

export type PagesSectionEntryUnion = Pages_Page_Entry;

export type Pages_Page_Entry = ElementInterface &
  EntryInterface & {
    __typename?: 'pages_page_Entry';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
    ancestors: Array<EntryInterface>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    banner: Array<Maybe<Banner_MatrixField>>;
    /** Returns the entry’s canonical ID. */
    canonicalId?: Maybe<Scalars['Int']['output']>;
    /** Returns the entry’s canonical UUID. */
    canonicalUid?: Maybe<Scalars['String']['output']>;
    /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
    children: Array<EntryInterface>;
    contentBlocks?: Maybe<Array<Maybe<ContentBlocks_NeoField>>>;
    darkMode?: Maybe<Scalars['Boolean']['output']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
    descendants: Array<EntryInterface>;
    description?: Maybe<NodeCollection>;
    /** The draft ID (from the `drafts` table). */
    draftId?: Maybe<Scalars['Int']['output']>;
    /** The name of the draft. */
    draftName?: Maybe<Scalars['String']['output']>;
    /** The notes for the draft. */
    draftNotes?: Maybe<Scalars['String']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the element is enabled for the site. */
    enabledForSite?: Maybe<Scalars['Boolean']['output']>;
    /** The expiry date of the entry. */
    expiryDate?: Maybe<Scalars['DateTime']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    image: Array<Maybe<AssetInterface>>;
    /** Returns whether this is a draft. */
    isDraft?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is a revision. */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is an unpublished draft. */
    isUnpublishedDraft?: Maybe<Scalars['Boolean']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The same element in other locales. */
    localized: Array<EntryInterface>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<EntryInterface>;
    /** The entry’s parent, if the section is a structure. */
    parent?: Maybe<EntryInterface>;
    /** The entry’s post date. */
    postDate?: Maybe<Scalars['DateTime']['output']>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<EntryInterface>;
    /** The revision ID (from the `revisions` table). */
    revisionId?: Maybe<Scalars['Int']['output']>;
    /** The revision notes (from the `revisions` table). */
    revisionNotes?: Maybe<Scalars['String']['output']>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the section that contains the entry. */
    sectionHandle: Scalars['String']['output'];
    /** The ID of the section that contains the entry. */
    sectionId: Scalars['Int']['output'];
    seoSettings?: Maybe<Scalars['String']['output']>;
    /** This query is used to query for SEOmatic meta data. */
    seomatic?: Maybe<SeomaticInterface>;
    shortLink?: Maybe<Scalars['String']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /**
     * Returns the entry’s canonical ID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
     */
    sourceId?: Maybe<Scalars['Int']['output']>;
    /**
     * Returns the entry’s canonical UUID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
     */
    sourceUid?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the entry type that contains the entry. */
    typeHandle: Scalars['String']['output'];
    /** The ID of the entry type that contains the entry. */
    typeId: Scalars['Int']['output'];
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The element’s full URL */
    url?: Maybe<Scalars['String']['output']>;
  };

export type Pages_Page_Entry_CountArgs = {
  field: Scalars['String']['input'];
};

export type Pages_Page_EntryAncestorsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Pages_Page_EntryBannerArgs = {
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  primaryOwnerId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Pages_Page_EntryChildrenArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Pages_Page_EntryContentBlocksArgs = {
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level?: InputMaybe<Scalars['QueryArgument']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  primaryOwnerId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Pages_Page_EntryDescendantsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Pages_Page_EntryImageArgs = {
  altText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  caption?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  credits?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified?: InputMaybe<Scalars['String']['input']>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalUrl?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mux?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uploader?: InputMaybe<Scalars['QueryArgument']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Pages_Page_EntryLocalizedArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Pages_Page_EntryNextArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Pages_Page_EntryParentArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Pages_Page_EntryPrevArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Pages_Page_EntrySeomaticArgs = {
  asArray?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<SeomaticEnvironment>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type ProposalCategoriesSectionEntryUnion =
  ProposalCategories_ProposalCategory_Entry;

export type ProposalCategories_ProposalCategory_Entry = ElementInterface &
  EntryInterface & {
    __typename?: 'proposalCategories_proposalCategory_Entry';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
    ancestors: Array<EntryInterface>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** Returns the entry’s canonical ID. */
    canonicalId?: Maybe<Scalars['Int']['output']>;
    /** Returns the entry’s canonical UUID. */
    canonicalUid?: Maybe<Scalars['String']['output']>;
    /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
    children: Array<EntryInterface>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
    descendants: Array<EntryInterface>;
    /** The draft ID (from the `drafts` table). */
    draftId?: Maybe<Scalars['Int']['output']>;
    /** The name of the draft. */
    draftName?: Maybe<Scalars['String']['output']>;
    /** The notes for the draft. */
    draftNotes?: Maybe<Scalars['String']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the element is enabled for the site. */
    enabledForSite?: Maybe<Scalars['Boolean']['output']>;
    /** The expiry date of the entry. */
    expiryDate?: Maybe<Scalars['DateTime']['output']>;
    externalData?: Maybe<Scalars['String']['output']>;
    externalId?: Maybe<Scalars['String']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** Returns whether this is a draft. */
    isDraft?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is a revision. */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is an unpublished draft. */
    isUnpublishedDraft?: Maybe<Scalars['Boolean']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The same element in other locales. */
    localized: Array<EntryInterface>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<EntryInterface>;
    /** The entry’s parent, if the section is a structure. */
    parent?: Maybe<EntryInterface>;
    /** The entry’s post date. */
    postDate?: Maybe<Scalars['DateTime']['output']>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<EntryInterface>;
    /** The revision ID (from the `revisions` table). */
    revisionId?: Maybe<Scalars['Int']['output']>;
    /** The revision notes (from the `revisions` table). */
    revisionNotes?: Maybe<Scalars['String']['output']>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the section that contains the entry. */
    sectionHandle: Scalars['String']['output'];
    /** The ID of the section that contains the entry. */
    sectionId: Scalars['Int']['output'];
    /** This query is used to query for SEOmatic meta data. */
    seomatic?: Maybe<SeomaticInterface>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /**
     * Returns the entry’s canonical ID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
     */
    sourceId?: Maybe<Scalars['Int']['output']>;
    /**
     * Returns the entry’s canonical UUID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
     */
    sourceUid?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    targetAudienceIds?: Maybe<Array<Maybe<TargetAudienceIds_TableRow>>>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the entry type that contains the entry. */
    typeHandle: Scalars['String']['output'];
    /** The ID of the entry type that contains the entry. */
    typeId: Scalars['Int']['output'];
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The element’s full URL */
    url?: Maybe<Scalars['String']['output']>;
  };

export type ProposalCategories_ProposalCategory_Entry_CountArgs = {
  field: Scalars['String']['input'];
};

export type ProposalCategories_ProposalCategory_EntryAncestorsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProposalCategories_ProposalCategory_EntryChildrenArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProposalCategories_ProposalCategory_EntryDescendantsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProposalCategories_ProposalCategory_EntryLocalizedArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProposalCategories_ProposalCategory_EntryNextArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProposalCategories_ProposalCategory_EntryParentArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProposalCategories_ProposalCategory_EntryPrevArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProposalCategories_ProposalCategory_EntrySeomaticArgs = {
  asArray?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<SeomaticEnvironment>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type SchoolGradesSectionEntryUnion = SchoolGrades_SchoolGrade_Entry;

export type SchoolGrades_SchoolGrade_Entry = ElementInterface &
  EntryInterface & {
    __typename?: 'schoolGrades_schoolGrade_Entry';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
    ancestors: Array<EntryInterface>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** Returns the entry’s canonical ID. */
    canonicalId?: Maybe<Scalars['Int']['output']>;
    /** Returns the entry’s canonical UUID. */
    canonicalUid?: Maybe<Scalars['String']['output']>;
    /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
    children: Array<EntryInterface>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
    descendants: Array<EntryInterface>;
    /** The draft ID (from the `drafts` table). */
    draftId?: Maybe<Scalars['Int']['output']>;
    /** The name of the draft. */
    draftName?: Maybe<Scalars['String']['output']>;
    /** The notes for the draft. */
    draftNotes?: Maybe<Scalars['String']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the element is enabled for the site. */
    enabledForSite?: Maybe<Scalars['Boolean']['output']>;
    /** The expiry date of the entry. */
    expiryDate?: Maybe<Scalars['DateTime']['output']>;
    externalData?: Maybe<Scalars['String']['output']>;
    externalId?: Maybe<Scalars['String']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** Returns whether this is a draft. */
    isDraft?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is a revision. */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is an unpublished draft. */
    isUnpublishedDraft?: Maybe<Scalars['Boolean']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The same element in other locales. */
    localized: Array<EntryInterface>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<EntryInterface>;
    /** The entry’s parent, if the section is a structure. */
    parent?: Maybe<EntryInterface>;
    /** The entry’s post date. */
    postDate?: Maybe<Scalars['DateTime']['output']>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<EntryInterface>;
    /** The revision ID (from the `revisions` table). */
    revisionId?: Maybe<Scalars['Int']['output']>;
    /** The revision notes (from the `revisions` table). */
    revisionNotes?: Maybe<Scalars['String']['output']>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the section that contains the entry. */
    sectionHandle: Scalars['String']['output'];
    /** The ID of the section that contains the entry. */
    sectionId: Scalars['Int']['output'];
    seoSettings?: Maybe<Scalars['String']['output']>;
    /** This query is used to query for SEOmatic meta data. */
    seomatic?: Maybe<SeomaticInterface>;
    shortLink?: Maybe<Scalars['String']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /**
     * Returns the entry’s canonical ID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
     */
    sourceId?: Maybe<Scalars['Int']['output']>;
    /**
     * Returns the entry’s canonical UUID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
     */
    sourceUid?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the entry type that contains the entry. */
    typeHandle: Scalars['String']['output'];
    /** The ID of the entry type that contains the entry. */
    typeId: Scalars['Int']['output'];
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The element’s full URL */
    url?: Maybe<Scalars['String']['output']>;
  };

export type SchoolGrades_SchoolGrade_Entry_CountArgs = {
  field: Scalars['String']['input'];
};

export type SchoolGrades_SchoolGrade_EntryAncestorsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SchoolGrades_SchoolGrade_EntryChildrenArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SchoolGrades_SchoolGrade_EntryDescendantsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SchoolGrades_SchoolGrade_EntryLocalizedArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SchoolGrades_SchoolGrade_EntryNextArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SchoolGrades_SchoolGrade_EntryParentArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SchoolGrades_SchoolGrade_EntryPrevArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SchoolGrades_SchoolGrade_EntrySeomaticArgs = {
  asArray?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<SeomaticEnvironment>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type SiteInfoSectionEntryUnion = SiteInfo_SiteInfo_Entry;

export type SiteInfo_SiteInfo_Entry = ElementInterface &
  EntryInterface & {
    __typename?: 'siteInfo_siteInfo_Entry';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
    ancestors: Array<EntryInterface>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** Returns the entry’s canonical ID. */
    canonicalId?: Maybe<Scalars['Int']['output']>;
    /** Returns the entry’s canonical UUID. */
    canonicalUid?: Maybe<Scalars['String']['output']>;
    /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
    children: Array<EntryInterface>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
    descendants: Array<EntryInterface>;
    /** The draft ID (from the `drafts` table). */
    draftId?: Maybe<Scalars['Int']['output']>;
    /** The name of the draft. */
    draftName?: Maybe<Scalars['String']['output']>;
    /** The notes for the draft. */
    draftNotes?: Maybe<Scalars['String']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the element is enabled for the site. */
    enabledForSite?: Maybe<Scalars['Boolean']['output']>;
    /** The expiry date of the entry. */
    expiryDate?: Maybe<Scalars['DateTime']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** Returns whether this is a draft. */
    isDraft?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is a revision. */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is an unpublished draft. */
    isUnpublishedDraft?: Maybe<Scalars['Boolean']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The same element in other locales. */
    localized: Array<EntryInterface>;
    metaDescription?: Maybe<NodeCollection>;
    metaImage: Array<Maybe<AssetInterface>>;
    metaTitle?: Maybe<Scalars['String']['output']>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<EntryInterface>;
    /** The entry’s parent, if the section is a structure. */
    parent?: Maybe<EntryInterface>;
    /** The entry’s post date. */
    postDate?: Maybe<Scalars['DateTime']['output']>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<EntryInterface>;
    /** The revision ID (from the `revisions` table). */
    revisionId?: Maybe<Scalars['Int']['output']>;
    /** The revision notes (from the `revisions` table). */
    revisionNotes?: Maybe<Scalars['String']['output']>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the section that contains the entry. */
    sectionHandle: Scalars['String']['output'];
    /** The ID of the section that contains the entry. */
    sectionId: Scalars['Int']['output'];
    /** This query is used to query for SEOmatic meta data. */
    seomatic?: Maybe<SeomaticInterface>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /**
     * Returns the entry’s canonical ID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
     */
    sourceId?: Maybe<Scalars['Int']['output']>;
    /**
     * Returns the entry’s canonical UUID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
     */
    sourceUid?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the entry type that contains the entry. */
    typeHandle: Scalars['String']['output'];
    /** The ID of the entry type that contains the entry. */
    typeId: Scalars['Int']['output'];
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The element’s full URL */
    url?: Maybe<Scalars['String']['output']>;
  };

export type SiteInfo_SiteInfo_Entry_CountArgs = {
  field: Scalars['String']['input'];
};

export type SiteInfo_SiteInfo_EntryAncestorsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SiteInfo_SiteInfo_EntryChildrenArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SiteInfo_SiteInfo_EntryDescendantsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SiteInfo_SiteInfo_EntryLocalizedArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SiteInfo_SiteInfo_EntryMetaImageArgs = {
  altText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  caption?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  credits?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified?: InputMaybe<Scalars['String']['input']>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalUrl?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mux?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uploader?: InputMaybe<Scalars['QueryArgument']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SiteInfo_SiteInfo_EntryNextArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SiteInfo_SiteInfo_EntryParentArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SiteInfo_SiteInfo_EntryPrevArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SiteInfo_SiteInfo_EntrySeomaticArgs = {
  asArray?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<SeomaticEnvironment>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type Subtitles_MatrixField = Subtitles_Subtitle_BlockType;

export type Subtitles_Subtitle_BlockType = ElementInterface &
  MatrixBlockInterface & {
    __typename?: 'subtitles_subtitle_BlockType';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The ID of the field that owns the matrix block. */
    fieldId: Scalars['Int']['output'];
    file: Array<Maybe<AssetInterface>>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    label?: Maybe<Scalars['String']['output']>;
    lang?: Maybe<Scalars['String']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The ID of the primary owner of the Matrix block. */
    primaryOwnerId: Scalars['Int']['output'];
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The sort order of the matrix block within the owner element field. */
    sortOrder?: Maybe<Scalars['Int']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the matrix block’s type. */
    typeHandle: Scalars['String']['output'];
    /** The ID of the matrix block’s type. */
    typeId: Scalars['Int']['output'];
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
  };

export type Subtitles_Subtitle_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type Subtitles_Subtitle_BlockTypeFileArgs = {
  altText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  caption?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  credits?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified?: InputMaybe<Scalars['String']['input']>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalUrl?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mux?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uploader?: InputMaybe<Scalars['QueryArgument']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TargetAudienceIds_TableRow = {
  __typename?: 'targetAudienceIds_TableRow';
  col1?: Maybe<Scalars['Number']['output']>;
  externalId?: Maybe<Scalars['Number']['output']>;
};

export type TextSnippets_GlobalSet = ElementInterface &
  GlobalSetInterface & {
    __typename?: 'textSnippets_GlobalSet';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    confirmPrivacy?: Maybe<NodeCollection>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the global set. */
    handle: Scalars['String']['output'];
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The name of the global set. */
    name: Scalars['String']['output'];
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
  };

export type TextSnippets_GlobalSet_CountArgs = {
  field: Scalars['String']['input'];
};

export type TopicsSectionEntryUnion = Topics_Topic_Entry;

export type Topics_Topic_Entry = ElementInterface &
  EntryInterface & {
    __typename?: 'topics_topic_Entry';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']['output']>;
    /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
    ancestors: Array<EntryInterface>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']['output']>;
    /** Returns the entry’s canonical ID. */
    canonicalId?: Maybe<Scalars['Int']['output']>;
    /** Returns the entry’s canonical UUID. */
    canonicalUid?: Maybe<Scalars['String']['output']>;
    /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
    children: Array<EntryInterface>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']['output']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']['output']>;
    /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
    descendants: Array<EntryInterface>;
    /** The draft ID (from the `drafts` table). */
    draftId?: Maybe<Scalars['Int']['output']>;
    /** The name of the draft. */
    draftName?: Maybe<Scalars['String']['output']>;
    /** The notes for the draft. */
    draftNotes?: Maybe<Scalars['String']['output']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the element is enabled for the site. */
    enabledForSite?: Maybe<Scalars['Boolean']['output']>;
    /** The expiry date of the entry. */
    expiryDate?: Maybe<Scalars['DateTime']['output']>;
    externalData?: Maybe<Scalars['String']['output']>;
    externalId?: Maybe<Scalars['String']['output']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']['output']>;
    /** Returns whether this is a draft. */
    isDraft?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is a revision. */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    /** Returns whether this is an unpublished draft. */
    isUnpublishedDraft?: Maybe<Scalars['Boolean']['output']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']['output']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']['output']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']['output']>;
    /** The same element in other locales. */
    localized: Array<EntryInterface>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<EntryInterface>;
    /** The entry’s parent, if the section is a structure. */
    parent?: Maybe<EntryInterface>;
    /** The entry’s post date. */
    postDate?: Maybe<Scalars['DateTime']['output']>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<EntryInterface>;
    /** The revision ID (from the `revisions` table). */
    revisionId?: Maybe<Scalars['Int']['output']>;
    /** The revision notes (from the `revisions` table). */
    revisionNotes?: Maybe<Scalars['String']['output']>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']['output']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']['output']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']['output']>;
    /** The handle of the section that contains the entry. */
    sectionHandle: Scalars['String']['output'];
    /** The ID of the section that contains the entry. */
    sectionId: Scalars['Int']['output'];
    seoSettings?: Maybe<Scalars['String']['output']>;
    /** This query is used to query for SEOmatic meta data. */
    seomatic?: Maybe<SeomaticInterface>;
    shortLink?: Maybe<Scalars['String']['output']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']['output']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']['output']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']['output']>;
    /**
     * Returns the entry’s canonical ID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
     */
    sourceId?: Maybe<Scalars['Int']['output']>;
    /**
     * Returns the entry’s canonical UUID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
     */
    sourceUid?: Maybe<Scalars['String']['output']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']['output']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']['output']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']['output']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']['output']>;
    /** The handle of the entry type that contains the entry. */
    typeHandle: Scalars['String']['output'];
    /** The ID of the entry type that contains the entry. */
    typeId: Scalars['Int']['output'];
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']['output']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']['output']>;
    /** The element’s full URL */
    url?: Maybe<Scalars['String']['output']>;
  };

export type Topics_Topic_Entry_CountArgs = {
  field: Scalars['String']['input'];
};

export type Topics_Topic_EntryAncestorsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Topics_Topic_EntryChildrenArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Topics_Topic_EntryDescendantsArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Topics_Topic_EntryLocalizedArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Topics_Topic_EntryNextArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Topics_Topic_EntryParentArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Topics_Topic_EntryPrevArgs = {
  accessibilityFeatures?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
  ageGroups?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ageRating?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ancestorDist?: InputMaybe<Scalars['Int']['input']>;
  ancestorOf?: InputMaybe<Scalars['Int']['input']>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  badge?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist?: InputMaybe<Scalars['Int']['input']>;
  descendantOf?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  duration?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  endDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  exhibition?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalData?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  externalId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  heading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideHeading?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse?: InputMaybe<Scalars['Boolean']['input']>;
  infoLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  infoText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  isBookable?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isRegisterable?: InputMaybe<Scalars['Boolean']['input']>;
  isSchoolOptional?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  label?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  maxParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  metaImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  metaTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minParticipants?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  museum?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  offerCategory?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offerLanguages?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  offers?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  openingTimes?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  openingTimesExtra?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  positionedAfter?: InputMaybe<Scalars['Int']['input']>;
  positionedBefore?: InputMaybe<Scalars['Int']['input']>;
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>;
  proposalCategories?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  purchaseLink?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  refundPolicyText?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  schoolGrades?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  schoolGradesFormated?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seoSettings?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  shortLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  shortTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTimeFormatted?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  storeHours?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  streetAddress?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  structureId?: InputMaybe<Scalars['Int']['input']>;
  subheading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  targetAudienceIds?: InputMaybe<
    Array<InputMaybe<Scalars['QueryArgument']['input']>>
  >;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Topics_Topic_EntrySeomaticArgs = {
  asArray?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<SeomaticEnvironment>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type GetTitleQueryVariables = Exact<{
  uri?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetTitleQuery = {
  __typename?: 'Query';
  seomatic?: {
    __typename?: 'SeomaticType';
    metaTitleContainer?: string | null;
    metaTagContainer?: string | null;
  } | null;
  linkTags?: {
    __typename?: 'SeomaticType';
    metaLinkContainer?: string | null;
    metaJsonLdContainer?: string | null;
    metaScriptContainer?: string | null;
  } | null;
};

type Node_FooterNavigation_Node_Fragment = {
  __typename?: 'footerNavigation_Node';
  id?: string | null;
  siteId?: number | null;
  title?: string | null;
  urlSuffix?: string | null;
  classes?: string | null;
  url?: string | null;
  nodeUri?: string | null;
  slug?: string | null;
  newWindow?: string | null;
  type?: string | null;
  customAttributes?: Array<{
    __typename?: 'NodeCustomAttribute';
    attribute?: string | null;
    value?: string | null;
  } | null> | null;
};

type Node_FooterSubNavigation_Node_Fragment = {
  __typename?: 'footerSubNavigation_Node';
  id?: string | null;
  siteId?: number | null;
  title?: string | null;
  urlSuffix?: string | null;
  classes?: string | null;
  url?: string | null;
  nodeUri?: string | null;
  slug?: string | null;
  newWindow?: string | null;
  type?: string | null;
  customAttributes?: Array<{
    __typename?: 'NodeCustomAttribute';
    attribute?: string | null;
    value?: string | null;
  } | null> | null;
};

type Node_IconNavigation_Node_Fragment = {
  __typename?: 'iconNavigation_Node';
  id?: string | null;
  siteId?: number | null;
  title?: string | null;
  urlSuffix?: string | null;
  classes?: string | null;
  url?: string | null;
  nodeUri?: string | null;
  slug?: string | null;
  newWindow?: string | null;
  type?: string | null;
  icon?: { __typename?: 'icon_Icon'; inline?: string | null } | null;
  customAttributes?: Array<{
    __typename?: 'NodeCustomAttribute';
    attribute?: string | null;
    value?: string | null;
  } | null> | null;
};

type Node_MainNavigation_Node_Fragment = {
  __typename?: 'mainNavigation_Node';
  id?: string | null;
  siteId?: number | null;
  title?: string | null;
  urlSuffix?: string | null;
  classes?: string | null;
  url?: string | null;
  nodeUri?: string | null;
  slug?: string | null;
  newWindow?: string | null;
  type?: string | null;
  customAttributes?: Array<{
    __typename?: 'NodeCustomAttribute';
    attribute?: string | null;
    value?: string | null;
  } | null> | null;
};

type Node_MainSubNavigation_Node_Fragment = {
  __typename?: 'mainSubNavigation_Node';
  id?: string | null;
  siteId?: number | null;
  title?: string | null;
  urlSuffix?: string | null;
  classes?: string | null;
  url?: string | null;
  nodeUri?: string | null;
  slug?: string | null;
  newWindow?: string | null;
  type?: string | null;
  customAttributes?: Array<{
    __typename?: 'NodeCustomAttribute';
    attribute?: string | null;
    value?: string | null;
  } | null> | null;
};

export type NodeFragment =
  | Node_FooterNavigation_Node_Fragment
  | Node_FooterSubNavigation_Node_Fragment
  | Node_IconNavigation_Node_Fragment
  | Node_MainNavigation_Node_Fragment
  | Node_MainSubNavigation_Node_Fragment;

export type GetNavigationQueryVariables = Exact<{
  site?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetNavigationQuery = {
  __typename?: 'Query';
  iconNavigation?: Array<
    | {
        __typename?: 'footerNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | {
        __typename?: 'footerSubNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | {
        __typename?: 'iconNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        icon?: { __typename?: 'icon_Icon'; inline?: string | null } | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | {
        __typename?: 'mainNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | {
        __typename?: 'mainSubNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | null
  > | null;
  mainNavigation?: Array<
    | {
        __typename?: 'footerNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | {
        __typename?: 'footerSubNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | {
        __typename?: 'iconNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        icon?: { __typename?: 'icon_Icon'; inline?: string | null } | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | {
        __typename?: 'mainNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | {
        __typename?: 'mainSubNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | null
  > | null;
  mainSubNavigation?: Array<
    | {
        __typename?: 'footerNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        children?: Array<
          | {
              __typename?: 'footerNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'footerSubNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'iconNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              icon?: {
                __typename?: 'icon_Icon';
                inline?: string | null;
              } | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'mainNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'mainSubNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | null
        > | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | {
        __typename?: 'footerSubNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        children?: Array<
          | {
              __typename?: 'footerNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'footerSubNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'iconNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              icon?: {
                __typename?: 'icon_Icon';
                inline?: string | null;
              } | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'mainNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'mainSubNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | null
        > | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | {
        __typename?: 'iconNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        children?: Array<
          | {
              __typename?: 'footerNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'footerSubNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'iconNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              icon?: {
                __typename?: 'icon_Icon';
                inline?: string | null;
              } | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'mainNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'mainSubNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | null
        > | null;
        icon?: { __typename?: 'icon_Icon'; inline?: string | null } | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | {
        __typename?: 'mainNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        children?: Array<
          | {
              __typename?: 'footerNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'footerSubNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'iconNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              icon?: {
                __typename?: 'icon_Icon';
                inline?: string | null;
              } | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'mainNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'mainSubNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | null
        > | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | {
        __typename?: 'mainSubNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        children?: Array<
          | {
              __typename?: 'footerNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'footerSubNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'iconNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              icon?: {
                __typename?: 'icon_Icon';
                inline?: string | null;
              } | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'mainNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'mainSubNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | null
        > | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | null
  > | null;
  footerNavigation?: Array<
    | {
        __typename?: 'footerNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        children?: Array<
          | {
              __typename?: 'footerNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'footerSubNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'iconNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              icon?: {
                __typename?: 'icon_Icon';
                inline?: string | null;
              } | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'mainNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'mainSubNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | null
        > | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | {
        __typename?: 'footerSubNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        children?: Array<
          | {
              __typename?: 'footerNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'footerSubNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'iconNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              icon?: {
                __typename?: 'icon_Icon';
                inline?: string | null;
              } | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'mainNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'mainSubNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | null
        > | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | {
        __typename?: 'iconNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        children?: Array<
          | {
              __typename?: 'footerNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'footerSubNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'iconNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              icon?: {
                __typename?: 'icon_Icon';
                inline?: string | null;
              } | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'mainNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'mainSubNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | null
        > | null;
        icon?: { __typename?: 'icon_Icon'; inline?: string | null } | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | {
        __typename?: 'mainNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        children?: Array<
          | {
              __typename?: 'footerNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'footerSubNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'iconNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              icon?: {
                __typename?: 'icon_Icon';
                inline?: string | null;
              } | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'mainNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'mainSubNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | null
        > | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | {
        __typename?: 'mainSubNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        children?: Array<
          | {
              __typename?: 'footerNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'footerSubNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'iconNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              icon?: {
                __typename?: 'icon_Icon';
                inline?: string | null;
              } | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'mainNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'mainSubNavigation_Node';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              urlSuffix?: string | null;
              classes?: string | null;
              url?: string | null;
              nodeUri?: string | null;
              slug?: string | null;
              newWindow?: string | null;
              type?: string | null;
              customAttributes?: Array<{
                __typename?: 'NodeCustomAttribute';
                attribute?: string | null;
                value?: string | null;
              } | null> | null;
            }
          | null
        > | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | null
  > | null;
  footerSubNavigation?: Array<
    | {
        __typename?: 'footerNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | {
        __typename?: 'footerSubNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | {
        __typename?: 'iconNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        icon?: { __typename?: 'icon_Icon'; inline?: string | null } | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | {
        __typename?: 'mainNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | {
        __typename?: 'mainSubNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        urlSuffix?: string | null;
        classes?: string | null;
        url?: string | null;
        nodeUri?: string | null;
        slug?: string | null;
        newWindow?: string | null;
        type?: string | null;
        customAttributes?: Array<{
          __typename?: 'NodeCustomAttribute';
          attribute?: string | null;
          value?: string | null;
        } | null> | null;
      }
    | null
  > | null;
};

export type GetExhibitionQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetExhibitionQuery = {
  __typename?: 'Query';
  entry?:
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'badges_badge_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'countries_country_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        heading?: string | null;
        url?: string | null;
        uri?: string | null;
        slug?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
        description?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        bodyText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        locations: Array<
          | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | { __typename?: 'badges_badge_Entry' }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              heading?: string | null;
              streetAddress?: string | null;
              postalCode?: string | null;
              city?: string | null;
              latitude?: any | null;
              longitude?: any | null;
            }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
        subheading?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        dateSpans?: Array<{
          __typename?: 'dateSpans_dateSpan_BlockType';
          startDateTime?: any | null;
          endDateTime?: any | null;
        } | null> | null;
        museum: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              heading?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | null
        >;
        image: Array<
          | { __typename?: 'documents_Asset' }
          | {
              __typename?: 'mediaFiles_Asset';
              id?: string | null;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              focalPoint?: Array<number | null> | null;
              srcset?: string | null;
              altText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              caption?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              credits?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
            }
          | null
        >;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'locations_location_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'museums_museum_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offers_event_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offers_tour_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'pages_page_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'topics_topic_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | null;
  groupOffersEntries?: Array<
    | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
    | { __typename?: 'ageGroups_ageGroup_Entry' }
    | { __typename?: 'audiences_audience_Entry' }
    | { __typename?: 'badges_badge_Entry' }
    | { __typename?: 'countries_country_Entry' }
    | { __typename?: 'exhibitions_exhibition_Entry' }
    | { __typename?: 'goMusSettings_goMusSettings_Entry' }
    | { __typename?: 'homePage_homePage_Entry' }
    | { __typename?: 'locations_location_Entry' }
    | { __typename?: 'museumsOverview_museumsOverview_Entry' }
    | { __typename?: 'museums_museum_Entry' }
    | { __typename?: 'newsItems_newsItem_Entry' }
    | { __typename?: 'offerCategories_offerCategory_Entry' }
    | { __typename?: 'offerLanguages_offerLanguage_Entry' }
    | { __typename?: 'offersOverview_offersOverview_Entry' }
    | { __typename?: 'offers_event_Entry' }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        typeHandle: string;
        language?: string | null;
        isBookable?: boolean | null;
        isRegisterable?: boolean | null;
        isFeatured?: boolean | null;
        title?: string | null;
        heading?: string | null;
        externalId?: string | null;
        url?: string | null;
        uri?: string | null;
        slug?: string | null;
        duration?: any | null;
        subheading?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        imageSquare: Array<
          | { __typename?: 'documents_Asset' }
          | {
              __typename?: 'mediaFiles_Asset';
              id?: string | null;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              focalPoint?: Array<number | null> | null;
              srcset?: string | null;
              altText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              caption?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              credits?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
            }
          | null
        >;
        museum: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              heading?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | null
        >;
        exhibition: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              heading?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | null
        >;
        offerCategory: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        topics: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        accessibilityFeatures: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              icon?: {
                __typename?: 'icon_Icon';
                inline?: string | null;
              } | null;
            }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | { __typename?: 'badges_badge_Entry' }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | { __typename?: 'locations_location_Entry' }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
        badge: Array<
          | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              isHighlighted?: boolean | null;
            }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | { __typename?: 'locations_location_Entry' }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
      }
    | { __typename?: 'pages_page_Entry' }
    | { __typename?: 'proposalCategories_proposalCategory_Entry' }
    | { __typename?: 'schoolGrades_schoolGrade_Entry' }
    | { __typename?: 'siteInfo_siteInfo_Entry' }
    | { __typename?: 'topics_topic_Entry' }
    | null
  > | null;
  offersEntries?: Array<
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        typeHandle: string;
        language?: string | null;
        isBookable?: boolean | null;
        isRegisterable?: boolean | null;
        isFeatured?: boolean | null;
        title?: string | null;
        heading?: string | null;
        externalId?: string | null;
        url?: string | null;
        uri?: string | null;
        slug?: string | null;
        duration?: any | null;
        subheading?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        imageSquare: Array<
          | { __typename?: 'documents_Asset' }
          | {
              __typename?: 'mediaFiles_Asset';
              id?: string | null;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              focalPoint?: Array<number | null> | null;
              srcset?: string | null;
              altText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              caption?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              credits?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
            }
          | null
        >;
        museum: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              heading?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | null
        >;
        exhibition: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              heading?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | null
        >;
        offerCategory: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        topics: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        accessibilityFeatures: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              icon?: {
                __typename?: 'icon_Icon';
                inline?: string | null;
              } | null;
            }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | { __typename?: 'badges_badge_Entry' }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | { __typename?: 'locations_location_Entry' }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
        badge: Array<
          | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              isHighlighted?: boolean | null;
            }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | { __typename?: 'locations_location_Entry' }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
      }
    | { __typename?: 'offers_tour_Entry' }
    | null
  > | null;
  offersOverviewEntries?: Array<{
    __typename?: 'offersOverview_offersOverview_Entry';
    id?: string | null;
    siteId?: number | null;
    url?: string | null;
    title?: string | null;
    heading?: string | null;
  } | null> | null;
};

export type GetHomepageQueryVariables = Exact<{
  site?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetHomepageQuery = {
  __typename?: 'Query';
  entry?:
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'badges_badge_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'countries_country_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        heading?: string | null;
        hideHeading?: boolean | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
        homepageIntro?: Array<
          | {
              __typename?: 'homepageIntro_element_BlockType';
              heading?: string | null;
              mediaAsset: Array<
                | { __typename?: 'documents_Asset' }
                | {
                    __typename?: 'mediaFiles_Asset';
                    id?: string | null;
                    kind: string;
                    url?: string | null;
                    focalPoint?: Array<number | null> | null;
                    width?: number | null;
                    height?: number | null;
                    mimeType?: string | null;
                    videoUrl?: string | null;
                    srcset?: string | null;
                    altText?: {
                      __typename?: 'NodeCollection';
                      renderHtml?: string | null;
                    } | null;
                    caption?: {
                      __typename?: 'NodeCollection';
                      renderHtml?: string | null;
                    } | null;
                    credits?: {
                      __typename?: 'NodeCollection';
                      renderHtml?: string | null;
                    } | null;
                    mux?: {
                      __typename?: 'mux_MuxMateField';
                      playback_id?: string | null;
                      error?: string | null;
                    } | null;
                    subtitles: Array<{
                      __typename?: 'subtitles_subtitle_BlockType';
                      id?: string | null;
                      label?: string | null;
                      lang?: string | null;
                      file: Array<
                        | {
                            __typename?: 'documents_Asset';
                            id?: string | null;
                            url?: string | null;
                            mimeType?: string | null;
                            format?: string | null;
                            kind: string;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'mediaFiles_Asset';
                            id?: string | null;
                            url?: string | null;
                            mimeType?: string | null;
                            format?: string | null;
                            kind: string;
                            title?: string | null;
                          }
                        | null
                      >;
                    } | null>;
                  }
                | null
              >;
              children?: Array<
                | { __typename?: 'contentBlocks_accordionItem_BlockType' }
                | { __typename?: 'contentBlocks_accordion_BlockType' }
                | { __typename?: 'contentBlocks_cookieInfo_BlockType' }
                | { __typename?: 'contentBlocks_filteredCalendar_BlockType' }
                | { __typename?: 'contentBlocks_mediaItem_BlockType' }
                | { __typename?: 'contentBlocks_newsletterSubscribe_BlockType' }
                | { __typename?: 'contentBlocks_oembed_BlockType' }
                | { __typename?: 'contentBlocks_spacer_BlockType' }
                | { __typename?: 'contentBlocks_textBlock_BlockType' }
                | { __typename?: 'contentBlocks_textMediaBlock_BlockType' }
                | { __typename?: 'contentBlocks_topic_BlockType' }
                | { __typename?: 'contentBlocks_videoPlayer_BlockType' }
                | { __typename?: 'dateSpans_dateSpan_BlockType' }
                | { __typename?: 'homepageIntro_element_BlockType' }
                | {
                    __typename?: 'homepageIntro_link_BlockType';
                    anchorId?: string | null;
                    label?: string | null;
                    linkStyle?: string | null;
                    webLink?: {
                      __typename?: 'Linkit_Link';
                      target?: string | null;
                      text?: string | null;
                      url?: string | null;
                      typeHandle?: string | null;
                      element?:
                        | {
                            __typename?: 'Address';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'Element';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'User';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'ageGroups_ageGroup_Entry';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'audiences_audience_Entry';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'badges_badge_Entry';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'banner_banner_BlockType';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'contentBlocks_accordionItem_BlockType';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'contentBlocks_accordion_BlockType';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'contentBlocks_cookieInfo_BlockType';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'contentBlocks_filteredCalendar_BlockType';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'contentBlocks_mediaItem_BlockType';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'contentBlocks_oembed_BlockType';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'contentBlocks_spacer_BlockType';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'contentBlocks_textBlock_BlockType';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'contentBlocks_textMediaBlock_BlockType';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'contentBlocks_topic_BlockType';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'contentBlocks_videoPlayer_BlockType';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'countries_country_Entry';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'dateSpans_dateSpan_BlockType';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'documents_Asset';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'exhibitions_exhibition_Entry';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'footerNavigation_Node';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'footerSubNavigation_Node';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'goMusSettings_goMusSettings_Entry';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'homePage_homePage_Entry';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'homepageIntro_element_BlockType';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'homepageIntro_link_BlockType';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'iconNavigation_Node';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'locations_location_Entry';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'mainNavigation_Node';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'mainSubNavigation_Node';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'mediaFiles_Asset';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'moreLink_link_BlockType';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'moreLinks_link_BlockType';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'museumsOverview_museumsOverview_Entry';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'museums_museum_Entry';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'newsItems_newsItem_Entry';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'newsletter_Form';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'newsletter_Submission';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'offerCategories_offerCategory_Entry';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'offerDates_offerDate_BlockType';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'offerLanguages_offerLanguage_Entry';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'offersOverview_offersOverview_Entry';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'offers_event_Entry';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'offers_tour_Entry';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'pages_page_Entry';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'proposalCategories_proposalCategory_Entry';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'schoolGrades_schoolGrade_Entry';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'siteInfo_siteInfo_Entry';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'subtitles_subtitle_BlockType';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'textSnippets_GlobalSet';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'topics_topic_Entry';
                            id?: string | null;
                            siteId?: number | null;
                            title?: string | null;
                          }
                        | null;
                    } | null;
                  }
                | { __typename?: 'moreLink_link_BlockType' }
                | { __typename?: 'moreLinks_link_BlockType' }
                | { __typename?: 'offerDates_offerDate_BlockType' }
                | { __typename?: 'offerPricingInfo_offerPricingInfo_BlockType' }
                | null
              > | null;
            }
          | { __typename?: 'homepageIntro_link_BlockType' }
          | null
        > | null;
        offers: Array<
          | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | { __typename?: 'badges_badge_Entry' }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | { __typename?: 'locations_location_Entry' }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              typeHandle: string;
              language?: string | null;
              isBookable?: boolean | null;
              isRegisterable?: boolean | null;
              isFeatured?: boolean | null;
              title?: string | null;
              heading?: string | null;
              externalId?: string | null;
              url?: string | null;
              uri?: string | null;
              slug?: string | null;
              duration?: any | null;
              subheading?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              imageSquare: Array<
                | { __typename?: 'documents_Asset' }
                | {
                    __typename?: 'mediaFiles_Asset';
                    id?: string | null;
                    url?: string | null;
                    width?: number | null;
                    height?: number | null;
                    focalPoint?: Array<number | null> | null;
                    srcset?: string | null;
                    altText?: {
                      __typename?: 'NodeCollection';
                      renderHtml?: string | null;
                    } | null;
                    caption?: {
                      __typename?: 'NodeCollection';
                      renderHtml?: string | null;
                    } | null;
                    credits?: {
                      __typename?: 'NodeCollection';
                      renderHtml?: string | null;
                    } | null;
                  }
                | null
              >;
              museum: Array<
                | {
                    __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'ageGroups_ageGroup_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'audiences_audience_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'badges_badge_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'countries_country_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'exhibitions_exhibition_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'goMusSettings_goMusSettings_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'homePage_homePage_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'locations_location_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'museumsOverview_museumsOverview_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'museums_museum_Entry';
                    heading?: string | null;
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'newsItems_newsItem_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'offerCategories_offerCategory_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'offerLanguages_offerLanguage_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'offersOverview_offersOverview_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'offers_event_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'offers_tour_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'pages_page_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'proposalCategories_proposalCategory_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'schoolGrades_schoolGrade_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'siteInfo_siteInfo_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'topics_topic_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | null
              >;
              exhibition: Array<
                | {
                    __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'ageGroups_ageGroup_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'audiences_audience_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'badges_badge_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'countries_country_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'exhibitions_exhibition_Entry';
                    heading?: string | null;
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'goMusSettings_goMusSettings_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'homePage_homePage_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'locations_location_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'museumsOverview_museumsOverview_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'museums_museum_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'newsItems_newsItem_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'offerCategories_offerCategory_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'offerLanguages_offerLanguage_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'offersOverview_offersOverview_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'offers_event_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'offers_tour_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'pages_page_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'proposalCategories_proposalCategory_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'schoolGrades_schoolGrade_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'siteInfo_siteInfo_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'topics_topic_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | null
              >;
              offerCategory: Array<
                | {
                    __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'ageGroups_ageGroup_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'audiences_audience_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'badges_badge_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'countries_country_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'exhibitions_exhibition_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'goMusSettings_goMusSettings_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'homePage_homePage_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'locations_location_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'museumsOverview_museumsOverview_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'museums_museum_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'newsItems_newsItem_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offerCategories_offerCategory_Entry';
                    externalId?: string | null;
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offerLanguages_offerLanguage_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offersOverview_offersOverview_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offers_event_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offers_tour_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'pages_page_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'proposalCategories_proposalCategory_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'schoolGrades_schoolGrade_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'siteInfo_siteInfo_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'topics_topic_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | null
              >;
              topics: Array<
                | {
                    __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'ageGroups_ageGroup_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'audiences_audience_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'badges_badge_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'countries_country_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'exhibitions_exhibition_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'goMusSettings_goMusSettings_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'homePage_homePage_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'locations_location_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'museumsOverview_museumsOverview_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'museums_museum_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'newsItems_newsItem_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offerCategories_offerCategory_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offerLanguages_offerLanguage_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offersOverview_offersOverview_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offers_event_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offers_tour_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'pages_page_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'proposalCategories_proposalCategory_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'schoolGrades_schoolGrade_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'siteInfo_siteInfo_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'topics_topic_Entry';
                    externalId?: string | null;
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | null
              >;
              accessibilityFeatures: Array<
                | {
                    __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    icon?: {
                      __typename?: 'icon_Icon';
                      inline?: string | null;
                    } | null;
                  }
                | { __typename?: 'ageGroups_ageGroup_Entry' }
                | { __typename?: 'audiences_audience_Entry' }
                | { __typename?: 'badges_badge_Entry' }
                | { __typename?: 'countries_country_Entry' }
                | { __typename?: 'exhibitions_exhibition_Entry' }
                | { __typename?: 'goMusSettings_goMusSettings_Entry' }
                | { __typename?: 'homePage_homePage_Entry' }
                | { __typename?: 'locations_location_Entry' }
                | { __typename?: 'museumsOverview_museumsOverview_Entry' }
                | { __typename?: 'museums_museum_Entry' }
                | { __typename?: 'newsItems_newsItem_Entry' }
                | { __typename?: 'offerCategories_offerCategory_Entry' }
                | { __typename?: 'offerLanguages_offerLanguage_Entry' }
                | { __typename?: 'offersOverview_offersOverview_Entry' }
                | { __typename?: 'offers_event_Entry' }
                | { __typename?: 'offers_tour_Entry' }
                | { __typename?: 'pages_page_Entry' }
                | { __typename?: 'proposalCategories_proposalCategory_Entry' }
                | { __typename?: 'schoolGrades_schoolGrade_Entry' }
                | { __typename?: 'siteInfo_siteInfo_Entry' }
                | { __typename?: 'topics_topic_Entry' }
                | null
              >;
              badge: Array<
                | {
                    __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
                  }
                | { __typename?: 'ageGroups_ageGroup_Entry' }
                | { __typename?: 'audiences_audience_Entry' }
                | {
                    __typename?: 'badges_badge_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    isHighlighted?: boolean | null;
                  }
                | { __typename?: 'countries_country_Entry' }
                | { __typename?: 'exhibitions_exhibition_Entry' }
                | { __typename?: 'goMusSettings_goMusSettings_Entry' }
                | { __typename?: 'homePage_homePage_Entry' }
                | { __typename?: 'locations_location_Entry' }
                | { __typename?: 'museumsOverview_museumsOverview_Entry' }
                | { __typename?: 'museums_museum_Entry' }
                | { __typename?: 'newsItems_newsItem_Entry' }
                | { __typename?: 'offerCategories_offerCategory_Entry' }
                | { __typename?: 'offerLanguages_offerLanguage_Entry' }
                | { __typename?: 'offersOverview_offersOverview_Entry' }
                | { __typename?: 'offers_event_Entry' }
                | { __typename?: 'offers_tour_Entry' }
                | { __typename?: 'pages_page_Entry' }
                | { __typename?: 'proposalCategories_proposalCategory_Entry' }
                | { __typename?: 'schoolGrades_schoolGrade_Entry' }
                | { __typename?: 'siteInfo_siteInfo_Entry' }
                | { __typename?: 'topics_topic_Entry' }
                | null
              >;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              typeHandle: string;
              language?: string | null;
              isBookable?: boolean | null;
              isRegisterable?: boolean | null;
              isFeatured?: boolean | null;
              title?: string | null;
              heading?: string | null;
              externalId?: string | null;
              url?: string | null;
              uri?: string | null;
              slug?: string | null;
              duration?: any | null;
              subheading?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              imageSquare: Array<
                | { __typename?: 'documents_Asset' }
                | {
                    __typename?: 'mediaFiles_Asset';
                    id?: string | null;
                    url?: string | null;
                    width?: number | null;
                    height?: number | null;
                    focalPoint?: Array<number | null> | null;
                    srcset?: string | null;
                    altText?: {
                      __typename?: 'NodeCollection';
                      renderHtml?: string | null;
                    } | null;
                    caption?: {
                      __typename?: 'NodeCollection';
                      renderHtml?: string | null;
                    } | null;
                    credits?: {
                      __typename?: 'NodeCollection';
                      renderHtml?: string | null;
                    } | null;
                  }
                | null
              >;
              museum: Array<
                | {
                    __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'ageGroups_ageGroup_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'audiences_audience_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'badges_badge_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'countries_country_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'exhibitions_exhibition_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'goMusSettings_goMusSettings_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'homePage_homePage_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'locations_location_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'museumsOverview_museumsOverview_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'museums_museum_Entry';
                    heading?: string | null;
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'newsItems_newsItem_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'offerCategories_offerCategory_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'offerLanguages_offerLanguage_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'offersOverview_offersOverview_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'offers_event_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'offers_tour_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'pages_page_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'proposalCategories_proposalCategory_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'schoolGrades_schoolGrade_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'siteInfo_siteInfo_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'topics_topic_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | null
              >;
              exhibition: Array<
                | {
                    __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'ageGroups_ageGroup_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'audiences_audience_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'badges_badge_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'countries_country_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'exhibitions_exhibition_Entry';
                    heading?: string | null;
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'goMusSettings_goMusSettings_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'homePage_homePage_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'locations_location_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'museumsOverview_museumsOverview_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'museums_museum_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'newsItems_newsItem_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'offerCategories_offerCategory_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'offerLanguages_offerLanguage_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'offersOverview_offersOverview_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'offers_event_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'offers_tour_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'pages_page_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'proposalCategories_proposalCategory_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'schoolGrades_schoolGrade_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'siteInfo_siteInfo_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | {
                    __typename?: 'topics_topic_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    url?: string | null;
                  }
                | null
              >;
              offerCategory: Array<
                | {
                    __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'ageGroups_ageGroup_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'audiences_audience_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'badges_badge_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'countries_country_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'exhibitions_exhibition_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'goMusSettings_goMusSettings_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'homePage_homePage_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'locations_location_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'museumsOverview_museumsOverview_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'museums_museum_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'newsItems_newsItem_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offerCategories_offerCategory_Entry';
                    externalId?: string | null;
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offerLanguages_offerLanguage_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offersOverview_offersOverview_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offers_event_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offers_tour_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'pages_page_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'proposalCategories_proposalCategory_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'schoolGrades_schoolGrade_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'siteInfo_siteInfo_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'topics_topic_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | null
              >;
              topics: Array<
                | {
                    __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'ageGroups_ageGroup_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'audiences_audience_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'badges_badge_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'countries_country_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'exhibitions_exhibition_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'goMusSettings_goMusSettings_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'homePage_homePage_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'locations_location_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'museumsOverview_museumsOverview_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'museums_museum_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'newsItems_newsItem_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offerCategories_offerCategory_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offerLanguages_offerLanguage_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offersOverview_offersOverview_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offers_event_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offers_tour_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'pages_page_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'proposalCategories_proposalCategory_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'schoolGrades_schoolGrade_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'siteInfo_siteInfo_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'topics_topic_Entry';
                    externalId?: string | null;
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | null
              >;
              accessibilityFeatures: Array<
                | {
                    __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    icon?: {
                      __typename?: 'icon_Icon';
                      inline?: string | null;
                    } | null;
                  }
                | { __typename?: 'ageGroups_ageGroup_Entry' }
                | { __typename?: 'audiences_audience_Entry' }
                | { __typename?: 'badges_badge_Entry' }
                | { __typename?: 'countries_country_Entry' }
                | { __typename?: 'exhibitions_exhibition_Entry' }
                | { __typename?: 'goMusSettings_goMusSettings_Entry' }
                | { __typename?: 'homePage_homePage_Entry' }
                | { __typename?: 'locations_location_Entry' }
                | { __typename?: 'museumsOverview_museumsOverview_Entry' }
                | { __typename?: 'museums_museum_Entry' }
                | { __typename?: 'newsItems_newsItem_Entry' }
                | { __typename?: 'offerCategories_offerCategory_Entry' }
                | { __typename?: 'offerLanguages_offerLanguage_Entry' }
                | { __typename?: 'offersOverview_offersOverview_Entry' }
                | { __typename?: 'offers_event_Entry' }
                | { __typename?: 'offers_tour_Entry' }
                | { __typename?: 'pages_page_Entry' }
                | { __typename?: 'proposalCategories_proposalCategory_Entry' }
                | { __typename?: 'schoolGrades_schoolGrade_Entry' }
                | { __typename?: 'siteInfo_siteInfo_Entry' }
                | { __typename?: 'topics_topic_Entry' }
                | null
              >;
              badge: Array<
                | {
                    __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
                  }
                | { __typename?: 'ageGroups_ageGroup_Entry' }
                | { __typename?: 'audiences_audience_Entry' }
                | {
                    __typename?: 'badges_badge_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                    isHighlighted?: boolean | null;
                  }
                | { __typename?: 'countries_country_Entry' }
                | { __typename?: 'exhibitions_exhibition_Entry' }
                | { __typename?: 'goMusSettings_goMusSettings_Entry' }
                | { __typename?: 'homePage_homePage_Entry' }
                | { __typename?: 'locations_location_Entry' }
                | { __typename?: 'museumsOverview_museumsOverview_Entry' }
                | { __typename?: 'museums_museum_Entry' }
                | { __typename?: 'newsItems_newsItem_Entry' }
                | { __typename?: 'offerCategories_offerCategory_Entry' }
                | { __typename?: 'offerLanguages_offerLanguage_Entry' }
                | { __typename?: 'offersOverview_offersOverview_Entry' }
                | { __typename?: 'offers_event_Entry' }
                | { __typename?: 'offers_tour_Entry' }
                | { __typename?: 'pages_page_Entry' }
                | { __typename?: 'proposalCategories_proposalCategory_Entry' }
                | { __typename?: 'schoolGrades_schoolGrade_Entry' }
                | { __typename?: 'siteInfo_siteInfo_Entry' }
                | { __typename?: 'topics_topic_Entry' }
                | null
              >;
            }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
        moreLink?: Array<{
          __typename?: 'moreLink_link_BlockType';
          id?: string | null;
          anchorId?: string | null;
          label?: string | null;
          linkStyle?: string | null;
          webLink?: {
            __typename?: 'Linkit_Link';
            target?: string | null;
            text?: string | null;
            url?: string | null;
            typeHandle?: string | null;
            element?:
              | {
                  __typename?: 'Address';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'Element';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'User';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'ageGroups_ageGroup_Entry';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'audiences_audience_Entry';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'badges_badge_Entry';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'banner_banner_BlockType';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'contentBlocks_accordionItem_BlockType';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'contentBlocks_accordion_BlockType';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'contentBlocks_cookieInfo_BlockType';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'contentBlocks_filteredCalendar_BlockType';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'contentBlocks_mediaItem_BlockType';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'contentBlocks_oembed_BlockType';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'contentBlocks_spacer_BlockType';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'contentBlocks_textBlock_BlockType';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'contentBlocks_textMediaBlock_BlockType';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'contentBlocks_topic_BlockType';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'contentBlocks_videoPlayer_BlockType';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'countries_country_Entry';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'dateSpans_dateSpan_BlockType';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'documents_Asset';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'exhibitions_exhibition_Entry';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'footerNavigation_Node';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'footerSubNavigation_Node';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'goMusSettings_goMusSettings_Entry';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'homePage_homePage_Entry';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'homepageIntro_element_BlockType';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'homepageIntro_link_BlockType';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'iconNavigation_Node';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'locations_location_Entry';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'mainNavigation_Node';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'mainSubNavigation_Node';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'mediaFiles_Asset';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'moreLink_link_BlockType';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'moreLinks_link_BlockType';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'museumsOverview_museumsOverview_Entry';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'museums_museum_Entry';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'newsItems_newsItem_Entry';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'newsletter_Form';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'newsletter_Submission';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'offerCategories_offerCategory_Entry';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'offerDates_offerDate_BlockType';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'offerLanguages_offerLanguage_Entry';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'offersOverview_offersOverview_Entry';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'offers_event_Entry';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'offers_tour_Entry';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'pages_page_Entry';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'proposalCategories_proposalCategory_Entry';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'schoolGrades_schoolGrade_Entry';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'siteInfo_siteInfo_Entry';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'subtitles_subtitle_BlockType';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'textSnippets_GlobalSet';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | {
                  __typename?: 'topics_topic_Entry';
                  id?: string | null;
                  siteId?: number | null;
                  title?: string | null;
                }
              | null;
          } | null;
        } | null> | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'museums_museum_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offers_event_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offers_tour_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'pages_page_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'topics_topic_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | null;
  newsItemsEntries?: Array<{
    __typename?: 'newsItems_newsItem_Entry';
    id?: string | null;
    siteId?: number | null;
    title?: string | null;
    postDate?: any | null;
    label?: string | null;
    museum: Array<
      | {
          __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'ageGroups_ageGroup_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'audiences_audience_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'badges_badge_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'countries_country_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'exhibitions_exhibition_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'goMusSettings_goMusSettings_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'homePage_homePage_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'locations_location_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'museumsOverview_museumsOverview_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'museums_museum_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'newsItems_newsItem_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offerCategories_offerCategory_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offerLanguages_offerLanguage_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offersOverview_offersOverview_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offers_event_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offers_tour_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'pages_page_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'proposalCategories_proposalCategory_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'schoolGrades_schoolGrade_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'siteInfo_siteInfo_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'topics_topic_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | null
    >;
    image: Array<
      | { __typename?: 'documents_Asset' }
      | {
          __typename?: 'mediaFiles_Asset';
          id?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          focalPoint?: Array<number | null> | null;
          srcset?: string | null;
          altText?: {
            __typename?: 'NodeCollection';
            renderHtml?: string | null;
          } | null;
          caption?: {
            __typename?: 'NodeCollection';
            renderHtml?: string | null;
          } | null;
          credits?: {
            __typename?: 'NodeCollection';
            renderHtml?: string | null;
          } | null;
        }
      | null
    >;
    bodyText?: {
      __typename?: 'NodeCollection';
      nodes?: Array<
        | { __typename?: 'VizyNode_Blockquote'; html?: string | null }
        | { __typename?: 'VizyNode_BulletList'; html?: string | null }
        | { __typename?: 'VizyNode_CodeBlock'; html?: string | null }
        | { __typename?: 'VizyNode_HardBreak'; html?: string | null }
        | { __typename?: 'VizyNode_Heading'; html?: string | null }
        | { __typename?: 'VizyNode_HorizontalRule'; html?: string | null }
        | { __typename?: 'VizyNode_Iframe'; html?: string | null }
        | { __typename?: 'VizyNode_Image'; html?: string | null }
        | { __typename?: 'VizyNode_ListItem'; html?: string | null }
        | { __typename?: 'VizyNode_MediaEmbed'; html?: string | null }
        | { __typename?: 'VizyNode_OrderedList'; html?: string | null }
        | { __typename?: 'VizyNode_Paragraph'; html?: string | null }
        | { __typename?: 'VizyNode_Table'; html?: string | null }
        | { __typename?: 'VizyNode_TableCell'; html?: string | null }
        | { __typename?: 'VizyNode_TableHeader'; html?: string | null }
        | { __typename?: 'VizyNode_TableRow'; html?: string | null }
        | { __typename?: 'VizyNode_Text'; html?: string | null }
        | null
      > | null;
    } | null;
  } | null> | null;
  offersEntries?: Array<
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        typeHandle: string;
        language?: string | null;
        isBookable?: boolean | null;
        isRegisterable?: boolean | null;
        isFeatured?: boolean | null;
        title?: string | null;
        heading?: string | null;
        externalId?: string | null;
        url?: string | null;
        uri?: string | null;
        slug?: string | null;
        duration?: any | null;
        subheading?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        imageSquare: Array<
          | { __typename?: 'documents_Asset' }
          | {
              __typename?: 'mediaFiles_Asset';
              id?: string | null;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              focalPoint?: Array<number | null> | null;
              srcset?: string | null;
              altText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              caption?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              credits?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
            }
          | null
        >;
        museum: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              heading?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | null
        >;
        exhibition: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              heading?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | null
        >;
        offerCategory: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        topics: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        accessibilityFeatures: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              icon?: {
                __typename?: 'icon_Icon';
                inline?: string | null;
              } | null;
            }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | { __typename?: 'badges_badge_Entry' }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | { __typename?: 'locations_location_Entry' }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
        badge: Array<
          | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              isHighlighted?: boolean | null;
            }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | { __typename?: 'locations_location_Entry' }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        typeHandle: string;
        language?: string | null;
        isBookable?: boolean | null;
        isRegisterable?: boolean | null;
        isFeatured?: boolean | null;
        title?: string | null;
        heading?: string | null;
        externalId?: string | null;
        url?: string | null;
        uri?: string | null;
        slug?: string | null;
        duration?: any | null;
        subheading?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        imageSquare: Array<
          | { __typename?: 'documents_Asset' }
          | {
              __typename?: 'mediaFiles_Asset';
              id?: string | null;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              focalPoint?: Array<number | null> | null;
              srcset?: string | null;
              altText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              caption?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              credits?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
            }
          | null
        >;
        museum: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              heading?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | null
        >;
        exhibition: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              heading?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | null
        >;
        offerCategory: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        topics: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        accessibilityFeatures: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              icon?: {
                __typename?: 'icon_Icon';
                inline?: string | null;
              } | null;
            }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | { __typename?: 'badges_badge_Entry' }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | { __typename?: 'locations_location_Entry' }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
        badge: Array<
          | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              isHighlighted?: boolean | null;
            }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | { __typename?: 'locations_location_Entry' }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
      }
    | null
  > | null;
  offersOverviewEntries?: Array<{
    __typename?: 'offersOverview_offersOverview_Entry';
    id?: string | null;
    siteId?: number | null;
    url?: string | null;
    title?: string | null;
    heading?: string | null;
  } | null> | null;
};

export type GetMuseumQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetMuseumQuery = {
  __typename?: 'Query';
  entry?:
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'badges_badge_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'countries_country_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'locations_location_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        heading?: string | null;
        url?: string | null;
        uri?: string | null;
        slug?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
        subheading?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        description?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        bodyText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        purchaseLink?: {
          __typename?: 'Linkit_Link';
          target?: string | null;
          text?: string | null;
          url?: string | null;
          typeHandle?: string | null;
          element?:
            | {
                __typename?: 'Address';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'Element';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'User';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'ageGroups_ageGroup_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'audiences_audience_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'badges_badge_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'banner_banner_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_accordionItem_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_accordion_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_cookieInfo_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_filteredCalendar_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_mediaItem_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_oembed_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_spacer_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_textBlock_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_textMediaBlock_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_topic_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_videoPlayer_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'countries_country_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'dateSpans_dateSpan_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'documents_Asset';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'exhibitions_exhibition_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'footerNavigation_Node';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'footerSubNavigation_Node';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'goMusSettings_goMusSettings_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'homePage_homePage_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'homepageIntro_element_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'homepageIntro_link_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'iconNavigation_Node';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'locations_location_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'mainNavigation_Node';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'mainSubNavigation_Node';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'mediaFiles_Asset';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'moreLink_link_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'moreLinks_link_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'museumsOverview_museumsOverview_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'museums_museum_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'newsItems_newsItem_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'newsletter_Form';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'newsletter_Submission';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offerCategories_offerCategory_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offerDates_offerDate_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offerLanguages_offerLanguage_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offersOverview_offersOverview_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offers_event_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offers_tour_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'pages_page_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'proposalCategories_proposalCategory_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'schoolGrades_schoolGrade_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'siteInfo_siteInfo_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'subtitles_subtitle_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'textSnippets_GlobalSet';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'topics_topic_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | null;
        } | null;
        infoLink?: {
          __typename?: 'Linkit_Link';
          target?: string | null;
          text?: string | null;
          url?: string | null;
          typeHandle?: string | null;
          element?:
            | {
                __typename?: 'Address';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'Element';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'User';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'ageGroups_ageGroup_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'audiences_audience_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'badges_badge_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'banner_banner_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_accordionItem_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_accordion_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_cookieInfo_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_filteredCalendar_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_mediaItem_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_oembed_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_spacer_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_textBlock_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_textMediaBlock_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_topic_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_videoPlayer_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'countries_country_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'dateSpans_dateSpan_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'documents_Asset';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'exhibitions_exhibition_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'footerNavigation_Node';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'footerSubNavigation_Node';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'goMusSettings_goMusSettings_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'homePage_homePage_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'homepageIntro_element_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'homepageIntro_link_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'iconNavigation_Node';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'locations_location_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'mainNavigation_Node';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'mainSubNavigation_Node';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'mediaFiles_Asset';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'moreLink_link_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'moreLinks_link_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'museumsOverview_museumsOverview_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'museums_museum_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'newsItems_newsItem_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'newsletter_Form';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'newsletter_Submission';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offerCategories_offerCategory_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offerDates_offerDate_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offerLanguages_offerLanguage_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offersOverview_offersOverview_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offers_event_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offers_tour_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'pages_page_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'proposalCategories_proposalCategory_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'schoolGrades_schoolGrade_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'siteInfo_siteInfo_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'subtitles_subtitle_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'textSnippets_GlobalSet';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'topics_topic_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | null;
        } | null;
        infoText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        image: Array<
          | { __typename?: 'documents_Asset' }
          | {
              __typename?: 'mediaFiles_Asset';
              id?: string | null;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              focalPoint?: Array<number | null> | null;
              srcset?: string | null;
              altText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              caption?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              credits?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
            }
          | null
        >;
        accessibilityFeatures: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              icon?: {
                __typename?: 'icon_Icon';
                inline?: string | null;
              } | null;
            }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | { __typename?: 'badges_badge_Entry' }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | { __typename?: 'locations_location_Entry' }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
        locations: Array<
          | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | { __typename?: 'badges_badge_Entry' }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              heading?: string | null;
              streetAddress?: string | null;
              postalCode?: string | null;
              city?: string | null;
              latitude?: any | null;
              longitude?: any | null;
            }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
        openingTimes?: Array<{
          __typename?: 'openingTimes_TableRow';
          openingTime?: any | null;
          closingTime?: any | null;
          day?: string | null;
        } | null> | null;
        openingTimesExtra?: Array<{
          __typename?: 'openingTimesExtra_TableRow';
          days?: string | null;
          openingTime?: any | null;
          closingTime?: any | null;
        } | null> | null;
        storeHours?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offers_event_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offers_tour_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'pages_page_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'topics_topic_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | null;
  exhibitionsEntries?: Array<{
    __typename?: 'exhibitions_exhibition_Entry';
    id?: string | null;
    siteId?: number | null;
    title?: string | null;
    heading?: string | null;
    url?: string | null;
    uri?: string | null;
    slug?: string | null;
    subheading?: {
      __typename?: 'NodeCollection';
      renderHtml?: string | null;
    } | null;
    dateSpans?: Array<{
      __typename?: 'dateSpans_dateSpan_BlockType';
      startDateTime?: any | null;
      endDateTime?: any | null;
    } | null> | null;
    museum: Array<
      | {
          __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
          url?: string | null;
        }
      | {
          __typename?: 'ageGroups_ageGroup_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
          url?: string | null;
        }
      | {
          __typename?: 'audiences_audience_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
          url?: string | null;
        }
      | {
          __typename?: 'badges_badge_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
          url?: string | null;
        }
      | {
          __typename?: 'countries_country_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
          url?: string | null;
        }
      | {
          __typename?: 'exhibitions_exhibition_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
          url?: string | null;
        }
      | {
          __typename?: 'goMusSettings_goMusSettings_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
          url?: string | null;
        }
      | {
          __typename?: 'homePage_homePage_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
          url?: string | null;
        }
      | {
          __typename?: 'locations_location_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
          url?: string | null;
        }
      | {
          __typename?: 'museumsOverview_museumsOverview_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
          url?: string | null;
        }
      | {
          __typename?: 'museums_museum_Entry';
          heading?: string | null;
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
          url?: string | null;
        }
      | {
          __typename?: 'newsItems_newsItem_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
          url?: string | null;
        }
      | {
          __typename?: 'offerCategories_offerCategory_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
          url?: string | null;
        }
      | {
          __typename?: 'offerLanguages_offerLanguage_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
          url?: string | null;
        }
      | {
          __typename?: 'offersOverview_offersOverview_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
          url?: string | null;
        }
      | {
          __typename?: 'offers_event_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
          url?: string | null;
        }
      | {
          __typename?: 'offers_tour_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
          url?: string | null;
        }
      | {
          __typename?: 'pages_page_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
          url?: string | null;
        }
      | {
          __typename?: 'proposalCategories_proposalCategory_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
          url?: string | null;
        }
      | {
          __typename?: 'schoolGrades_schoolGrade_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
          url?: string | null;
        }
      | {
          __typename?: 'siteInfo_siteInfo_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
          url?: string | null;
        }
      | {
          __typename?: 'topics_topic_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
          url?: string | null;
        }
      | null
    >;
    image: Array<
      | { __typename?: 'documents_Asset' }
      | {
          __typename?: 'mediaFiles_Asset';
          id?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          focalPoint?: Array<number | null> | null;
          srcset?: string | null;
          altText?: {
            __typename?: 'NodeCollection';
            renderHtml?: string | null;
          } | null;
          caption?: {
            __typename?: 'NodeCollection';
            renderHtml?: string | null;
          } | null;
          credits?: {
            __typename?: 'NodeCollection';
            renderHtml?: string | null;
          } | null;
        }
      | null
    >;
  } | null> | null;
  groupOffersEntries?: Array<
    | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
    | { __typename?: 'ageGroups_ageGroup_Entry' }
    | { __typename?: 'audiences_audience_Entry' }
    | { __typename?: 'badges_badge_Entry' }
    | { __typename?: 'countries_country_Entry' }
    | { __typename?: 'exhibitions_exhibition_Entry' }
    | { __typename?: 'goMusSettings_goMusSettings_Entry' }
    | { __typename?: 'homePage_homePage_Entry' }
    | { __typename?: 'locations_location_Entry' }
    | { __typename?: 'museumsOverview_museumsOverview_Entry' }
    | { __typename?: 'museums_museum_Entry' }
    | { __typename?: 'newsItems_newsItem_Entry' }
    | { __typename?: 'offerCategories_offerCategory_Entry' }
    | { __typename?: 'offerLanguages_offerLanguage_Entry' }
    | { __typename?: 'offersOverview_offersOverview_Entry' }
    | { __typename?: 'offers_event_Entry' }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        typeHandle: string;
        language?: string | null;
        isBookable?: boolean | null;
        isRegisterable?: boolean | null;
        isFeatured?: boolean | null;
        title?: string | null;
        heading?: string | null;
        externalId?: string | null;
        url?: string | null;
        uri?: string | null;
        slug?: string | null;
        duration?: any | null;
        subheading?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        imageSquare: Array<
          | { __typename?: 'documents_Asset' }
          | {
              __typename?: 'mediaFiles_Asset';
              id?: string | null;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              focalPoint?: Array<number | null> | null;
              srcset?: string | null;
              altText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              caption?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              credits?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
            }
          | null
        >;
        museum: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              heading?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | null
        >;
        exhibition: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              heading?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | null
        >;
        offerCategory: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        topics: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        accessibilityFeatures: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              icon?: {
                __typename?: 'icon_Icon';
                inline?: string | null;
              } | null;
            }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | { __typename?: 'badges_badge_Entry' }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | { __typename?: 'locations_location_Entry' }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
        badge: Array<
          | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              isHighlighted?: boolean | null;
            }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | { __typename?: 'locations_location_Entry' }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
      }
    | { __typename?: 'pages_page_Entry' }
    | { __typename?: 'proposalCategories_proposalCategory_Entry' }
    | { __typename?: 'schoolGrades_schoolGrade_Entry' }
    | { __typename?: 'siteInfo_siteInfo_Entry' }
    | { __typename?: 'topics_topic_Entry' }
    | null
  > | null;
  offersEntries?: Array<
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        typeHandle: string;
        language?: string | null;
        isBookable?: boolean | null;
        isRegisterable?: boolean | null;
        isFeatured?: boolean | null;
        title?: string | null;
        heading?: string | null;
        externalId?: string | null;
        url?: string | null;
        uri?: string | null;
        slug?: string | null;
        duration?: any | null;
        subheading?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        imageSquare: Array<
          | { __typename?: 'documents_Asset' }
          | {
              __typename?: 'mediaFiles_Asset';
              id?: string | null;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              focalPoint?: Array<number | null> | null;
              srcset?: string | null;
              altText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              caption?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              credits?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
            }
          | null
        >;
        museum: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              heading?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | null
        >;
        exhibition: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              heading?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | null
        >;
        offerCategory: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        topics: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        accessibilityFeatures: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              icon?: {
                __typename?: 'icon_Icon';
                inline?: string | null;
              } | null;
            }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | { __typename?: 'badges_badge_Entry' }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | { __typename?: 'locations_location_Entry' }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
        badge: Array<
          | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              isHighlighted?: boolean | null;
            }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | { __typename?: 'locations_location_Entry' }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
      }
    | { __typename?: 'offers_tour_Entry' }
    | null
  > | null;
  offersOverviewEntries?: Array<{
    __typename?: 'offersOverview_offersOverview_Entry';
    id?: string | null;
    siteId?: number | null;
    url?: string | null;
    title?: string | null;
    heading?: string | null;
  } | null> | null;
};

export type MuseumsOverviewFragment = {
  __typename?: 'museumsOverview_museumsOverview_Entry';
  id?: string | null;
  siteId?: number | null;
  title?: string | null;
  heading?: string | null;
  description?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
};

export type GetMuseumOverviewQueryVariables = Exact<{
  site?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetMuseumOverviewQuery = {
  __typename?: 'Query';
  entry?:
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'badges_badge_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'countries_country_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'locations_location_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        heading?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
        description?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offers_event_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offers_tour_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'pages_page_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'topics_topic_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | null;
  entries?: Array<
    | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
    | { __typename?: 'ageGroups_ageGroup_Entry' }
    | { __typename?: 'audiences_audience_Entry' }
    | { __typename?: 'badges_badge_Entry' }
    | { __typename?: 'countries_country_Entry' }
    | { __typename?: 'exhibitions_exhibition_Entry' }
    | { __typename?: 'goMusSettings_goMusSettings_Entry' }
    | { __typename?: 'homePage_homePage_Entry' }
    | { __typename?: 'locations_location_Entry' }
    | { __typename?: 'museumsOverview_museumsOverview_Entry' }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        heading?: string | null;
        url?: string | null;
        uri?: string | null;
        slug?: string | null;
        image: Array<
          | { __typename?: 'documents_Asset' }
          | {
              __typename?: 'mediaFiles_Asset';
              id?: string | null;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              focalPoint?: Array<number | null> | null;
              srcset?: string | null;
              altText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              caption?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              credits?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
            }
          | null
        >;
        accessibilityFeatures: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              icon?: {
                __typename?: 'icon_Icon';
                inline?: string | null;
              } | null;
            }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | { __typename?: 'badges_badge_Entry' }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | { __typename?: 'locations_location_Entry' }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
        locations: Array<
          | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | { __typename?: 'badges_badge_Entry' }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              heading?: string | null;
              streetAddress?: string | null;
              postalCode?: string | null;
              city?: string | null;
              latitude?: any | null;
              longitude?: any | null;
            }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
        openingTimes?: Array<{
          __typename?: 'openingTimes_TableRow';
          openingTime?: any | null;
          closingTime?: any | null;
          day?: string | null;
        } | null> | null;
        openingTimesExtra?: Array<{
          __typename?: 'openingTimesExtra_TableRow';
          days?: string | null;
          openingTime?: any | null;
          closingTime?: any | null;
        } | null> | null;
        storeHours?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
      }
    | { __typename?: 'newsItems_newsItem_Entry' }
    | { __typename?: 'offerCategories_offerCategory_Entry' }
    | { __typename?: 'offerLanguages_offerLanguage_Entry' }
    | { __typename?: 'offersOverview_offersOverview_Entry' }
    | { __typename?: 'offers_event_Entry' }
    | { __typename?: 'offers_tour_Entry' }
    | { __typename?: 'pages_page_Entry' }
    | { __typename?: 'proposalCategories_proposalCategory_Entry' }
    | { __typename?: 'schoolGrades_schoolGrade_Entry' }
    | { __typename?: 'siteInfo_siteInfo_Entry' }
    | { __typename?: 'topics_topic_Entry' }
    | null
  > | null;
  offersOverviewEntries?: Array<{
    __typename?: 'offersOverview_offersOverview_Entry';
    id?: string | null;
    siteId?: number | null;
    url?: string | null;
    title?: string | null;
    heading?: string | null;
  } | null> | null;
};

export type GetOfferQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetOfferQuery = {
  __typename?: 'Query';
  entry?:
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        language?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        language?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        language?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        language?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        language?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        language?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        language?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        language?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        language?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        language?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        language?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        language?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        language?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        language?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        language?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        language?: string | null;
        ageRating?: string | null;
        schoolGradesFormated?: string | null;
        isSchoolOptional?: string | null;
        typeHandle: string;
        isBookable?: boolean | null;
        isRegisterable?: boolean | null;
        isFeatured?: boolean | null;
        title?: string | null;
        heading?: string | null;
        externalId?: string | null;
        url?: string | null;
        uri?: string | null;
        slug?: string | null;
        duration?: any | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
        image: Array<
          | { __typename?: 'documents_Asset' }
          | {
              __typename?: 'mediaFiles_Asset';
              id?: string | null;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              focalPoint?: Array<number | null> | null;
              srcset?: string | null;
              altText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              caption?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              credits?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
            }
          | null
        >;
        description?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        ageGroups: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              parent?:
                | {
                    __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'ageGroups_ageGroup_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'audiences_audience_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'badges_badge_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'countries_country_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'exhibitions_exhibition_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'goMusSettings_goMusSettings_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'homePage_homePage_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'locations_location_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'museumsOverview_museumsOverview_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'museums_museum_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'newsItems_newsItem_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offerCategories_offerCategory_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offerLanguages_offerLanguage_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offersOverview_offersOverview_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offers_event_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offers_tour_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'pages_page_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'proposalCategories_proposalCategory_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'schoolGrades_schoolGrade_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'siteInfo_siteInfo_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'topics_topic_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        schoolGrades: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        topics: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        offerLanguages: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        accessibilityFeatures: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              icon?: {
                __typename?: 'icon_Icon';
                inline?: string | null;
              } | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        offerDates?: Array<{
          __typename?: 'offerDates_offerDate_BlockType';
          startDateTime?: any | null;
          city?: string | null;
          maxParticipants?: any | null;
          maxPerRegistration?: any | null;
          seatsAvailable?: any | null;
          seatsBooked?: any | null;
          externalId?: string | null;
          offerLanguages: Array<
            | {
                __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'ageGroups_ageGroup_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'audiences_audience_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'badges_badge_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'countries_country_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'exhibitions_exhibition_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'goMusSettings_goMusSettings_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'homePage_homePage_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'locations_location_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'museumsOverview_museumsOverview_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'museums_museum_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'newsItems_newsItem_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offerCategories_offerCategory_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offerLanguages_offerLanguage_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offersOverview_offersOverview_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offers_event_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offers_tour_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'pages_page_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'proposalCategories_proposalCategory_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'schoolGrades_schoolGrade_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'siteInfo_siteInfo_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'topics_topic_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | null
          >;
        } | null> | null;
        bodyText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        subheading?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        imageSquare: Array<
          | { __typename?: 'documents_Asset' }
          | {
              __typename?: 'mediaFiles_Asset';
              id?: string | null;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              focalPoint?: Array<number | null> | null;
              srcset?: string | null;
              altText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              caption?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              credits?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
            }
          | null
        >;
        museum: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              heading?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | null
        >;
        exhibition: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              heading?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | null
        >;
        offerCategory: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        badge: Array<
          | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              isHighlighted?: boolean | null;
            }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | { __typename?: 'locations_location_Entry' }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        language?: string | null;
        ageRating?: string | null;
        schoolGradesFormated?: string | null;
        isSchoolOptional?: string | null;
        minParticipants?: any | null;
        maxParticipants?: any | null;
        typeHandle: string;
        isBookable?: boolean | null;
        isRegisterable?: boolean | null;
        isFeatured?: boolean | null;
        title?: string | null;
        heading?: string | null;
        externalId?: string | null;
        url?: string | null;
        uri?: string | null;
        slug?: string | null;
        duration?: any | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
        image: Array<
          | { __typename?: 'documents_Asset' }
          | {
              __typename?: 'mediaFiles_Asset';
              id?: string | null;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              focalPoint?: Array<number | null> | null;
              srcset?: string | null;
              altText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              caption?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              credits?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
            }
          | null
        >;
        description?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        ageGroups: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              parent?:
                | {
                    __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'ageGroups_ageGroup_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'audiences_audience_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'badges_badge_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'countries_country_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'exhibitions_exhibition_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'goMusSettings_goMusSettings_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'homePage_homePage_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'locations_location_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'museumsOverview_museumsOverview_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'museums_museum_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'newsItems_newsItem_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offerCategories_offerCategory_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offerLanguages_offerLanguage_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offersOverview_offersOverview_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offers_event_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offers_tour_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'pages_page_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'proposalCategories_proposalCategory_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'schoolGrades_schoolGrade_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'siteInfo_siteInfo_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'topics_topic_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        schoolGrades: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        topics: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        offerLanguages: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        accessibilityFeatures: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              icon?: {
                __typename?: 'icon_Icon';
                inline?: string | null;
              } | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        offerPricingInfo?: Array<{
          __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
          minPrice?: any | null;
          maxPrice?: any | null;
          proposalCategory: Array<
            | {
                __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              }
            | { __typename?: 'ageGroups_ageGroup_Entry' }
            | { __typename?: 'audiences_audience_Entry' }
            | { __typename?: 'badges_badge_Entry' }
            | { __typename?: 'countries_country_Entry' }
            | { __typename?: 'exhibitions_exhibition_Entry' }
            | { __typename?: 'goMusSettings_goMusSettings_Entry' }
            | { __typename?: 'homePage_homePage_Entry' }
            | { __typename?: 'locations_location_Entry' }
            | { __typename?: 'museumsOverview_museumsOverview_Entry' }
            | { __typename?: 'museums_museum_Entry' }
            | { __typename?: 'newsItems_newsItem_Entry' }
            | { __typename?: 'offerCategories_offerCategory_Entry' }
            | { __typename?: 'offerLanguages_offerLanguage_Entry' }
            | { __typename?: 'offersOverview_offersOverview_Entry' }
            | { __typename?: 'offers_event_Entry' }
            | { __typename?: 'offers_tour_Entry' }
            | { __typename?: 'pages_page_Entry' }
            | {
                __typename?: 'proposalCategories_proposalCategory_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
                heading?: string | null;
                externalId?: string | null;
              }
            | { __typename?: 'schoolGrades_schoolGrade_Entry' }
            | { __typename?: 'siteInfo_siteInfo_Entry' }
            | { __typename?: 'topics_topic_Entry' }
            | null
          >;
        } | null> | null;
        bodyText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        subheading?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        imageSquare: Array<
          | { __typename?: 'documents_Asset' }
          | {
              __typename?: 'mediaFiles_Asset';
              id?: string | null;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              focalPoint?: Array<number | null> | null;
              srcset?: string | null;
              altText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              caption?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              credits?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
            }
          | null
        >;
        museum: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              heading?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | null
        >;
        exhibition: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              heading?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | null
        >;
        offerCategory: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        badge: Array<
          | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              isHighlighted?: boolean | null;
            }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | { __typename?: 'locations_location_Entry' }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        language?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        language?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        language?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        language?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        language?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | null;
  countriesEntries?: Array<{
    __typename?: 'countries_country_Entry';
    id?: string | null;
    siteId?: number | null;
    title?: string | null;
  } | null> | null;
  globalSet?: {
    __typename?: 'textSnippets_GlobalSet';
    confirmPrivacy?: {
      __typename?: 'NodeCollection';
      renderHtml?: string | null;
    } | null;
  } | null;
};

export type GetOfferOverviewQueryVariables = Exact<{
  site?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetOfferOverviewQuery = {
  __typename?: 'Query';
  entry?:
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | null;
};

export type GetFilteredOffersQueryVariables = Exact<{
  site?: InputMaybe<Scalars['String']['input']>;
  relatedTo?: InputMaybe<
    | Array<InputMaybe<Scalars['QueryArgument']['input']>>
    | InputMaybe<Scalars['QueryArgument']['input']>
  >;
  filter?: InputMaybe<
    Array<InputMaybe<EntryCriteriaInput>> | InputMaybe<EntryCriteriaInput>
  >;
  filterWithoutMuseum?: InputMaybe<
    Array<InputMaybe<EntryCriteriaInput>> | InputMaybe<EntryCriteriaInput>
  >;
}>;

export type GetFilteredOffersQuery = {
  __typename?: 'Query';
  offersEntries?: Array<
    | { __typename?: 'offers_event_Entry' }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        typeHandle: string;
        language?: string | null;
        isBookable?: boolean | null;
        isRegisterable?: boolean | null;
        isFeatured?: boolean | null;
        title?: string | null;
        heading?: string | null;
        externalId?: string | null;
        url?: string | null;
        uri?: string | null;
        slug?: string | null;
        duration?: any | null;
        subheading?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        imageSquare: Array<
          | { __typename?: 'documents_Asset' }
          | {
              __typename?: 'mediaFiles_Asset';
              id?: string | null;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              focalPoint?: Array<number | null> | null;
              srcset?: string | null;
              altText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              caption?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              credits?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
            }
          | null
        >;
        museum: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              heading?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | null
        >;
        exhibition: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              heading?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | null
        >;
        offerCategory: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        topics: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        accessibilityFeatures: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              icon?: {
                __typename?: 'icon_Icon';
                inline?: string | null;
              } | null;
            }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | { __typename?: 'badges_badge_Entry' }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | { __typename?: 'locations_location_Entry' }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
        badge: Array<
          | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              isHighlighted?: boolean | null;
            }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | { __typename?: 'locations_location_Entry' }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
      }
    | null
  > | null;
  allMuseumsEntries?: Array<{
    __typename?: 'museums_museum_Entry';
    id?: string | null;
    siteId?: number | null;
    title?: string | null;
    heading?: string | null;
    shortTitle?: string | null;
    enabled?: boolean | null;
    image: Array<
      | { __typename?: 'documents_Asset' }
      | {
          __typename?: 'mediaFiles_Asset';
          id?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          focalPoint?: Array<number | null> | null;
          srcset?: string | null;
          altText?: {
            __typename?: 'NodeCollection';
            renderHtml?: string | null;
          } | null;
          caption?: {
            __typename?: 'NodeCollection';
            renderHtml?: string | null;
          } | null;
          credits?: {
            __typename?: 'NodeCollection';
            renderHtml?: string | null;
          } | null;
        }
      | null
    >;
  } | null> | null;
  museumsEntries?: Array<{
    __typename: 'museums_museum_Entry';
    id?: string | null;
    siteId?: number | null;
    title?: string | null;
  } | null> | null;
  exhibitionsEntries?: Array<{
    __typename: 'exhibitions_exhibition_Entry';
    id?: string | null;
    siteId?: number | null;
    title?: string | null;
  } | null> | null;
  ageGroupsEntries?: Array<{
    __typename: 'ageGroups_ageGroup_Entry';
    id?: string | null;
    siteId?: number | null;
    title?: string | null;
    parent?:
      | {
          __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'ageGroups_ageGroup_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'audiences_audience_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'badges_badge_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'countries_country_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'exhibitions_exhibition_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'goMusSettings_goMusSettings_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'homePage_homePage_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'locations_location_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'museumsOverview_museumsOverview_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'museums_museum_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'newsItems_newsItem_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offerCategories_offerCategory_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offerLanguages_offerLanguage_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offersOverview_offersOverview_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offers_event_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offers_tour_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'pages_page_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'proposalCategories_proposalCategory_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'schoolGrades_schoolGrade_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'siteInfo_siteInfo_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'topics_topic_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | null;
  } | null> | null;
  topicsEntries?: Array<{
    __typename: 'topics_topic_Entry';
    id?: string | null;
    siteId?: number | null;
    title?: string | null;
  } | null> | null;
  offerLanguagesEntries?: Array<{
    __typename: 'offerLanguages_offerLanguage_Entry';
    id?: string | null;
    siteId?: number | null;
    title?: string | null;
  } | null> | null;
  proposalCategoriesEntries?: Array<{
    __typename: 'proposalCategories_proposalCategory_Entry';
    id?: string | null;
    siteId?: number | null;
    title?: string | null;
  } | null> | null;
  schoolGradesEntries?: Array<{
    __typename: 'schoolGrades_schoolGrade_Entry';
    id?: string | null;
    siteId?: number | null;
    title?: string | null;
  } | null> | null;
  offerCategoriesEntries?: Array<{
    __typename: 'offerCategories_offerCategory_Entry';
    id?: string | null;
    siteId?: number | null;
    title?: string | null;
  } | null> | null;
  accessibilityFeaturesEntries?: Array<{
    __typename: 'accessibilityFeatures_accessibilityFeature_Entry';
    id?: string | null;
    siteId?: number | null;
    title?: string | null;
  } | null> | null;
};

export type GetPageQueryVariables = Exact<{
  uri?: InputMaybe<Scalars['String']['input']>;
  prefixedUri?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetPageQuery = {
  __typename?: 'Query';
  entry?:
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'badges_badge_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'countries_country_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'locations_location_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'museums_museum_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offers_event_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'offers_tour_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        heading?: string | null;
        darkMode?: boolean | null;
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
        description?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        image: Array<
          | { __typename?: 'documents_Asset' }
          | {
              __typename?: 'mediaFiles_Asset';
              id?: string | null;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              focalPoint?: Array<number | null> | null;
              srcset?: string | null;
              altText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              caption?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              credits?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
            }
          | null
        >;
        contentBlocks?: Array<
          | {
              __typename?: 'contentBlocks_accordionItem_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_accordion_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
              children?: Array<
                | {
                    __typename?: 'contentBlocks_accordionItem_BlockType';
                    heading?: string | null;
                    level?: number | null;
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                    children?: Array<
                      | {
                          __typename?: 'contentBlocks_accordionItem_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_accordion_BlockType';
                          level?: number | null;
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                          children?: Array<
                            | {
                                __typename?: 'contentBlocks_accordionItem_BlockType';
                                heading?: string | null;
                                level?: number | null;
                                children?: Array<
                                  | {
                                      __typename?: 'contentBlocks_accordionItem_BlockType';
                                      id?: string | null;
                                      siteId?: number | null;
                                      typeHandle?: string | null;
                                      sortOrder?: number | null;
                                    }
                                  | {
                                      __typename?: 'contentBlocks_accordion_BlockType';
                                      id?: string | null;
                                      siteId?: number | null;
                                      typeHandle?: string | null;
                                      sortOrder?: number | null;
                                    }
                                  | {
                                      __typename?: 'contentBlocks_cookieInfo_BlockType';
                                      id?: string | null;
                                      siteId?: number | null;
                                      typeHandle?: string | null;
                                      sortOrder?: number | null;
                                    }
                                  | {
                                      __typename?: 'contentBlocks_filteredCalendar_BlockType';
                                      id?: string | null;
                                      siteId?: number | null;
                                      typeHandle?: string | null;
                                      sortOrder?: number | null;
                                    }
                                  | {
                                      __typename?: 'contentBlocks_mediaItem_BlockType';
                                      id?: string | null;
                                      siteId?: number | null;
                                      typeHandle?: string | null;
                                      sortOrder?: number | null;
                                    }
                                  | {
                                      __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
                                      id?: string | null;
                                      siteId?: number | null;
                                      typeHandle?: string | null;
                                      sortOrder?: number | null;
                                    }
                                  | {
                                      __typename?: 'contentBlocks_oembed_BlockType';
                                      id?: string | null;
                                      siteId?: number | null;
                                      typeHandle?: string | null;
                                      sortOrder?: number | null;
                                      uid?: string | null;
                                      htmlOrLink?: boolean | null;
                                      heading?: string | null;
                                      embed?: {
                                        __typename?: 'embed_OembedField';
                                        title?: string | null;
                                        description?: string | null;
                                        url?: string | null;
                                        type?: string | null;
                                        image?: string | null;
                                        imageWidth?: string | null;
                                        imageHeight?: string | null;
                                        code?: string | null;
                                        aspectRatio?: string | null;
                                        providerName?: string | null;
                                      } | null;
                                      description?: {
                                        __typename?: 'NodeCollection';
                                        renderHtml?: string | null;
                                      } | null;
                                      code?: {
                                        __typename?: 'code_CodeData';
                                        language?: string | null;
                                        value?: string | null;
                                      } | null;
                                    }
                                  | {
                                      __typename?: 'contentBlocks_spacer_BlockType';
                                      id?: string | null;
                                      siteId?: number | null;
                                      typeHandle?: string | null;
                                      sortOrder?: number | null;
                                    }
                                  | {
                                      __typename?: 'contentBlocks_textBlock_BlockType';
                                      id?: string | null;
                                      siteId?: number | null;
                                      typeHandle?: string | null;
                                      sortOrder?: number | null;
                                      bodyText?: {
                                        __typename?: 'NodeCollection';
                                        renderHtml?: string | null;
                                      } | null;
                                    }
                                  | {
                                      __typename?: 'contentBlocks_textMediaBlock_BlockType';
                                      id?: string | null;
                                      siteId?: number | null;
                                      typeHandle?: string | null;
                                      sortOrder?: number | null;
                                    }
                                  | {
                                      __typename?: 'contentBlocks_topic_BlockType';
                                      id?: string | null;
                                      siteId?: number | null;
                                      typeHandle?: string | null;
                                      sortOrder?: number | null;
                                    }
                                  | {
                                      __typename?: 'contentBlocks_videoPlayer_BlockType';
                                      id?: string | null;
                                      siteId?: number | null;
                                      typeHandle?: string | null;
                                      sortOrder?: number | null;
                                    }
                                  | {
                                      __typename?: 'dateSpans_dateSpan_BlockType';
                                      id?: string | null;
                                      siteId?: number | null;
                                      typeHandle?: string | null;
                                      sortOrder?: number | null;
                                    }
                                  | {
                                      __typename?: 'homepageIntro_element_BlockType';
                                      id?: string | null;
                                      siteId?: number | null;
                                      typeHandle?: string | null;
                                      sortOrder?: number | null;
                                    }
                                  | {
                                      __typename?: 'homepageIntro_link_BlockType';
                                      id?: string | null;
                                      siteId?: number | null;
                                      typeHandle?: string | null;
                                      sortOrder?: number | null;
                                    }
                                  | {
                                      __typename?: 'moreLink_link_BlockType';
                                      id?: string | null;
                                      siteId?: number | null;
                                      typeHandle?: string | null;
                                      sortOrder?: number | null;
                                    }
                                  | {
                                      __typename?: 'moreLinks_link_BlockType';
                                      id?: string | null;
                                      siteId?: number | null;
                                      typeHandle?: string | null;
                                      sortOrder?: number | null;
                                    }
                                  | {
                                      __typename?: 'offerDates_offerDate_BlockType';
                                      id?: string | null;
                                      siteId?: number | null;
                                      typeHandle?: string | null;
                                      sortOrder?: number | null;
                                    }
                                  | {
                                      __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
                                      id?: string | null;
                                      siteId?: number | null;
                                      typeHandle?: string | null;
                                      sortOrder?: number | null;
                                    }
                                  | null
                                > | null;
                              }
                            | {
                                __typename?: 'contentBlocks_accordion_BlockType';
                              }
                            | {
                                __typename?: 'contentBlocks_cookieInfo_BlockType';
                              }
                            | {
                                __typename?: 'contentBlocks_filteredCalendar_BlockType';
                              }
                            | {
                                __typename?: 'contentBlocks_mediaItem_BlockType';
                              }
                            | {
                                __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
                              }
                            | { __typename?: 'contentBlocks_oembed_BlockType' }
                            | { __typename?: 'contentBlocks_spacer_BlockType' }
                            | {
                                __typename?: 'contentBlocks_textBlock_BlockType';
                              }
                            | {
                                __typename?: 'contentBlocks_textMediaBlock_BlockType';
                              }
                            | { __typename?: 'contentBlocks_topic_BlockType' }
                            | {
                                __typename?: 'contentBlocks_videoPlayer_BlockType';
                              }
                            | { __typename?: 'dateSpans_dateSpan_BlockType' }
                            | { __typename?: 'homepageIntro_element_BlockType' }
                            | { __typename?: 'homepageIntro_link_BlockType' }
                            | { __typename?: 'moreLink_link_BlockType' }
                            | { __typename?: 'moreLinks_link_BlockType' }
                            | { __typename?: 'offerDates_offerDate_BlockType' }
                            | {
                                __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
                              }
                            | null
                          > | null;
                        }
                      | {
                          __typename?: 'contentBlocks_cookieInfo_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_filteredCalendar_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_mediaItem_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_oembed_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                          uid?: string | null;
                          htmlOrLink?: boolean | null;
                          heading?: string | null;
                          embed?: {
                            __typename?: 'embed_OembedField';
                            title?: string | null;
                            description?: string | null;
                            url?: string | null;
                            type?: string | null;
                            image?: string | null;
                            imageWidth?: string | null;
                            imageHeight?: string | null;
                            code?: string | null;
                            aspectRatio?: string | null;
                            providerName?: string | null;
                          } | null;
                          description?: {
                            __typename?: 'NodeCollection';
                            renderHtml?: string | null;
                          } | null;
                          code?: {
                            __typename?: 'code_CodeData';
                            language?: string | null;
                            value?: string | null;
                          } | null;
                        }
                      | {
                          __typename?: 'contentBlocks_spacer_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_textBlock_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                          bodyText?: {
                            __typename?: 'NodeCollection';
                            renderHtml?: string | null;
                          } | null;
                        }
                      | {
                          __typename?: 'contentBlocks_textMediaBlock_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_topic_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_videoPlayer_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'dateSpans_dateSpan_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'homepageIntro_element_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'homepageIntro_link_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'moreLink_link_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'moreLinks_link_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'offerDates_offerDate_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | null
                    > | null;
                  }
                | { __typename?: 'contentBlocks_accordion_BlockType' }
                | { __typename?: 'contentBlocks_cookieInfo_BlockType' }
                | { __typename?: 'contentBlocks_filteredCalendar_BlockType' }
                | { __typename?: 'contentBlocks_mediaItem_BlockType' }
                | { __typename?: 'contentBlocks_newsletterSubscribe_BlockType' }
                | { __typename?: 'contentBlocks_oembed_BlockType' }
                | { __typename?: 'contentBlocks_spacer_BlockType' }
                | { __typename?: 'contentBlocks_textBlock_BlockType' }
                | { __typename?: 'contentBlocks_textMediaBlock_BlockType' }
                | { __typename?: 'contentBlocks_topic_BlockType' }
                | { __typename?: 'contentBlocks_videoPlayer_BlockType' }
                | { __typename?: 'dateSpans_dateSpan_BlockType' }
                | { __typename?: 'homepageIntro_element_BlockType' }
                | { __typename?: 'homepageIntro_link_BlockType' }
                | { __typename?: 'moreLink_link_BlockType' }
                | { __typename?: 'moreLinks_link_BlockType' }
                | { __typename?: 'offerDates_offerDate_BlockType' }
                | { __typename?: 'offerPricingInfo_offerPricingInfo_BlockType' }
                | null
              > | null;
            }
          | {
              __typename?: 'contentBlocks_cookieInfo_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
              bodyText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
            }
          | {
              __typename?: 'contentBlocks_filteredCalendar_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
              offerCategories: Array<
                | {
                    __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'ageGroups_ageGroup_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'audiences_audience_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'badges_badge_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'countries_country_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'exhibitions_exhibition_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'goMusSettings_goMusSettings_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'homePage_homePage_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'locations_location_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'museumsOverview_museumsOverview_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'museums_museum_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'newsItems_newsItem_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offerCategories_offerCategory_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offerLanguages_offerLanguage_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offersOverview_offersOverview_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offers_event_Entry';
                    externalId?: string | null;
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'offers_tour_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'pages_page_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'proposalCategories_proposalCategory_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'schoolGrades_schoolGrade_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'siteInfo_siteInfo_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'topics_topic_Entry';
                    id?: string | null;
                    siteId?: number | null;
                    title?: string | null;
                  }
                | null
              >;
            }
          | {
              __typename?: 'contentBlocks_mediaItem_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
              mediaAsset: Array<
                | { __typename?: 'documents_Asset' }
                | {
                    __typename?: 'mediaFiles_Asset';
                    id?: string | null;
                    kind: string;
                    url?: string | null;
                    focalPoint?: Array<number | null> | null;
                    width?: number | null;
                    height?: number | null;
                    mimeType?: string | null;
                    videoUrl?: string | null;
                    srcset?: string | null;
                    altText?: {
                      __typename?: 'NodeCollection';
                      renderHtml?: string | null;
                    } | null;
                    caption?: {
                      __typename?: 'NodeCollection';
                      renderHtml?: string | null;
                    } | null;
                    credits?: {
                      __typename?: 'NodeCollection';
                      renderHtml?: string | null;
                    } | null;
                    mux?: {
                      __typename?: 'mux_MuxMateField';
                      playback_id?: string | null;
                      error?: string | null;
                    } | null;
                    subtitles: Array<{
                      __typename?: 'subtitles_subtitle_BlockType';
                      id?: string | null;
                      label?: string | null;
                      lang?: string | null;
                      file: Array<
                        | {
                            __typename?: 'documents_Asset';
                            id?: string | null;
                            url?: string | null;
                            mimeType?: string | null;
                            format?: string | null;
                            kind: string;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'mediaFiles_Asset';
                            id?: string | null;
                            url?: string | null;
                            mimeType?: string | null;
                            format?: string | null;
                            kind: string;
                            title?: string | null;
                          }
                        | null
                      >;
                    } | null>;
                  }
                | null
              >;
            }
          | {
              __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
              heading?: string | null;
              bodyText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              confirmPrivacy?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              relatedForm?: Array<{
                __typename?: 'newsletter_Form';
                handle?: string | null;
                submissionEndpoint?: string | null;
                submissionMutationName?: string | null;
                formFields?: Array<
                  | {
                      __typename?: 'Field_Address';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | {
                      __typename?: 'Field_Agree';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | {
                      __typename?: 'Field_Calculations';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | {
                      __typename?: 'Field_Checkboxes';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | {
                      __typename?: 'Field_Date';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | {
                      __typename?: 'Field_Dropdown';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | {
                      __typename?: 'Field_Email';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | {
                      __typename?: 'Field_Entries';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | {
                      __typename?: 'Field_Group';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | {
                      __typename?: 'Field_Heading';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | {
                      __typename?: 'Field_Hidden';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | {
                      __typename?: 'Field_Html';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | {
                      __typename?: 'Field_MultiLineText';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | {
                      __typename?: 'Field_Name';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | {
                      __typename?: 'Field_Number';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | {
                      __typename?: 'Field_Password';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | {
                      __typename?: 'Field_Payment';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | {
                      __typename?: 'Field_Phone';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | {
                      __typename?: 'Field_Radio';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | {
                      __typename?: 'Field_Recipients';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | {
                      __typename?: 'Field_Section';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | {
                      __typename?: 'Field_Signature';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | {
                      __typename?: 'Field_SingleLineText';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | {
                      __typename?: 'Field_Summary';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | {
                      __typename?: 'Field_Table';
                      id?: string | null;
                      handle?: string | null;
                      inputTypeName?: string | null;
                    }
                  | null
                > | null;
              } | null> | null;
            }
          | {
              __typename?: 'contentBlocks_oembed_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
              uid?: string | null;
              htmlOrLink?: boolean | null;
              heading?: string | null;
              embed?: {
                __typename?: 'embed_OembedField';
                title?: string | null;
                description?: string | null;
                url?: string | null;
                type?: string | null;
                image?: string | null;
                imageWidth?: string | null;
                imageHeight?: string | null;
                code?: string | null;
                aspectRatio?: string | null;
                providerName?: string | null;
              } | null;
              description?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              code?: {
                __typename?: 'code_CodeData';
                language?: string | null;
                value?: string | null;
              } | null;
            }
          | {
              __typename?: 'contentBlocks_spacer_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
              size?: string | null;
            }
          | {
              __typename?: 'contentBlocks_textBlock_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
              bodyText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
            }
          | {
              __typename?: 'contentBlocks_textMediaBlock_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
              children?: Array<
                | {
                    __typename?: 'contentBlocks_accordionItem_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_accordion_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_cookieInfo_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_filteredCalendar_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_mediaItem_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                    mediaAsset: Array<
                      | { __typename?: 'documents_Asset' }
                      | {
                          __typename?: 'mediaFiles_Asset';
                          id?: string | null;
                          kind: string;
                          url?: string | null;
                          focalPoint?: Array<number | null> | null;
                          width?: number | null;
                          height?: number | null;
                          mimeType?: string | null;
                          videoUrl?: string | null;
                          srcset?: string | null;
                          altText?: {
                            __typename?: 'NodeCollection';
                            renderHtml?: string | null;
                          } | null;
                          caption?: {
                            __typename?: 'NodeCollection';
                            renderHtml?: string | null;
                          } | null;
                          credits?: {
                            __typename?: 'NodeCollection';
                            renderHtml?: string | null;
                          } | null;
                          mux?: {
                            __typename?: 'mux_MuxMateField';
                            playback_id?: string | null;
                            error?: string | null;
                          } | null;
                          subtitles: Array<{
                            __typename?: 'subtitles_subtitle_BlockType';
                            id?: string | null;
                            label?: string | null;
                            lang?: string | null;
                            file: Array<
                              | {
                                  __typename?: 'documents_Asset';
                                  id?: string | null;
                                  url?: string | null;
                                  mimeType?: string | null;
                                  format?: string | null;
                                  kind: string;
                                  title?: string | null;
                                }
                              | {
                                  __typename?: 'mediaFiles_Asset';
                                  id?: string | null;
                                  url?: string | null;
                                  mimeType?: string | null;
                                  format?: string | null;
                                  kind: string;
                                  title?: string | null;
                                }
                              | null
                            >;
                          } | null>;
                        }
                      | null
                    >;
                  }
                | {
                    __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_oembed_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_spacer_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_textBlock_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                    bodyText?: {
                      __typename?: 'NodeCollection';
                      renderHtml?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'contentBlocks_textMediaBlock_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_topic_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_videoPlayer_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'dateSpans_dateSpan_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'homepageIntro_element_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'homepageIntro_link_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'moreLink_link_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'moreLinks_link_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'offerDates_offerDate_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | null
              > | null;
            }
          | {
              __typename?: 'contentBlocks_topic_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
              heading?: string | null;
              hideHeading?: boolean | null;
              subheading?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
            }
          | {
              __typename?: 'contentBlocks_videoPlayer_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
              video: Array<
                | { __typename?: 'documents_Asset' }
                | {
                    __typename?: 'mediaFiles_Asset';
                    id?: string | null;
                    kind: string;
                    url?: string | null;
                    focalPoint?: Array<number | null> | null;
                    width?: number | null;
                    height?: number | null;
                    mimeType?: string | null;
                    videoUrl?: string | null;
                    srcset?: string | null;
                    altText?: {
                      __typename?: 'NodeCollection';
                      renderHtml?: string | null;
                    } | null;
                    caption?: {
                      __typename?: 'NodeCollection';
                      renderHtml?: string | null;
                    } | null;
                    credits?: {
                      __typename?: 'NodeCollection';
                      renderHtml?: string | null;
                    } | null;
                    mux?: {
                      __typename?: 'mux_MuxMateField';
                      playback_id?: string | null;
                      error?: string | null;
                    } | null;
                    subtitles: Array<{
                      __typename?: 'subtitles_subtitle_BlockType';
                      id?: string | null;
                      label?: string | null;
                      lang?: string | null;
                      file: Array<
                        | {
                            __typename?: 'documents_Asset';
                            id?: string | null;
                            url?: string | null;
                            mimeType?: string | null;
                            format?: string | null;
                            kind: string;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'mediaFiles_Asset';
                            id?: string | null;
                            url?: string | null;
                            mimeType?: string | null;
                            format?: string | null;
                            kind: string;
                            title?: string | null;
                          }
                        | null
                      >;
                    } | null>;
                  }
                | null
              >;
            }
          | null
        > | null;
        banner: Array<{
          __typename?: 'banner_banner_BlockType';
          text?: string | null;
          filter: Array<
            | {
                __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
                id?: string | null;
                siteId?: number | null;
                sectionHandle: string;
                title?: string | null;
              }
            | {
                __typename?: 'ageGroups_ageGroup_Entry';
                id?: string | null;
                siteId?: number | null;
                sectionHandle: string;
                title?: string | null;
              }
            | {
                __typename?: 'audiences_audience_Entry';
                id?: string | null;
                siteId?: number | null;
                sectionHandle: string;
                title?: string | null;
              }
            | {
                __typename?: 'badges_badge_Entry';
                id?: string | null;
                siteId?: number | null;
                sectionHandle: string;
                title?: string | null;
              }
            | {
                __typename?: 'countries_country_Entry';
                id?: string | null;
                siteId?: number | null;
                sectionHandle: string;
                title?: string | null;
              }
            | {
                __typename?: 'exhibitions_exhibition_Entry';
                id?: string | null;
                siteId?: number | null;
                sectionHandle: string;
                title?: string | null;
              }
            | {
                __typename?: 'goMusSettings_goMusSettings_Entry';
                id?: string | null;
                siteId?: number | null;
                sectionHandle: string;
                title?: string | null;
              }
            | {
                __typename?: 'homePage_homePage_Entry';
                id?: string | null;
                siteId?: number | null;
                sectionHandle: string;
                title?: string | null;
              }
            | {
                __typename?: 'locations_location_Entry';
                id?: string | null;
                siteId?: number | null;
                sectionHandle: string;
                title?: string | null;
              }
            | {
                __typename?: 'museumsOverview_museumsOverview_Entry';
                id?: string | null;
                siteId?: number | null;
                sectionHandle: string;
                title?: string | null;
              }
            | {
                __typename?: 'museums_museum_Entry';
                id?: string | null;
                siteId?: number | null;
                sectionHandle: string;
                title?: string | null;
              }
            | {
                __typename?: 'newsItems_newsItem_Entry';
                id?: string | null;
                siteId?: number | null;
                sectionHandle: string;
                title?: string | null;
              }
            | {
                __typename?: 'offerCategories_offerCategory_Entry';
                id?: string | null;
                siteId?: number | null;
                sectionHandle: string;
                title?: string | null;
              }
            | {
                __typename?: 'offerLanguages_offerLanguage_Entry';
                id?: string | null;
                siteId?: number | null;
                sectionHandle: string;
                title?: string | null;
              }
            | {
                __typename?: 'offersOverview_offersOverview_Entry';
                id?: string | null;
                siteId?: number | null;
                sectionHandle: string;
                title?: string | null;
              }
            | {
                __typename?: 'offers_event_Entry';
                id?: string | null;
                siteId?: number | null;
                sectionHandle: string;
                title?: string | null;
              }
            | {
                __typename?: 'offers_tour_Entry';
                id?: string | null;
                siteId?: number | null;
                sectionHandle: string;
                title?: string | null;
              }
            | {
                __typename?: 'pages_page_Entry';
                id?: string | null;
                siteId?: number | null;
                sectionHandle: string;
                title?: string | null;
              }
            | {
                __typename?: 'proposalCategories_proposalCategory_Entry';
                id?: string | null;
                siteId?: number | null;
                sectionHandle: string;
                title?: string | null;
              }
            | {
                __typename?: 'schoolGrades_schoolGrade_Entry';
                id?: string | null;
                siteId?: number | null;
                sectionHandle: string;
                title?: string | null;
              }
            | {
                __typename?: 'siteInfo_siteInfo_Entry';
                id?: string | null;
                siteId?: number | null;
                sectionHandle: string;
                title?: string | null;
              }
            | {
                __typename?: 'topics_topic_Entry';
                id?: string | null;
                siteId?: number | null;
                sectionHandle: string;
                title?: string | null;
              }
            | null
          >;
        } | null>;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | {
        __typename?: 'topics_topic_Entry';
        localized: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              language?: string | null;
              url?: string | null;
              title?: string | null;
            }
        >;
      }
    | null;
  offersOverviewEntries?: Array<{
    __typename?: 'offersOverview_offersOverview_Entry';
    id?: string | null;
    siteId?: number | null;
    url?: string | null;
    title?: string | null;
    heading?: string | null;
  } | null> | null;
  retourResolveRedirect?: {
    __typename?: 'RetourType';
    redirectSrcUrl?: string | null;
    redirectDestUrl?: string | null;
    redirectHttpCode?: number | null;
  } | null;
};

export type ImageFragment = {
  __typename?: 'mediaFiles_Asset';
  id?: string | null;
  url?: string | null;
  width?: number | null;
  height?: number | null;
  focalPoint?: Array<number | null> | null;
  srcset?: string | null;
  altText?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  caption?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  credits?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
};

export type ImageSquareFragment = {
  __typename?: 'mediaFiles_Asset';
  id?: string | null;
  url?: string | null;
  width?: number | null;
  height?: number | null;
  focalPoint?: Array<number | null> | null;
  srcset?: string | null;
  altText?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  caption?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  credits?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
};

export type MediaAssetFragment = {
  __typename?: 'mediaFiles_Asset';
  id?: string | null;
  kind: string;
  url?: string | null;
  focalPoint?: Array<number | null> | null;
  width?: number | null;
  height?: number | null;
  mimeType?: string | null;
  videoUrl?: string | null;
  srcset?: string | null;
  altText?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  caption?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  credits?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  mux?: {
    __typename?: 'mux_MuxMateField';
    playback_id?: string | null;
    error?: string | null;
  } | null;
  subtitles: Array<{
    __typename?: 'subtitles_subtitle_BlockType';
    id?: string | null;
    label?: string | null;
    lang?: string | null;
    file: Array<
      | {
          __typename?: 'documents_Asset';
          id?: string | null;
          url?: string | null;
          mimeType?: string | null;
          format?: string | null;
          kind: string;
          title?: string | null;
        }
      | {
          __typename?: 'mediaFiles_Asset';
          id?: string | null;
          url?: string | null;
          mimeType?: string | null;
          format?: string | null;
          kind: string;
          title?: string | null;
        }
      | null
    >;
  } | null>;
};

export type SubtitleFragment = {
  __typename?: 'subtitles_subtitle_BlockType';
  id?: string | null;
  label?: string | null;
  lang?: string | null;
  file: Array<
    | {
        __typename?: 'documents_Asset';
        id?: string | null;
        url?: string | null;
        mimeType?: string | null;
        format?: string | null;
        kind: string;
        title?: string | null;
      }
    | {
        __typename?: 'mediaFiles_Asset';
        id?: string | null;
        url?: string | null;
        mimeType?: string | null;
        format?: string | null;
        kind: string;
        title?: string | null;
      }
    | null
  >;
};

export type HomePageIntroElementFragment = {
  __typename?: 'homepageIntro_element_BlockType';
  heading?: string | null;
  mediaAsset: Array<
    | { __typename?: 'documents_Asset' }
    | {
        __typename?: 'mediaFiles_Asset';
        id?: string | null;
        kind: string;
        url?: string | null;
        focalPoint?: Array<number | null> | null;
        width?: number | null;
        height?: number | null;
        mimeType?: string | null;
        videoUrl?: string | null;
        srcset?: string | null;
        altText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        caption?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        credits?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        mux?: {
          __typename?: 'mux_MuxMateField';
          playback_id?: string | null;
          error?: string | null;
        } | null;
        subtitles: Array<{
          __typename?: 'subtitles_subtitle_BlockType';
          id?: string | null;
          label?: string | null;
          lang?: string | null;
          file: Array<
            | {
                __typename?: 'documents_Asset';
                id?: string | null;
                url?: string | null;
                mimeType?: string | null;
                format?: string | null;
                kind: string;
                title?: string | null;
              }
            | {
                __typename?: 'mediaFiles_Asset';
                id?: string | null;
                url?: string | null;
                mimeType?: string | null;
                format?: string | null;
                kind: string;
                title?: string | null;
              }
            | null
          >;
        } | null>;
      }
    | null
  >;
  children?: Array<
    | { __typename?: 'contentBlocks_accordionItem_BlockType' }
    | { __typename?: 'contentBlocks_accordion_BlockType' }
    | { __typename?: 'contentBlocks_cookieInfo_BlockType' }
    | { __typename?: 'contentBlocks_filteredCalendar_BlockType' }
    | { __typename?: 'contentBlocks_mediaItem_BlockType' }
    | { __typename?: 'contentBlocks_newsletterSubscribe_BlockType' }
    | { __typename?: 'contentBlocks_oembed_BlockType' }
    | { __typename?: 'contentBlocks_spacer_BlockType' }
    | { __typename?: 'contentBlocks_textBlock_BlockType' }
    | { __typename?: 'contentBlocks_textMediaBlock_BlockType' }
    | { __typename?: 'contentBlocks_topic_BlockType' }
    | { __typename?: 'contentBlocks_videoPlayer_BlockType' }
    | { __typename?: 'dateSpans_dateSpan_BlockType' }
    | { __typename?: 'homepageIntro_element_BlockType' }
    | {
        __typename?: 'homepageIntro_link_BlockType';
        anchorId?: string | null;
        label?: string | null;
        linkStyle?: string | null;
        webLink?: {
          __typename?: 'Linkit_Link';
          target?: string | null;
          text?: string | null;
          url?: string | null;
          typeHandle?: string | null;
          element?:
            | {
                __typename?: 'Address';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'Element';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'User';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'ageGroups_ageGroup_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'audiences_audience_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'badges_badge_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'banner_banner_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_accordionItem_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_accordion_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_cookieInfo_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_filteredCalendar_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_mediaItem_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_oembed_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_spacer_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_textBlock_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_textMediaBlock_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_topic_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'contentBlocks_videoPlayer_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'countries_country_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'dateSpans_dateSpan_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'documents_Asset';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'exhibitions_exhibition_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'footerNavigation_Node';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'footerSubNavigation_Node';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'goMusSettings_goMusSettings_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'homePage_homePage_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'homepageIntro_element_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'homepageIntro_link_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'iconNavigation_Node';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'locations_location_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'mainNavigation_Node';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'mainSubNavigation_Node';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'mediaFiles_Asset';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'moreLink_link_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'moreLinks_link_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'museumsOverview_museumsOverview_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'museums_museum_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'newsItems_newsItem_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'newsletter_Form';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'newsletter_Submission';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offerCategories_offerCategory_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offerDates_offerDate_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offerLanguages_offerLanguage_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offersOverview_offersOverview_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offers_event_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'offers_tour_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'pages_page_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'proposalCategories_proposalCategory_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'schoolGrades_schoolGrade_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'siteInfo_siteInfo_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'subtitles_subtitle_BlockType';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'textSnippets_GlobalSet';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | {
                __typename?: 'topics_topic_Entry';
                id?: string | null;
                siteId?: number | null;
                title?: string | null;
              }
            | null;
        } | null;
      }
    | { __typename?: 'moreLink_link_BlockType' }
    | { __typename?: 'moreLinks_link_BlockType' }
    | { __typename?: 'offerDates_offerDate_BlockType' }
    | { __typename?: 'offerPricingInfo_offerPricingInfo_BlockType' }
    | null
  > | null;
};

export type WebLinkFragment = {
  __typename?: 'Linkit_Link';
  target?: string | null;
  text?: string | null;
  url?: string | null;
  typeHandle?: string | null;
  element?:
    | {
        __typename?: 'Address';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'Element';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'User';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'banner_banner_BlockType';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'contentBlocks_accordionItem_BlockType';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'contentBlocks_accordion_BlockType';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'contentBlocks_cookieInfo_BlockType';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'contentBlocks_filteredCalendar_BlockType';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'contentBlocks_mediaItem_BlockType';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'contentBlocks_oembed_BlockType';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'contentBlocks_spacer_BlockType';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'contentBlocks_textBlock_BlockType';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'contentBlocks_textMediaBlock_BlockType';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'contentBlocks_topic_BlockType';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'contentBlocks_videoPlayer_BlockType';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'dateSpans_dateSpan_BlockType';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'documents_Asset';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'footerNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'footerSubNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'homepageIntro_element_BlockType';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'homepageIntro_link_BlockType';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'iconNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'mainNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'mainSubNavigation_Node';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'mediaFiles_Asset';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'moreLink_link_BlockType';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'moreLinks_link_BlockType';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'newsletter_Form';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'newsletter_Submission';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerDates_offerDate_BlockType';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'subtitles_subtitle_BlockType';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'textSnippets_GlobalSet';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | null;
};

export type NewsItemFragment = {
  __typename?: 'newsItems_newsItem_Entry';
  id?: string | null;
  siteId?: number | null;
  title?: string | null;
  postDate?: any | null;
  label?: string | null;
  museum: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | null
  >;
  image: Array<
    | { __typename?: 'documents_Asset' }
    | {
        __typename?: 'mediaFiles_Asset';
        id?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        focalPoint?: Array<number | null> | null;
        srcset?: string | null;
        altText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        caption?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        credits?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
      }
    | null
  >;
  bodyText?: {
    __typename?: 'NodeCollection';
    nodes?: Array<
      | { __typename?: 'VizyNode_Blockquote'; html?: string | null }
      | { __typename?: 'VizyNode_BulletList'; html?: string | null }
      | { __typename?: 'VizyNode_CodeBlock'; html?: string | null }
      | { __typename?: 'VizyNode_HardBreak'; html?: string | null }
      | { __typename?: 'VizyNode_Heading'; html?: string | null }
      | { __typename?: 'VizyNode_HorizontalRule'; html?: string | null }
      | { __typename?: 'VizyNode_Iframe'; html?: string | null }
      | { __typename?: 'VizyNode_Image'; html?: string | null }
      | { __typename?: 'VizyNode_ListItem'; html?: string | null }
      | { __typename?: 'VizyNode_MediaEmbed'; html?: string | null }
      | { __typename?: 'VizyNode_OrderedList'; html?: string | null }
      | { __typename?: 'VizyNode_Paragraph'; html?: string | null }
      | { __typename?: 'VizyNode_Table'; html?: string | null }
      | { __typename?: 'VizyNode_TableCell'; html?: string | null }
      | { __typename?: 'VizyNode_TableHeader'; html?: string | null }
      | { __typename?: 'VizyNode_TableRow'; html?: string | null }
      | { __typename?: 'VizyNode_Text'; html?: string | null }
      | null
    > | null;
  } | null;
};

type Localized_AccessibilityFeatures_AccessibilityFeature_Entry_Fragment = {
  __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
  id?: string | null;
  siteId?: number | null;
  language?: string | null;
  url?: string | null;
  title?: string | null;
};

type Localized_AgeGroups_AgeGroup_Entry_Fragment = {
  __typename?: 'ageGroups_ageGroup_Entry';
  id?: string | null;
  siteId?: number | null;
  language?: string | null;
  url?: string | null;
  title?: string | null;
};

type Localized_Audiences_Audience_Entry_Fragment = {
  __typename?: 'audiences_audience_Entry';
  id?: string | null;
  siteId?: number | null;
  language?: string | null;
  url?: string | null;
  title?: string | null;
};

type Localized_Badges_Badge_Entry_Fragment = {
  __typename?: 'badges_badge_Entry';
  id?: string | null;
  siteId?: number | null;
  language?: string | null;
  url?: string | null;
  title?: string | null;
};

type Localized_Countries_Country_Entry_Fragment = {
  __typename?: 'countries_country_Entry';
  id?: string | null;
  siteId?: number | null;
  language?: string | null;
  url?: string | null;
  title?: string | null;
};

type Localized_Exhibitions_Exhibition_Entry_Fragment = {
  __typename?: 'exhibitions_exhibition_Entry';
  id?: string | null;
  siteId?: number | null;
  language?: string | null;
  url?: string | null;
  title?: string | null;
};

type Localized_GoMusSettings_GoMusSettings_Entry_Fragment = {
  __typename?: 'goMusSettings_goMusSettings_Entry';
  id?: string | null;
  siteId?: number | null;
  language?: string | null;
  url?: string | null;
  title?: string | null;
};

type Localized_HomePage_HomePage_Entry_Fragment = {
  __typename?: 'homePage_homePage_Entry';
  id?: string | null;
  siteId?: number | null;
  language?: string | null;
  url?: string | null;
  title?: string | null;
};

type Localized_Locations_Location_Entry_Fragment = {
  __typename?: 'locations_location_Entry';
  id?: string | null;
  siteId?: number | null;
  language?: string | null;
  url?: string | null;
  title?: string | null;
};

type Localized_MuseumsOverview_MuseumsOverview_Entry_Fragment = {
  __typename?: 'museumsOverview_museumsOverview_Entry';
  id?: string | null;
  siteId?: number | null;
  language?: string | null;
  url?: string | null;
  title?: string | null;
};

type Localized_Museums_Museum_Entry_Fragment = {
  __typename?: 'museums_museum_Entry';
  id?: string | null;
  siteId?: number | null;
  language?: string | null;
  url?: string | null;
  title?: string | null;
};

type Localized_NewsItems_NewsItem_Entry_Fragment = {
  __typename?: 'newsItems_newsItem_Entry';
  id?: string | null;
  siteId?: number | null;
  language?: string | null;
  url?: string | null;
  title?: string | null;
};

type Localized_OfferCategories_OfferCategory_Entry_Fragment = {
  __typename?: 'offerCategories_offerCategory_Entry';
  id?: string | null;
  siteId?: number | null;
  language?: string | null;
  url?: string | null;
  title?: string | null;
};

type Localized_OfferLanguages_OfferLanguage_Entry_Fragment = {
  __typename?: 'offerLanguages_offerLanguage_Entry';
  id?: string | null;
  siteId?: number | null;
  language?: string | null;
  url?: string | null;
  title?: string | null;
};

type Localized_OffersOverview_OffersOverview_Entry_Fragment = {
  __typename?: 'offersOverview_offersOverview_Entry';
  id?: string | null;
  siteId?: number | null;
  language?: string | null;
  url?: string | null;
  title?: string | null;
};

type Localized_Offers_Event_Entry_Fragment = {
  __typename?: 'offers_event_Entry';
  id?: string | null;
  siteId?: number | null;
  language?: string | null;
  url?: string | null;
  title?: string | null;
};

type Localized_Offers_Tour_Entry_Fragment = {
  __typename?: 'offers_tour_Entry';
  id?: string | null;
  siteId?: number | null;
  language?: string | null;
  url?: string | null;
  title?: string | null;
};

type Localized_Pages_Page_Entry_Fragment = {
  __typename?: 'pages_page_Entry';
  id?: string | null;
  siteId?: number | null;
  language?: string | null;
  url?: string | null;
  title?: string | null;
};

type Localized_ProposalCategories_ProposalCategory_Entry_Fragment = {
  __typename?: 'proposalCategories_proposalCategory_Entry';
  id?: string | null;
  siteId?: number | null;
  language?: string | null;
  url?: string | null;
  title?: string | null;
};

type Localized_SchoolGrades_SchoolGrade_Entry_Fragment = {
  __typename?: 'schoolGrades_schoolGrade_Entry';
  id?: string | null;
  siteId?: number | null;
  language?: string | null;
  url?: string | null;
  title?: string | null;
};

type Localized_SiteInfo_SiteInfo_Entry_Fragment = {
  __typename?: 'siteInfo_siteInfo_Entry';
  id?: string | null;
  siteId?: number | null;
  language?: string | null;
  url?: string | null;
  title?: string | null;
};

type Localized_Topics_Topic_Entry_Fragment = {
  __typename?: 'topics_topic_Entry';
  id?: string | null;
  siteId?: number | null;
  language?: string | null;
  url?: string | null;
  title?: string | null;
};

export type LocalizedFragment =
  | Localized_AccessibilityFeatures_AccessibilityFeature_Entry_Fragment
  | Localized_AgeGroups_AgeGroup_Entry_Fragment
  | Localized_Audiences_Audience_Entry_Fragment
  | Localized_Badges_Badge_Entry_Fragment
  | Localized_Countries_Country_Entry_Fragment
  | Localized_Exhibitions_Exhibition_Entry_Fragment
  | Localized_GoMusSettings_GoMusSettings_Entry_Fragment
  | Localized_HomePage_HomePage_Entry_Fragment
  | Localized_Locations_Location_Entry_Fragment
  | Localized_MuseumsOverview_MuseumsOverview_Entry_Fragment
  | Localized_Museums_Museum_Entry_Fragment
  | Localized_NewsItems_NewsItem_Entry_Fragment
  | Localized_OfferCategories_OfferCategory_Entry_Fragment
  | Localized_OfferLanguages_OfferLanguage_Entry_Fragment
  | Localized_OffersOverview_OffersOverview_Entry_Fragment
  | Localized_Offers_Event_Entry_Fragment
  | Localized_Offers_Tour_Entry_Fragment
  | Localized_Pages_Page_Entry_Fragment
  | Localized_ProposalCategories_ProposalCategory_Entry_Fragment
  | Localized_SchoolGrades_SchoolGrade_Entry_Fragment
  | Localized_SiteInfo_SiteInfo_Entry_Fragment
  | Localized_Topics_Topic_Entry_Fragment;

export type IconFragment = { __typename?: 'icon_Icon'; inline?: string | null };

export type LocationFragment = {
  __typename?: 'locations_location_Entry';
  id?: string | null;
  siteId?: number | null;
  title?: string | null;
  heading?: string | null;
  streetAddress?: string | null;
  postalCode?: string | null;
  city?: string | null;
  latitude?: any | null;
  longitude?: any | null;
};

export type CountryFragment = {
  __typename?: 'countries_country_Entry';
  id?: string | null;
  siteId?: number | null;
  title?: string | null;
};

export type MuseumPreviewFragment = {
  __typename?: 'museums_museum_Entry';
  id?: string | null;
  siteId?: number | null;
  title?: string | null;
  heading?: string | null;
  url?: string | null;
  uri?: string | null;
  slug?: string | null;
  image: Array<
    | { __typename?: 'documents_Asset' }
    | {
        __typename?: 'mediaFiles_Asset';
        id?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        focalPoint?: Array<number | null> | null;
        srcset?: string | null;
        altText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        caption?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        credits?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
      }
    | null
  >;
  accessibilityFeatures: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        icon?: { __typename?: 'icon_Icon'; inline?: string | null } | null;
      }
    | { __typename?: 'ageGroups_ageGroup_Entry' }
    | { __typename?: 'audiences_audience_Entry' }
    | { __typename?: 'badges_badge_Entry' }
    | { __typename?: 'countries_country_Entry' }
    | { __typename?: 'exhibitions_exhibition_Entry' }
    | { __typename?: 'goMusSettings_goMusSettings_Entry' }
    | { __typename?: 'homePage_homePage_Entry' }
    | { __typename?: 'locations_location_Entry' }
    | { __typename?: 'museumsOverview_museumsOverview_Entry' }
    | { __typename?: 'museums_museum_Entry' }
    | { __typename?: 'newsItems_newsItem_Entry' }
    | { __typename?: 'offerCategories_offerCategory_Entry' }
    | { __typename?: 'offerLanguages_offerLanguage_Entry' }
    | { __typename?: 'offersOverview_offersOverview_Entry' }
    | { __typename?: 'offers_event_Entry' }
    | { __typename?: 'offers_tour_Entry' }
    | { __typename?: 'pages_page_Entry' }
    | { __typename?: 'proposalCategories_proposalCategory_Entry' }
    | { __typename?: 'schoolGrades_schoolGrade_Entry' }
    | { __typename?: 'siteInfo_siteInfo_Entry' }
    | { __typename?: 'topics_topic_Entry' }
    | null
  >;
  locations: Array<
    | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
    | { __typename?: 'ageGroups_ageGroup_Entry' }
    | { __typename?: 'audiences_audience_Entry' }
    | { __typename?: 'badges_badge_Entry' }
    | { __typename?: 'countries_country_Entry' }
    | { __typename?: 'exhibitions_exhibition_Entry' }
    | { __typename?: 'goMusSettings_goMusSettings_Entry' }
    | { __typename?: 'homePage_homePage_Entry' }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        heading?: string | null;
        streetAddress?: string | null;
        postalCode?: string | null;
        city?: string | null;
        latitude?: any | null;
        longitude?: any | null;
      }
    | { __typename?: 'museumsOverview_museumsOverview_Entry' }
    | { __typename?: 'museums_museum_Entry' }
    | { __typename?: 'newsItems_newsItem_Entry' }
    | { __typename?: 'offerCategories_offerCategory_Entry' }
    | { __typename?: 'offerLanguages_offerLanguage_Entry' }
    | { __typename?: 'offersOverview_offersOverview_Entry' }
    | { __typename?: 'offers_event_Entry' }
    | { __typename?: 'offers_tour_Entry' }
    | { __typename?: 'pages_page_Entry' }
    | { __typename?: 'proposalCategories_proposalCategory_Entry' }
    | { __typename?: 'schoolGrades_schoolGrade_Entry' }
    | { __typename?: 'siteInfo_siteInfo_Entry' }
    | { __typename?: 'topics_topic_Entry' }
    | null
  >;
  openingTimes?: Array<{
    __typename?: 'openingTimes_TableRow';
    openingTime?: any | null;
    closingTime?: any | null;
    day?: string | null;
  } | null> | null;
  openingTimesExtra?: Array<{
    __typename?: 'openingTimesExtra_TableRow';
    days?: string | null;
    openingTime?: any | null;
    closingTime?: any | null;
  } | null> | null;
  storeHours?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
};

export type HomepageFragment = {
  __typename?: 'homePage_homePage_Entry';
  id?: string | null;
  siteId?: number | null;
  title?: string | null;
  heading?: string | null;
  hideHeading?: boolean | null;
  homepageIntro?: Array<
    | {
        __typename?: 'homepageIntro_element_BlockType';
        heading?: string | null;
        mediaAsset: Array<
          | { __typename?: 'documents_Asset' }
          | {
              __typename?: 'mediaFiles_Asset';
              id?: string | null;
              kind: string;
              url?: string | null;
              focalPoint?: Array<number | null> | null;
              width?: number | null;
              height?: number | null;
              mimeType?: string | null;
              videoUrl?: string | null;
              srcset?: string | null;
              altText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              caption?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              credits?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              mux?: {
                __typename?: 'mux_MuxMateField';
                playback_id?: string | null;
                error?: string | null;
              } | null;
              subtitles: Array<{
                __typename?: 'subtitles_subtitle_BlockType';
                id?: string | null;
                label?: string | null;
                lang?: string | null;
                file: Array<
                  | {
                      __typename?: 'documents_Asset';
                      id?: string | null;
                      url?: string | null;
                      mimeType?: string | null;
                      format?: string | null;
                      kind: string;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'mediaFiles_Asset';
                      id?: string | null;
                      url?: string | null;
                      mimeType?: string | null;
                      format?: string | null;
                      kind: string;
                      title?: string | null;
                    }
                  | null
                >;
              } | null>;
            }
          | null
        >;
        children?: Array<
          | { __typename?: 'contentBlocks_accordionItem_BlockType' }
          | { __typename?: 'contentBlocks_accordion_BlockType' }
          | { __typename?: 'contentBlocks_cookieInfo_BlockType' }
          | { __typename?: 'contentBlocks_filteredCalendar_BlockType' }
          | { __typename?: 'contentBlocks_mediaItem_BlockType' }
          | { __typename?: 'contentBlocks_newsletterSubscribe_BlockType' }
          | { __typename?: 'contentBlocks_oembed_BlockType' }
          | { __typename?: 'contentBlocks_spacer_BlockType' }
          | { __typename?: 'contentBlocks_textBlock_BlockType' }
          | { __typename?: 'contentBlocks_textMediaBlock_BlockType' }
          | { __typename?: 'contentBlocks_topic_BlockType' }
          | { __typename?: 'contentBlocks_videoPlayer_BlockType' }
          | { __typename?: 'dateSpans_dateSpan_BlockType' }
          | { __typename?: 'homepageIntro_element_BlockType' }
          | {
              __typename?: 'homepageIntro_link_BlockType';
              anchorId?: string | null;
              label?: string | null;
              linkStyle?: string | null;
              webLink?: {
                __typename?: 'Linkit_Link';
                target?: string | null;
                text?: string | null;
                url?: string | null;
                typeHandle?: string | null;
                element?:
                  | {
                      __typename?: 'Address';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'Element';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'User';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'ageGroups_ageGroup_Entry';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'audiences_audience_Entry';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'badges_badge_Entry';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'banner_banner_BlockType';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'contentBlocks_accordionItem_BlockType';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'contentBlocks_accordion_BlockType';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'contentBlocks_cookieInfo_BlockType';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'contentBlocks_filteredCalendar_BlockType';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'contentBlocks_mediaItem_BlockType';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'contentBlocks_oembed_BlockType';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'contentBlocks_spacer_BlockType';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'contentBlocks_textBlock_BlockType';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'contentBlocks_textMediaBlock_BlockType';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'contentBlocks_topic_BlockType';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'contentBlocks_videoPlayer_BlockType';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'countries_country_Entry';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'dateSpans_dateSpan_BlockType';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'documents_Asset';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'exhibitions_exhibition_Entry';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'footerNavigation_Node';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'footerSubNavigation_Node';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'goMusSettings_goMusSettings_Entry';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'homePage_homePage_Entry';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'homepageIntro_element_BlockType';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'homepageIntro_link_BlockType';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'iconNavigation_Node';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'locations_location_Entry';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'mainNavigation_Node';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'mainSubNavigation_Node';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'mediaFiles_Asset';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'moreLink_link_BlockType';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'moreLinks_link_BlockType';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'museumsOverview_museumsOverview_Entry';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'museums_museum_Entry';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'newsItems_newsItem_Entry';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'newsletter_Form';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'newsletter_Submission';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'offerCategories_offerCategory_Entry';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'offerDates_offerDate_BlockType';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'offerLanguages_offerLanguage_Entry';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'offersOverview_offersOverview_Entry';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'offers_event_Entry';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'offers_tour_Entry';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'pages_page_Entry';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'proposalCategories_proposalCategory_Entry';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'schoolGrades_schoolGrade_Entry';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'siteInfo_siteInfo_Entry';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'subtitles_subtitle_BlockType';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'textSnippets_GlobalSet';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'topics_topic_Entry';
                      id?: string | null;
                      siteId?: number | null;
                      title?: string | null;
                    }
                  | null;
              } | null;
            }
          | { __typename?: 'moreLink_link_BlockType' }
          | { __typename?: 'moreLinks_link_BlockType' }
          | { __typename?: 'offerDates_offerDate_BlockType' }
          | { __typename?: 'offerPricingInfo_offerPricingInfo_BlockType' }
          | null
        > | null;
      }
    | { __typename?: 'homepageIntro_link_BlockType' }
    | null
  > | null;
  offers: Array<
    | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
    | { __typename?: 'ageGroups_ageGroup_Entry' }
    | { __typename?: 'audiences_audience_Entry' }
    | { __typename?: 'badges_badge_Entry' }
    | { __typename?: 'countries_country_Entry' }
    | { __typename?: 'exhibitions_exhibition_Entry' }
    | { __typename?: 'goMusSettings_goMusSettings_Entry' }
    | { __typename?: 'homePage_homePage_Entry' }
    | { __typename?: 'locations_location_Entry' }
    | { __typename?: 'museumsOverview_museumsOverview_Entry' }
    | { __typename?: 'museums_museum_Entry' }
    | { __typename?: 'newsItems_newsItem_Entry' }
    | { __typename?: 'offerCategories_offerCategory_Entry' }
    | { __typename?: 'offerLanguages_offerLanguage_Entry' }
    | { __typename?: 'offersOverview_offersOverview_Entry' }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        typeHandle: string;
        language?: string | null;
        isBookable?: boolean | null;
        isRegisterable?: boolean | null;
        isFeatured?: boolean | null;
        title?: string | null;
        heading?: string | null;
        externalId?: string | null;
        url?: string | null;
        uri?: string | null;
        slug?: string | null;
        duration?: any | null;
        subheading?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        imageSquare: Array<
          | { __typename?: 'documents_Asset' }
          | {
              __typename?: 'mediaFiles_Asset';
              id?: string | null;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              focalPoint?: Array<number | null> | null;
              srcset?: string | null;
              altText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              caption?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              credits?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
            }
          | null
        >;
        museum: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              heading?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | null
        >;
        exhibition: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              heading?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | null
        >;
        offerCategory: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        topics: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        accessibilityFeatures: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              icon?: {
                __typename?: 'icon_Icon';
                inline?: string | null;
              } | null;
            }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | { __typename?: 'badges_badge_Entry' }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | { __typename?: 'locations_location_Entry' }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
        badge: Array<
          | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              isHighlighted?: boolean | null;
            }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | { __typename?: 'locations_location_Entry' }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        typeHandle: string;
        language?: string | null;
        isBookable?: boolean | null;
        isRegisterable?: boolean | null;
        isFeatured?: boolean | null;
        title?: string | null;
        heading?: string | null;
        externalId?: string | null;
        url?: string | null;
        uri?: string | null;
        slug?: string | null;
        duration?: any | null;
        subheading?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        imageSquare: Array<
          | { __typename?: 'documents_Asset' }
          | {
              __typename?: 'mediaFiles_Asset';
              id?: string | null;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              focalPoint?: Array<number | null> | null;
              srcset?: string | null;
              altText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              caption?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              credits?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
            }
          | null
        >;
        museum: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              heading?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | null
        >;
        exhibition: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              heading?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              url?: string | null;
            }
          | null
        >;
        offerCategory: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        topics: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
        accessibilityFeatures: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              icon?: {
                __typename?: 'icon_Icon';
                inline?: string | null;
              } | null;
            }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | { __typename?: 'badges_badge_Entry' }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | { __typename?: 'locations_location_Entry' }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
        badge: Array<
          | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
          | { __typename?: 'ageGroups_ageGroup_Entry' }
          | { __typename?: 'audiences_audience_Entry' }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
              isHighlighted?: boolean | null;
            }
          | { __typename?: 'countries_country_Entry' }
          | { __typename?: 'exhibitions_exhibition_Entry' }
          | { __typename?: 'goMusSettings_goMusSettings_Entry' }
          | { __typename?: 'homePage_homePage_Entry' }
          | { __typename?: 'locations_location_Entry' }
          | { __typename?: 'museumsOverview_museumsOverview_Entry' }
          | { __typename?: 'museums_museum_Entry' }
          | { __typename?: 'newsItems_newsItem_Entry' }
          | { __typename?: 'offerCategories_offerCategory_Entry' }
          | { __typename?: 'offerLanguages_offerLanguage_Entry' }
          | { __typename?: 'offersOverview_offersOverview_Entry' }
          | { __typename?: 'offers_event_Entry' }
          | { __typename?: 'offers_tour_Entry' }
          | { __typename?: 'pages_page_Entry' }
          | { __typename?: 'proposalCategories_proposalCategory_Entry' }
          | { __typename?: 'schoolGrades_schoolGrade_Entry' }
          | { __typename?: 'siteInfo_siteInfo_Entry' }
          | { __typename?: 'topics_topic_Entry' }
          | null
        >;
      }
    | { __typename?: 'pages_page_Entry' }
    | { __typename?: 'proposalCategories_proposalCategory_Entry' }
    | { __typename?: 'schoolGrades_schoolGrade_Entry' }
    | { __typename?: 'siteInfo_siteInfo_Entry' }
    | { __typename?: 'topics_topic_Entry' }
    | null
  >;
  moreLink?: Array<{
    __typename?: 'moreLink_link_BlockType';
    id?: string | null;
    anchorId?: string | null;
    label?: string | null;
    linkStyle?: string | null;
    webLink?: {
      __typename?: 'Linkit_Link';
      target?: string | null;
      text?: string | null;
      url?: string | null;
      typeHandle?: string | null;
      element?:
        | {
            __typename?: 'Address';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'Element';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'User';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'ageGroups_ageGroup_Entry';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'audiences_audience_Entry';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'badges_badge_Entry';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'banner_banner_BlockType';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'contentBlocks_accordionItem_BlockType';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'contentBlocks_accordion_BlockType';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'contentBlocks_cookieInfo_BlockType';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'contentBlocks_filteredCalendar_BlockType';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'contentBlocks_mediaItem_BlockType';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'contentBlocks_oembed_BlockType';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'contentBlocks_spacer_BlockType';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'contentBlocks_textBlock_BlockType';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'contentBlocks_textMediaBlock_BlockType';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'contentBlocks_topic_BlockType';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'contentBlocks_videoPlayer_BlockType';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'countries_country_Entry';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'dateSpans_dateSpan_BlockType';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'documents_Asset';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'exhibitions_exhibition_Entry';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'footerNavigation_Node';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'footerSubNavigation_Node';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'goMusSettings_goMusSettings_Entry';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'homePage_homePage_Entry';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'homepageIntro_element_BlockType';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'homepageIntro_link_BlockType';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'iconNavigation_Node';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'locations_location_Entry';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'mainNavigation_Node';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'mainSubNavigation_Node';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'mediaFiles_Asset';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'moreLink_link_BlockType';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'moreLinks_link_BlockType';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'museumsOverview_museumsOverview_Entry';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'museums_museum_Entry';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'newsItems_newsItem_Entry';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'newsletter_Form';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'newsletter_Submission';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'offerCategories_offerCategory_Entry';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'offerDates_offerDate_BlockType';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'offerLanguages_offerLanguage_Entry';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'offersOverview_offersOverview_Entry';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'offers_event_Entry';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'offers_tour_Entry';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'pages_page_Entry';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'proposalCategories_proposalCategory_Entry';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'schoolGrades_schoolGrade_Entry';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'siteInfo_siteInfo_Entry';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'subtitles_subtitle_BlockType';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'textSnippets_GlobalSet';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | {
            __typename?: 'topics_topic_Entry';
            id?: string | null;
            siteId?: number | null;
            title?: string | null;
          }
        | null;
    } | null;
  } | null> | null;
};

export type OverviewLinkFragment = {
  __typename?: 'offersOverview_offersOverview_Entry';
  id?: string | null;
  siteId?: number | null;
  url?: string | null;
  title?: string | null;
  heading?: string | null;
};

export type MuseumFragment = {
  __typename?: 'museums_museum_Entry';
  id?: string | null;
  siteId?: number | null;
  title?: string | null;
  heading?: string | null;
  url?: string | null;
  uri?: string | null;
  slug?: string | null;
  subheading?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  description?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  bodyText?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  purchaseLink?: {
    __typename?: 'Linkit_Link';
    target?: string | null;
    text?: string | null;
    url?: string | null;
    typeHandle?: string | null;
    element?:
      | {
          __typename?: 'Address';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'Element';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'User';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'ageGroups_ageGroup_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'audiences_audience_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'badges_badge_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'banner_banner_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'contentBlocks_accordionItem_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'contentBlocks_accordion_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'contentBlocks_cookieInfo_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'contentBlocks_filteredCalendar_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'contentBlocks_mediaItem_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'contentBlocks_oembed_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'contentBlocks_spacer_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'contentBlocks_textBlock_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'contentBlocks_textMediaBlock_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'contentBlocks_topic_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'contentBlocks_videoPlayer_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'countries_country_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'dateSpans_dateSpan_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'documents_Asset';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'exhibitions_exhibition_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'footerNavigation_Node';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'footerSubNavigation_Node';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'goMusSettings_goMusSettings_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'homePage_homePage_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'homepageIntro_element_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'homepageIntro_link_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'iconNavigation_Node';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'locations_location_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'mainNavigation_Node';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'mainSubNavigation_Node';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'mediaFiles_Asset';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'moreLink_link_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'moreLinks_link_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'museumsOverview_museumsOverview_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'museums_museum_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'newsItems_newsItem_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'newsletter_Form';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'newsletter_Submission';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offerCategories_offerCategory_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offerDates_offerDate_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offerLanguages_offerLanguage_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offersOverview_offersOverview_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offers_event_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offers_tour_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'pages_page_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'proposalCategories_proposalCategory_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'schoolGrades_schoolGrade_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'siteInfo_siteInfo_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'subtitles_subtitle_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'textSnippets_GlobalSet';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'topics_topic_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | null;
  } | null;
  infoLink?: {
    __typename?: 'Linkit_Link';
    target?: string | null;
    text?: string | null;
    url?: string | null;
    typeHandle?: string | null;
    element?:
      | {
          __typename?: 'Address';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'Element';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'User';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'ageGroups_ageGroup_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'audiences_audience_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'badges_badge_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'banner_banner_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'contentBlocks_accordionItem_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'contentBlocks_accordion_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'contentBlocks_cookieInfo_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'contentBlocks_filteredCalendar_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'contentBlocks_mediaItem_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'contentBlocks_oembed_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'contentBlocks_spacer_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'contentBlocks_textBlock_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'contentBlocks_textMediaBlock_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'contentBlocks_topic_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'contentBlocks_videoPlayer_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'countries_country_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'dateSpans_dateSpan_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'documents_Asset';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'exhibitions_exhibition_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'footerNavigation_Node';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'footerSubNavigation_Node';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'goMusSettings_goMusSettings_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'homePage_homePage_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'homepageIntro_element_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'homepageIntro_link_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'iconNavigation_Node';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'locations_location_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'mainNavigation_Node';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'mainSubNavigation_Node';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'mediaFiles_Asset';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'moreLink_link_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'moreLinks_link_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'museumsOverview_museumsOverview_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'museums_museum_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'newsItems_newsItem_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'newsletter_Form';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'newsletter_Submission';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offerCategories_offerCategory_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offerDates_offerDate_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offerLanguages_offerLanguage_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offersOverview_offersOverview_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offers_event_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offers_tour_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'pages_page_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'proposalCategories_proposalCategory_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'schoolGrades_schoolGrade_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'siteInfo_siteInfo_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'subtitles_subtitle_BlockType';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'textSnippets_GlobalSet';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'topics_topic_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | null;
  } | null;
  infoText?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  image: Array<
    | { __typename?: 'documents_Asset' }
    | {
        __typename?: 'mediaFiles_Asset';
        id?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        focalPoint?: Array<number | null> | null;
        srcset?: string | null;
        altText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        caption?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        credits?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
      }
    | null
  >;
  accessibilityFeatures: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        icon?: { __typename?: 'icon_Icon'; inline?: string | null } | null;
      }
    | { __typename?: 'ageGroups_ageGroup_Entry' }
    | { __typename?: 'audiences_audience_Entry' }
    | { __typename?: 'badges_badge_Entry' }
    | { __typename?: 'countries_country_Entry' }
    | { __typename?: 'exhibitions_exhibition_Entry' }
    | { __typename?: 'goMusSettings_goMusSettings_Entry' }
    | { __typename?: 'homePage_homePage_Entry' }
    | { __typename?: 'locations_location_Entry' }
    | { __typename?: 'museumsOverview_museumsOverview_Entry' }
    | { __typename?: 'museums_museum_Entry' }
    | { __typename?: 'newsItems_newsItem_Entry' }
    | { __typename?: 'offerCategories_offerCategory_Entry' }
    | { __typename?: 'offerLanguages_offerLanguage_Entry' }
    | { __typename?: 'offersOverview_offersOverview_Entry' }
    | { __typename?: 'offers_event_Entry' }
    | { __typename?: 'offers_tour_Entry' }
    | { __typename?: 'pages_page_Entry' }
    | { __typename?: 'proposalCategories_proposalCategory_Entry' }
    | { __typename?: 'schoolGrades_schoolGrade_Entry' }
    | { __typename?: 'siteInfo_siteInfo_Entry' }
    | { __typename?: 'topics_topic_Entry' }
    | null
  >;
  locations: Array<
    | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
    | { __typename?: 'ageGroups_ageGroup_Entry' }
    | { __typename?: 'audiences_audience_Entry' }
    | { __typename?: 'badges_badge_Entry' }
    | { __typename?: 'countries_country_Entry' }
    | { __typename?: 'exhibitions_exhibition_Entry' }
    | { __typename?: 'goMusSettings_goMusSettings_Entry' }
    | { __typename?: 'homePage_homePage_Entry' }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        heading?: string | null;
        streetAddress?: string | null;
        postalCode?: string | null;
        city?: string | null;
        latitude?: any | null;
        longitude?: any | null;
      }
    | { __typename?: 'museumsOverview_museumsOverview_Entry' }
    | { __typename?: 'museums_museum_Entry' }
    | { __typename?: 'newsItems_newsItem_Entry' }
    | { __typename?: 'offerCategories_offerCategory_Entry' }
    | { __typename?: 'offerLanguages_offerLanguage_Entry' }
    | { __typename?: 'offersOverview_offersOverview_Entry' }
    | { __typename?: 'offers_event_Entry' }
    | { __typename?: 'offers_tour_Entry' }
    | { __typename?: 'pages_page_Entry' }
    | { __typename?: 'proposalCategories_proposalCategory_Entry' }
    | { __typename?: 'schoolGrades_schoolGrade_Entry' }
    | { __typename?: 'siteInfo_siteInfo_Entry' }
    | { __typename?: 'topics_topic_Entry' }
    | null
  >;
  openingTimes?: Array<{
    __typename?: 'openingTimes_TableRow';
    openingTime?: any | null;
    closingTime?: any | null;
    day?: string | null;
  } | null> | null;
  openingTimesExtra?: Array<{
    __typename?: 'openingTimesExtra_TableRow';
    days?: string | null;
    openingTime?: any | null;
    closingTime?: any | null;
  } | null> | null;
  storeHours?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
};

export type ExhibitionPreviewFragment = {
  __typename?: 'exhibitions_exhibition_Entry';
  id?: string | null;
  siteId?: number | null;
  title?: string | null;
  heading?: string | null;
  url?: string | null;
  uri?: string | null;
  slug?: string | null;
  subheading?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  dateSpans?: Array<{
    __typename?: 'dateSpans_dateSpan_BlockType';
    startDateTime?: any | null;
    endDateTime?: any | null;
  } | null> | null;
  museum: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        heading?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | null
  >;
  image: Array<
    | { __typename?: 'documents_Asset' }
    | {
        __typename?: 'mediaFiles_Asset';
        id?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        focalPoint?: Array<number | null> | null;
        srcset?: string | null;
        altText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        caption?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        credits?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
      }
    | null
  >;
};

export type ExhibitionFragment = {
  __typename?: 'exhibitions_exhibition_Entry';
  id?: string | null;
  siteId?: number | null;
  title?: string | null;
  heading?: string | null;
  url?: string | null;
  uri?: string | null;
  slug?: string | null;
  description?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  bodyText?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  locations: Array<
    | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
    | { __typename?: 'ageGroups_ageGroup_Entry' }
    | { __typename?: 'audiences_audience_Entry' }
    | { __typename?: 'badges_badge_Entry' }
    | { __typename?: 'countries_country_Entry' }
    | { __typename?: 'exhibitions_exhibition_Entry' }
    | { __typename?: 'goMusSettings_goMusSettings_Entry' }
    | { __typename?: 'homePage_homePage_Entry' }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        heading?: string | null;
        streetAddress?: string | null;
        postalCode?: string | null;
        city?: string | null;
        latitude?: any | null;
        longitude?: any | null;
      }
    | { __typename?: 'museumsOverview_museumsOverview_Entry' }
    | { __typename?: 'museums_museum_Entry' }
    | { __typename?: 'newsItems_newsItem_Entry' }
    | { __typename?: 'offerCategories_offerCategory_Entry' }
    | { __typename?: 'offerLanguages_offerLanguage_Entry' }
    | { __typename?: 'offersOverview_offersOverview_Entry' }
    | { __typename?: 'offers_event_Entry' }
    | { __typename?: 'offers_tour_Entry' }
    | { __typename?: 'pages_page_Entry' }
    | { __typename?: 'proposalCategories_proposalCategory_Entry' }
    | { __typename?: 'schoolGrades_schoolGrade_Entry' }
    | { __typename?: 'siteInfo_siteInfo_Entry' }
    | { __typename?: 'topics_topic_Entry' }
    | null
  >;
  subheading?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  dateSpans?: Array<{
    __typename?: 'dateSpans_dateSpan_BlockType';
    startDateTime?: any | null;
    endDateTime?: any | null;
  } | null> | null;
  museum: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        heading?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | null
  >;
  image: Array<
    | { __typename?: 'documents_Asset' }
    | {
        __typename?: 'mediaFiles_Asset';
        id?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        focalPoint?: Array<number | null> | null;
        srcset?: string | null;
        altText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        caption?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        credits?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
      }
    | null
  >;
};

export type MuseumFilterItemFragment = {
  __typename?: 'museums_museum_Entry';
  id?: string | null;
  siteId?: number | null;
  title?: string | null;
  heading?: string | null;
  shortTitle?: string | null;
  enabled?: boolean | null;
  image: Array<
    | { __typename?: 'documents_Asset' }
    | {
        __typename?: 'mediaFiles_Asset';
        id?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        focalPoint?: Array<number | null> | null;
        srcset?: string | null;
        altText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        caption?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        credits?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
      }
    | null
  >;
};

export type TourPreviewFragment = {
  __typename?: 'offers_tour_Entry';
  id?: string | null;
  siteId?: number | null;
  typeHandle: string;
  language?: string | null;
  isBookable?: boolean | null;
  isRegisterable?: boolean | null;
  isFeatured?: boolean | null;
  title?: string | null;
  heading?: string | null;
  externalId?: string | null;
  url?: string | null;
  uri?: string | null;
  slug?: string | null;
  duration?: any | null;
  subheading?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  imageSquare: Array<
    | { __typename?: 'documents_Asset' }
    | {
        __typename?: 'mediaFiles_Asset';
        id?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        focalPoint?: Array<number | null> | null;
        srcset?: string | null;
        altText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        caption?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        credits?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
      }
    | null
  >;
  museum: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        heading?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | null
  >;
  exhibition: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        heading?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | null
  >;
  offerCategory: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        externalId?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | null
  >;
  topics: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        externalId?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | null
  >;
  accessibilityFeatures: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        icon?: { __typename?: 'icon_Icon'; inline?: string | null } | null;
      }
    | { __typename?: 'ageGroups_ageGroup_Entry' }
    | { __typename?: 'audiences_audience_Entry' }
    | { __typename?: 'badges_badge_Entry' }
    | { __typename?: 'countries_country_Entry' }
    | { __typename?: 'exhibitions_exhibition_Entry' }
    | { __typename?: 'goMusSettings_goMusSettings_Entry' }
    | { __typename?: 'homePage_homePage_Entry' }
    | { __typename?: 'locations_location_Entry' }
    | { __typename?: 'museumsOverview_museumsOverview_Entry' }
    | { __typename?: 'museums_museum_Entry' }
    | { __typename?: 'newsItems_newsItem_Entry' }
    | { __typename?: 'offerCategories_offerCategory_Entry' }
    | { __typename?: 'offerLanguages_offerLanguage_Entry' }
    | { __typename?: 'offersOverview_offersOverview_Entry' }
    | { __typename?: 'offers_event_Entry' }
    | { __typename?: 'offers_tour_Entry' }
    | { __typename?: 'pages_page_Entry' }
    | { __typename?: 'proposalCategories_proposalCategory_Entry' }
    | { __typename?: 'schoolGrades_schoolGrade_Entry' }
    | { __typename?: 'siteInfo_siteInfo_Entry' }
    | { __typename?: 'topics_topic_Entry' }
    | null
  >;
  badge: Array<
    | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
    | { __typename?: 'ageGroups_ageGroup_Entry' }
    | { __typename?: 'audiences_audience_Entry' }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        isHighlighted?: boolean | null;
      }
    | { __typename?: 'countries_country_Entry' }
    | { __typename?: 'exhibitions_exhibition_Entry' }
    | { __typename?: 'goMusSettings_goMusSettings_Entry' }
    | { __typename?: 'homePage_homePage_Entry' }
    | { __typename?: 'locations_location_Entry' }
    | { __typename?: 'museumsOverview_museumsOverview_Entry' }
    | { __typename?: 'museums_museum_Entry' }
    | { __typename?: 'newsItems_newsItem_Entry' }
    | { __typename?: 'offerCategories_offerCategory_Entry' }
    | { __typename?: 'offerLanguages_offerLanguage_Entry' }
    | { __typename?: 'offersOverview_offersOverview_Entry' }
    | { __typename?: 'offers_event_Entry' }
    | { __typename?: 'offers_tour_Entry' }
    | { __typename?: 'pages_page_Entry' }
    | { __typename?: 'proposalCategories_proposalCategory_Entry' }
    | { __typename?: 'schoolGrades_schoolGrade_Entry' }
    | { __typename?: 'siteInfo_siteInfo_Entry' }
    | { __typename?: 'topics_topic_Entry' }
    | null
  >;
};

export type EventPreviewFragment = {
  __typename?: 'offers_event_Entry';
  id?: string | null;
  siteId?: number | null;
  typeHandle: string;
  language?: string | null;
  isBookable?: boolean | null;
  isRegisterable?: boolean | null;
  isFeatured?: boolean | null;
  title?: string | null;
  heading?: string | null;
  externalId?: string | null;
  url?: string | null;
  uri?: string | null;
  slug?: string | null;
  duration?: any | null;
  subheading?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  imageSquare: Array<
    | { __typename?: 'documents_Asset' }
    | {
        __typename?: 'mediaFiles_Asset';
        id?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        focalPoint?: Array<number | null> | null;
        srcset?: string | null;
        altText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        caption?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        credits?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
      }
    | null
  >;
  museum: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        heading?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | null
  >;
  exhibition: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        heading?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | null
  >;
  offerCategory: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        externalId?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | null
  >;
  topics: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        externalId?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | null
  >;
  accessibilityFeatures: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        icon?: { __typename?: 'icon_Icon'; inline?: string | null } | null;
      }
    | { __typename?: 'ageGroups_ageGroup_Entry' }
    | { __typename?: 'audiences_audience_Entry' }
    | { __typename?: 'badges_badge_Entry' }
    | { __typename?: 'countries_country_Entry' }
    | { __typename?: 'exhibitions_exhibition_Entry' }
    | { __typename?: 'goMusSettings_goMusSettings_Entry' }
    | { __typename?: 'homePage_homePage_Entry' }
    | { __typename?: 'locations_location_Entry' }
    | { __typename?: 'museumsOverview_museumsOverview_Entry' }
    | { __typename?: 'museums_museum_Entry' }
    | { __typename?: 'newsItems_newsItem_Entry' }
    | { __typename?: 'offerCategories_offerCategory_Entry' }
    | { __typename?: 'offerLanguages_offerLanguage_Entry' }
    | { __typename?: 'offersOverview_offersOverview_Entry' }
    | { __typename?: 'offers_event_Entry' }
    | { __typename?: 'offers_tour_Entry' }
    | { __typename?: 'pages_page_Entry' }
    | { __typename?: 'proposalCategories_proposalCategory_Entry' }
    | { __typename?: 'schoolGrades_schoolGrade_Entry' }
    | { __typename?: 'siteInfo_siteInfo_Entry' }
    | { __typename?: 'topics_topic_Entry' }
    | null
  >;
  badge: Array<
    | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
    | { __typename?: 'ageGroups_ageGroup_Entry' }
    | { __typename?: 'audiences_audience_Entry' }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        isHighlighted?: boolean | null;
      }
    | { __typename?: 'countries_country_Entry' }
    | { __typename?: 'exhibitions_exhibition_Entry' }
    | { __typename?: 'goMusSettings_goMusSettings_Entry' }
    | { __typename?: 'homePage_homePage_Entry' }
    | { __typename?: 'locations_location_Entry' }
    | { __typename?: 'museumsOverview_museumsOverview_Entry' }
    | { __typename?: 'museums_museum_Entry' }
    | { __typename?: 'newsItems_newsItem_Entry' }
    | { __typename?: 'offerCategories_offerCategory_Entry' }
    | { __typename?: 'offerLanguages_offerLanguage_Entry' }
    | { __typename?: 'offersOverview_offersOverview_Entry' }
    | { __typename?: 'offers_event_Entry' }
    | { __typename?: 'offers_tour_Entry' }
    | { __typename?: 'pages_page_Entry' }
    | { __typename?: 'proposalCategories_proposalCategory_Entry' }
    | { __typename?: 'schoolGrades_schoolGrade_Entry' }
    | { __typename?: 'siteInfo_siteInfo_Entry' }
    | { __typename?: 'topics_topic_Entry' }
    | null
  >;
};

export type TourFragment = {
  __typename?: 'offers_tour_Entry';
  ageRating?: string | null;
  schoolGradesFormated?: string | null;
  isSchoolOptional?: string | null;
  minParticipants?: any | null;
  maxParticipants?: any | null;
  id?: string | null;
  siteId?: number | null;
  typeHandle: string;
  language?: string | null;
  isBookable?: boolean | null;
  isRegisterable?: boolean | null;
  isFeatured?: boolean | null;
  title?: string | null;
  heading?: string | null;
  externalId?: string | null;
  url?: string | null;
  uri?: string | null;
  slug?: string | null;
  duration?: any | null;
  image: Array<
    | { __typename?: 'documents_Asset' }
    | {
        __typename?: 'mediaFiles_Asset';
        id?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        focalPoint?: Array<number | null> | null;
        srcset?: string | null;
        altText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        caption?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        credits?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
      }
    | null
  >;
  description?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  ageGroups: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        externalId?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        parent?:
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | null
  >;
  schoolGrades: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        externalId?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | null
  >;
  topics: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        externalId?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | null
  >;
  offerLanguages: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        externalId?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | null
  >;
  accessibilityFeatures: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        externalId?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        icon?: { __typename?: 'icon_Icon'; inline?: string | null } | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | null
  >;
  offerPricingInfo?: Array<{
    __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
    minPrice?: any | null;
    maxPrice?: any | null;
    proposalCategory: Array<
      | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
      | { __typename?: 'ageGroups_ageGroup_Entry' }
      | { __typename?: 'audiences_audience_Entry' }
      | { __typename?: 'badges_badge_Entry' }
      | { __typename?: 'countries_country_Entry' }
      | { __typename?: 'exhibitions_exhibition_Entry' }
      | { __typename?: 'goMusSettings_goMusSettings_Entry' }
      | { __typename?: 'homePage_homePage_Entry' }
      | { __typename?: 'locations_location_Entry' }
      | { __typename?: 'museumsOverview_museumsOverview_Entry' }
      | { __typename?: 'museums_museum_Entry' }
      | { __typename?: 'newsItems_newsItem_Entry' }
      | { __typename?: 'offerCategories_offerCategory_Entry' }
      | { __typename?: 'offerLanguages_offerLanguage_Entry' }
      | { __typename?: 'offersOverview_offersOverview_Entry' }
      | { __typename?: 'offers_event_Entry' }
      | { __typename?: 'offers_tour_Entry' }
      | { __typename?: 'pages_page_Entry' }
      | {
          __typename?: 'proposalCategories_proposalCategory_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
          heading?: string | null;
          externalId?: string | null;
        }
      | { __typename?: 'schoolGrades_schoolGrade_Entry' }
      | { __typename?: 'siteInfo_siteInfo_Entry' }
      | { __typename?: 'topics_topic_Entry' }
      | null
    >;
  } | null> | null;
  bodyText?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  subheading?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  imageSquare: Array<
    | { __typename?: 'documents_Asset' }
    | {
        __typename?: 'mediaFiles_Asset';
        id?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        focalPoint?: Array<number | null> | null;
        srcset?: string | null;
        altText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        caption?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        credits?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
      }
    | null
  >;
  museum: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        heading?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | null
  >;
  exhibition: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        heading?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | null
  >;
  offerCategory: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        externalId?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | null
  >;
  badge: Array<
    | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
    | { __typename?: 'ageGroups_ageGroup_Entry' }
    | { __typename?: 'audiences_audience_Entry' }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        isHighlighted?: boolean | null;
      }
    | { __typename?: 'countries_country_Entry' }
    | { __typename?: 'exhibitions_exhibition_Entry' }
    | { __typename?: 'goMusSettings_goMusSettings_Entry' }
    | { __typename?: 'homePage_homePage_Entry' }
    | { __typename?: 'locations_location_Entry' }
    | { __typename?: 'museumsOverview_museumsOverview_Entry' }
    | { __typename?: 'museums_museum_Entry' }
    | { __typename?: 'newsItems_newsItem_Entry' }
    | { __typename?: 'offerCategories_offerCategory_Entry' }
    | { __typename?: 'offerLanguages_offerLanguage_Entry' }
    | { __typename?: 'offersOverview_offersOverview_Entry' }
    | { __typename?: 'offers_event_Entry' }
    | { __typename?: 'offers_tour_Entry' }
    | { __typename?: 'pages_page_Entry' }
    | { __typename?: 'proposalCategories_proposalCategory_Entry' }
    | { __typename?: 'schoolGrades_schoolGrade_Entry' }
    | { __typename?: 'siteInfo_siteInfo_Entry' }
    | { __typename?: 'topics_topic_Entry' }
    | null
  >;
};

export type EventFragment = {
  __typename?: 'offers_event_Entry';
  ageRating?: string | null;
  schoolGradesFormated?: string | null;
  isSchoolOptional?: string | null;
  id?: string | null;
  siteId?: number | null;
  typeHandle: string;
  language?: string | null;
  isBookable?: boolean | null;
  isRegisterable?: boolean | null;
  isFeatured?: boolean | null;
  title?: string | null;
  heading?: string | null;
  externalId?: string | null;
  url?: string | null;
  uri?: string | null;
  slug?: string | null;
  duration?: any | null;
  image: Array<
    | { __typename?: 'documents_Asset' }
    | {
        __typename?: 'mediaFiles_Asset';
        id?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        focalPoint?: Array<number | null> | null;
        srcset?: string | null;
        altText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        caption?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        credits?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
      }
    | null
  >;
  description?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  ageGroups: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        externalId?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        parent?:
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | null
  >;
  schoolGrades: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        externalId?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | null
  >;
  topics: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        externalId?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | null
  >;
  offerLanguages: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        externalId?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | null
  >;
  accessibilityFeatures: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        externalId?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        icon?: { __typename?: 'icon_Icon'; inline?: string | null } | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | null
  >;
  offerDates?: Array<{
    __typename?: 'offerDates_offerDate_BlockType';
    startDateTime?: any | null;
    city?: string | null;
    maxParticipants?: any | null;
    maxPerRegistration?: any | null;
    seatsAvailable?: any | null;
    seatsBooked?: any | null;
    externalId?: string | null;
    offerLanguages: Array<
      | {
          __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'ageGroups_ageGroup_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'audiences_audience_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'badges_badge_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'countries_country_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'exhibitions_exhibition_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'goMusSettings_goMusSettings_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'homePage_homePage_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'locations_location_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'museumsOverview_museumsOverview_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'museums_museum_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'newsItems_newsItem_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offerCategories_offerCategory_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offerLanguages_offerLanguage_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offersOverview_offersOverview_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offers_event_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'offers_tour_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'pages_page_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'proposalCategories_proposalCategory_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'schoolGrades_schoolGrade_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'siteInfo_siteInfo_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | {
          __typename?: 'topics_topic_Entry';
          id?: string | null;
          siteId?: number | null;
          title?: string | null;
        }
      | null
    >;
  } | null> | null;
  bodyText?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  subheading?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  imageSquare: Array<
    | { __typename?: 'documents_Asset' }
    | {
        __typename?: 'mediaFiles_Asset';
        id?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        focalPoint?: Array<number | null> | null;
        srcset?: string | null;
        altText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        caption?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        credits?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
      }
    | null
  >;
  museum: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        heading?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | null
  >;
  exhibition: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        heading?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        url?: string | null;
      }
    | null
  >;
  offerCategory: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        externalId?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | null
  >;
  badge: Array<
    | { __typename?: 'accessibilityFeatures_accessibilityFeature_Entry' }
    | { __typename?: 'ageGroups_ageGroup_Entry' }
    | { __typename?: 'audiences_audience_Entry' }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
        isHighlighted?: boolean | null;
      }
    | { __typename?: 'countries_country_Entry' }
    | { __typename?: 'exhibitions_exhibition_Entry' }
    | { __typename?: 'goMusSettings_goMusSettings_Entry' }
    | { __typename?: 'homePage_homePage_Entry' }
    | { __typename?: 'locations_location_Entry' }
    | { __typename?: 'museumsOverview_museumsOverview_Entry' }
    | { __typename?: 'museums_museum_Entry' }
    | { __typename?: 'newsItems_newsItem_Entry' }
    | { __typename?: 'offerCategories_offerCategory_Entry' }
    | { __typename?: 'offerLanguages_offerLanguage_Entry' }
    | { __typename?: 'offersOverview_offersOverview_Entry' }
    | { __typename?: 'offers_event_Entry' }
    | { __typename?: 'offers_tour_Entry' }
    | { __typename?: 'pages_page_Entry' }
    | { __typename?: 'proposalCategories_proposalCategory_Entry' }
    | { __typename?: 'schoolGrades_schoolGrade_Entry' }
    | { __typename?: 'siteInfo_siteInfo_Entry' }
    | { __typename?: 'topics_topic_Entry' }
    | null
  >;
};

export type PageFragment = {
  __typename?: 'pages_page_Entry';
  id?: string | null;
  siteId?: number | null;
  title?: string | null;
  heading?: string | null;
  darkMode?: boolean | null;
  description?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  image: Array<
    | { __typename?: 'documents_Asset' }
    | {
        __typename?: 'mediaFiles_Asset';
        id?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        focalPoint?: Array<number | null> | null;
        srcset?: string | null;
        altText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        caption?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        credits?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
      }
    | null
  >;
  contentBlocks?: Array<
    | {
        __typename?: 'contentBlocks_accordionItem_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_accordion_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
        children?: Array<
          | {
              __typename?: 'contentBlocks_accordionItem_BlockType';
              heading?: string | null;
              level?: number | null;
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
              children?: Array<
                | {
                    __typename?: 'contentBlocks_accordionItem_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_accordion_BlockType';
                    level?: number | null;
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                    children?: Array<
                      | {
                          __typename?: 'contentBlocks_accordionItem_BlockType';
                          heading?: string | null;
                          level?: number | null;
                          children?: Array<
                            | {
                                __typename?: 'contentBlocks_accordionItem_BlockType';
                                id?: string | null;
                                siteId?: number | null;
                                typeHandle?: string | null;
                                sortOrder?: number | null;
                              }
                            | {
                                __typename?: 'contentBlocks_accordion_BlockType';
                                id?: string | null;
                                siteId?: number | null;
                                typeHandle?: string | null;
                                sortOrder?: number | null;
                              }
                            | {
                                __typename?: 'contentBlocks_cookieInfo_BlockType';
                                id?: string | null;
                                siteId?: number | null;
                                typeHandle?: string | null;
                                sortOrder?: number | null;
                              }
                            | {
                                __typename?: 'contentBlocks_filteredCalendar_BlockType';
                                id?: string | null;
                                siteId?: number | null;
                                typeHandle?: string | null;
                                sortOrder?: number | null;
                              }
                            | {
                                __typename?: 'contentBlocks_mediaItem_BlockType';
                                id?: string | null;
                                siteId?: number | null;
                                typeHandle?: string | null;
                                sortOrder?: number | null;
                              }
                            | {
                                __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
                                id?: string | null;
                                siteId?: number | null;
                                typeHandle?: string | null;
                                sortOrder?: number | null;
                              }
                            | {
                                __typename?: 'contentBlocks_oembed_BlockType';
                                id?: string | null;
                                siteId?: number | null;
                                typeHandle?: string | null;
                                sortOrder?: number | null;
                                uid?: string | null;
                                htmlOrLink?: boolean | null;
                                heading?: string | null;
                                embed?: {
                                  __typename?: 'embed_OembedField';
                                  title?: string | null;
                                  description?: string | null;
                                  url?: string | null;
                                  type?: string | null;
                                  image?: string | null;
                                  imageWidth?: string | null;
                                  imageHeight?: string | null;
                                  code?: string | null;
                                  aspectRatio?: string | null;
                                  providerName?: string | null;
                                } | null;
                                description?: {
                                  __typename?: 'NodeCollection';
                                  renderHtml?: string | null;
                                } | null;
                                code?: {
                                  __typename?: 'code_CodeData';
                                  language?: string | null;
                                  value?: string | null;
                                } | null;
                              }
                            | {
                                __typename?: 'contentBlocks_spacer_BlockType';
                                id?: string | null;
                                siteId?: number | null;
                                typeHandle?: string | null;
                                sortOrder?: number | null;
                              }
                            | {
                                __typename?: 'contentBlocks_textBlock_BlockType';
                                id?: string | null;
                                siteId?: number | null;
                                typeHandle?: string | null;
                                sortOrder?: number | null;
                                bodyText?: {
                                  __typename?: 'NodeCollection';
                                  renderHtml?: string | null;
                                } | null;
                              }
                            | {
                                __typename?: 'contentBlocks_textMediaBlock_BlockType';
                                id?: string | null;
                                siteId?: number | null;
                                typeHandle?: string | null;
                                sortOrder?: number | null;
                              }
                            | {
                                __typename?: 'contentBlocks_topic_BlockType';
                                id?: string | null;
                                siteId?: number | null;
                                typeHandle?: string | null;
                                sortOrder?: number | null;
                              }
                            | {
                                __typename?: 'contentBlocks_videoPlayer_BlockType';
                                id?: string | null;
                                siteId?: number | null;
                                typeHandle?: string | null;
                                sortOrder?: number | null;
                              }
                            | {
                                __typename?: 'dateSpans_dateSpan_BlockType';
                                id?: string | null;
                                siteId?: number | null;
                                typeHandle?: string | null;
                                sortOrder?: number | null;
                              }
                            | {
                                __typename?: 'homepageIntro_element_BlockType';
                                id?: string | null;
                                siteId?: number | null;
                                typeHandle?: string | null;
                                sortOrder?: number | null;
                              }
                            | {
                                __typename?: 'homepageIntro_link_BlockType';
                                id?: string | null;
                                siteId?: number | null;
                                typeHandle?: string | null;
                                sortOrder?: number | null;
                              }
                            | {
                                __typename?: 'moreLink_link_BlockType';
                                id?: string | null;
                                siteId?: number | null;
                                typeHandle?: string | null;
                                sortOrder?: number | null;
                              }
                            | {
                                __typename?: 'moreLinks_link_BlockType';
                                id?: string | null;
                                siteId?: number | null;
                                typeHandle?: string | null;
                                sortOrder?: number | null;
                              }
                            | {
                                __typename?: 'offerDates_offerDate_BlockType';
                                id?: string | null;
                                siteId?: number | null;
                                typeHandle?: string | null;
                                sortOrder?: number | null;
                              }
                            | {
                                __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
                                id?: string | null;
                                siteId?: number | null;
                                typeHandle?: string | null;
                                sortOrder?: number | null;
                              }
                            | null
                          > | null;
                        }
                      | { __typename?: 'contentBlocks_accordion_BlockType' }
                      | { __typename?: 'contentBlocks_cookieInfo_BlockType' }
                      | {
                          __typename?: 'contentBlocks_filteredCalendar_BlockType';
                        }
                      | { __typename?: 'contentBlocks_mediaItem_BlockType' }
                      | {
                          __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
                        }
                      | { __typename?: 'contentBlocks_oembed_BlockType' }
                      | { __typename?: 'contentBlocks_spacer_BlockType' }
                      | { __typename?: 'contentBlocks_textBlock_BlockType' }
                      | {
                          __typename?: 'contentBlocks_textMediaBlock_BlockType';
                        }
                      | { __typename?: 'contentBlocks_topic_BlockType' }
                      | { __typename?: 'contentBlocks_videoPlayer_BlockType' }
                      | { __typename?: 'dateSpans_dateSpan_BlockType' }
                      | { __typename?: 'homepageIntro_element_BlockType' }
                      | { __typename?: 'homepageIntro_link_BlockType' }
                      | { __typename?: 'moreLink_link_BlockType' }
                      | { __typename?: 'moreLinks_link_BlockType' }
                      | { __typename?: 'offerDates_offerDate_BlockType' }
                      | {
                          __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
                        }
                      | null
                    > | null;
                  }
                | {
                    __typename?: 'contentBlocks_cookieInfo_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_filteredCalendar_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_mediaItem_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_oembed_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                    uid?: string | null;
                    htmlOrLink?: boolean | null;
                    heading?: string | null;
                    embed?: {
                      __typename?: 'embed_OembedField';
                      title?: string | null;
                      description?: string | null;
                      url?: string | null;
                      type?: string | null;
                      image?: string | null;
                      imageWidth?: string | null;
                      imageHeight?: string | null;
                      code?: string | null;
                      aspectRatio?: string | null;
                      providerName?: string | null;
                    } | null;
                    description?: {
                      __typename?: 'NodeCollection';
                      renderHtml?: string | null;
                    } | null;
                    code?: {
                      __typename?: 'code_CodeData';
                      language?: string | null;
                      value?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'contentBlocks_spacer_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_textBlock_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                    bodyText?: {
                      __typename?: 'NodeCollection';
                      renderHtml?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'contentBlocks_textMediaBlock_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_topic_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_videoPlayer_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'dateSpans_dateSpan_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'homepageIntro_element_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'homepageIntro_link_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'moreLink_link_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'moreLinks_link_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'offerDates_offerDate_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | null
              > | null;
            }
          | { __typename?: 'contentBlocks_accordion_BlockType' }
          | { __typename?: 'contentBlocks_cookieInfo_BlockType' }
          | { __typename?: 'contentBlocks_filteredCalendar_BlockType' }
          | { __typename?: 'contentBlocks_mediaItem_BlockType' }
          | { __typename?: 'contentBlocks_newsletterSubscribe_BlockType' }
          | { __typename?: 'contentBlocks_oembed_BlockType' }
          | { __typename?: 'contentBlocks_spacer_BlockType' }
          | { __typename?: 'contentBlocks_textBlock_BlockType' }
          | { __typename?: 'contentBlocks_textMediaBlock_BlockType' }
          | { __typename?: 'contentBlocks_topic_BlockType' }
          | { __typename?: 'contentBlocks_videoPlayer_BlockType' }
          | { __typename?: 'dateSpans_dateSpan_BlockType' }
          | { __typename?: 'homepageIntro_element_BlockType' }
          | { __typename?: 'homepageIntro_link_BlockType' }
          | { __typename?: 'moreLink_link_BlockType' }
          | { __typename?: 'moreLinks_link_BlockType' }
          | { __typename?: 'offerDates_offerDate_BlockType' }
          | { __typename?: 'offerPricingInfo_offerPricingInfo_BlockType' }
          | null
        > | null;
      }
    | {
        __typename?: 'contentBlocks_cookieInfo_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
        bodyText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
      }
    | {
        __typename?: 'contentBlocks_filteredCalendar_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
        offerCategories: Array<
          | {
              __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'ageGroups_ageGroup_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'audiences_audience_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'badges_badge_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'countries_country_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'exhibitions_exhibition_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'goMusSettings_goMusSettings_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'homePage_homePage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'locations_location_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museumsOverview_museumsOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'museums_museum_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'newsItems_newsItem_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerCategories_offerCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offerLanguages_offerLanguage_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offersOverview_offersOverview_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_event_Entry';
              externalId?: string | null;
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'offers_tour_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'pages_page_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'proposalCategories_proposalCategory_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'schoolGrades_schoolGrade_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'siteInfo_siteInfo_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | {
              __typename?: 'topics_topic_Entry';
              id?: string | null;
              siteId?: number | null;
              title?: string | null;
            }
          | null
        >;
      }
    | {
        __typename?: 'contentBlocks_mediaItem_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
        mediaAsset: Array<
          | { __typename?: 'documents_Asset' }
          | {
              __typename?: 'mediaFiles_Asset';
              id?: string | null;
              kind: string;
              url?: string | null;
              focalPoint?: Array<number | null> | null;
              width?: number | null;
              height?: number | null;
              mimeType?: string | null;
              videoUrl?: string | null;
              srcset?: string | null;
              altText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              caption?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              credits?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              mux?: {
                __typename?: 'mux_MuxMateField';
                playback_id?: string | null;
                error?: string | null;
              } | null;
              subtitles: Array<{
                __typename?: 'subtitles_subtitle_BlockType';
                id?: string | null;
                label?: string | null;
                lang?: string | null;
                file: Array<
                  | {
                      __typename?: 'documents_Asset';
                      id?: string | null;
                      url?: string | null;
                      mimeType?: string | null;
                      format?: string | null;
                      kind: string;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'mediaFiles_Asset';
                      id?: string | null;
                      url?: string | null;
                      mimeType?: string | null;
                      format?: string | null;
                      kind: string;
                      title?: string | null;
                    }
                  | null
                >;
              } | null>;
            }
          | null
        >;
      }
    | {
        __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
        heading?: string | null;
        bodyText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        confirmPrivacy?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        relatedForm?: Array<{
          __typename?: 'newsletter_Form';
          handle?: string | null;
          submissionEndpoint?: string | null;
          submissionMutationName?: string | null;
          formFields?: Array<
            | {
                __typename?: 'Field_Address';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | {
                __typename?: 'Field_Agree';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | {
                __typename?: 'Field_Calculations';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | {
                __typename?: 'Field_Checkboxes';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | {
                __typename?: 'Field_Date';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | {
                __typename?: 'Field_Dropdown';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | {
                __typename?: 'Field_Email';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | {
                __typename?: 'Field_Entries';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | {
                __typename?: 'Field_Group';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | {
                __typename?: 'Field_Heading';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | {
                __typename?: 'Field_Hidden';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | {
                __typename?: 'Field_Html';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | {
                __typename?: 'Field_MultiLineText';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | {
                __typename?: 'Field_Name';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | {
                __typename?: 'Field_Number';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | {
                __typename?: 'Field_Password';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | {
                __typename?: 'Field_Payment';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | {
                __typename?: 'Field_Phone';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | {
                __typename?: 'Field_Radio';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | {
                __typename?: 'Field_Recipients';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | {
                __typename?: 'Field_Section';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | {
                __typename?: 'Field_Signature';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | {
                __typename?: 'Field_SingleLineText';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | {
                __typename?: 'Field_Summary';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | {
                __typename?: 'Field_Table';
                id?: string | null;
                handle?: string | null;
                inputTypeName?: string | null;
              }
            | null
          > | null;
        } | null> | null;
      }
    | {
        __typename?: 'contentBlocks_oembed_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
        uid?: string | null;
        htmlOrLink?: boolean | null;
        heading?: string | null;
        embed?: {
          __typename?: 'embed_OembedField';
          title?: string | null;
          description?: string | null;
          url?: string | null;
          type?: string | null;
          image?: string | null;
          imageWidth?: string | null;
          imageHeight?: string | null;
          code?: string | null;
          aspectRatio?: string | null;
          providerName?: string | null;
        } | null;
        description?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        code?: {
          __typename?: 'code_CodeData';
          language?: string | null;
          value?: string | null;
        } | null;
      }
    | {
        __typename?: 'contentBlocks_spacer_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
        size?: string | null;
      }
    | {
        __typename?: 'contentBlocks_textBlock_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
        bodyText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
      }
    | {
        __typename?: 'contentBlocks_textMediaBlock_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
        children?: Array<
          | {
              __typename?: 'contentBlocks_accordionItem_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_accordion_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_cookieInfo_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_filteredCalendar_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_mediaItem_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
              mediaAsset: Array<
                | { __typename?: 'documents_Asset' }
                | {
                    __typename?: 'mediaFiles_Asset';
                    id?: string | null;
                    kind: string;
                    url?: string | null;
                    focalPoint?: Array<number | null> | null;
                    width?: number | null;
                    height?: number | null;
                    mimeType?: string | null;
                    videoUrl?: string | null;
                    srcset?: string | null;
                    altText?: {
                      __typename?: 'NodeCollection';
                      renderHtml?: string | null;
                    } | null;
                    caption?: {
                      __typename?: 'NodeCollection';
                      renderHtml?: string | null;
                    } | null;
                    credits?: {
                      __typename?: 'NodeCollection';
                      renderHtml?: string | null;
                    } | null;
                    mux?: {
                      __typename?: 'mux_MuxMateField';
                      playback_id?: string | null;
                      error?: string | null;
                    } | null;
                    subtitles: Array<{
                      __typename?: 'subtitles_subtitle_BlockType';
                      id?: string | null;
                      label?: string | null;
                      lang?: string | null;
                      file: Array<
                        | {
                            __typename?: 'documents_Asset';
                            id?: string | null;
                            url?: string | null;
                            mimeType?: string | null;
                            format?: string | null;
                            kind: string;
                            title?: string | null;
                          }
                        | {
                            __typename?: 'mediaFiles_Asset';
                            id?: string | null;
                            url?: string | null;
                            mimeType?: string | null;
                            format?: string | null;
                            kind: string;
                            title?: string | null;
                          }
                        | null
                      >;
                    } | null>;
                  }
                | null
              >;
            }
          | {
              __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_oembed_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_spacer_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_textBlock_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
              bodyText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
            }
          | {
              __typename?: 'contentBlocks_textMediaBlock_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_topic_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_videoPlayer_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'dateSpans_dateSpan_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'homepageIntro_element_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'homepageIntro_link_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'moreLink_link_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'moreLinks_link_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'offerDates_offerDate_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | null
        > | null;
      }
    | {
        __typename?: 'contentBlocks_topic_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
        heading?: string | null;
        hideHeading?: boolean | null;
        subheading?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
      }
    | {
        __typename?: 'contentBlocks_videoPlayer_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
        video: Array<
          | { __typename?: 'documents_Asset' }
          | {
              __typename?: 'mediaFiles_Asset';
              id?: string | null;
              kind: string;
              url?: string | null;
              focalPoint?: Array<number | null> | null;
              width?: number | null;
              height?: number | null;
              mimeType?: string | null;
              videoUrl?: string | null;
              srcset?: string | null;
              altText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              caption?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              credits?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              mux?: {
                __typename?: 'mux_MuxMateField';
                playback_id?: string | null;
                error?: string | null;
              } | null;
              subtitles: Array<{
                __typename?: 'subtitles_subtitle_BlockType';
                id?: string | null;
                label?: string | null;
                lang?: string | null;
                file: Array<
                  | {
                      __typename?: 'documents_Asset';
                      id?: string | null;
                      url?: string | null;
                      mimeType?: string | null;
                      format?: string | null;
                      kind: string;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'mediaFiles_Asset';
                      id?: string | null;
                      url?: string | null;
                      mimeType?: string | null;
                      format?: string | null;
                      kind: string;
                      title?: string | null;
                    }
                  | null
                >;
              } | null>;
            }
          | null
        >;
      }
    | null
  > | null;
  banner: Array<{
    __typename?: 'banner_banner_BlockType';
    text?: string | null;
    filter: Array<
      | {
          __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
          id?: string | null;
          siteId?: number | null;
          sectionHandle: string;
          title?: string | null;
        }
      | {
          __typename?: 'ageGroups_ageGroup_Entry';
          id?: string | null;
          siteId?: number | null;
          sectionHandle: string;
          title?: string | null;
        }
      | {
          __typename?: 'audiences_audience_Entry';
          id?: string | null;
          siteId?: number | null;
          sectionHandle: string;
          title?: string | null;
        }
      | {
          __typename?: 'badges_badge_Entry';
          id?: string | null;
          siteId?: number | null;
          sectionHandle: string;
          title?: string | null;
        }
      | {
          __typename?: 'countries_country_Entry';
          id?: string | null;
          siteId?: number | null;
          sectionHandle: string;
          title?: string | null;
        }
      | {
          __typename?: 'exhibitions_exhibition_Entry';
          id?: string | null;
          siteId?: number | null;
          sectionHandle: string;
          title?: string | null;
        }
      | {
          __typename?: 'goMusSettings_goMusSettings_Entry';
          id?: string | null;
          siteId?: number | null;
          sectionHandle: string;
          title?: string | null;
        }
      | {
          __typename?: 'homePage_homePage_Entry';
          id?: string | null;
          siteId?: number | null;
          sectionHandle: string;
          title?: string | null;
        }
      | {
          __typename?: 'locations_location_Entry';
          id?: string | null;
          siteId?: number | null;
          sectionHandle: string;
          title?: string | null;
        }
      | {
          __typename?: 'museumsOverview_museumsOverview_Entry';
          id?: string | null;
          siteId?: number | null;
          sectionHandle: string;
          title?: string | null;
        }
      | {
          __typename?: 'museums_museum_Entry';
          id?: string | null;
          siteId?: number | null;
          sectionHandle: string;
          title?: string | null;
        }
      | {
          __typename?: 'newsItems_newsItem_Entry';
          id?: string | null;
          siteId?: number | null;
          sectionHandle: string;
          title?: string | null;
        }
      | {
          __typename?: 'offerCategories_offerCategory_Entry';
          id?: string | null;
          siteId?: number | null;
          sectionHandle: string;
          title?: string | null;
        }
      | {
          __typename?: 'offerLanguages_offerLanguage_Entry';
          id?: string | null;
          siteId?: number | null;
          sectionHandle: string;
          title?: string | null;
        }
      | {
          __typename?: 'offersOverview_offersOverview_Entry';
          id?: string | null;
          siteId?: number | null;
          sectionHandle: string;
          title?: string | null;
        }
      | {
          __typename?: 'offers_event_Entry';
          id?: string | null;
          siteId?: number | null;
          sectionHandle: string;
          title?: string | null;
        }
      | {
          __typename?: 'offers_tour_Entry';
          id?: string | null;
          siteId?: number | null;
          sectionHandle: string;
          title?: string | null;
        }
      | {
          __typename?: 'pages_page_Entry';
          id?: string | null;
          siteId?: number | null;
          sectionHandle: string;
          title?: string | null;
        }
      | {
          __typename?: 'proposalCategories_proposalCategory_Entry';
          id?: string | null;
          siteId?: number | null;
          sectionHandle: string;
          title?: string | null;
        }
      | {
          __typename?: 'schoolGrades_schoolGrade_Entry';
          id?: string | null;
          siteId?: number | null;
          sectionHandle: string;
          title?: string | null;
        }
      | {
          __typename?: 'siteInfo_siteInfo_Entry';
          id?: string | null;
          siteId?: number | null;
          sectionHandle: string;
          title?: string | null;
        }
      | {
          __typename?: 'topics_topic_Entry';
          id?: string | null;
          siteId?: number | null;
          sectionHandle: string;
          title?: string | null;
        }
      | null
    >;
  } | null>;
};

type NeoBlock_ContentBlocks_AccordionItem_BlockType_Fragment = {
  __typename?: 'contentBlocks_accordionItem_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type NeoBlock_ContentBlocks_Accordion_BlockType_Fragment = {
  __typename?: 'contentBlocks_accordion_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type NeoBlock_ContentBlocks_CookieInfo_BlockType_Fragment = {
  __typename?: 'contentBlocks_cookieInfo_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type NeoBlock_ContentBlocks_FilteredCalendar_BlockType_Fragment = {
  __typename?: 'contentBlocks_filteredCalendar_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type NeoBlock_ContentBlocks_MediaItem_BlockType_Fragment = {
  __typename?: 'contentBlocks_mediaItem_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type NeoBlock_ContentBlocks_NewsletterSubscribe_BlockType_Fragment = {
  __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type NeoBlock_ContentBlocks_Oembed_BlockType_Fragment = {
  __typename?: 'contentBlocks_oembed_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type NeoBlock_ContentBlocks_Spacer_BlockType_Fragment = {
  __typename?: 'contentBlocks_spacer_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type NeoBlock_ContentBlocks_TextBlock_BlockType_Fragment = {
  __typename?: 'contentBlocks_textBlock_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type NeoBlock_ContentBlocks_TextMediaBlock_BlockType_Fragment = {
  __typename?: 'contentBlocks_textMediaBlock_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type NeoBlock_ContentBlocks_Topic_BlockType_Fragment = {
  __typename?: 'contentBlocks_topic_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type NeoBlock_ContentBlocks_VideoPlayer_BlockType_Fragment = {
  __typename?: 'contentBlocks_videoPlayer_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type NeoBlock_DateSpans_DateSpan_BlockType_Fragment = {
  __typename?: 'dateSpans_dateSpan_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type NeoBlock_HomepageIntro_Element_BlockType_Fragment = {
  __typename?: 'homepageIntro_element_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type NeoBlock_HomepageIntro_Link_BlockType_Fragment = {
  __typename?: 'homepageIntro_link_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type NeoBlock_MoreLink_Link_BlockType_Fragment = {
  __typename?: 'moreLink_link_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type NeoBlock_MoreLinks_Link_BlockType_Fragment = {
  __typename?: 'moreLinks_link_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type NeoBlock_OfferDates_OfferDate_BlockType_Fragment = {
  __typename?: 'offerDates_offerDate_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type NeoBlock_OfferPricingInfo_OfferPricingInfo_BlockType_Fragment = {
  __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

export type NeoBlockFragment =
  | NeoBlock_ContentBlocks_AccordionItem_BlockType_Fragment
  | NeoBlock_ContentBlocks_Accordion_BlockType_Fragment
  | NeoBlock_ContentBlocks_CookieInfo_BlockType_Fragment
  | NeoBlock_ContentBlocks_FilteredCalendar_BlockType_Fragment
  | NeoBlock_ContentBlocks_MediaItem_BlockType_Fragment
  | NeoBlock_ContentBlocks_NewsletterSubscribe_BlockType_Fragment
  | NeoBlock_ContentBlocks_Oembed_BlockType_Fragment
  | NeoBlock_ContentBlocks_Spacer_BlockType_Fragment
  | NeoBlock_ContentBlocks_TextBlock_BlockType_Fragment
  | NeoBlock_ContentBlocks_TextMediaBlock_BlockType_Fragment
  | NeoBlock_ContentBlocks_Topic_BlockType_Fragment
  | NeoBlock_ContentBlocks_VideoPlayer_BlockType_Fragment
  | NeoBlock_DateSpans_DateSpan_BlockType_Fragment
  | NeoBlock_HomepageIntro_Element_BlockType_Fragment
  | NeoBlock_HomepageIntro_Link_BlockType_Fragment
  | NeoBlock_MoreLink_Link_BlockType_Fragment
  | NeoBlock_MoreLinks_Link_BlockType_Fragment
  | NeoBlock_OfferDates_OfferDate_BlockType_Fragment
  | NeoBlock_OfferPricingInfo_OfferPricingInfo_BlockType_Fragment;

type ContentBlock_ContentBlocks_AccordionItem_BlockType_Fragment = {
  __typename?: 'contentBlocks_accordionItem_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type ContentBlock_ContentBlocks_Accordion_BlockType_Fragment = {
  __typename?: 'contentBlocks_accordion_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
  children?: Array<
    | {
        __typename?: 'contentBlocks_accordionItem_BlockType';
        heading?: string | null;
        level?: number | null;
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
        children?: Array<
          | {
              __typename?: 'contentBlocks_accordionItem_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_accordion_BlockType';
              level?: number | null;
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
              children?: Array<
                | {
                    __typename?: 'contentBlocks_accordionItem_BlockType';
                    heading?: string | null;
                    level?: number | null;
                    children?: Array<
                      | {
                          __typename?: 'contentBlocks_accordionItem_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_accordion_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_cookieInfo_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_filteredCalendar_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_mediaItem_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_oembed_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                          uid?: string | null;
                          htmlOrLink?: boolean | null;
                          heading?: string | null;
                          embed?: {
                            __typename?: 'embed_OembedField';
                            title?: string | null;
                            description?: string | null;
                            url?: string | null;
                            type?: string | null;
                            image?: string | null;
                            imageWidth?: string | null;
                            imageHeight?: string | null;
                            code?: string | null;
                            aspectRatio?: string | null;
                            providerName?: string | null;
                          } | null;
                          description?: {
                            __typename?: 'NodeCollection';
                            renderHtml?: string | null;
                          } | null;
                          code?: {
                            __typename?: 'code_CodeData';
                            language?: string | null;
                            value?: string | null;
                          } | null;
                        }
                      | {
                          __typename?: 'contentBlocks_spacer_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_textBlock_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                          bodyText?: {
                            __typename?: 'NodeCollection';
                            renderHtml?: string | null;
                          } | null;
                        }
                      | {
                          __typename?: 'contentBlocks_textMediaBlock_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_topic_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_videoPlayer_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'dateSpans_dateSpan_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'homepageIntro_element_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'homepageIntro_link_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'moreLink_link_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'moreLinks_link_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'offerDates_offerDate_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | null
                    > | null;
                  }
                | { __typename?: 'contentBlocks_accordion_BlockType' }
                | { __typename?: 'contentBlocks_cookieInfo_BlockType' }
                | { __typename?: 'contentBlocks_filteredCalendar_BlockType' }
                | { __typename?: 'contentBlocks_mediaItem_BlockType' }
                | { __typename?: 'contentBlocks_newsletterSubscribe_BlockType' }
                | { __typename?: 'contentBlocks_oembed_BlockType' }
                | { __typename?: 'contentBlocks_spacer_BlockType' }
                | { __typename?: 'contentBlocks_textBlock_BlockType' }
                | { __typename?: 'contentBlocks_textMediaBlock_BlockType' }
                | { __typename?: 'contentBlocks_topic_BlockType' }
                | { __typename?: 'contentBlocks_videoPlayer_BlockType' }
                | { __typename?: 'dateSpans_dateSpan_BlockType' }
                | { __typename?: 'homepageIntro_element_BlockType' }
                | { __typename?: 'homepageIntro_link_BlockType' }
                | { __typename?: 'moreLink_link_BlockType' }
                | { __typename?: 'moreLinks_link_BlockType' }
                | { __typename?: 'offerDates_offerDate_BlockType' }
                | { __typename?: 'offerPricingInfo_offerPricingInfo_BlockType' }
                | null
              > | null;
            }
          | {
              __typename?: 'contentBlocks_cookieInfo_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_filteredCalendar_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_mediaItem_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_oembed_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
              uid?: string | null;
              htmlOrLink?: boolean | null;
              heading?: string | null;
              embed?: {
                __typename?: 'embed_OembedField';
                title?: string | null;
                description?: string | null;
                url?: string | null;
                type?: string | null;
                image?: string | null;
                imageWidth?: string | null;
                imageHeight?: string | null;
                code?: string | null;
                aspectRatio?: string | null;
                providerName?: string | null;
              } | null;
              description?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              code?: {
                __typename?: 'code_CodeData';
                language?: string | null;
                value?: string | null;
              } | null;
            }
          | {
              __typename?: 'contentBlocks_spacer_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_textBlock_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
              bodyText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
            }
          | {
              __typename?: 'contentBlocks_textMediaBlock_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_topic_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_videoPlayer_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'dateSpans_dateSpan_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'homepageIntro_element_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'homepageIntro_link_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'moreLink_link_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'moreLinks_link_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'offerDates_offerDate_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | null
        > | null;
      }
    | { __typename?: 'contentBlocks_accordion_BlockType' }
    | { __typename?: 'contentBlocks_cookieInfo_BlockType' }
    | { __typename?: 'contentBlocks_filteredCalendar_BlockType' }
    | { __typename?: 'contentBlocks_mediaItem_BlockType' }
    | { __typename?: 'contentBlocks_newsletterSubscribe_BlockType' }
    | { __typename?: 'contentBlocks_oembed_BlockType' }
    | { __typename?: 'contentBlocks_spacer_BlockType' }
    | { __typename?: 'contentBlocks_textBlock_BlockType' }
    | { __typename?: 'contentBlocks_textMediaBlock_BlockType' }
    | { __typename?: 'contentBlocks_topic_BlockType' }
    | { __typename?: 'contentBlocks_videoPlayer_BlockType' }
    | { __typename?: 'dateSpans_dateSpan_BlockType' }
    | { __typename?: 'homepageIntro_element_BlockType' }
    | { __typename?: 'homepageIntro_link_BlockType' }
    | { __typename?: 'moreLink_link_BlockType' }
    | { __typename?: 'moreLinks_link_BlockType' }
    | { __typename?: 'offerDates_offerDate_BlockType' }
    | { __typename?: 'offerPricingInfo_offerPricingInfo_BlockType' }
    | null
  > | null;
};

type ContentBlock_ContentBlocks_CookieInfo_BlockType_Fragment = {
  __typename?: 'contentBlocks_cookieInfo_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
  bodyText?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
};

type ContentBlock_ContentBlocks_FilteredCalendar_BlockType_Fragment = {
  __typename?: 'contentBlocks_filteredCalendar_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
  offerCategories: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        externalId?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | null
  >;
};

type ContentBlock_ContentBlocks_MediaItem_BlockType_Fragment = {
  __typename?: 'contentBlocks_mediaItem_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
  mediaAsset: Array<
    | { __typename?: 'documents_Asset' }
    | {
        __typename?: 'mediaFiles_Asset';
        id?: string | null;
        kind: string;
        url?: string | null;
        focalPoint?: Array<number | null> | null;
        width?: number | null;
        height?: number | null;
        mimeType?: string | null;
        videoUrl?: string | null;
        srcset?: string | null;
        altText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        caption?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        credits?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        mux?: {
          __typename?: 'mux_MuxMateField';
          playback_id?: string | null;
          error?: string | null;
        } | null;
        subtitles: Array<{
          __typename?: 'subtitles_subtitle_BlockType';
          id?: string | null;
          label?: string | null;
          lang?: string | null;
          file: Array<
            | {
                __typename?: 'documents_Asset';
                id?: string | null;
                url?: string | null;
                mimeType?: string | null;
                format?: string | null;
                kind: string;
                title?: string | null;
              }
            | {
                __typename?: 'mediaFiles_Asset';
                id?: string | null;
                url?: string | null;
                mimeType?: string | null;
                format?: string | null;
                kind: string;
                title?: string | null;
              }
            | null
          >;
        } | null>;
      }
    | null
  >;
};

type ContentBlock_ContentBlocks_NewsletterSubscribe_BlockType_Fragment = {
  __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
  heading?: string | null;
  bodyText?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  confirmPrivacy?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  relatedForm?: Array<{
    __typename?: 'newsletter_Form';
    handle?: string | null;
    submissionEndpoint?: string | null;
    submissionMutationName?: string | null;
    formFields?: Array<
      | {
          __typename?: 'Field_Address';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Agree';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Calculations';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Checkboxes';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Date';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Dropdown';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Email';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Entries';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Group';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Heading';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Hidden';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Html';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_MultiLineText';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Name';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Number';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Password';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Payment';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Phone';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Radio';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Recipients';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Section';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Signature';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_SingleLineText';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Summary';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Table';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | null
    > | null;
  } | null> | null;
};

type ContentBlock_ContentBlocks_Oembed_BlockType_Fragment = {
  __typename?: 'contentBlocks_oembed_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
  uid?: string | null;
  htmlOrLink?: boolean | null;
  heading?: string | null;
  embed?: {
    __typename?: 'embed_OembedField';
    title?: string | null;
    description?: string | null;
    url?: string | null;
    type?: string | null;
    image?: string | null;
    imageWidth?: string | null;
    imageHeight?: string | null;
    code?: string | null;
    aspectRatio?: string | null;
    providerName?: string | null;
  } | null;
  description?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  code?: {
    __typename?: 'code_CodeData';
    language?: string | null;
    value?: string | null;
  } | null;
};

type ContentBlock_ContentBlocks_Spacer_BlockType_Fragment = {
  __typename?: 'contentBlocks_spacer_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
  size?: string | null;
};

type ContentBlock_ContentBlocks_TextBlock_BlockType_Fragment = {
  __typename?: 'contentBlocks_textBlock_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
  bodyText?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
};

type ContentBlock_ContentBlocks_TextMediaBlock_BlockType_Fragment = {
  __typename?: 'contentBlocks_textMediaBlock_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
  children?: Array<
    | {
        __typename?: 'contentBlocks_accordionItem_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_accordion_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_cookieInfo_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_filteredCalendar_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_mediaItem_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
        mediaAsset: Array<
          | { __typename?: 'documents_Asset' }
          | {
              __typename?: 'mediaFiles_Asset';
              id?: string | null;
              kind: string;
              url?: string | null;
              focalPoint?: Array<number | null> | null;
              width?: number | null;
              height?: number | null;
              mimeType?: string | null;
              videoUrl?: string | null;
              srcset?: string | null;
              altText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              caption?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              credits?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              mux?: {
                __typename?: 'mux_MuxMateField';
                playback_id?: string | null;
                error?: string | null;
              } | null;
              subtitles: Array<{
                __typename?: 'subtitles_subtitle_BlockType';
                id?: string | null;
                label?: string | null;
                lang?: string | null;
                file: Array<
                  | {
                      __typename?: 'documents_Asset';
                      id?: string | null;
                      url?: string | null;
                      mimeType?: string | null;
                      format?: string | null;
                      kind: string;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'mediaFiles_Asset';
                      id?: string | null;
                      url?: string | null;
                      mimeType?: string | null;
                      format?: string | null;
                      kind: string;
                      title?: string | null;
                    }
                  | null
                >;
              } | null>;
            }
          | null
        >;
      }
    | {
        __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_oembed_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_spacer_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_textBlock_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
        bodyText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
      }
    | {
        __typename?: 'contentBlocks_textMediaBlock_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_topic_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_videoPlayer_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'dateSpans_dateSpan_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'homepageIntro_element_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'homepageIntro_link_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'moreLink_link_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'moreLinks_link_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'offerDates_offerDate_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | null
  > | null;
};

type ContentBlock_ContentBlocks_Topic_BlockType_Fragment = {
  __typename?: 'contentBlocks_topic_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
  heading?: string | null;
  hideHeading?: boolean | null;
  subheading?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
};

type ContentBlock_ContentBlocks_VideoPlayer_BlockType_Fragment = {
  __typename?: 'contentBlocks_videoPlayer_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
  video: Array<
    | { __typename?: 'documents_Asset' }
    | {
        __typename?: 'mediaFiles_Asset';
        id?: string | null;
        kind: string;
        url?: string | null;
        focalPoint?: Array<number | null> | null;
        width?: number | null;
        height?: number | null;
        mimeType?: string | null;
        videoUrl?: string | null;
        srcset?: string | null;
        altText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        caption?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        credits?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        mux?: {
          __typename?: 'mux_MuxMateField';
          playback_id?: string | null;
          error?: string | null;
        } | null;
        subtitles: Array<{
          __typename?: 'subtitles_subtitle_BlockType';
          id?: string | null;
          label?: string | null;
          lang?: string | null;
          file: Array<
            | {
                __typename?: 'documents_Asset';
                id?: string | null;
                url?: string | null;
                mimeType?: string | null;
                format?: string | null;
                kind: string;
                title?: string | null;
              }
            | {
                __typename?: 'mediaFiles_Asset';
                id?: string | null;
                url?: string | null;
                mimeType?: string | null;
                format?: string | null;
                kind: string;
                title?: string | null;
              }
            | null
          >;
        } | null>;
      }
    | null
  >;
};

type ContentBlock_DateSpans_DateSpan_BlockType_Fragment = {
  __typename?: 'dateSpans_dateSpan_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type ContentBlock_HomepageIntro_Element_BlockType_Fragment = {
  __typename?: 'homepageIntro_element_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type ContentBlock_HomepageIntro_Link_BlockType_Fragment = {
  __typename?: 'homepageIntro_link_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type ContentBlock_MoreLink_Link_BlockType_Fragment = {
  __typename?: 'moreLink_link_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type ContentBlock_MoreLinks_Link_BlockType_Fragment = {
  __typename?: 'moreLinks_link_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type ContentBlock_OfferDates_OfferDate_BlockType_Fragment = {
  __typename?: 'offerDates_offerDate_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

type ContentBlock_OfferPricingInfo_OfferPricingInfo_BlockType_Fragment = {
  __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
};

export type ContentBlockFragment =
  | ContentBlock_ContentBlocks_AccordionItem_BlockType_Fragment
  | ContentBlock_ContentBlocks_Accordion_BlockType_Fragment
  | ContentBlock_ContentBlocks_CookieInfo_BlockType_Fragment
  | ContentBlock_ContentBlocks_FilteredCalendar_BlockType_Fragment
  | ContentBlock_ContentBlocks_MediaItem_BlockType_Fragment
  | ContentBlock_ContentBlocks_NewsletterSubscribe_BlockType_Fragment
  | ContentBlock_ContentBlocks_Oembed_BlockType_Fragment
  | ContentBlock_ContentBlocks_Spacer_BlockType_Fragment
  | ContentBlock_ContentBlocks_TextBlock_BlockType_Fragment
  | ContentBlock_ContentBlocks_TextMediaBlock_BlockType_Fragment
  | ContentBlock_ContentBlocks_Topic_BlockType_Fragment
  | ContentBlock_ContentBlocks_VideoPlayer_BlockType_Fragment
  | ContentBlock_DateSpans_DateSpan_BlockType_Fragment
  | ContentBlock_HomepageIntro_Element_BlockType_Fragment
  | ContentBlock_HomepageIntro_Link_BlockType_Fragment
  | ContentBlock_MoreLink_Link_BlockType_Fragment
  | ContentBlock_MoreLinks_Link_BlockType_Fragment
  | ContentBlock_OfferDates_OfferDate_BlockType_Fragment
  | ContentBlock_OfferPricingInfo_OfferPricingInfo_BlockType_Fragment;

export type TopicBlockFragment = {
  __typename?: 'contentBlocks_topic_BlockType';
  heading?: string | null;
  hideHeading?: boolean | null;
  subheading?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
};

export type TextMediaBlockFragment = {
  __typename?: 'contentBlocks_textMediaBlock_BlockType';
  children?: Array<
    | {
        __typename?: 'contentBlocks_accordionItem_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_accordion_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_cookieInfo_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_filteredCalendar_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_mediaItem_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
        mediaAsset: Array<
          | { __typename?: 'documents_Asset' }
          | {
              __typename?: 'mediaFiles_Asset';
              id?: string | null;
              kind: string;
              url?: string | null;
              focalPoint?: Array<number | null> | null;
              width?: number | null;
              height?: number | null;
              mimeType?: string | null;
              videoUrl?: string | null;
              srcset?: string | null;
              altText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              caption?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              credits?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              mux?: {
                __typename?: 'mux_MuxMateField';
                playback_id?: string | null;
                error?: string | null;
              } | null;
              subtitles: Array<{
                __typename?: 'subtitles_subtitle_BlockType';
                id?: string | null;
                label?: string | null;
                lang?: string | null;
                file: Array<
                  | {
                      __typename?: 'documents_Asset';
                      id?: string | null;
                      url?: string | null;
                      mimeType?: string | null;
                      format?: string | null;
                      kind: string;
                      title?: string | null;
                    }
                  | {
                      __typename?: 'mediaFiles_Asset';
                      id?: string | null;
                      url?: string | null;
                      mimeType?: string | null;
                      format?: string | null;
                      kind: string;
                      title?: string | null;
                    }
                  | null
                >;
              } | null>;
            }
          | null
        >;
      }
    | {
        __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_oembed_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_spacer_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_textBlock_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
        bodyText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
      }
    | {
        __typename?: 'contentBlocks_textMediaBlock_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_topic_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_videoPlayer_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'dateSpans_dateSpan_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'homepageIntro_element_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'homepageIntro_link_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'moreLink_link_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'moreLinks_link_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'offerDates_offerDate_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | null
  > | null;
};

export type MediaItemBlockFragment = {
  __typename?: 'contentBlocks_mediaItem_BlockType';
  mediaAsset: Array<
    | { __typename?: 'documents_Asset' }
    | {
        __typename?: 'mediaFiles_Asset';
        id?: string | null;
        kind: string;
        url?: string | null;
        focalPoint?: Array<number | null> | null;
        width?: number | null;
        height?: number | null;
        mimeType?: string | null;
        videoUrl?: string | null;
        srcset?: string | null;
        altText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        caption?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        credits?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        mux?: {
          __typename?: 'mux_MuxMateField';
          playback_id?: string | null;
          error?: string | null;
        } | null;
        subtitles: Array<{
          __typename?: 'subtitles_subtitle_BlockType';
          id?: string | null;
          label?: string | null;
          lang?: string | null;
          file: Array<
            | {
                __typename?: 'documents_Asset';
                id?: string | null;
                url?: string | null;
                mimeType?: string | null;
                format?: string | null;
                kind: string;
                title?: string | null;
              }
            | {
                __typename?: 'mediaFiles_Asset';
                id?: string | null;
                url?: string | null;
                mimeType?: string | null;
                format?: string | null;
                kind: string;
                title?: string | null;
              }
            | null
          >;
        } | null>;
      }
    | null
  >;
};

export type TextBlockFragment = {
  __typename?: 'contentBlocks_textBlock_BlockType';
  bodyText?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
};

export type VideoBlockFragment = {
  __typename?: 'contentBlocks_videoPlayer_BlockType';
  video: Array<
    | { __typename?: 'documents_Asset' }
    | {
        __typename?: 'mediaFiles_Asset';
        id?: string | null;
        kind: string;
        url?: string | null;
        focalPoint?: Array<number | null> | null;
        width?: number | null;
        height?: number | null;
        mimeType?: string | null;
        videoUrl?: string | null;
        srcset?: string | null;
        altText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        caption?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        credits?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        mux?: {
          __typename?: 'mux_MuxMateField';
          playback_id?: string | null;
          error?: string | null;
        } | null;
        subtitles: Array<{
          __typename?: 'subtitles_subtitle_BlockType';
          id?: string | null;
          label?: string | null;
          lang?: string | null;
          file: Array<
            | {
                __typename?: 'documents_Asset';
                id?: string | null;
                url?: string | null;
                mimeType?: string | null;
                format?: string | null;
                kind: string;
                title?: string | null;
              }
            | {
                __typename?: 'mediaFiles_Asset';
                id?: string | null;
                url?: string | null;
                mimeType?: string | null;
                format?: string | null;
                kind: string;
                title?: string | null;
              }
            | null
          >;
        } | null>;
      }
    | null
  >;
};

export type OEmbedBlockFragment = {
  __typename?: 'contentBlocks_oembed_BlockType';
  uid?: string | null;
  htmlOrLink?: boolean | null;
  heading?: string | null;
  embed?: {
    __typename?: 'embed_OembedField';
    title?: string | null;
    description?: string | null;
    url?: string | null;
    type?: string | null;
    image?: string | null;
    imageWidth?: string | null;
    imageHeight?: string | null;
    code?: string | null;
    aspectRatio?: string | null;
    providerName?: string | null;
  } | null;
  description?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  code?: {
    __typename?: 'code_CodeData';
    language?: string | null;
    value?: string | null;
  } | null;
};

export type AccordionBlockFragment = {
  __typename?: 'contentBlocks_accordion_BlockType';
  children?: Array<
    | {
        __typename?: 'contentBlocks_accordionItem_BlockType';
        heading?: string | null;
        level?: number | null;
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
        children?: Array<
          | {
              __typename?: 'contentBlocks_accordionItem_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_accordion_BlockType';
              level?: number | null;
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
              children?: Array<
                | {
                    __typename?: 'contentBlocks_accordionItem_BlockType';
                    heading?: string | null;
                    level?: number | null;
                    children?: Array<
                      | {
                          __typename?: 'contentBlocks_accordionItem_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_accordion_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_cookieInfo_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_filteredCalendar_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_mediaItem_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_oembed_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                          uid?: string | null;
                          htmlOrLink?: boolean | null;
                          heading?: string | null;
                          embed?: {
                            __typename?: 'embed_OembedField';
                            title?: string | null;
                            description?: string | null;
                            url?: string | null;
                            type?: string | null;
                            image?: string | null;
                            imageWidth?: string | null;
                            imageHeight?: string | null;
                            code?: string | null;
                            aspectRatio?: string | null;
                            providerName?: string | null;
                          } | null;
                          description?: {
                            __typename?: 'NodeCollection';
                            renderHtml?: string | null;
                          } | null;
                          code?: {
                            __typename?: 'code_CodeData';
                            language?: string | null;
                            value?: string | null;
                          } | null;
                        }
                      | {
                          __typename?: 'contentBlocks_spacer_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_textBlock_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                          bodyText?: {
                            __typename?: 'NodeCollection';
                            renderHtml?: string | null;
                          } | null;
                        }
                      | {
                          __typename?: 'contentBlocks_textMediaBlock_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_topic_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'contentBlocks_videoPlayer_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'dateSpans_dateSpan_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'homepageIntro_element_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'homepageIntro_link_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'moreLink_link_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'moreLinks_link_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'offerDates_offerDate_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | {
                          __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
                          id?: string | null;
                          siteId?: number | null;
                          typeHandle?: string | null;
                          sortOrder?: number | null;
                        }
                      | null
                    > | null;
                  }
                | { __typename?: 'contentBlocks_accordion_BlockType' }
                | { __typename?: 'contentBlocks_cookieInfo_BlockType' }
                | { __typename?: 'contentBlocks_filteredCalendar_BlockType' }
                | { __typename?: 'contentBlocks_mediaItem_BlockType' }
                | { __typename?: 'contentBlocks_newsletterSubscribe_BlockType' }
                | { __typename?: 'contentBlocks_oembed_BlockType' }
                | { __typename?: 'contentBlocks_spacer_BlockType' }
                | { __typename?: 'contentBlocks_textBlock_BlockType' }
                | { __typename?: 'contentBlocks_textMediaBlock_BlockType' }
                | { __typename?: 'contentBlocks_topic_BlockType' }
                | { __typename?: 'contentBlocks_videoPlayer_BlockType' }
                | { __typename?: 'dateSpans_dateSpan_BlockType' }
                | { __typename?: 'homepageIntro_element_BlockType' }
                | { __typename?: 'homepageIntro_link_BlockType' }
                | { __typename?: 'moreLink_link_BlockType' }
                | { __typename?: 'moreLinks_link_BlockType' }
                | { __typename?: 'offerDates_offerDate_BlockType' }
                | { __typename?: 'offerPricingInfo_offerPricingInfo_BlockType' }
                | null
              > | null;
            }
          | {
              __typename?: 'contentBlocks_cookieInfo_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_filteredCalendar_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_mediaItem_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_oembed_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
              uid?: string | null;
              htmlOrLink?: boolean | null;
              heading?: string | null;
              embed?: {
                __typename?: 'embed_OembedField';
                title?: string | null;
                description?: string | null;
                url?: string | null;
                type?: string | null;
                image?: string | null;
                imageWidth?: string | null;
                imageHeight?: string | null;
                code?: string | null;
                aspectRatio?: string | null;
                providerName?: string | null;
              } | null;
              description?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
              code?: {
                __typename?: 'code_CodeData';
                language?: string | null;
                value?: string | null;
              } | null;
            }
          | {
              __typename?: 'contentBlocks_spacer_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_textBlock_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
              bodyText?: {
                __typename?: 'NodeCollection';
                renderHtml?: string | null;
              } | null;
            }
          | {
              __typename?: 'contentBlocks_textMediaBlock_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_topic_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'contentBlocks_videoPlayer_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'dateSpans_dateSpan_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'homepageIntro_element_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'homepageIntro_link_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'moreLink_link_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'moreLinks_link_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'offerDates_offerDate_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | {
              __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
              id?: string | null;
              siteId?: number | null;
              typeHandle?: string | null;
              sortOrder?: number | null;
            }
          | null
        > | null;
      }
    | { __typename?: 'contentBlocks_accordion_BlockType' }
    | { __typename?: 'contentBlocks_cookieInfo_BlockType' }
    | { __typename?: 'contentBlocks_filteredCalendar_BlockType' }
    | { __typename?: 'contentBlocks_mediaItem_BlockType' }
    | { __typename?: 'contentBlocks_newsletterSubscribe_BlockType' }
    | { __typename?: 'contentBlocks_oembed_BlockType' }
    | { __typename?: 'contentBlocks_spacer_BlockType' }
    | { __typename?: 'contentBlocks_textBlock_BlockType' }
    | { __typename?: 'contentBlocks_textMediaBlock_BlockType' }
    | { __typename?: 'contentBlocks_topic_BlockType' }
    | { __typename?: 'contentBlocks_videoPlayer_BlockType' }
    | { __typename?: 'dateSpans_dateSpan_BlockType' }
    | { __typename?: 'homepageIntro_element_BlockType' }
    | { __typename?: 'homepageIntro_link_BlockType' }
    | { __typename?: 'moreLink_link_BlockType' }
    | { __typename?: 'moreLinks_link_BlockType' }
    | { __typename?: 'offerDates_offerDate_BlockType' }
    | { __typename?: 'offerPricingInfo_offerPricingInfo_BlockType' }
    | null
  > | null;
};

export type AccordionItemBlockFragment = {
  __typename?: 'contentBlocks_accordionItem_BlockType';
  heading?: string | null;
  level?: number | null;
  id?: string | null;
  siteId?: number | null;
  typeHandle?: string | null;
  sortOrder?: number | null;
  children?: Array<
    | {
        __typename?: 'contentBlocks_accordionItem_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_accordion_BlockType';
        level?: number | null;
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
        children?: Array<
          | {
              __typename?: 'contentBlocks_accordionItem_BlockType';
              heading?: string | null;
              level?: number | null;
              children?: Array<
                | {
                    __typename?: 'contentBlocks_accordionItem_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_accordion_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_cookieInfo_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_filteredCalendar_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_mediaItem_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_oembed_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                    uid?: string | null;
                    htmlOrLink?: boolean | null;
                    heading?: string | null;
                    embed?: {
                      __typename?: 'embed_OembedField';
                      title?: string | null;
                      description?: string | null;
                      url?: string | null;
                      type?: string | null;
                      image?: string | null;
                      imageWidth?: string | null;
                      imageHeight?: string | null;
                      code?: string | null;
                      aspectRatio?: string | null;
                      providerName?: string | null;
                    } | null;
                    description?: {
                      __typename?: 'NodeCollection';
                      renderHtml?: string | null;
                    } | null;
                    code?: {
                      __typename?: 'code_CodeData';
                      language?: string | null;
                      value?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'contentBlocks_spacer_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_textBlock_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                    bodyText?: {
                      __typename?: 'NodeCollection';
                      renderHtml?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'contentBlocks_textMediaBlock_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_topic_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'contentBlocks_videoPlayer_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'dateSpans_dateSpan_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'homepageIntro_element_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'homepageIntro_link_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'moreLink_link_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'moreLinks_link_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'offerDates_offerDate_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | {
                    __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
                    id?: string | null;
                    siteId?: number | null;
                    typeHandle?: string | null;
                    sortOrder?: number | null;
                  }
                | null
              > | null;
            }
          | { __typename?: 'contentBlocks_accordion_BlockType' }
          | { __typename?: 'contentBlocks_cookieInfo_BlockType' }
          | { __typename?: 'contentBlocks_filteredCalendar_BlockType' }
          | { __typename?: 'contentBlocks_mediaItem_BlockType' }
          | { __typename?: 'contentBlocks_newsletterSubscribe_BlockType' }
          | { __typename?: 'contentBlocks_oembed_BlockType' }
          | { __typename?: 'contentBlocks_spacer_BlockType' }
          | { __typename?: 'contentBlocks_textBlock_BlockType' }
          | { __typename?: 'contentBlocks_textMediaBlock_BlockType' }
          | { __typename?: 'contentBlocks_topic_BlockType' }
          | { __typename?: 'contentBlocks_videoPlayer_BlockType' }
          | { __typename?: 'dateSpans_dateSpan_BlockType' }
          | { __typename?: 'homepageIntro_element_BlockType' }
          | { __typename?: 'homepageIntro_link_BlockType' }
          | { __typename?: 'moreLink_link_BlockType' }
          | { __typename?: 'moreLinks_link_BlockType' }
          | { __typename?: 'offerDates_offerDate_BlockType' }
          | { __typename?: 'offerPricingInfo_offerPricingInfo_BlockType' }
          | null
        > | null;
      }
    | {
        __typename?: 'contentBlocks_cookieInfo_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_filteredCalendar_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_mediaItem_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_oembed_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
        uid?: string | null;
        htmlOrLink?: boolean | null;
        heading?: string | null;
        embed?: {
          __typename?: 'embed_OembedField';
          title?: string | null;
          description?: string | null;
          url?: string | null;
          type?: string | null;
          image?: string | null;
          imageWidth?: string | null;
          imageHeight?: string | null;
          code?: string | null;
          aspectRatio?: string | null;
          providerName?: string | null;
        } | null;
        description?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
        code?: {
          __typename?: 'code_CodeData';
          language?: string | null;
          value?: string | null;
        } | null;
      }
    | {
        __typename?: 'contentBlocks_spacer_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_textBlock_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
        bodyText?: {
          __typename?: 'NodeCollection';
          renderHtml?: string | null;
        } | null;
      }
    | {
        __typename?: 'contentBlocks_textMediaBlock_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_topic_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'contentBlocks_videoPlayer_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'dateSpans_dateSpan_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'homepageIntro_element_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'homepageIntro_link_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'moreLink_link_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'moreLinks_link_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'offerDates_offerDate_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | {
        __typename?: 'offerPricingInfo_offerPricingInfo_BlockType';
        id?: string | null;
        siteId?: number | null;
        typeHandle?: string | null;
        sortOrder?: number | null;
      }
    | null
  > | null;
};

export type SpacerBlockFragment = {
  __typename?: 'contentBlocks_spacer_BlockType';
  size?: string | null;
};

export type FilteredCalendarBlockFragment = {
  __typename?: 'contentBlocks_filteredCalendar_BlockType';
  offerCategories: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        externalId?: string | null;
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        title?: string | null;
      }
    | null
  >;
};

export type NewsletterSubscribeBlockFragment = {
  __typename?: 'contentBlocks_newsletterSubscribe_BlockType';
  id?: string | null;
  heading?: string | null;
  bodyText?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  confirmPrivacy?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
  relatedForm?: Array<{
    __typename?: 'newsletter_Form';
    handle?: string | null;
    submissionEndpoint?: string | null;
    submissionMutationName?: string | null;
    formFields?: Array<
      | {
          __typename?: 'Field_Address';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Agree';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Calculations';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Checkboxes';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Date';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Dropdown';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Email';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Entries';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Group';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Heading';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Hidden';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Html';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_MultiLineText';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Name';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Number';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Password';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Payment';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Phone';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Radio';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Recipients';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Section';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Signature';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_SingleLineText';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Summary';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | {
          __typename?: 'Field_Table';
          id?: string | null;
          handle?: string | null;
          inputTypeName?: string | null;
        }
      | null
    > | null;
  } | null> | null;
};

type FormFields_Field_Address_Fragment = {
  __typename?: 'Field_Address';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

type FormFields_Field_Agree_Fragment = {
  __typename?: 'Field_Agree';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

type FormFields_Field_Calculations_Fragment = {
  __typename?: 'Field_Calculations';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

type FormFields_Field_Checkboxes_Fragment = {
  __typename?: 'Field_Checkboxes';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

type FormFields_Field_Date_Fragment = {
  __typename?: 'Field_Date';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

type FormFields_Field_Dropdown_Fragment = {
  __typename?: 'Field_Dropdown';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

type FormFields_Field_Email_Fragment = {
  __typename?: 'Field_Email';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

type FormFields_Field_Entries_Fragment = {
  __typename?: 'Field_Entries';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

type FormFields_Field_Group_Fragment = {
  __typename?: 'Field_Group';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

type FormFields_Field_Heading_Fragment = {
  __typename?: 'Field_Heading';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

type FormFields_Field_Hidden_Fragment = {
  __typename?: 'Field_Hidden';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

type FormFields_Field_Html_Fragment = {
  __typename?: 'Field_Html';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

type FormFields_Field_MultiLineText_Fragment = {
  __typename?: 'Field_MultiLineText';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

type FormFields_Field_Name_Fragment = {
  __typename?: 'Field_Name';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

type FormFields_Field_Number_Fragment = {
  __typename?: 'Field_Number';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

type FormFields_Field_Password_Fragment = {
  __typename?: 'Field_Password';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

type FormFields_Field_Payment_Fragment = {
  __typename?: 'Field_Payment';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

type FormFields_Field_Phone_Fragment = {
  __typename?: 'Field_Phone';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

type FormFields_Field_Radio_Fragment = {
  __typename?: 'Field_Radio';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

type FormFields_Field_Recipients_Fragment = {
  __typename?: 'Field_Recipients';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

type FormFields_Field_Section_Fragment = {
  __typename?: 'Field_Section';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

type FormFields_Field_Signature_Fragment = {
  __typename?: 'Field_Signature';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

type FormFields_Field_SingleLineText_Fragment = {
  __typename?: 'Field_SingleLineText';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

type FormFields_Field_Summary_Fragment = {
  __typename?: 'Field_Summary';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

type FormFields_Field_Table_Fragment = {
  __typename?: 'Field_Table';
  id?: string | null;
  handle?: string | null;
  inputTypeName?: string | null;
};

export type FormFieldsFragment =
  | FormFields_Field_Address_Fragment
  | FormFields_Field_Agree_Fragment
  | FormFields_Field_Calculations_Fragment
  | FormFields_Field_Checkboxes_Fragment
  | FormFields_Field_Date_Fragment
  | FormFields_Field_Dropdown_Fragment
  | FormFields_Field_Email_Fragment
  | FormFields_Field_Entries_Fragment
  | FormFields_Field_Group_Fragment
  | FormFields_Field_Heading_Fragment
  | FormFields_Field_Hidden_Fragment
  | FormFields_Field_Html_Fragment
  | FormFields_Field_MultiLineText_Fragment
  | FormFields_Field_Name_Fragment
  | FormFields_Field_Number_Fragment
  | FormFields_Field_Password_Fragment
  | FormFields_Field_Payment_Fragment
  | FormFields_Field_Phone_Fragment
  | FormFields_Field_Radio_Fragment
  | FormFields_Field_Recipients_Fragment
  | FormFields_Field_Section_Fragment
  | FormFields_Field_Signature_Fragment
  | FormFields_Field_SingleLineText_Fragment
  | FormFields_Field_Summary_Fragment
  | FormFields_Field_Table_Fragment;

export type CookieInfoBlockFragment = {
  __typename?: 'contentBlocks_cookieInfo_BlockType';
  bodyText?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
};

export type OEmbedFragment = {
  __typename?: 'embed_OembedField';
  title?: string | null;
  description?: string | null;
  url?: string | null;
  type?: string | null;
  image?: string | null;
  imageWidth?: string | null;
  imageHeight?: string | null;
  code?: string | null;
  aspectRatio?: string | null;
  providerName?: string | null;
};

export type TextSnippetsFragment = {
  __typename?: 'textSnippets_GlobalSet';
  confirmPrivacy?: {
    __typename?: 'NodeCollection';
    renderHtml?: string | null;
  } | null;
};

export type BannerFragment = {
  __typename?: 'banner_banner_BlockType';
  text?: string | null;
  filter: Array<
    | {
        __typename?: 'accessibilityFeatures_accessibilityFeature_Entry';
        id?: string | null;
        siteId?: number | null;
        sectionHandle: string;
        title?: string | null;
      }
    | {
        __typename?: 'ageGroups_ageGroup_Entry';
        id?: string | null;
        siteId?: number | null;
        sectionHandle: string;
        title?: string | null;
      }
    | {
        __typename?: 'audiences_audience_Entry';
        id?: string | null;
        siteId?: number | null;
        sectionHandle: string;
        title?: string | null;
      }
    | {
        __typename?: 'badges_badge_Entry';
        id?: string | null;
        siteId?: number | null;
        sectionHandle: string;
        title?: string | null;
      }
    | {
        __typename?: 'countries_country_Entry';
        id?: string | null;
        siteId?: number | null;
        sectionHandle: string;
        title?: string | null;
      }
    | {
        __typename?: 'exhibitions_exhibition_Entry';
        id?: string | null;
        siteId?: number | null;
        sectionHandle: string;
        title?: string | null;
      }
    | {
        __typename?: 'goMusSettings_goMusSettings_Entry';
        id?: string | null;
        siteId?: number | null;
        sectionHandle: string;
        title?: string | null;
      }
    | {
        __typename?: 'homePage_homePage_Entry';
        id?: string | null;
        siteId?: number | null;
        sectionHandle: string;
        title?: string | null;
      }
    | {
        __typename?: 'locations_location_Entry';
        id?: string | null;
        siteId?: number | null;
        sectionHandle: string;
        title?: string | null;
      }
    | {
        __typename?: 'museumsOverview_museumsOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        sectionHandle: string;
        title?: string | null;
      }
    | {
        __typename?: 'museums_museum_Entry';
        id?: string | null;
        siteId?: number | null;
        sectionHandle: string;
        title?: string | null;
      }
    | {
        __typename?: 'newsItems_newsItem_Entry';
        id?: string | null;
        siteId?: number | null;
        sectionHandle: string;
        title?: string | null;
      }
    | {
        __typename?: 'offerCategories_offerCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        sectionHandle: string;
        title?: string | null;
      }
    | {
        __typename?: 'offerLanguages_offerLanguage_Entry';
        id?: string | null;
        siteId?: number | null;
        sectionHandle: string;
        title?: string | null;
      }
    | {
        __typename?: 'offersOverview_offersOverview_Entry';
        id?: string | null;
        siteId?: number | null;
        sectionHandle: string;
        title?: string | null;
      }
    | {
        __typename?: 'offers_event_Entry';
        id?: string | null;
        siteId?: number | null;
        sectionHandle: string;
        title?: string | null;
      }
    | {
        __typename?: 'offers_tour_Entry';
        id?: string | null;
        siteId?: number | null;
        sectionHandle: string;
        title?: string | null;
      }
    | {
        __typename?: 'pages_page_Entry';
        id?: string | null;
        siteId?: number | null;
        sectionHandle: string;
        title?: string | null;
      }
    | {
        __typename?: 'proposalCategories_proposalCategory_Entry';
        id?: string | null;
        siteId?: number | null;
        sectionHandle: string;
        title?: string | null;
      }
    | {
        __typename?: 'schoolGrades_schoolGrade_Entry';
        id?: string | null;
        siteId?: number | null;
        sectionHandle: string;
        title?: string | null;
      }
    | {
        __typename?: 'siteInfo_siteInfo_Entry';
        id?: string | null;
        siteId?: number | null;
        sectionHandle: string;
        title?: string | null;
      }
    | {
        __typename?: 'topics_topic_Entry';
        id?: string | null;
        siteId?: number | null;
        sectionHandle: string;
        title?: string | null;
      }
    | null
  >;
};

export const IconFragmentDoc = gql`
  fragment Icon on icon_Icon {
    inline
  }
`;
export const NodeFragmentDoc = gql`
  fragment Node on NodeInterface {
    id
    siteId
    title
    urlSuffix
    classes
    url
    nodeUri
    slug
    newWindow
    type
    ... on iconNavigation_Node {
      icon {
        ...Icon
      }
    }
    customAttributes {
      attribute
      value
    }
  }
  ${IconFragmentDoc}
`;
export const MuseumsOverviewFragmentDoc = gql`
  fragment MuseumsOverview on museumsOverview_museumsOverview_Entry {
    id
    siteId
    title
    heading
    description {
      renderHtml
    }
  }
`;
export const ImageFragmentDoc = gql`
  fragment Image on mediaFiles_Asset {
    id
    url @imagerTransform(width: 500)
    srcset: url @imagerSrcset(handle: "large")
    width
    height
    altText {
      renderHtml @stripTags
    }
    focalPoint
    caption {
      renderHtml
    }
    credits {
      renderHtml
    }
  }
`;
export const NewsItemFragmentDoc = gql`
  fragment NewsItem on newsItems_newsItem_Entry {
    id
    siteId
    title
    postDate
    museum {
      id
      siteId
      title
    }
    label
    image {
      ...Image
    }
    bodyText {
      nodes {
        html
      }
    }
  }
  ${ImageFragmentDoc}
`;
export const LocalizedFragmentDoc = gql`
  fragment Localized on EntryInterface {
    id
    siteId
    language
    url
    title
  }
`;
export const CountryFragmentDoc = gql`
  fragment Country on countries_country_Entry {
    id
    siteId
    title
  }
`;
export const SubtitleFragmentDoc = gql`
  fragment Subtitle on subtitles_subtitle_BlockType {
    id
    label
    lang
    file {
      id
      url
      mimeType
      format
      kind
      title
    }
  }
`;
export const MediaAssetFragmentDoc = gql`
  fragment MediaAsset on mediaFiles_Asset {
    id
    kind
    videoUrl: url
    url @imagerTransform(width: 500)
    srcset: url @imagerSrcset(handle: "large")
    focalPoint
    width
    height
    mimeType
    altText {
      renderHtml @stripTags
    }
    caption {
      renderHtml
    }
    credits {
      renderHtml
    }
    mux {
      playback_id
      error
    }
    subtitles {
      ...Subtitle
    }
  }
  ${SubtitleFragmentDoc}
`;
export const WebLinkFragmentDoc = gql`
  fragment WebLink on Linkit_Link {
    element {
      id
      siteId
      title
    }
    target
    text
    url
    typeHandle
  }
`;
export const HomePageIntroElementFragmentDoc = gql`
  fragment HomePageIntroElement on homepageIntro_element_BlockType {
    mediaAsset {
      ...MediaAsset
    }
    heading
    children {
      ... on homepageIntro_link_BlockType {
        webLink {
          ...WebLink
        }
        anchorId
        label
        linkStyle
      }
    }
  }
  ${MediaAssetFragmentDoc}
  ${WebLinkFragmentDoc}
`;
export const ImageSquareFragmentDoc = gql`
  fragment ImageSquare on mediaFiles_Asset {
    id
    url @imagerTransform(width: 500, height: 500)
    srcset: url @imagerSrcset(handle: "largeSquare")
    width
    height
    altText {
      renderHtml @stripTags
    }
    focalPoint
    caption {
      renderHtml
    }
    credits {
      renderHtml
    }
  }
`;
export const EventPreviewFragmentDoc = gql`
  fragment EventPreview on offers_event_Entry {
    id
    siteId
    typeHandle
    language
    isBookable
    isRegisterable
    isFeatured
    title
    heading
    externalId
    subheading {
      renderHtml
    }
    url
    uri
    slug
    duration
    imageSquare: image {
      ...ImageSquare
    }
    museum {
      id
      siteId
      title
      url
      ... on museums_museum_Entry {
        heading
      }
    }
    exhibition {
      id
      siteId
      title
      url
      ... on exhibitions_exhibition_Entry {
        heading
      }
    }
    offerCategory {
      id
      siteId
      title
      ... on offerCategories_offerCategory_Entry {
        externalId
      }
    }
    topics {
      id
      siteId
      title
      ... on topics_topic_Entry {
        externalId
      }
    }
    accessibilityFeatures {
      ... on accessibilityFeatures_accessibilityFeature_Entry {
        id
        siteId
        title
        icon {
          ...Icon
        }
      }
    }
    badge {
      ... on badges_badge_Entry {
        id
        siteId
        title
        isHighlighted
      }
    }
  }
  ${ImageSquareFragmentDoc}
  ${IconFragmentDoc}
`;
export const TourPreviewFragmentDoc = gql`
  fragment TourPreview on offers_tour_Entry {
    id
    siteId
    typeHandle
    language
    isBookable
    isRegisterable
    isFeatured
    title
    heading
    externalId
    subheading {
      renderHtml
    }
    url
    uri
    slug
    duration
    imageSquare: image {
      ...ImageSquare
    }
    museum {
      id
      siteId
      title
      url
      ... on museums_museum_Entry {
        heading
      }
    }
    exhibition {
      id
      siteId
      title
      url
      ... on exhibitions_exhibition_Entry {
        heading
      }
    }
    offerCategory {
      id
      siteId
      title
      ... on offerCategories_offerCategory_Entry {
        externalId
      }
    }
    topics {
      id
      siteId
      title
      ... on topics_topic_Entry {
        externalId
      }
    }
    accessibilityFeatures {
      ... on accessibilityFeatures_accessibilityFeature_Entry {
        id
        siteId
        title
        icon {
          ...Icon
        }
      }
    }
    badge {
      ... on badges_badge_Entry {
        id
        siteId
        title
        isHighlighted
      }
    }
  }
  ${ImageSquareFragmentDoc}
  ${IconFragmentDoc}
`;
export const HomepageFragmentDoc = gql`
  fragment Homepage on homePage_homePage_Entry {
    id
    siteId
    title
    homepageIntro {
      ...HomePageIntroElement
    }
    heading
    hideHeading
    offers(limit: 3) {
      ...EventPreview
      ...TourPreview
    }
    moreLink {
      ... on moreLink_link_BlockType {
        id
        webLink {
          ...WebLink
        }
        anchorId
        label
        linkStyle
      }
    }
  }
  ${HomePageIntroElementFragmentDoc}
  ${EventPreviewFragmentDoc}
  ${TourPreviewFragmentDoc}
  ${WebLinkFragmentDoc}
`;
export const OverviewLinkFragmentDoc = gql`
  fragment OverviewLink on offersOverview_offersOverview_Entry {
    id
    siteId
    url
    title
    heading
  }
`;
export const LocationFragmentDoc = gql`
  fragment Location on locations_location_Entry {
    id
    siteId
    title
    heading
    streetAddress
    postalCode
    city
    latitude
    longitude
  }
`;
export const MuseumPreviewFragmentDoc = gql`
  fragment MuseumPreview on museums_museum_Entry {
    id
    siteId
    title
    heading
    url
    uri
    slug
    image {
      ...ImageSquare
    }
    accessibilityFeatures {
      ... on accessibilityFeatures_accessibilityFeature_Entry {
        id
        siteId
        title
        icon {
          ...Icon
        }
      }
    }
    locations {
      ...Location
    }
    openingTimes {
      openingTime
      closingTime
      day
    }
    openingTimesExtra {
      days
      openingTime
      closingTime
    }
    storeHours {
      renderHtml
    }
  }
  ${ImageSquareFragmentDoc}
  ${IconFragmentDoc}
  ${LocationFragmentDoc}
`;
export const MuseumFragmentDoc = gql`
  fragment Museum on museums_museum_Entry {
    ...MuseumPreview
    subheading {
      renderHtml
    }
    description {
      renderHtml
    }
    bodyText {
      renderHtml
    }
    purchaseLink {
      ...WebLink
    }
    infoLink {
      ...WebLink
    }
    infoText {
      renderHtml
    }
  }
  ${MuseumPreviewFragmentDoc}
  ${WebLinkFragmentDoc}
`;
export const ExhibitionPreviewFragmentDoc = gql`
  fragment ExhibitionPreview on exhibitions_exhibition_Entry {
    id
    siteId
    title
    heading
    subheading {
      renderHtml
    }
    url
    uri
    slug
    dateSpans {
      ... on dateSpans_dateSpan_BlockType {
        startDateTime
        endDateTime
      }
    }
    museum {
      id
      siteId
      title
      url
      ... on museums_museum_Entry {
        heading
      }
    }
    image {
      ...Image
    }
  }
  ${ImageFragmentDoc}
`;
export const ExhibitionFragmentDoc = gql`
  fragment Exhibition on exhibitions_exhibition_Entry {
    ...ExhibitionPreview
    description {
      renderHtml
    }
    bodyText {
      renderHtml
    }
    locations {
      ...Location
    }
  }
  ${ExhibitionPreviewFragmentDoc}
  ${LocationFragmentDoc}
`;
export const MuseumFilterItemFragmentDoc = gql`
  fragment MuseumFilterItem on museums_museum_Entry {
    id
    siteId
    title
    heading
    shortTitle
    enabled
    image {
      ...ImageSquare
    }
  }
  ${ImageSquareFragmentDoc}
`;
export const TourFragmentDoc = gql`
  fragment Tour on offers_tour_Entry {
    ...TourPreview
    image {
      ...Image
    }
    description {
      renderHtml
    }
    ageGroups {
      id
      siteId
      title
      ... on ageGroups_ageGroup_Entry {
        externalId
        parent {
          id
          siteId
          title
        }
      }
    }
    ageRating
    schoolGrades {
      id
      siteId
      title
      ... on schoolGrades_schoolGrade_Entry {
        externalId
      }
    }
    schoolGradesFormated
    isSchoolOptional
    topics {
      id
      siteId
      title
      ... on topics_topic_Entry {
        externalId
      }
    }
    offerLanguages {
      id
      siteId
      title
      ... on offerLanguages_offerLanguage_Entry {
        externalId
      }
    }
    accessibilityFeatures {
      id
      siteId
      title
      ... on accessibilityFeatures_accessibilityFeature_Entry {
        externalId
        icon {
          ...Icon
        }
      }
    }
    offerPricingInfo {
      ... on offerPricingInfo_offerPricingInfo_BlockType {
        proposalCategory {
          ... on proposalCategories_proposalCategory_Entry {
            id
            siteId
            title
            heading
            externalId
          }
        }
        minPrice
        maxPrice
      }
    }
    minParticipants
    maxParticipants
    bodyText {
      renderHtml
    }
  }
  ${TourPreviewFragmentDoc}
  ${ImageFragmentDoc}
  ${IconFragmentDoc}
`;
export const EventFragmentDoc = gql`
  fragment Event on offers_event_Entry {
    ...EventPreview
    image {
      ...Image
    }
    description {
      renderHtml
    }
    ageGroups {
      id
      siteId
      title
      ... on ageGroups_ageGroup_Entry {
        externalId
        parent {
          id
          siteId
          title
        }
      }
    }
    ageRating
    schoolGrades {
      id
      siteId
      title
      ... on schoolGrades_schoolGrade_Entry {
        externalId
      }
    }
    schoolGradesFormated
    isSchoolOptional
    topics {
      id
      siteId
      title
      ... on topics_topic_Entry {
        externalId
      }
    }
    offerLanguages {
      id
      siteId
      title
      ... on offerLanguages_offerLanguage_Entry {
        externalId
      }
    }
    accessibilityFeatures {
      id
      siteId
      title
      ... on accessibilityFeatures_accessibilityFeature_Entry {
        externalId
        icon {
          ...Icon
        }
      }
    }
    offerDates {
      ... on offerDates_offerDate_BlockType {
        startDateTime
        offerLanguages {
          id
          siteId
          title
        }
        city
        maxParticipants
        maxPerRegistration
        seatsAvailable
        seatsBooked
        externalId
      }
    }
    bodyText {
      renderHtml
    }
  }
  ${EventPreviewFragmentDoc}
  ${ImageFragmentDoc}
  ${IconFragmentDoc}
`;
export const NeoBlockFragmentDoc = gql`
  fragment NeoBlock on NeoBlockInterface {
    id
    siteId
    typeHandle
    sortOrder
  }
`;
export const TopicBlockFragmentDoc = gql`
  fragment TopicBlock on contentBlocks_topic_BlockType {
    heading
    hideHeading
    subheading {
      renderHtml
    }
  }
`;
export const MediaItemBlockFragmentDoc = gql`
  fragment MediaItemBlock on contentBlocks_mediaItem_BlockType {
    mediaAsset {
      ...MediaAsset
    }
  }
  ${MediaAssetFragmentDoc}
`;
export const TextBlockFragmentDoc = gql`
  fragment TextBlock on contentBlocks_textBlock_BlockType {
    bodyText {
      renderHtml
    }
  }
`;
export const TextMediaBlockFragmentDoc = gql`
  fragment TextMediaBlock on contentBlocks_textMediaBlock_BlockType {
    children {
      ...NeoBlock
      ...MediaItemBlock
      ...TextBlock
    }
  }
  ${NeoBlockFragmentDoc}
  ${MediaItemBlockFragmentDoc}
  ${TextBlockFragmentDoc}
`;
export const VideoBlockFragmentDoc = gql`
  fragment VideoBlock on contentBlocks_videoPlayer_BlockType {
    video {
      ...MediaAsset
    }
  }
  ${MediaAssetFragmentDoc}
`;
export const OEmbedFragmentDoc = gql`
  fragment oEmbed on embed_OembedField {
    title
    description
    url
    type
    image
    imageWidth
    imageHeight
    code
    aspectRatio
    providerName
  }
`;
export const OEmbedBlockFragmentDoc = gql`
  fragment oEmbedBlock on contentBlocks_oembed_BlockType {
    uid
    htmlOrLink
    embed {
      ...oEmbed
    }
    heading
    description {
      renderHtml
    }
    code {
      language
      value
    }
  }
  ${OEmbedFragmentDoc}
`;
export const AccordionItemBlockFragmentDoc = gql`
  fragment AccordionItemBlock on contentBlocks_accordionItem_BlockType {
    ...NeoBlock
    heading
    level
    children {
      ...NeoBlock
      ...oEmbedBlock
      ...TextBlock
      ... on contentBlocks_accordion_BlockType {
        level
        children {
          ... on contentBlocks_accordionItem_BlockType {
            heading
            level
            children {
              ...NeoBlock
              ...oEmbedBlock
              ...TextBlock
            }
          }
        }
      }
    }
  }
  ${NeoBlockFragmentDoc}
  ${OEmbedBlockFragmentDoc}
  ${TextBlockFragmentDoc}
`;
export const AccordionBlockFragmentDoc = gql`
  fragment AccordionBlock on contentBlocks_accordion_BlockType {
    children {
      ...AccordionItemBlock
    }
  }
  ${AccordionItemBlockFragmentDoc}
`;
export const SpacerBlockFragmentDoc = gql`
  fragment SpacerBlock on contentBlocks_spacer_BlockType {
    size
  }
`;
export const FilteredCalendarBlockFragmentDoc = gql`
  fragment FilteredCalendarBlock on contentBlocks_filteredCalendar_BlockType {
    offerCategories {
      id
      siteId
      title
      ... on offers_event_Entry {
        externalId
      }
    }
  }
`;
export const FormFieldsFragmentDoc = gql`
  fragment FormFields on FieldInterface {
    id
    handle
    inputTypeName
  }
`;
export const NewsletterSubscribeBlockFragmentDoc = gql`
  fragment NewsletterSubscribeBlock on contentBlocks_newsletterSubscribe_BlockType {
    id
    heading
    bodyText {
      renderHtml
    }
    confirmPrivacy {
      renderHtml
    }
    relatedForm {
      handle
      submissionEndpoint
      submissionMutationName
      formFields {
        ...FormFields
      }
    }
  }
  ${FormFieldsFragmentDoc}
`;
export const CookieInfoBlockFragmentDoc = gql`
  fragment CookieInfoBlock on contentBlocks_cookieInfo_BlockType {
    bodyText {
      renderHtml
    }
  }
`;
export const ContentBlockFragmentDoc = gql`
  fragment ContentBlock on NeoBlockInterface {
    ...NeoBlock
    ...TopicBlock
    ...TextMediaBlock
    ...TextBlock
    ...MediaItemBlock
    ...VideoBlock
    ...oEmbedBlock
    ...AccordionBlock
    ...SpacerBlock
    ...FilteredCalendarBlock
    ...NewsletterSubscribeBlock
    ...CookieInfoBlock
  }
  ${NeoBlockFragmentDoc}
  ${TopicBlockFragmentDoc}
  ${TextMediaBlockFragmentDoc}
  ${TextBlockFragmentDoc}
  ${MediaItemBlockFragmentDoc}
  ${VideoBlockFragmentDoc}
  ${OEmbedBlockFragmentDoc}
  ${AccordionBlockFragmentDoc}
  ${SpacerBlockFragmentDoc}
  ${FilteredCalendarBlockFragmentDoc}
  ${NewsletterSubscribeBlockFragmentDoc}
  ${CookieInfoBlockFragmentDoc}
`;
export const BannerFragmentDoc = gql`
  fragment Banner on banner_banner_BlockType {
    text
    filter {
      id
      siteId
      sectionHandle
      title
    }
  }
`;
export const PageFragmentDoc = gql`
  fragment Page on pages_page_Entry {
    id
    siteId
    title
    heading
    darkMode
    description {
      renderHtml
    }
    image {
      ...Image
    }
    contentBlocks {
      ...ContentBlock
    }
    banner {
      ...Banner
    }
  }
  ${ImageFragmentDoc}
  ${ContentBlockFragmentDoc}
  ${BannerFragmentDoc}
`;
export const TextSnippetsFragmentDoc = gql`
  fragment TextSnippets on textSnippets_GlobalSet {
    confirmPrivacy {
      renderHtml
    }
  }
`;
export const GetTitleDocument = gql`
  query GetTitle($uri: String, $site: String) {
    seomatic(uri: $uri, site: $site, asArray: true) {
      metaTitleContainer
      metaTagContainer
    }
    linkTags: seomatic(uri: $uri, site: $site, asArray: false) {
      metaLinkContainer
      metaJsonLdContainer
      metaScriptContainer
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetTitleGQL extends Apollo.Query<
  GetTitleQuery,
  GetTitleQueryVariables
> {
  override document = GetTitleDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetNavigationDocument = gql`
  query GetNavigation($site: String) {
    iconNavigation: navigationNodes(
      navHandle: "iconNavigation"
      site: [$site]
    ) {
      ...Node
    }
    mainNavigation: navigationNodes(
      navHandle: "mainNavigation"
      site: [$site]
    ) {
      ...Node
    }
    mainSubNavigation: navigationNodes(
      navHandle: "mainSubNavigation"
      site: [$site]
      level: 1
    ) {
      ...Node
      children {
        ...Node
      }
    }
    footerNavigation: navigationNodes(
      navHandle: "footerNavigation"
      site: [$site]
      level: 1
    ) {
      ...Node
      children {
        ...Node
      }
    }
    footerSubNavigation: navigationNodes(
      navHandle: "footerSubNavigation"
      site: [$site]
    ) {
      ...Node
    }
  }
  ${NodeFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetNavigationGQL extends Apollo.Query<
  GetNavigationQuery,
  GetNavigationQueryVariables
> {
  override document = GetNavigationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetExhibitionDocument = gql`
  query GetExhibition($slug: String, $site: String) {
    entry(type: "exhibition", slug: [$slug], site: [$site]) {
      ...Exhibition
      localized(site: "*") {
        ...Localized
      }
    }
    groupOffersEntries: entries(
      type: "tour"
      relatedToEntries: [
        { slug: [$slug] }
        { type: "offerCategory", externalId: 8 }
      ]
      orderBy: "type ASC"
      site: [$site]
    ) {
      ...TourPreview
    }
    offersEntries(
      type: "event"
      relatedToEntries: [{ slug: [$slug] }]
      orderBy: "type ASC"
      site: [$site]
    ) {
      ...EventPreview
    }
    offersOverviewEntries(site: [$site]) {
      ...OverviewLink
    }
  }
  ${ExhibitionFragmentDoc}
  ${LocalizedFragmentDoc}
  ${TourPreviewFragmentDoc}
  ${EventPreviewFragmentDoc}
  ${OverviewLinkFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetExhibitionGQL extends Apollo.Query<
  GetExhibitionQuery,
  GetExhibitionQueryVariables
> {
  override document = GetExhibitionDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetHomepageDocument = gql`
  query GetHomepage($site: String) {
    entry(type: "homePage", site: [$site]) {
      ...Homepage
      localized(site: "*") {
        ...Localized
      }
    }
    newsItemsEntries(site: [$site], orderBy: "postDate desc", limit: 5) {
      ...NewsItem
    }
    offersEntries(
      site: [$site]
      isFeatured: true
      orderBy: "RAND()"
      limit: 3
    ) {
      ...EventPreview
      ...TourPreview
    }
    offersOverviewEntries(site: [$site]) {
      ...OverviewLink
    }
  }
  ${HomepageFragmentDoc}
  ${LocalizedFragmentDoc}
  ${NewsItemFragmentDoc}
  ${EventPreviewFragmentDoc}
  ${TourPreviewFragmentDoc}
  ${OverviewLinkFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetHomepageGQL extends Apollo.Query<
  GetHomepageQuery,
  GetHomepageQueryVariables
> {
  override document = GetHomepageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetMuseumDocument = gql`
  query GetMuseum($slug: String, $site: String) {
    entry(type: "museum", slug: [$slug], site: [$site]) {
      ...Museum
      localized(site: "*") {
        ...Localized
      }
    }
    exhibitionsEntries(
      relatedToEntries: [{ slug: [$slug] }]
      orderBy: "endDateTimeFormatted ASC, title ASC"
      site: [$site]
    ) {
      ...ExhibitionPreview
    }
    groupOffersEntries: entries(
      type: "tour"
      relatedToEntries: [
        { slug: [$slug] }
        { type: "offerCategory", externalId: 8 }
      ]
      orderBy: "type ASC"
      site: [$site]
    ) {
      ...TourPreview
    }
    offersEntries(
      type: "event"
      relatedToEntries: [{ slug: [$slug] }]
      orderBy: "type ASC"
      site: [$site]
    ) {
      ...EventPreview
    }
    offersOverviewEntries(site: [$site]) {
      ...OverviewLink
    }
  }
  ${MuseumFragmentDoc}
  ${LocalizedFragmentDoc}
  ${ExhibitionPreviewFragmentDoc}
  ${TourPreviewFragmentDoc}
  ${EventPreviewFragmentDoc}
  ${OverviewLinkFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetMuseumGQL extends Apollo.Query<
  GetMuseumQuery,
  GetMuseumQueryVariables
> {
  override document = GetMuseumDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetMuseumOverviewDocument = gql`
  query GetMuseumOverview($site: String) {
    entry(type: "museumsOverview", site: [$site]) {
      ...MuseumsOverview
      localized(site: "*") {
        ...Localized
      }
    }
    entries(
      type: "museum"
      uri: ":notempty:"
      site: [$site]
      orderBy: "title ASC"
    ) {
      ...MuseumPreview
    }
    offersOverviewEntries(site: [$site]) {
      ...OverviewLink
    }
  }
  ${MuseumsOverviewFragmentDoc}
  ${LocalizedFragmentDoc}
  ${MuseumPreviewFragmentDoc}
  ${OverviewLinkFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetMuseumOverviewGQL extends Apollo.Query<
  GetMuseumOverviewQuery,
  GetMuseumOverviewQueryVariables
> {
  override document = GetMuseumOverviewDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetOfferDocument = gql`
  query GetOffer($slug: String, $site: String) {
    entry(section: "offers", slug: [$slug], site: [$site]) {
      id
      siteId
      language
      ...Tour
      ...Event
      localized(site: "*") {
        ...Localized
      }
    }
    countriesEntries(site: [$site]) {
      ...Country
    }
    globalSet(handle: "textSnippets", site: [$site]) {
      ...TextSnippets
    }
  }
  ${TourFragmentDoc}
  ${EventFragmentDoc}
  ${LocalizedFragmentDoc}
  ${CountryFragmentDoc}
  ${TextSnippetsFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetOfferGQL extends Apollo.Query<
  GetOfferQuery,
  GetOfferQueryVariables
> {
  override document = GetOfferDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetOfferOverviewDocument = gql`
  query GetOfferOverview($site: String) {
    entry(type: "offersOverview", site: [$site]) {
      id
      siteId
      title
      localized(site: "*") {
        ...Localized
      }
    }
  }
  ${LocalizedFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetOfferOverviewGQL extends Apollo.Query<
  GetOfferOverviewQuery,
  GetOfferOverviewQueryVariables
> {
  override document = GetOfferOverviewDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetFilteredOffersDocument = gql`
  query GetFilteredOffers(
    $site: String
    $relatedTo: [QueryArgument] = []
    $filter: [EntryCriteriaInput] = null
    $filterWithoutMuseum: [EntryCriteriaInput] = null
  ) {
    offersEntries(
      type: "tour"
      uri: ":notempty:"
      site: [$site]
      orderBy: "title ASC"
      relatedTo: $relatedTo
      relatedToEntries: [{ type: "offerCategory", externalId: ["not", 8] }]
    ) {
      ...TourPreview
    }
    allMuseumsEntries: museumsEntries(site: [$site], orderBy: "title ASC") {
      ...MuseumFilterItem
    }
    museumsEntries(
      site: [$site]
      orderBy: "title ASC"
      relatedToEntries: $filterWithoutMuseum
    ) {
      __typename
      ... on museums_museum_Entry {
        id
        siteId
        title
      }
    }
    exhibitionsEntries(site: [$site], relatedToEntries: $filter) {
      __typename
      ... on exhibitions_exhibition_Entry {
        id
        siteId
        title
      }
    }
    ageGroupsEntries(site: [$site], relatedToEntries: $filter, leaves: true) {
      __typename
      ... on ageGroups_ageGroup_Entry {
        id
        siteId
        title
        parent {
          id
          siteId
          title
        }
      }
    }
    topicsEntries(site: [$site], relatedToEntries: $filter) {
      __typename
      ... on topics_topic_Entry {
        id
        siteId
        title
      }
    }
    offerLanguagesEntries(site: [$site], relatedToEntries: $filter) {
      __typename
      ... on offerLanguages_offerLanguage_Entry {
        id
        siteId
        title
      }
    }
    proposalCategoriesEntries(site: [$site], relatedToEntries: $filter) {
      __typename
      ... on proposalCategories_proposalCategory_Entry {
        id
        siteId
        title
      }
    }
    schoolGradesEntries(site: [$site], relatedToEntries: $filter) {
      __typename
      ... on schoolGrades_schoolGrade_Entry {
        id
        siteId
        title
      }
    }
    offerCategoriesEntries(site: [$site], relatedToEntries: $filter) {
      __typename
      ... on offerCategories_offerCategory_Entry {
        id
        siteId
        title
      }
    }
    accessibilityFeaturesEntries(site: [$site], relatedToEntries: $filter) {
      __typename
      ... on accessibilityFeatures_accessibilityFeature_Entry {
        id
        siteId
        title
      }
    }
  }
  ${TourPreviewFragmentDoc}
  ${MuseumFilterItemFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetFilteredOffersGQL extends Apollo.Query<
  GetFilteredOffersQuery,
  GetFilteredOffersQueryVariables
> {
  override document = GetFilteredOffersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetPageDocument = gql`
  query GetPage($uri: String, $prefixedUri: String, $site: String) {
    entry(type: "page", uri: [$uri], site: [$site]) {
      ...Page
      localized(site: "*") {
        ...Localized
      }
    }
    offersOverviewEntries(site: [$site]) {
      ...OverviewLink
    }
    retourResolveRedirect(uri: $prefixedUri, site: $site) {
      redirectSrcUrl
      redirectDestUrl
      redirectHttpCode
    }
  }
  ${PageFragmentDoc}
  ${LocalizedFragmentDoc}
  ${OverviewLinkFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetPageGQL extends Apollo.Query<
  GetPageQuery,
  GetPageQueryVariables
> {
  override document = GetPageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AddressInterface: ['Address'],
    AssetInterface: ['documents_Asset', 'mediaFiles_Asset'],
    ElementInterface: [
      'Address',
      'Element',
      'User',
      'accessibilityFeatures_accessibilityFeature_Entry',
      'ageGroups_ageGroup_Entry',
      'audiences_audience_Entry',
      'badges_badge_Entry',
      'banner_banner_BlockType',
      'contentBlocks_accordionItem_BlockType',
      'contentBlocks_accordion_BlockType',
      'contentBlocks_cookieInfo_BlockType',
      'contentBlocks_filteredCalendar_BlockType',
      'contentBlocks_mediaItem_BlockType',
      'contentBlocks_newsletterSubscribe_BlockType',
      'contentBlocks_oembed_BlockType',
      'contentBlocks_spacer_BlockType',
      'contentBlocks_textBlock_BlockType',
      'contentBlocks_textMediaBlock_BlockType',
      'contentBlocks_topic_BlockType',
      'contentBlocks_videoPlayer_BlockType',
      'countries_country_Entry',
      'dateSpans_dateSpan_BlockType',
      'documents_Asset',
      'exhibitions_exhibition_Entry',
      'footerNavigation_Node',
      'footerSubNavigation_Node',
      'goMusSettings_goMusSettings_Entry',
      'homePage_homePage_Entry',
      'homepageIntro_element_BlockType',
      'homepageIntro_link_BlockType',
      'iconNavigation_Node',
      'locations_location_Entry',
      'mainNavigation_Node',
      'mainSubNavigation_Node',
      'mediaFiles_Asset',
      'moreLink_link_BlockType',
      'moreLinks_link_BlockType',
      'museumsOverview_museumsOverview_Entry',
      'museums_museum_Entry',
      'newsItems_newsItem_Entry',
      'newsletter_Form',
      'newsletter_Submission',
      'offerCategories_offerCategory_Entry',
      'offerDates_offerDate_BlockType',
      'offerLanguages_offerLanguage_Entry',
      'offerPricingInfo_offerPricingInfo_BlockType',
      'offersOverview_offersOverview_Entry',
      'offers_event_Entry',
      'offers_tour_Entry',
      'pages_page_Entry',
      'proposalCategories_proposalCategory_Entry',
      'schoolGrades_schoolGrade_Entry',
      'siteInfo_siteInfo_Entry',
      'subtitles_subtitle_BlockType',
      'textSnippets_GlobalSet',
      'topics_topic_Entry',
    ],
    EntryInterface: [
      'accessibilityFeatures_accessibilityFeature_Entry',
      'ageGroups_ageGroup_Entry',
      'audiences_audience_Entry',
      'badges_badge_Entry',
      'countries_country_Entry',
      'exhibitions_exhibition_Entry',
      'goMusSettings_goMusSettings_Entry',
      'homePage_homePage_Entry',
      'locations_location_Entry',
      'museumsOverview_museumsOverview_Entry',
      'museums_museum_Entry',
      'newsItems_newsItem_Entry',
      'offerCategories_offerCategory_Entry',
      'offerLanguages_offerLanguage_Entry',
      'offersOverview_offersOverview_Entry',
      'offers_event_Entry',
      'offers_tour_Entry',
      'pages_page_Entry',
      'proposalCategories_proposalCategory_Entry',
      'schoolGrades_schoolGrade_Entry',
      'siteInfo_siteInfo_Entry',
      'topics_topic_Entry',
    ],
    FieldInterface: [
      'Field_Address',
      'Field_Agree',
      'Field_Calculations',
      'Field_Checkboxes',
      'Field_Date',
      'Field_Dropdown',
      'Field_Email',
      'Field_Entries',
      'Field_Group',
      'Field_Heading',
      'Field_Hidden',
      'Field_Html',
      'Field_MultiLineText',
      'Field_Name',
      'Field_Number',
      'Field_Password',
      'Field_Payment',
      'Field_Phone',
      'Field_Radio',
      'Field_Recipients',
      'Field_Section',
      'Field_Signature',
      'Field_SingleLineText',
      'Field_Summary',
      'Field_Table',
    ],
    FormInterface: ['newsletter_Form'],
    GlobalSetInterface: ['textSnippets_GlobalSet'],
    ImagerTransformedImageInterface: ['imagerx'],
    MatrixBlockInterface: [
      'banner_banner_BlockType',
      'subtitles_subtitle_BlockType',
    ],
    NeoBlockInterface: [
      'contentBlocks_accordionItem_BlockType',
      'contentBlocks_accordion_BlockType',
      'contentBlocks_cookieInfo_BlockType',
      'contentBlocks_filteredCalendar_BlockType',
      'contentBlocks_mediaItem_BlockType',
      'contentBlocks_newsletterSubscribe_BlockType',
      'contentBlocks_oembed_BlockType',
      'contentBlocks_spacer_BlockType',
      'contentBlocks_textBlock_BlockType',
      'contentBlocks_textMediaBlock_BlockType',
      'contentBlocks_topic_BlockType',
      'contentBlocks_videoPlayer_BlockType',
      'dateSpans_dateSpan_BlockType',
      'homepageIntro_element_BlockType',
      'homepageIntro_link_BlockType',
      'moreLink_link_BlockType',
      'moreLinks_link_BlockType',
      'offerDates_offerDate_BlockType',
      'offerPricingInfo_offerPricingInfo_BlockType',
    ],
    NodeInterface: [
      'footerNavigation_Node',
      'footerSubNavigation_Node',
      'iconNavigation_Node',
      'mainNavigation_Node',
      'mainSubNavigation_Node',
    ],
    PageInterface: ['PageType'],
    PageSettingsInterface: ['PageSettingsType'],
    RetourInterface: ['RetourType'],
    RowInterface: ['RowType'],
    SeomaticInterface: ['SeomaticType'],
    SubmissionInterface: ['newsletter_Submission'],
    UserInterface: ['User'],
    VizyMarkInterface: [],
    VizyNodeInterface: [
      'VizyNode_Blockquote',
      'VizyNode_BulletList',
      'VizyNode_CodeBlock',
      'VizyNode_HardBreak',
      'VizyNode_Heading',
      'VizyNode_HorizontalRule',
      'VizyNode_Iframe',
      'VizyNode_Image',
      'VizyNode_ListItem',
      'VizyNode_MediaEmbed',
      'VizyNode_OrderedList',
      'VizyNode_Paragraph',
      'VizyNode_Table',
      'VizyNode_TableCell',
      'VizyNode_TableHeader',
      'VizyNode_TableRow',
      'VizyNode_Text',
    ],
    accessibilityFeaturesSectionEntryUnion: [
      'accessibilityFeatures_accessibilityFeature_Entry',
    ],
    ageGroupsSectionEntryUnion: ['ageGroups_ageGroup_Entry'],
    audiencesSectionEntryUnion: ['audiences_audience_Entry'],
    badgesSectionEntryUnion: ['badges_badge_Entry'],
    banner_MatrixField: ['banner_banner_BlockType'],
    contentBlocks_NeoField: [
      'contentBlocks_accordionItem_BlockType',
      'contentBlocks_accordion_BlockType',
      'contentBlocks_cookieInfo_BlockType',
      'contentBlocks_filteredCalendar_BlockType',
      'contentBlocks_mediaItem_BlockType',
      'contentBlocks_newsletterSubscribe_BlockType',
      'contentBlocks_oembed_BlockType',
      'contentBlocks_spacer_BlockType',
      'contentBlocks_textBlock_BlockType',
      'contentBlocks_textMediaBlock_BlockType',
      'contentBlocks_topic_BlockType',
      'contentBlocks_videoPlayer_BlockType',
    ],
    countriesSectionEntryUnion: ['countries_country_Entry'],
    dateSpans_NeoField: ['dateSpans_dateSpan_BlockType'],
    exhibitionsSectionEntryUnion: ['exhibitions_exhibition_Entry'],
    goMusSettingsSectionEntryUnion: ['goMusSettings_goMusSettings_Entry'],
    homePageSectionEntryUnion: ['homePage_homePage_Entry'],
    homepageIntro_NeoField: [
      'homepageIntro_element_BlockType',
      'homepageIntro_link_BlockType',
    ],
    locationsSectionEntryUnion: ['locations_location_Entry'],
    moreLink_NeoField: ['moreLink_link_BlockType'],
    museumsOverviewSectionEntryUnion: ['museumsOverview_museumsOverview_Entry'],
    museumsSectionEntryUnion: ['museums_museum_Entry'],
    newsItemsSectionEntryUnion: ['newsItems_newsItem_Entry'],
    offerCategoriesSectionEntryUnion: ['offerCategories_offerCategory_Entry'],
    offerDates_NeoField: ['offerDates_offerDate_BlockType'],
    offerLanguagesSectionEntryUnion: ['offerLanguages_offerLanguage_Entry'],
    offerPricingInfo_NeoField: ['offerPricingInfo_offerPricingInfo_BlockType'],
    offersOverviewSectionEntryUnion: ['offersOverview_offersOverview_Entry'],
    offersSectionEntryUnion: ['offers_event_Entry', 'offers_tour_Entry'],
    pagesSectionEntryUnion: ['pages_page_Entry'],
    proposalCategoriesSectionEntryUnion: [
      'proposalCategories_proposalCategory_Entry',
    ],
    schoolGradesSectionEntryUnion: ['schoolGrades_schoolGrade_Entry'],
    siteInfoSectionEntryUnion: ['siteInfo_siteInfo_Entry'],
    subtitles_MatrixField: ['subtitles_subtitle_BlockType'],
    topicsSectionEntryUnion: ['topics_topic_Entry'],
  },
};
export default result;
